import {
  FORM_STRUCTURE,
  FORM_STRUCTURE_DRAGGED,
  FORM_STRUCTURE_UPDATE,
} from "../constants/constants";

const initialState = [];

export const getFormStructureReducers = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case FORM_STRUCTURE:
      return [...state, { ...payload }];
    case FORM_STRUCTURE_UPDATE:
      return payload;

    default:
      return state;
  }
};
