import React, { useEffect, useState } from "react";

import TextInput from "../../common/TextInput/index";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import "./styles.scss";
import { Box, Typography, Button, CircularProgress } from "@mui/material";
import CustomSelect from "../../common/Select";
// formik and yup for validation
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import PhoneFeild from "../../common/phoneFeild";
import { useDispatch, useSelector } from "react-redux";
import {
  createMember,
  updateMember,
  request_more,
} from "../../../store/actions/recruiterMembers";

const RequestMember = ({ setOpen2, open2 }) => {
  const [selectValue, setSelectValue] = useState("Member");
  const auth = useSelector((state) => state?.auth);
  // console.log("auth", auth);

  const dispatch = useDispatch();

  const initialValues = {};

  const SignupSchema = Yup.object().shape({
    request_member_count: Yup.string().required("Required"),
  });

  const onSubmit = async (values, { resetForm }) => {
    const local_data = {
      ...values,
      workspace_id: auth?.results?.workspace_id,
      uuid: auth?.results?.uuid,
    };

    dispatch(request_more(local_data)).then(() => {
      setOpen2(false);
    });
  };

  return (
    <Box className="interviewParrent">
      <Typography variant="h4"> Request Extra Team Member</Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={SignupSchema}
        onSubmit={onSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box className={"box-parent-textInput"}>
              <TextInput
                label="How many extra members you want to add?"
                type="number"
                name="request_member_count"
                placeholder="Enter Members"
                customClass={"inputInterview"}
                onChange={handleChange}
                // onBlur={handleBlur}

                value={values.request_member_count}
              />
              <span>{errors.request_member_count}</span>
            </Box>

            <Box className={"box-parent-textInput"}>
              <TextInput
                type="text"
                name="request_reason"
                label={<>Request Reason</>}
                placeholder="Request Reason"
                value={values.reason}
                customClass={"inputInterview"}
                textarea={true}
                onChange={handleChange}
              />
            </Box>

            <Box className="button-primary buttoninterview">
              <Button onClick={handleSubmit}>Save</Button>
              <Button onClick={() => setOpen2(false)} className="secondary-btn">
                Cancel
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default RequestMember;
