import React from "react";
import Main from "../../components/license_order_detail/index";
const LicenseOrderDetail = () => {
  return (
    <div>
      <Main />
    </div>
  );
};

export default LicenseOrderDetail;
