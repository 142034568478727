import React, { useEffect, useRef, useState } from "react";
import { Box } from "@mui/system";
import Actions from "./Actions";
import style from "./style.module.scss";
import { useSelector } from "react-redux";
import shortid from "shortid";
import $ from "jquery";
import useDragField from "../../../../customHook/useDragField";
function CheckboxField({
  id,
  main_id,
  type,
  label,
  name,
  SelectField,
  two_col,
  col_name,
  index,
  moveCard,
}) {
  var nameArr = SelectField?.split(",");
  const ref = useRef(null);
  const dragField = useDragField(ref, index, moveCard, id);
  const opacity = dragField.opacity;
  const getpreviewform = useSelector((state) => state?.getpreviewform);
  const handleupload = async (value) => {};
  return (
    <Box
      ref={ref}
      style={{ ...style, opacity }}
      data-handler-id={dragField?.handlerId}
      className={`${style.inputBox} dropItem`}
    >
      <Actions
        type={type}
        id={id}
        two_col={two_col}
        col_name={col_name}
        main_id={main_id}
      />
      <Box className={style.checkboxgroup}>
        <label>{label}</label>
        {two_col === 1
          ? getpreviewform.map((item) => {
              {
                return item?.type[col_name]?.data?.map((subitem) => {
                  if (subitem.id === id) {
                    return subitem.type.options.map((subitemop) => {
                      return (
                        <Box className={style.customCheckBox}>
                          <label className="labelContainer">
                            {subitemop.label}
                            {nameArr?.map((singlename) => {
                              {
                                return subitemop.label === singlename ? (
                                  <input
                                    type="checkbox"
                                    name={name}
                                    checked
                                    value={subitemop.label}
                                  />
                                ) : (
                                  <input
                                    type="checkbox"
                                    name={name}
                                    value={subitemop.label}
                                  />
                                );
                              }
                            })}
                            {nameArr === "" || nameArr === undefined ? (
                              <input
                                type="checkbox"
                                name={name}
                                value={subitemop.label}
                              />
                            ) : (
                              ""
                            )}

                            <span className="checkbox"></span>
                          </label>
                        </Box>
                      );
                    });
                  }
                });
              }
            })
          : getpreviewform.map((item) => {
              if (item.id === id) {
                {
                  return item.type.options.map((subitem) => {
                    return (
                      <Box className={style.customCheckBox}>
                        <label className="labelContainer">
                          {subitem.label}
                          {nameArr?.map((singlename) => {
                            {
                              return subitem.label === singlename ? (
                                <input
                                  type="checkbox"
                                  name={name}
                                  checked
                                  value={subitem.label}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  name={name}
                                  value={subitem.label}
                                />
                              );
                            }
                          })}
                          {nameArr === "" || nameArr === undefined ? (
                            <input
                              type="checkbox"
                              name={name}
                              value={subitem.label}
                            />
                          ) : (
                            ""
                          )}

                          <span className="checkbox"></span>
                        </label>
                      </Box>
                    );
                  });
                }
              }
            })}
      </Box>
    </Box>
  );
}

export default CheckboxField;
