import { Box, Typography, Button } from "@mui/material";
import React from "react";
import "./styles.scss";
import { Formik } from "formik";
import TextInput from "../../common/TextInput";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { allocateCredits } from "../../../store/actions/recruiterMembers";

function AssignTextPack({ setOpen, open, item }) {
  const auth = useSelector((state) => state?.auth);
  const member = useSelector((state) => state?.member);
  const dispatch = useDispatch();
  const allocate_current_count =
    member?.allocatedData?.results?.recruiter?.current_count;

  const initialValues = {
    allocate_count: member?.allocatedData?.results?.member?.current_count || "",
  };
  const onSubmit = async (values, { resetForm }) => {
    const local_data = {
      ...values,
      workspace_id: auth?.results?.workspace_id,
      recruiter_id: auth?.results?.recruiter_id,
      qoute: "aa1a1",
    };
    // const local_data2 = {
    //   ...values,
    //   uuid: member?.editedMember?.results?.uuid,
    //   country_code: country_code.trim() === "" ? "AU" : country_code,
    // };
    // console.log(local_data2);
    // if (member?.editedMember?.results) {
    //   dispatch(updateMember(local_data2, auth?.results?.workspace_id)).then(
    //     () => {
    //       setOpen(false);
    //     }
    //   );
    // } else {
    //   dispatch(createMember(local_data, auth?.results?.workspace_id));
    //   !open && resetForm();
    // }

    dispatch(allocateCredits(item?.uuid, local_data)).then(() => {
      setOpen(false);
    });
    !open && resetForm();
  };

  const SignupSchema = Yup.object().shape({
    // allocate_count: Yup.string()
    //   .min(2, "Too Short!")
    //   .max(50, "Too Long!")
    //   .required("Required"),
  });

  return (
    <Box className="assignCredits">
      <Typography variant="h4">Assign Text Packs</Typography>
      Your Text Message Pack Balance: {allocate_current_count}
      <Formik
        initialValues={initialValues}
        validationSchema={SignupSchema}
        onSubmit={onSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box width="100%" className={"box-parent-textInput"}>
              <TextInput
                type="text"
                label={"Assign text message packs to this member"}
                name="allocate_count"
                placeholder="Assign Text Packs"
                customClass={"inputInterview"}
                onChange={handleChange}
                // onBlur={handleBlur}

                value={values.allocate_count}
              />
              <span>
                {errors.allocate_count &&
                  touched.allocate_count &&
                  errors.allocate_count}
              </span>
            </Box>

            <Box className="button-primary buttoninterview">
              <Button onClick={handleSubmit} disabled={member?.loading}>
                {member?.loading ? (
                  <p style={{ color: "white" }}>Loading...</p>
                ) : (
                  // : member?.editedMember?.results ? (
                  //   "Assign"
                  // )
                  "Assign"
                )}
              </Button>
              <Button onClick={() => setOpen(false)} className="secondary-btn">
                Cancel
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
}

export default AssignTextPack;
