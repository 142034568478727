import React, { useState } from "react";

import TextInput from "../../common/TextInput/index";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import "./styles.scss";
import {
  Box,
  Typography,
  Button
} from "@mui/material";
import CustomSelect from "../../common/Select";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { ShowAlert } from "../../../store/actions/alertActions";
import {
  createSingleInterviewFunc,
  resetSingleCandidateReff,
  updateSingleInterviewFunc,
} from "../../../store/actions/singleCandidateScreenActions";
import { ReactComponent as Add } from "../../../assets/interview/addInterview.svg";
import { ReactComponent as Close } from "../../../assets/interview/close.svg";
import { useEffect } from "react";
import { getExisitingCandidateFunc } from "../../../store/actions/recruiterCandidateActions";
import ImageUploader from "../../common/ImageUploader";

const InterviewPopup = ({ opne, setOpen }) => {
  const auth = useSelector((state) => state?.auth);
  const candidate = useSelector((state) => state?.rcandidate);
  const singleCandidate = useSelector((state) => state?.singleCandidate);

  // const [selectValue, setSelectValue] = useState(0);
  const [startDate, setStartDate] = useState(
    singleCandidate?.editInterView?.results?.date
      ? new Date(singleCandidate?.editInterView?.results?.date)
      : new Date()
  );

  // const candidateID = JSON.parse(localStorage.getItem("candidateID"));
  const [edit1, setEdit1] = useState(false);
  const [file, setFile] = useState(null);
  const getExisitCandi = candidate?.getExisitingCandidate?.results?.map(
    (data) => ({
      label: data?.text,
      value: data?.id,
    })
  );
  const dispatch = useDispatch();
  const options = [
    { value: "Office", label: "Office" },
    { value: "Online", label: "Online" },
  ];
  const generateTimeOptions = () => {
    const times = [];
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 15) {
        const formattedHour = hour.toString().padStart(2, "0");
        const formattedMinute = minute.toString().padStart(2, "0");
        const formattedSecond = "00";
        times.push(`${formattedHour}:${formattedMinute}:${formattedSecond}`);
      }
    }
    return times;
  };
  const timeOptions = generateTimeOptions();
  const mapTags =
    singleCandidate?.editInterView?.results?.candidate_interview_invitation?.map(
      (e) => (e?.length === 0 ? [] : { email: e?.email })
    );

  const [inputList, setInputList] = useState(
    mapTags?.length > 0 ? mapTags : [{ email: "" }]
  );

  const [values, setValues] = useState({
    title: singleCandidate?.editInterView?.results?.title || "",
    emails: "",
    tags: [candidate?.showCandidate?.results?.email] || mapTags,
    description: singleCandidate?.editInterView?.results?.description || "",

    time: singleCandidate?.editInterView?.results?.start_time
      ? singleCandidate?.editInterView?.results?.start_time
      : "",
    time2: singleCandidate?.editInterView?.results?.end_time
      ? singleCandidate?.editInterView?.results?.end_time
      : "",

    type: {
      value: singleCandidate?.editInterView?.results?.type,
      label: singleCandidate?.editInterView?.results?.type,
    } || { value: "", label: "" },
    topic: singleCandidate?.editInterView?.results?.topic || "",
    address: singleCandidate?.editInterView?.results?.address || "",
    selectEmail: {label: singleCandidate?.editInterView?.results?.candidate?.email || "", value: singleCandidate?.editInterView?.results?.candidate?.id || -1},
  });

  function ValidateEmail(mail) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
      return true;
    }
  }

  const [Customerror, setErrors] = useState({});
  const validate = (data) => {
    const newError = {};
    for (let key in data) {
      let value = data[key];
      switch (key) {
        case "title":
          if (value.length === 0 || value.toString() === "")
            newError[key] = "fields is Required";
          break;
        case "description":
          if (value.length === 0 || value.toString() === "")
            newError[key] = "fields is Required";
          break;
        case "time":
          if (value.length === 0 || value.toString() === "")
            newError[key] = "fields is Required";
          // else if (new Date().getTime() <= new Date().getTime(values?.time))
          //   newError[key] = "Please select correct date";
          break;
        case "time2":
          if (value.length === 0 || value.toString() === "")
            newError[key] = "fields is Required";
          // else if (new Date().getTime() <= new Date().getTime(values?.time))
          //   newError[key] = "Please select correct date";
          break;

        case "type":
          if (value.length === 0 || value.toString() === "")
            newError[key] = "fields is Required";
          break;
        case "emails":
          if (!ValidateEmail(value))
            newError[key] = "Please add email in correct format";
          else if (values?.tags?.includes(value))
            newError[key] = "Email already in tags";
          break;
        default:
      }
    }
    return newError;
  };

  const onChange = (e) => {
    const { name, value } = e?.target;
    if (Customerror.hasOwnProperty(name)) {
      delete Customerror[name];
      setErrors(Customerror);
    }

    setValues((preVal) => {
      return {
        ...preVal,

        [name]: value,
      };
    });
  };

  // console.log(values?.tags);
  const submitData = async (e) => {
    e.preventDefault();
    const validateSelect = validate(values);
    if (values.emails === "") {
      delete validateSelect["emails"];
    }
    if (Object?.keys(validateSelect)?.length > 0) {
      dispatch(ShowAlert("Please fill all fields", "error"));

      setErrors(validateSelect);
      return;
    }

    let objectCreate = {};

    const local_data = {
      ...values,
      email_invitations: JSON.stringify(objectCreate),
      email: values?.email?.label,
      type: values?.type?.value.toLowerCase(),
      address: values?.address,
      date: moment(startDate).format("DD-MM-YYYY"),

      recruiter_id: auth?.results?.recruiter_id,
      workspace_id: auth?.results?.workspace_id,
      candidate_id: values?.selectEmail.value,
      start_time: values?.time.split(":").slice(0, 2).join(":"),
      end_time: values?.time2.split(":").slice(0, 2).join(":"),
      duration: "60",
      host_video: "1",
      agenda: "testsetset",
      participant_video: "1",
      front_image: file,
    };

    let formData = new FormData();

    for (const key in local_data) {
      formData.append([key], local_data[key]);
    }

    const local_data2 = {
      ...values,
      uuid: singleCandidate?.editInterView?.results?.uuid,
      type: values?.type?.value.toLowerCase(),
      email_invitations: objectCreate,
      address: values?.address,

      date: moment(startDate).format("DD-MM-YYYY"),
      recruiter_id: auth?.results?.recruiter_id,
      workspace_id: auth?.results?.workspace_id,
      candidate_id: singleCandidate?.editInterView?.results?.candidate?.id,
      // start_time: startDate,
      start_time: values?.time.split(":").slice(0, 2).join(":"),
      end_time: values?.time2.split(":").slice(0, 2).join(":"),
      duration: "60",
      host_video: "1",
      agenda: "testsetset",
      participant_video: "1",
      zoom_id: "82629994706",
      host_id: "FQVcUJkIQ1iNYCmcnvjumA",
      front_image: file,
    };

    if (singleCandidate?.editInterView?.success === true) {
      dispatch(
        updateSingleInterviewFunc(local_data2, {
          workspace_id: auth?.results?.workspace_id,
        })
      );
    } else {
      dispatch(
        createSingleInterviewFunc(formData, {
          workspace_id: auth?.results?.workspace_id,
        })
      );
    }
  };
  useEffect(() => {
    if (singleCandidate?.createInterView?.data?.success === true) {
      setOpen(false);
      dispatch(resetSingleCandidateReff());
    }
  }, [singleCandidate?.createInterView?.data?.success]);

  useEffect(() => {
    if (singleCandidate?.updateInterView?.success === true) {
      setOpen(false);
      dispatch(resetSingleCandidateReff());
    }
  }, [singleCandidate?.updateInterView?.success]);

  // handle input change
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };

  // handle click event of the Remove button
  const handleRemoveClick = (e, index) => {
    e?.preventDefault();
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  // handle click event of the Add button
  const handleAddClick = (e) => {
    setInputList([...inputList, { email: "" }]);
    e?.preventDefault();
  };

  console.log("values", values);

  return (
    <Box className="interviewParrent">
      <Typography variant="h3">Schedule Interview</Typography>
      <form onSubmit={submitData}>
        <Box className="date-picker">
          <label>
            Date
            <span style={{ color: "red" }}>*</span>
          </label>
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            placeholderText="Date *"
            minDate={moment().toDate()}
            dateFormat="dd/MM/yyyy"
          />
        </Box>
        <Box className={"timebox"}>
          <label htmlFor="">
            Start Time<span style={{ color: "red" }}>*</span>
          </label>
          <select
            id="time"
            name="time"
            value={values.time || "default"}
            onChange={onChange}
            className="inputInterview"
            required
          >
            <option value="default" disabled hidden>
              Select a time
            </option>
            {timeOptions.map((time) => (
              <option key={time} value={time}>
                {time}
              </option>
            ))}
          </select>

          <label htmlFor="" style={{ marginTop: "20px" }}>
            End Time<span style={{ color: "red" }}>*</span>
          </label>
          <select
            id="time2"
            name="time2"
            value={values.time2 || "default"}
            onChange={onChange}
            className="inputInterview"
            required
          >
            <option value="default" disabled hidden>
              Select a time
            </option>
            {timeOptions.map((time) => (
              <option key={time} value={time}>
                {time}
              </option>
            ))}
          </select>
        </Box>

        <CustomSelect
          options={options}
          customClass={"interviewSelect"}
          placeholder="Location *"
          label={
            <>
              Location&nbsp;
              <span style={{ color: "red" }}>*</span>
            </>
          }
          name="type"
          value={values.type?.value ? values?.type : null}
          onChange={(e) => {
            setValues({ ...values, type: e });
            if (Customerror.hasOwnProperty("type")) {
              delete Customerror["type"];
              setErrors(Customerror);
            }
          }}
        />
        {values?.type?.value === "Office" && (
          <TextInput
            type="text"
            name="address"
            label="Address"
            value={values?.address}
            onChange={onChange}
            placeholder="20-40 Meagher St, Sydney NSW 2008, Australia"
            customClass={"inputInterview"}
          />
        )}
        <CustomSelect
          options={getExisitCandi}
          customClass={"interviewSelect"}
          name="email"
          isDisabled={singleCandidate?.editInterView?.results}
          label={
            <>
              Email&nbsp;
              <span style={{ color: "red" }}>*</span>
              <span
                style={{
                  color: "red",
                  marginLeft: "3px",
                  position: "relative",
                  display: "initial",
                }}
              ></span>
            </>
          }
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
          }}
          onFocus={() => {
            dispatch(
              getExisitingCandidateFunc({
                request_type: "getinterview_candidate",
              })
            );
          }}
          onChange={(e, action) => {
            setValues({
              ...values,
              selectEmail: e,
            });

            if (Customerror.hasOwnProperty("email")) {
              delete Customerror["email"];
              setErrors(Customerror);
            }
          }}
          placeholder={"Enter your email"}
          value={values.selectEmail}
        />
        <TextInput
          type="text"
          name="title"
          label={
            <>
              Subject&nbsp;
              <span style={{ color: "red" }}>*</span>
            </>
          }
          value={values.title}
          placeholder="Subject *"
          customClass={"inputInterview"}
          onChange={onChange}
        />
        <TextInput
          type="text"
          name="description"
          label={
            <>
              Description&nbsp;
              <span style={{ color: "red" }}>*</span>
            </>
          }
          placeholder="Description *"
          value={values.description}
          customClass={"inputInterview"}
          textarea={true}
          onChange={onChange}
        />

        <Box>
          <ImageUploader
            label={
              <>
                <span>Upload Resume</span>
              </>
            }
            valuenew={file}
            edit={edit1}
            accept={".pdf, .docx"}
            onChange={(e) => {
              setEdit1(false);
              setFile(e.target.files[0]);
            }}
            name="front_image"
          />
        </Box>

        <>
          {inputList?.map((e, i) => (
              (values.selectEmail.label !== e?.email) && 
            (<Box className="parrentCandidateCloseInvite" key={i}>
              <TextInput
                type="text"
                key={i}
                name="email"
                label=" Attendees"
                value={e?.email}
                placeholder="Enter Attendees Email"
                customClass={"inputInterview"}
                onChange={(e) => handleInputChange(e, i)}
              />
              <Close
                className="candidateCloseInvite"
                onClick={(e) => handleRemoveClick(e, i)}
              />
            </Box>)
          ))}
          <Box className={"addEmailCandidate"}>
            <Add onClick={(e) => handleAddClick(e)} />
            <Typography>Add Optional Attendees</Typography>
          </Box>
        </>

        <Box className="button-primary buttoninterview">
          <Button onClick={submitData}>
            {singleCandidate?.loading ? (
              <p style={{ color: "white" }}>Loading...</p>
            ) : singleCandidate?.editInterView?.success ? (
              " Update"
            ) : (
              " Send Invite"
            )}
          </Button>
          <Button
            className="secondary-btn"
            onClick={() => {
              setOpen(false);
              dispatch(resetSingleCandidateReff());
            }}
          >
            Cancel
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default InterviewPopup;
