import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { ReactComponent as Cross } from "../../../../assets/industries/model/x.svg";
import Modal from "@mui/material/Modal";
import styles from "./stylesUpload.module.scss";
import { Button } from "@mui/material";
import Alerts from "../../../common/Alert";
import SignatureCanvas from "react-signature-canvas";
import { useDispatch } from "react-redux";
import { uploadsign } from "../../../../store/actions/uploadsign";

function SignatureModel({
  openModal,
  setOpenModal,
  signImage,
  setSignImage,
  id,
  name,
}) {
  let dispatch = useDispatch();
  const handleClose = () => setOpenModal(false);
  const sigRef = useRef();
  const [signature, setSignature] = useState(null);
  const handleSignatureEnd = () => {
    setSignature(sigRef.current.toDataURL());
  };
  const clearSignature = () => {
    sigRef.current.clear();
    setSignature(null);
    setSignImage(null);
  };

  const handleSubmit = () => {
    dispatch(uploadsign({ name: name, value: signature }));
    setSignImage(signature);
    setOpenModal(false);
  };

  return (
    <>
      <Alerts />
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={`${styles.industryModel}`}
      >
        <Box className={`${styles.boxModel} xym1`}>
          <Box className={styles.modalwapper}>
            <Box
              className={styles.cross}
              onClick={handleClose}
              style={{ display: "flex", top: "5px", right: "5px" }}
            >
              <Cross />
            </Box>
            <Typography variant="h5" className={styles.heading}>
              Draw your Signature
            </Typography>
            <Box className={styles.statusbox}>
              <Box className={styles.offermodelDetail}>
                <SignatureCanvas
                  penColor="black"
                  canvasProps={{ className: "signature" }}
                  ref={sigRef}
                  onEnd={handleSignatureEnd}
                />
              </Box>
              <Box
                className={`${styles.button} button-primary d-flex justify-end`}
              >
                <Box className={`${styles.button} w-50 d-flex justify-end`}>
                  <Button className="mr-2" onClick={handleSubmit}>
                    Submit
                  </Button>
                  <Button className="secondary-btn" onClick={clearSignature}>
                    Clear
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
}

export default SignatureModel;
