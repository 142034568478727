import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import React, { useState } from 'react';

import { Box, Button, Grid } from "@mui/material";

import TextInput from "../../common/TextInput";
import styles from "./styles.module.scss";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { verifySingleCandidateCertificate } from '../../../store/actions/singleCandidateScreenActions';
import { PAYMENT_SENT, PAYMENT_START } from '../../../store/constants/constants';

export default function CheckoutForm({
  data,
  setOpen,
}){
  const stripe = useStripe();
  const elements = useElements();

  const auth = useSelector((state) => state?.auth);
  const dispatch = useDispatch();
  const payment = useSelector((state) => state?.pricing);
  const candidate = useSelector((state) => state?.rcandidate);
  const [Customerror, setErrors] = useState({});
  const [paymentId, setPaymentId] = useState('');

  const initialState = {
    name: "",
  };
  const checkAlpha = (value) => {
    return /^[A-Za-z\s]*$/.test(value);
  };

  const onChange = (e) => {
    const { name, value } = e?.target;

    if (name === "name") {
      if ((checkAlpha(value) && value?.trim().length > 0) || value == "") {
        setValues({
          ...values,
          [name]: value,
        });
      }
    }
    else {
      setValues({
        ...values,
        [name]: value,
      });
    }

    if (Customerror.hasOwnProperty(name)) {
      delete Customerror[name];
      setErrors(Customerror);
    }
  };
  const [values, setValues] = useState(initialState);
  const validate = (data) => {
    const newError = {};
    for (let key in data) {
      let value = data[key];
      switch (key) {
        case "name":
          if (value.length === 0 || value.toString() === "")
            newError[key] = "Field is required";
          break;
        default:
          break;
      }
    }
    return newError;
  };

  const submitData = async (e) => {
    e.preventDefault();

    const validateSelect = validate(values);
    console.log("validateSelect", validateSelect);
    
    if (Object?.keys(validateSelect)?.length > 0) {
      setErrors(validateSelect);
      return;
    }

    if (!stripe || !elements) {
      return;
    }
  
    const card = elements.getElement(CardElement);
  
    if (card == null) {
      return;
    }
      dispatch({
        type: PAYMENT_START,
      });
    // Use your card Element with other Stripe.js APIs
    const {error, paymentMethod} = await stripe.createPaymentMethod({
      type: 'card',
      card,
      billing_details: {
        name: values?.name,
      },
    });
  
    if (error) {
      console.log('[error] submit data', error);
      return;
    } else {
      setPaymentId(paymentMethod.id);
    }
  


    const local_data = {
      // ...data,
      name: values?.name,
      uuid: data?.uuid,
      terms: values?.terms,
      plan_name: values?.name,
      payment_id: paymentMethod.id,
    };
    console.log("local_data", local_data);
    
    dispatch(
      verifySingleCandidateCertificate(local_data, {
        workspace_id: auth?.results?.workspace_id,
        candidate_id: candidate?.showCandidate?.results?.id,
      })
    ).then(() => {
      setOpen(false);
      dispatch({
        type: PAYMENT_SENT,
      });
    });
  };
  const cardElementOptions = {
    style: {
        base: {
            fontSize: '16px',
            color: '#32325d',
            '::placeholder': {
                color: '#aab7c4',
            },
        },
        invalid: {
            color: '#fa755a',
        },
    },
  };

  return (

    <form onSubmit={submitData}>
    <Grid item md={12} xs={12} lg={12}>
      <Box className={styles.parentBox}>
        <TextInput
          compulsory={true}
          customClass={styles.input}
          label={"Name"}
          placeholder={"name"}
          name="name"
          value={values?.name}
          onChange={onChange}
        />
        {Customerror?.name && (
          <p className="error-class position-absolute">
            {Customerror?.name}
          </p>
        )}
      </Box>
    </Grid>
    <Grid item md={12} xs={12} lg={12}>
        <Box className={styles.parentBox} style={{
            marginTop: "2rem",
            background: "#FFFFFF",
            border: "1px solid #afafaf",
            borderRadius: "3px",
            width: "100%",
            position: "relative",
            minHeight: "41px",
            fontFamily: "poppins-regular",
            fontStyle: "normal",
            fontSize: "12px",
            lineHeight: "18px",
            padding: "10px 16px",
            letterSpacing: "0.025em",
          }}>
            <CardElement options={cardElementOptions}/>
        </Box>
      </Grid>
  <Box className={`button-primary ${styles.button}`}>
    <Button onClick={submitData} disabled={payment?.isLoading}>
      {payment?.isLoading ? (
        <p style={{ color: "white" }}>Loading...</p>
      ) : (
        "Send"
      )}
    </Button>
  </Box>
</form>
  );
};