import axios from "axios";
import authHeader from "../../api/authToken";
import {
  GET_ALL_BLOGS_HOMEPAGE,
  GET_ALL_BLOGS_HOMEPAGE_START,
  GET_ALL_BLOGS_HOMEPAGE_FAIL,
  SINGLE_BLOG,
  SINGLE_BLOG_START,
  SINGLE_BLOG_FAIL,
  LOADING_TRUE,
  LOADING_FALSE,
  RESET_MODAL,
} from "../constants/constants";
import { ShowAlert } from "./alertActions";

export const getHomeBlogs = (body) => async (dispatch) => {
  try {
    dispatch({
      type: LOADING_TRUE,
    });

    const data = JSON?.stringify(body);
    dispatch({
      type: GET_ALL_BLOGS_HOMEPAGE_START,
    });
    const request = await axios.post(
      `${process.env.REACT_APP_API_URL}/resources`,
      body,
      { headers: authHeader() }
    );
    const results = await request.data;

    dispatch({
      type: GET_ALL_BLOGS_HOMEPAGE,
      payload: results,
    });
    dispatch({
      type: LOADING_FALSE,
    });
  } catch (e) {
    dispatch({
      type: GET_ALL_BLOGS_HOMEPAGE_FAIL,
    });

    dispatch({
      type: LOADING_FALSE,
    });
  }
};

export const getSingleBlog = (uuid) => async (dispatch) => {
  try {
    dispatch({
      type: LOADING_TRUE,
    });

    // const data = JSON?.stringify(body);
    dispatch({
      type: SINGLE_BLOG_START,
    });
    const request = await axios.post(
      `${process.env.REACT_APP_API_URL}/resource-detail/${uuid}`,
      {},
      { headers: authHeader() }
    );
    const results = await request.data;

    dispatch({
      type: SINGLE_BLOG,
      payload: results,
    });
    dispatch({
      type: LOADING_FALSE,
    });
  } catch (e) {
    dispatch({
      type: SINGLE_BLOG_FAIL,
    });

    dispatch({
      type: LOADING_FALSE,
    });
  }
};
