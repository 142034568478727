import { Box } from "@mui/system";
import React, { useRef } from "react";
import style from "./style.module.scss";
import Actions from "./Actions";
import useDragField from "../../../../customHook/useDragField";

function VerticalTableField({
  id,
  main_id,
  type,
  header,
  table,
  two_col,
  col_name,
  index,
  moveCard,
}) {
  const ref = useRef(null);
  const dragField = useDragField(ref, index, moveCard, id);
  const opacity = dragField.opacity;
  // console.log("table", table);
  // console.log("header", header);
  return (
    <Box
      ref={ref}
      style={{ ...style, opacity }}
      data-handler-id={dragField?.handlerId}
      className={`${style.inputBox} dropItem`}
    >
      <Actions
        type={type}
        id={id}
        two_col={two_col}
        col_name={col_name}
        main_id={main_id}
      />

      <table>
        {header?.data.map((item, i) => {
          return (
            <tr>
              <th>{item.name}</th>
              {table[i].map((item, j) => {
                return <td>{item}</td>;
              })}
            </tr>
          );
        })}
      </table>
    </Box>
  );
}

export default VerticalTableField;
