import { STORE_COMPONENT_ID } from "../constants/constants";

const initialState = [];

export const getcomponentidReducers = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case STORE_COMPONENT_ID:
      return [...state, payload];
    default:
      return state;
  }
};
