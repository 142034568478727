import React, { useState, useEffect } from "react";
import TextInput from "../../common/TextInput";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import { Button, Grid } from "@mui/material";
import { ReactComponent as Dustin } from "../../../assets/workspace/dustbin.svg";
import { ReactComponent as AddIcon } from "../../../assets/candidates/add.svg";
import PhoneInput from "react-phone-number-input";
import styles from "./styles.module.scss";

const MultiPersons = ({ formValues, setFormValues }) => {
  let handleChange = (i, e) => {
    let newFormValues = [...formValues];
    newFormValues[i][e.target.name] = e.target.value;
    setFormValues(newFormValues);
  };

  let handlePhoneChange = (i, value) => {
    let newFormValues = [...formValues];
    newFormValues[i]["contact_number"] = value;
    newFormValues[i]["country_code"] = "AU";
    setFormValues(newFormValues);
  };

  let addFormFields = () => {
    setFormValues([...formValues, { to_email: "", contact_number: "" }]);
  };

  let removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
  };

  return (
    <form>
      {/* <Box className="m-5">
        <Divider />
      </Box> */}

      {formValues.map((element, index) => (
        <Grid container alignItems="flex-end" spacing={0}>
          <Grid item lg={4} xs={12} className="mb-2">
            <TextInput
              type="text"
              name="to_email"
              placeholder="Enter email address"
              label={index === 0 ? "Email Address*" : "Email Address"}
              value={element.to_email || ""}
              onChange={(e) => handleChange(index, e)}
            />
          </Grid>
          <Grid
            item
            lg={4}
            xs={12}
            className={`${styles.parentInput} phone-parrent`}
            style={{ margin: "0 !important" }}
          >
            <label>Contact Number</label>
            <PhoneInput
              name="contact_number"
              className={styles.input}
              placeholder="614 234 6782"
              country="AU"
              defaultCountry="AU"
              label={index === 0 ? "Contact Number*" : "Contact Number"}
              value={element.contact_number || ""}
              onChange={(value) => handlePhoneChange(index, value)}
            />
          </Grid>
          <Grid
            item
            lg={3}
            xs={12}
            // className={`${styles.w_20}`}
            // style={{
            //   marginLeft: !index && "10px",
            // }}
          >
            {index ? (
              <Button
                type="button"
                className="w-100 mt-3"
                onClick={() => removeFormFields(index)}
                style={{ border: "1px solid #f34123" }}
              >
                <span
                  style={{
                    fontFamily: "poppins-regular",
                    color: "#f34123",
                  }}
                >
                  Remove
                </span>
              </Button>
            ) : (
              <Button
                className="w-100 mt-3"
                type="button"
                onClick={() => addFormFields()}
                style={{ border: "1px solid #00CFC5" }}
              >
                <span
                  style={{
                    fontFamily: "poppins-regular",
                    color: "#00CFC5",
                  }}
                >
                  Add More
                </span>
              </Button>
            )}
          </Grid>
        </Grid>
      ))}
    </form>
  );
};

export default MultiPersons;
