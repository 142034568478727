import { STORE_SUBSCRIPTION } from "../constants/constants";

const initialStoreValue = false;

const subScriptionReducer = (state = initialStoreValue, { type, payload }) => {
  switch (type) {
    case STORE_SUBSCRIPTION:
      state = payload;
      return state;

    default:
      return state;
  }
};

export default subScriptionReducer;
