import React from "react";
import { Outlet } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { routes } from "./routes";

function NotRecruiterCheckRole() {
  const token = JSON.parse(localStorage.getItem("token"));
  const userType = JSON.parse(localStorage.getItem("user"));

  let user = false;
  if (userType !== "recruitment") {
    user = true;
  }
  return user ? <Outlet /> : <Navigate to={routes.LOGIN} replace />;
}

export default NotRecruiterCheckRole;
