import { Box } from "@mui/material";
import React from "react";
import Select from "react-select";
import styles from "./styles.module.scss";

const CountrySelect = ({
  compolsory,
  label,
  field,
  form,
  options,
  customClass,
  placeholder,
  ...props
}) => {
  var country_list = [
    "afghanistan",
    "åland islands",
    "albania",
    "algeria",
    "american samoa",
    "andorra",
    "angola",
    "anguilla",
    "antarctica",
    "antigua and barbuda",
    "argentina",
    "armenia",
    "aruba",
    "australia",
    "austria",
    "azerbaijan",
    "bahamas",
    "bahrain",
    "bangladesh",
    "barbados",
    "belarus",
    "belgium",
    "belize",
    "benin",
    "bermuda",
    "bhutan",
    "bolivia",
    "bosnia and herzegovina",
    "botswana",
    "bouvet island",
    "brazil",
    "british indian ocean territory",
    "brunei darussalam",
    "bulgaria",
    "burkina faso",
    "burundi",
    "cambodia",
    "cameroon",
    "canada",
    "cape verde",
    "cayman islands",
    "central african republic",
    "chad",
    "chile",
    "china",
    "christmas island",
    "cocos (keeling) islands",
    "colombia",
    "comoros",
    "congo",
    "congo, the democratic republic of the congo",
    "cook islands",
    "costa rica",
    "cote d'ivoire",
    "croatia",
    "cuba",
    "cyprus",
    "czech republic",
    "denmark",
    "djibouti",
    "dominica",
    "dominican republic",
    "ecuador",
    "egypt",
    "el salvador",
    "equatorial guinea",
    "eritrea",
    "estonia",
    "ethiopia",
    "falkland islands (malvinas)",
    "faroe islands",
    "fiji",
    "finland",
    "france",
    "french guiana",
    "french polynesia",
    "french southern territories",
    "gabon",
    "gambia",
    "georgia",
    "germany",
    "ghana",
    "gibraltar",
    "greece",
    "greenland",
    "grenada",
    "guadeloupe",
    "guam",
    "guatemala",
    "guinea",
    "guinea-bissau",
    "guyana",
    "haiti",
    "heard island and mcdonald islands",
    "holy see (vatican city state)",
    "honduras",
    "hong kong",
    "hungary",
    "iceland",
    "india",
    "indonesia",
    "iran, islamic republic of iran",
    "iraq",
    "ireland",
    "israel",
    "italy",
    "jamaica",
    "japan",
    "jordan",
    "kazakhstan",
    "kenya",
    "kiribati",
    "korea, democratic people's republic of korea",
    "korea, republic of korea",
    "kuwait",
    "kyrgyzstan",
    "lao people's democratic republic",
    "latvia",
    "lebanon",
    "lesotho",
    "liberia",
    "libyan arab jamahiriya",
    "liechtenstein",
    "lithuania",
    "luxembourg",
    "macao",
    "macedonia, the former yugoslav republic of macedonia",
    "madagascar",
    "malawi",
    "malaysia",
    "maldives",
    "mali",
    "malta",
    "marshall islands",
    "martinique",
    "mauritania",
    "mauritius",
    "mayotte",
    "mexico",
    "micronesia, federated states of",
    "moldova, republic of moldova",
    "monaco",
    "mongolia",
    "montenegro",
    "montserrat",
    "morocco",
    "mozambique",
    "myanmar",
    "namibia",
    "nauru",
    "nepal",
    "netherlands",
    "netherlands antilles",
    "new caledonia",
    "new zealand",
    "nicaragua",
    "niger",
    "nigeria",
    "niue",
    "norfolk island",
    "northern mariana islands",
    "norway",
    "oman",
    "pakistan",
    "palau",
    "palestinian territory, occupied",
    "panama",
    "papua new guinea",
    "paraguay",
    "peru",
    "philippines",
    "pitcairn",
    "poland",
    "portugal",
    "puerto rico",
    "qatar",
    "reunion",
    "romania",
    "russian federation",
    "rwanda",
    "saint barthélemy",
    "saint helena",
    "saint kitts and nevis",
    "saint lucia",
    "saint martin (french part)",
    "saint pierre and miquelon",
    "saint vincent and the grenadines",
    "samoa",
    "san marino",
    "sao tome and principe",
    "saudi arabia",
    "senegal",
    "serbia",
    "seychelles",
    "sierra leone",
    "singapore",
    "slovakia",
    "slovenia",
    "solomon islands",
    "somalia",
    "south africa",
    "south georgia and the south sandwich islands",
    "spain",
    "sri lanka",
    "sudan",
    "suriname",
    "svalbard and jan mayen",
    "swaziland",
    "sweden",
    "switzerland",
    "syrian arab republic",
    "taiwan, province of china",
    "tajikistan",
    "tanzania, united republic of tanzania",
    "thailand",
    "timor-leste",
    "togo",
    "tokelau",
    "tonga",
    "trinidad and tobago",
    "tunisia",
    "turkey",
    "turkmenistan",
    "turks and caicos islands",
    "tuvalu",
    "uganda",
    "ukraine",
    "united arab emirates",
    "united kingdom",
    "united states",
    "united states minor outlying islands",
    "uruguay",
    "uzbekistan",
    "vanuatu",
    "venezuela",
    "viet nam",
    "virgin islands, british",
    "virgin islands, u.s.",
    "western sahara",
    "yemen",
    "zambia",
    "zimbabwe",
  ];

  const countryListOptions = country_list.map((country) => {
    return {
      value: country,
      label: country.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()),
    };
  });
  const customStyles = {
    control: (provided) => ({
      ...provided,
      minHeight: "41px",
      border: "1px solid #afafaf",
    }),
    menu: (provided) => ({
      ...provided,
      minHeight: "41px",
      border: "1px solid #afafaf",
    }),
  };

  return (
    <Box
      className={`${styles.formInput} ${customClass ? customClass : " "} `}
      {...props}
    >
      <label
        style={{
          fontFamily: "poppins-light",
          fontStyle: "normal",
          fontSize: "15px",
          lineHeight: "22px",
          display: "block",
          letterSpacing: "0.025em",
          color: "#000000",
          marginBottom: "4px",
        }}
      >
        {label}
        {compolsory && (
          <span
            style={{
              color: "red",
              textDecoration: "none",
              position: "relative",
              display: "initial",
            }}
          >
            *
          </span>
        )}
      </label>
      <Select
        styles={customStyles}
        options={countryListOptions}
        name={field.name}
        placeholder={placeholder}
        value={
          countryListOptions
            ? countryListOptions.find((option) => option.value === field.value)
            : ""
        }
        onChange={(option) => form.setFieldValue(field.name, option.value)}
        onBlur={field.onBlur}
      />
    </Box>
  );
};

export default CountrySelect;
