import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { ReactComponent as Cross } from "../../../../../assets/industries/model/x.svg";
import { ReactComponent as UploadIcon } from "../../../../../assets/candidates/uploadfile.svg";
import Modal from "@mui/material/Modal";
import styles from "./stylesUpload.module.scss";
import { Button, CircularProgress } from "@mui/material";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ShowAlert } from "../../../../../store/actions/alertActions";
import Alerts from "../../../../common/Alert";
import { useNavigate } from "react-router-dom";
import { mirrorBubblesend } from "../../../../../api/formBuilder/formBuilder.class";

export default function CustomModal({ openModal, setOpenModal, olddata }) {
  const handleClose = () => setOpenModal(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);
  let { candidateid } = useParams();
  const [loader, setLoader] = useState(0);

  const handleSubmit = async () => {
    setLoader(1);
    const api = async () => {
      try {
        const payload = {
          recruiter_id: auth.results.recruiter_id,
          workspace_id: auth.results.workspace_id,
          candidate_id: candidateid,
          form_category_name: "Job Offer Letter",
          form_builder_category_id: 1,
        };

        const response = await mirrorBubblesend(payload);

        if (response?.data?.success == true) {
          dispatch(ShowAlert(response?.data?.message, "success"));
          setOpenModal(false);
          setLoader(0);
        } else {
          dispatch(ShowAlert(response?.data?.message, "error"));
          setOpenModal(false);
          setLoader(0);
        }
      } catch (error) {
        console.log(error);
      }
    };
    api();
  };

  return (
    <>
      <Alerts />
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={`${styles.industryModel}`}
      >
        <Box className={`${styles.boxModel} xym1`}>
          <Box className={styles.modalwapper}>
            <Box className={styles.cross} onClick={handleClose}>
              <Cross />
            </Box>
            <Typography variant="h4" className={styles.heading}>
              Send Letter
            </Typography>
            <Box className={styles.statusbox}>
              <Box className={styles.offermodelDetail}>
                <Typography component="p" className={styles.textHelp}>
                  You are about to send the current letter of offer to{" "}
                  {olddata?.results?.form_data?.candidate_name}. Please review
                  the pdf file before sending.
                </Typography>
              </Box>
              <Box className={styles.offermodelDetail}>
                <p>
                  Last updated: <span>{olddata?.results?.updated_date}</span>
                </p>
              </Box>

              <Box className={styles.offermodelDetail}>
                <p>
                  Link:{" "}
                  <a
                    target="_blank"
                    href={`${process.env.REACT_APP_URL}${olddata?.results?.pdf_url}`}
                  >
                    Job Offer Letter of{" "}
                    {olddata?.results?.form_data?.candidate_name}
                  </a>
                </p>
              </Box>
              <Box className={`${styles.button} button-primary d-flex`}>
                <div className="d-flex">
                  <Button
                    onClick={() => {
                      handleSubmit(true);
                    }}
                  >
                    {loader === 1 ? (
                      <p style={{ color: "white" }}>Loading...</p>
                    ) : (
                      "Send Letter"
                    )}
                  </Button>

                  {/* <Button>
                    <CircularProgress variant="solid" thickness={2} />
                  </Button> */}
                </div>
                <div>
                  <Button className="secondary-btn" onClick={handleClose}>
                    Cancel
                  </Button>
                </div>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
