import React from "react";
import { Box } from "@mui/system";
import style from "../FormComponents/style.module.scss";
import { useSelector } from "react-redux";
function Item({ indexCount, setindexCount }) {
  const getlabel = useSelector((state) => state?.getlabelkey);
  const getlabelvalue = useSelector((state) => state?.getlabelvalue);
  return (
    <Box className={style.customCheckBox}>
      <label className="labelContainer">
        <p>{getlabel[indexCount - 1]?.value}</p>
        <input
          type="checkbox"
          name="checkbox-group"
          value={getlabelvalue[indexCount - 1]?.value}
        />
        <span className="checkbox"></span>
      </label>
    </Box>
  );
}

export default Item;
