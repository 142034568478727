import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { ReactComponent as Cross } from "../../../../../assets/industries/model/x.svg";
import Modal from "@mui/material/Modal";
import styles from "./styles.module.scss";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Alerts from "../../../../common/Alert";
import { Logout } from "../../../../../store/actions/authActions";
import { routes } from "../../../../../routes";
import { useNavigate } from "react-router-dom";

export default function CustomModal({ openModal, setOpenModal }) {
  const handleClose = () => setOpenModal(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const candidateId = useSelector((state) => state.auth.results.id);

  const logout = () => {
    dispatch(Logout()).then(() => {
      navigate(routes.LOGIN);
    });
  };

  return (
    <>
      <Alerts />
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={`${styles.industryModel}`}
      >
        <Box className={`${styles.boxModel} xym1`}>
          <Box className={styles.modalwapper}>
            <Box className={styles.cross} onClick={handleClose}>
              <Cross />
            </Box>
            <Typography variant="h4" className={styles.heading}>
              Are you sure want to logout?
            </Typography>

            <Box
              className={`${styles.button} button-primary d-flex justify-content-center mw-100`}
            >
              <Button onClick={logout} className="mr-2" type="submit">
                Yes
              </Button>
              <Button className="secondary-btn" onClick={handleClose}>
                No
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
