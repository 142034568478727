import EndPoints from "../EndPoints";
import axios from "../axiosConfig";

export const createForm = async (payload) => {
  try {
    return await axios.post(EndPoints.createForm, payload);
  } catch (error) {
    return { success: false, message: error.message };
  }
};

export const mirrorBubblesend = async (payload) => {
  try {
    return await axios.post(EndPoints.mirrorBubblesend, payload);
  } catch (error) {
    return { success: false, message: error.message };
  }
};

export const generatePdf = async (payload) => {
  try {
    return await axios.post(EndPoints.generatePdf, payload);
  } catch (error) {
    return { success: false, message: error.message };
  }
};

export const getBubbleFormData = async (payload) => {
  try {
    return await axios.post(EndPoints.getGeneratedFormData, payload);
  } catch (error) {
    return { success: false, message: error.message };
  }
};

export const rejectBubbleForm = async (payload) => {
  try {
    return await axios.post(
      `${EndPoints.rejectBubbleForm}/${payload.denyToken}`,
      payload
    );
  } catch (error) {
    return { success: false, message: error.message };
  }
};

export const acceptBubbleForm = async (payload) => {
  try {
    return await axios.post(
      `${EndPoints.acceptBubbleForm}/${payload.acceptToken}`,
      payload
    );
  } catch (error) {
    return { success: false, message: error.message };
  }
};
