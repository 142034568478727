import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { routes } from "./routes";

const ProtectedRoute = ({ children }) => {
  const auth = useSelector((state) => state.auth);
  const token = JSON.parse(localStorage.getItem("token"));
  const userType = JSON.parse(localStorage.getItem("user"));
  useEffect(() => {}, [token, userType]);
  let isLogin = true;
  if (!token && !userType) {
    isLogin = false;
  }

  return isLogin ? <Outlet /> : <Navigate to={routes.LOGIN} replace />;
};

export default ProtectedRoute;
