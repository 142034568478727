import React, { useRef, useState } from "react";
import { Box } from "@mui/system";
import Actions from "./Actions";
import style from "./style.module.scss";
import { Typography } from "@mui/material";
import useDragField from "../../../../customHook/useDragField";

function HeadingField({
  id,
  main_id,
  value,
  type,
  field_style,
  two_col,
  col_name,
  index,
  moveCard,
}) {
  const ref = useRef(null);
  const dragField = useDragField(ref, index, moveCard, id);
  const opacity = dragField.opacity;
  return (
    <Box
      ref={ref}
      style={{ ...style, opacity }}
      data-handler-id={dragField?.handlerId}
      className={`${style.inputBox} dropItem`}
    >
      <Actions
        type={type}
        id={id}
        two_col={two_col}
        col_name={col_name}
        main_id={main_id}
      />
      <Typography
        component={field_style.heading_tag}
        style={{
          fontSize: `${field_style.font_size}px`,
          color: `${field_style.text_color}`,
          fontWeight: `${field_style.font_weight}`,
        }}
      >
        {value}
      </Typography>
    </Box>
  );
}

export default HeadingField;
