import React from "react";
import Interview from "../../components/candidate_screens/interview/index";

const InterviewScreen = () => {
  return (
    <>
      <Interview />
    </>
  );
};

export default InterviewScreen;
