import React, {
  useCallback,
  useEffect,
  useState,
  useLayoutEffect,
} from "react";
import { a11yProps, TabPanel } from "../../common/mui-tabs";
import FormsTabel from "../e_doc_tabel/EDocTable";
import { Box, Button, Tab, Tabs, Typography } from "@mui/material";
import styles from "./styles.module.scss";
import { AiOutlinePlus } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import {
  EDocListing,
  EDocTemplateListing,
  subScriptionStatus,
} from "../../../api/sinner/SinnerApi";
import { routes } from "../../../routes";
import { useDispatch, useSelector } from "react-redux";
import { ShowAlert } from "../../../store/actions/alertActions";
import { subscriptionAction } from "../../../store/actions/eDocAction";
import {
  LOADING_FALSE,
  LOADING_TRUE,
} from "../../../store/constants/constants";

// import SEO from "../../meta-tags";
const DocumetPage = () => {
  const navigate = useNavigate();
  const [value, setValue] = useState(0);
  const [formData, setFormData] = useState([]);
  const [update, setUpdate] = useState(false);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);

  const dispatch = useDispatch();
  const userData = JSON.parse(localStorage.getItem("auth"))?.results || {};
  const userType = JSON.parse(localStorage.getItem("user"));

  const subsScriptionStatus = useSelector(
    (state) => state.eDocSubscriptionData
  );

  {
    //check subscription API
    const subsCriptionAPICall = useCallback(async () => {
      try {
        const query = `recruiter_id=${
          userData?.recruiter_id || ""
        }&workspace_id=${userData?.workspace_id || ""}`;
        const response = await subScriptionStatus(query);
        if (response?.data?.code === 200) {
          const data = response?.data?.results;
          if (
            (!data?.is_trial_active && !data?.subscription) ||
            (!data?.subscription &&
              data?.is_trial_active &&
              data?.total_completed_signed === 1)
          ) {
            dispatch(subscriptionAction(false));
          } else if (
            !data?.subscription &&
            data?.is_trial_active &&
            data?.total_completed_signed === 0
          ) {
            dispatch(subscriptionAction(false));
          } else {
            dispatch(subscriptionAction(true));
          }
        } else {
          dispatch(ShowAlert("Something went wrong..!!", "error"));
        }
      } catch (error) {
        console.error(error);
        dispatch(ShowAlert("Something went wrong..!!", "error"));
      }
    }, [dispatch]);

    useLayoutEffect(() => {
      if (userType === "recruitment") {
        subsCriptionAPICall();
      }
    }, [subsCriptionAPICall, userType]);
  }

  {
    //get template list api
    const getTemplateDocumentList = useCallback(async () => {
      dispatch({
        type: LOADING_TRUE,
      });
      setFormData([]);
      const query = `recruiter_id=${
        userData?.recruiter_id || ""
      }&workspace_id=${userData?.workspace_id || ""}&page=${page + 1}`;
      try {
        const response = await EDocTemplateListing(query);
        if (response?.data?.code === 200) {
          setFormData(response?.data?.results?.data || []);
          setTotal(response?.data?.results?.total);
        } else {
          dispatch(ShowAlert(response?.data?.message, "error"));
        }
      } catch (error) {
        console.error(error);
        dispatch(ShowAlert("Something went wrong...!!", "error"));
      } finally {
        dispatch({
          type: LOADING_FALSE,
        });
      }
    }, [
      dispatch,
      page,
      userData?.recruiter_id,
      userData?.workspace_id,
      update,
    ]);
    //List API call and definition
    const getDocumentList = useCallback(async () => {
      dispatch({
        type: LOADING_TRUE,
      });
      setFormData([]);
      const query = `recruiter_id=${
        userData?.recruiter_id || ""
      }&workspace_id=${userData?.workspace_id || ""}&doc_builder_status=${
        value === 0 ? 1 : value === 1 ? 0 : ""
      }&page=${page + 1}&candidate_id=${userData?.candidate_id || ""}&isDraft=${
        value === 2 ? 1 : ""
      }`;
      try {
        const response = await EDocListing(query);
        if (response?.data?.code === 200) {
          setFormData(response?.data?.results?.data || []);
          setTotal(response?.data?.results?.total);
        } else {
          dispatch(ShowAlert(response?.data?.message, "error"));
        }
      } catch (error) {
        console.error(error);
        dispatch(ShowAlert("Something went wrong...!!", "error"));
      } finally {
        dispatch({
          type: LOADING_FALSE,
        });
      }
    }, [
      dispatch,
      page,
      userData?.candidate_id,
      userData?.recruiter_id,
      userData?.workspace_id,
      value,
      update,
    ]);

    useEffect(() => {
      if (value !== 3) {
        getDocumentList();
      } else {
        getTemplateDocumentList();
      }
    }, [getDocumentList, getTemplateDocumentList, update, value]);
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setPage(0);
  };

  return (
    <Box className={styles.parent}>
      <Box className={styles.head}>
        <Typography variant="h4">Documents</Typography>
        {subsScriptionStatus && (
          <Box className={styles.buttonGroup}>
            <Box className={`button-preimary ${styles.button}`}>
              <Button
                // className={"secondary-btn"}
                onClick={() => navigate(`${routes.DOCUMENT_SIGN.UPLOAD_PDF}`)}
              >
                <AiOutlinePlus /> Create
              </Button>
            </Box>
          </Box>
        )}
      </Box>
      <Box>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
          allowScrollButtonsMobile
          className={styles.buttonTabs}
        >
          <Tab label="All Documents" {...a11yProps(0)} />
          <Tab label="Archived Documents" {...a11yProps(1)} />
          <Tab label="Drafted Documents" {...a11yProps(2)} />
          <Tab label="Template Documents" {...a11yProps(3)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <FormsTabel
          titles={[
            "Document Title",
            "Category Name",
            "Sign Status",
            "Status",
            // "Resend Document",
            "Actions",
          ]}
          rows={formData}
          setUpdate={setUpdate}
          type="All Document"
          page={page}
          setPage={setPage}
          total={total}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <FormsTabel
          titles={[
            "Document Title",
            "Category Name",
            "Sign Status",
            "Status",
            "Actions",
          ]}
          rows={formData}
          setUpdate={setUpdate}
          type="deactive"
          page={page}
          setPage={setPage}
          total={total}
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <FormsTabel
          titles={["Document Title", "Category Name", "Actions"]}
          rows={formData}
          setUpdate={setUpdate}
          type="Draft"
          page={page}
          setPage={setPage}
          total={total}
        />
      </TabPanel>
      {/* {formData?.length > 5 && (
        <TablePagination
          rowsPerPageOptions={[]}
          component='Box'
          count={formData?.length}
          rowsPerPage={15}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )} */}
      {/* <PopupForm open={open} setOpen={setOpen}>
          <CreateForm />
        </PopupForm> */}
      <TabPanel value={value} index={3}>
        <FormsTabel
          titles={["Template Title", "Category Name", "Actions"]}
          rows={formData}
          setUpdate={setUpdate}
          type="template"
          page={page}
          setPage={setPage}
          total={total}
        />
      </TabPanel>
    </Box>
  );
};

export default DocumetPage;
