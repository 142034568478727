import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { ReactComponent as Cross } from "../../../../../assets/industries/model/x.svg";
import Modal from "@mui/material/Modal";
import styles from "./stylesUpload.module.scss";
import { Button } from "@mui/material";
import Alerts from "../../../../common/Alert";

export default function CustomModal({ openModal, setOpenModal, olddata }) {
  const handleClose = () => setOpenModal(false);
  return (
    <>
      <Alerts />
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={`${styles.industryModel}`}
      >
        <Box className={`${styles.boxModel} xym1`}>
          <Box className={styles.modalwapper}>
            <Box className={styles.cross} onClick={handleClose}>
              <Cross />
            </Box>
            <Typography variant="h5" className={styles.heading}>
              Reason for job offer rejection
            </Typography>
            <Box className={styles.statusbox}>
              <Box className={styles.offermodelDetail}>
                <Typography component="p" className={styles.textHelp}>
                  {olddata}
                </Typography>
              </Box>
              <Box
                className={`${styles.button} button-primary d-flex justify-end`}
              >
                <Button className="secondary-btn" onClick={handleClose}>
                  Close
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
