import React, { useEffect } from "react";
import styles from "./styles.module.scss";
import { Box, Typography, Grid, Button, CircularProgress } from "@mui/material";
import TextInput from "../../common/TextInput";
import CustomSelect from "../../common/Select";
import { useState } from "react";
import { ShowAlert } from "../../../store/actions/alertActions";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { uploadFilecontact } from "../../../store/actions/profileActions";

const ContactUs = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state?.auth);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("");
  const options2 = [
    { value: "Customer Support", label: "Customer Support" },
    { value: "Sales Support", label: "Sales Support" },
    { value: "Technical Support", label: "Technical Support" },
    { value: " Billing Support    ", label: " Billing Support    " },
    { value: "Feedback", label: "Quality Feedback" },
  ];

  const options1 = [
    { value: "Business Enquiry", label: "Business Enquiry" },
    { value: "Trouble Shooting Enquiry", label: "Trouble Shooting Enquiry" },
  ];
  const [fileName, setFileName] = useState();
  const [open, setOpen] = useState(false);
  const alertType = useSelector((state) => state.alert);
  const [Customerror, setErrors] = useState({});

  const [values, setValues] = useState({
    name: `${auth?.results?.first_name} ${auth?.results?.last_name}`,
    subject: "",
    message: "",
    documents: "",
    enquiry_type: "",
  });
  const onChange = (e) => {
    const { name, value } = e?.target;
    setValues({
      ...values,
      [name]: value,
    });
    if (Customerror.hasOwnProperty(name)) {
      delete Customerror[name];
      setErrors(Customerror);
    }
  };

  const filecontact = (e) => {
    if (!e.target.files[0]) return;
    if (e.target?.files[0]?.size <= "10000000") {
      setValues({
        ...values,
        documents: e?.target?.files[0],
      });
      let name = e.target?.files[0]?.name;
      setFileName(name);
    } else {
      dispatch(ShowAlert("Upload file max size is 10MB.", "error"));
    }
  };
  useEffect(() => {
    if (alertType[0]?.alertType === "success") {
      setOpen(true);
      setLoading(false);
      setValues({
        name: `${auth?.results?.first_name} ${auth?.results?.last_name}`,
        subject: "",
        message: "",
        enquiry_type: "",
        documents: "",
      });
    } else if (alertType[0]?.alertType === "error") {
      setLoading(false);
    }
  }, [alertType]);

  const validate = (data) => {
    const newError = {};
    for (let key in data) {
      let value = data[key];
      switch (key) {
        case "subject":
          if (value.length === 0 || value.toString() === "")
            newError[key] = "Subject field is required";
          break;
        case "enquiry_type":
          if (value.length === 0 || value.toString() === "")
            newError[key] = "Enquiry type field is Required";
          break;
        case "message":
          if (
            data.subject &&
            data.enquiry_type &&
            (value.length === 0 || value.toString() === "")
          )
            newError[key] = "Message field is Required";
          break;

        default:
      }
      // console.log("value", value);
    }
    return newError;
  };
  // console.log("values", values);

  const submitData = async (e) => {
    setLoading(true);
    e.preventDefault();
    const validateSelect = validate(values);
    // console.log("validateSelect", validateSelect);

    if (Object?.keys(validateSelect)?.length > 0) {
      Object.keys(validateSelect).forEach((key) => {
        dispatch(ShowAlert(validateSelect[key], "error")); // Dispatch alert for each error
      });
      // dispatch(ShowAlert("Subject is required", "error"));
      setErrors(validateSelect);
      setLoading(false);
      return;
    }

    const local_data = {
      ...values,
      subject: values?.subject?.value,
      enquiry_type: values?.enquiry_type?.value,
      email: auth?.results?.email,
      user_id: auth?.results?.recruiter_id,
    };

    let formData = new FormData();
    for (const key in local_data) {
      formData.append([key], local_data?.[key]);
    }

    try {
      const request = await axios.post(
        `${process.env.REACT_APP_API_URL}/contact-form`,
        formData
      );
      const response = await request.data;

      if (response.success) {
        dispatch(ShowAlert("Form submited", "success"));
      } else {
        dispatch(ShowAlert("Error", "error"));
      }
      setValues({
        name: `${auth?.results?.first_name} ${auth?.results?.last_name}`,
        subject: "",
        message: "",
        enquiry_type: "",
        documents: "",
      });
      setLoading(false);
    } catch (e) {
      dispatch(ShowAlert("Error", "error"));

      setLoading(false);
      setValues({
        name: `${auth?.results?.first_name} ${auth?.results?.last_name}`,
        subject: "",
        message: "",
        enquiry_type: "",
        documents: "",
      });
    }
  };

  return (
    <Box className={styles.parent}>
      <Box className={styles.header}>
        <Typography variant="h4">Help Desk</Typography>
      </Box>
      <Box className={styles.contactUs}>
        <Box className={styles.contactUsHeader}>
          <Typography variant="h5">Contact Us</Typography>
          <Typography>
            Our friendly team would love to hear from you!
          </Typography>
        </Box>
        <Box className={styles.contactUsContent}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={8}>
              <form onSubmit={(e) => submitData(e)}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <TextInput
                      autoComplete={"off"}
                      type="text"
                      name="name"
                      label={
                        <>
                          Name&nbsp;
                          <span style={{ color: "red" }}>*</span>
                        </>
                      }
                      disabled={true}
                      // placeholder={`${auth?.results?.first_name} ${auth?.results?.last_name}`}
                      value={`${auth?.results?.first_name} ${auth?.results?.last_name}`}
                      onChange={onChange}
                      customClass={styles.contactusInp}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <CustomSelect
                      options={options2}
                      label={
                        <>
                          Subject&nbsp;
                          <span style={{ color: "red" }}>*</span>
                        </>
                      }
                      name="subject"
                      values={values?.subject}
                      customClass={`${styles.select} jobSalaryy`}
                      onChange={(e) => {
                        setValues({ ...values, subject: e });
                        if (Customerror.hasOwnProperty("subject")) {
                          delete Customerror["subject"];
                          setErrors(Customerror);
                        }
                      }}
                    />
                    {/* <TextInput
                    autoComplete={'off'}
                  placeholder={""}
                  type="text"
                  name="subject"
                  label="Subject"
                  customClass={styles.contactusInp}
                /> */}
                  </Grid>
                  {/* <Grid item xs={12} lg={6} className={styles.lastInpGrid}>
                  <TextInput
                  autoComplete={'off'}
                    placeholder={""}
                    type="text"
                    name="subject"
                    label="Subject"
                    customClass={styles.contactusInp}
                  />
                </Grid> */}
                  {/* <Grid item xs={12}>
                <TextInput
                autoComplete={'off'}
                  placeholder={""}
                  type="text"
                  name="emailaddress"
                  label="Email Address"
                  customClass={styles.contactusInp}
                />
                </Grid> */}

                  {/* <Grid item xs={12}>
                  <TextInput
                  autoComplete={'off'}
              placeholder={""}
                    type="text"
                    name="phonenumber"
                    label="Phone Number"
                    customClass={styles.contactusInp}
                  />
                </Grid> */}
                  <Grid item xs={12} md={6}>
                    <CustomSelect
                      options={options1}
                      label={
                        <>
                          Choose Enquiry&nbsp;
                          <span style={{ color: "red" }}>*</span>
                        </>
                      }
                      name="enquiry_type"
                      placeholder="Select Form Subject"
                      values={values?.enquiry_type}
                      customClass={`${styles.select} jobSalaryy`}
                      onChange={(e) => {
                        setValues({ ...values, enquiry_type: e });
                        if (Customerror.hasOwnProperty("subject")) {
                          delete Customerror["subject"];
                          setErrors(Customerror);
                        }
                      }}
                    />
                  </Grid>
                  {values?.enquiry_type?.value ===
                    "Trouble Shooting Enquiry" && (
                    <Grid item xs={12} md={6}>
                      <Button
                        className="attach_btn_input"
                        variant="outlined"
                        component="label"
                        name="documents"
                        id="document_file"
                        style={{
                          marginBottom: "4px",
                          padding: "5px",
                          marginTop: "30px",
                        }}
                      >
                        <span>{fileName ? fileName : "upload file"}</span>
                        {/* Attach File */}
                        <input
                          accept="image/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                          style={{ display: "none" }}
                          type="file"
                          onChange={filecontact}
                        />
                      </Button>
                      <Box>
                        <Typography
                          variant="body1"
                          className="attach_field_name"
                        >
                          File size of your documents should not exceed 10MB
                        </Typography>
                      </Box>
                    </Grid>
                  )}
                  {values?.enquiry_type?.value === "Business Enquiry" ||
                  values?.enquiry_type?.value === "Trouble Shooting Enquiry" ? (
                    <Grid item xs={12} md={12}>
                      <TextInput
                        placeholder={""}
                        type="text"
                        name="message"
                        value={values?.message}
                        onChange={onChange}
                        label={
                          <>
                            Message&nbsp;
                            <span style={{ color: "red" }}>*</span>
                          </>
                        }
                        textarea
                        rows={4}
                        customClass={styles.contactusInp}
                      />
                    </Grid>
                  ) : (
                    ""
                  )}
                  {/* <Grid item xs={12}>
                    <TextInput
                      placeholder={""}
                      type="text"
                      name="message"
                      value={values?.message}
                      onChange={onChange}
                      label={
                        <>
                          Message&nbsp;
                          <span style={{ color: "red" }}>*</span>
                        </>
                      }
                      textarea
                      rows={4}
                      customClass={styles.contactusInp}
                    />
                  </Grid> */}
                </Grid>

                <Box className="button-primary">
                  <Button
                    onClick={(e) => submitData(e)}
                    className={styles.btnSend}
                  >
                    {loading ? (
                      <p style={{ color: "white" }}>Loading...</p>
                    ) : (
                      "     Send Message"
                    )}
                  </Button>
                </Box>
              </form>
            </Grid>

            <Grid item xs={12} md={12} lg={4}>
              <Box className={styles.rightContent}>
                <Typography variant="h6">General questions</Typography>
                <Typography>
                  For general queries, including questions about the platform,
                  please email us at
                </Typography>
                <a
                  href="mailto:contact@worecs.com"
                  rel="noreferrer"
                  target="_blank"
                >
                  contact@worecs.com
                </a>
              </Box>
              <Box className={styles.rightContent}>
                <Typography variant="h6">To schedule a demo</Typography>
                <Typography>
                  To schedule a demo of Worecs, please email us at
                </Typography>
                <a
                  href="mailto:contact@worecs.com"
                  rel="noreferrer"
                  target="_blank"
                >
                  contact@worecs.com
                </a>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default ContactUs;
