import React, { useState, useRef } from "react";
import { useDrop, useDrag } from "react-dnd";
import update from "immutability-helper";
import {
  FORM_STRUCTURE_DRAGGED,
  FORM_STRUCTURE_UPDATE,
  SIDEBAR_ITEM,
} from "../../../store/constants/constants.js";
import { useSelector } from "react-redux";
import { useEffect, useCallback } from "react";
import shortid from "shortid";
import { useDispatch } from "react-redux";
import { storeFormStructure } from "../../../store/actions/storeFormStructure.js";
import InputField from "./FormComponents/InputField.js";
import { FORM_STRUCTURE } from "../../../store/constants/constants.js";
import ParagraphField from "./FormComponents/ParagraphField.js";
import HeadingField from "./FormComponents/HeadingField.js";
import InputEmail from "./FormComponents/InputEmail.js";
import InputPhone from "./FormComponents/InputPhone.js";
import InputNumber from "./FormComponents/InputNumber.js";
import InputDate from "./FormComponents/InputDate.js";
import TextArea from "./FormComponents/TextArea.js";
import SelectField from "./FormComponents/SelectField.js";
import RadioField from "./FormComponents/RadioField.js";
import CheckboxField from "./FormComponents/CheckboxField.js";
import UploadFileField from "./FormComponents/UploadFileField.js";
import StarRating from "./FormComponents/StarRating";
import SignatureField from "./FormComponents/SignatureField";
import AutocompleteField from "./FormComponents/AutocompleteField";
import TwoColGrid from "./GridLayout/TwoColGrid.js";
import InputURL from "./FormComponents/InputURL.js";
import Line from "./FormComponents/Line.js";
import HyperLinkField from "./FormComponents/HyperLinkField.js";
import ImageField from "./FormComponents/ImageField.js";
import TableField from "./FormComponents/TableField.js";
import RecruiterInfo from "./FormComponents/RecruiterInfo.js";
import VerticalTableField from "./FormComponents/VerticalTableField.js";

function getStyle(backgroundColor) {
  return {
    border: "1px solid #e5e5e5",
    width: "100%",
    backgroundColor,
    padding: "16px",
    fontSize: "1rem",
  };
}
function getParaStyle(color) {
  return {
    color,
  };
}

export const DropBox = ({ greedy, children }) => {
  const [html, setHtml] = useState([]);
  const [hasDropped, setHasDropped] = useState(false);
  const [dragInd, setDragInd] = useState();
  const [hoverInd, setHoverInd] = useState();
  const id = useSelector((state) => state?.deletedBox);
  const getpreviewform = useSelector((state) => state?.getpreviewform);
  // const [array, setArray] = useState(getpreviewform);
  // console.log("getpreviewform", getpreviewform);
  let dispatch = useDispatch();
  let setNew;

  const moveCard = useCallback(
    (dragIndex, hoverIndex) => {
      // if (dragIndex) {
      //   setDragInd(dragIndex ? dragIndex : hoverIndex + 1);
      // }
      // if (hoverIndex) {
      //   setHoverInd(hoverIndex);
      // }
      if (getpreviewform.length !== 0) {
        const updatedArray = (getpreviewform) =>
          update(getpreviewform, {
            $splice: [
              [dragIndex ? dragIndex : hoverIndex + 1, 1],
              [
                hoverIndex,
                0,
                getpreviewform[
                  dragIndex
                    ? dragIndex
                    : hoverIndex + 1 || getpreviewform.length - 1
                ],
              ],
            ],
          });

        setNew = updatedArray(getpreviewform);
        setNew = setNew.filter((value) => value !== undefined);
        // console.log("setNew", setNew);
        // console.log("dragIndex", dragIndex ? dragIndex : hoverIndex + 1);
        // console.log("hoverIndex", hoverIndex);
        // console.log(
        //   "ABC",
        //   getpreviewform[dragIndex ? dragIndex : hoverIndex + 1]
        // );
        dispatch(storeFormStructure(FORM_STRUCTURE_UPDATE, setNew));
      }
    },
    [getpreviewform]
  );

  // const handeltest = (dragIndex, hoverIndex, anOtherArr) => {
  //   console.log(
  //     dragIndex,
  //     hoverIndex,
  //     anOtherArr,
  //     "dragIndex, hoverIndex, anOtherArr"
  //   );
  //   if (anOtherArr.length !== 0) {
  //     const updatedArray = (anOtherArr) =>
  //       update(anOtherArr, {
  //         $splice: [
  //           [dragIndex ? dragIndex : hoverIndex + 1, 1],
  //           [
  //             hoverIndex,
  //             0,
  //             anOtherArr[
  //               dragIndex ? dragIndex : hoverIndex + 1 || anOtherArr.length - 1
  //             ],
  //           ],
  //         ],
  //       });

  //     setNew = updatedArray(anOtherArr);
  //     setNew = setNew.filter((value) => value !== undefined);
  //     console.log("setNew", setNew);
  //     console.log("dragIndex", dragIndex ? dragIndex : hoverIndex + 1);
  //     console.log("hoverIndex", hoverIndex);
  //     console.log("ABC", anOtherArr[dragIndex ? dragIndex : hoverIndex + 1]);
  //     // dispatch(storeFormStructure(FORM_STRUCTURE_UPDATE, setNew));
  //   }
  // };

  // console.log("dragInd", dragInd);
  // console.log("hoverIND", hoverInd);

  const [{ isOver, isOverCurrent }, drop] = useDrop(
    () => ({
      accept: SIDEBAR_ITEM,

      drop(_item, monitor) {
        const didDrop = monitor.didDrop();
        if (didDrop && !greedy) {
          return;
        }
        setHasDropped(true);
        let testval = _item.value;
        testval.id = shortid.generate();
        testval.name = shortid.generate();
        if (_item.value.input_type === "two_col") {
          testval.col_1_id = shortid.generate();
          testval.col_2_id = shortid.generate();
        }
        // console.log("-fsffsfsf", _item.index);

        dispatch(storeFormStructure(FORM_STRUCTURE, _item.value));
        // let anOtherArr = [...getpreviewform, _item.value];
        // console.log(getpreviewform, _item.value, "_item.index");
        // handeltest(dragInd, hoverInd, anOtherArr);
      },

      collect: (monitor) => ({
        isOver: monitor.isOver(),
        isOverCurrent: monitor.isOver({ shallow: true }),
      }),
    }),
    [greedy, setHtml, setHasDropped]
  );

  let backgroundColor = "rgba(255, 255, 255)";
  let color = "#adadad";

  if (isOverCurrent || (isOver && greedy)) {
    backgroundColor = "skyblue";
    color = "#fff";
  }

  const list = getpreviewform.map((dropItem, index) => {
    switch (dropItem?.type?.field_type) {
      case "input":
        return (
          <InputField
            id={dropItem.id}
            label={dropItem.type.label}
            placeholder={dropItem.type.placeholder}
            type={dropItem.type.field_type}
            name={dropItem.name}
            moveCard={moveCard}
            index={index}
          ></InputField>
        );
      case "paragraph":
        return (
          <ParagraphField
            type={dropItem.type.field_type}
            id={dropItem.id}
            value={dropItem.type.field_value}
            field_style={dropItem.type.style}
            moveCard={moveCard}
            index={index}
          />
        );
      case "heading":
        return (
          <HeadingField
            type={dropItem.type.field_type}
            id={dropItem.id}
            value={dropItem.type.field_value}
            field_style={dropItem.type.style}
            moveCard={moveCard}
            index={index}
          />
        );
      case "email":
        return (
          <InputEmail
            id={dropItem.id}
            label={dropItem.type.label}
            placeholder={dropItem.type.placeholder}
            type={dropItem.type.field_type}
            value={dropItem.type.field_value}
            name={dropItem.name}
            moveCard={moveCard}
            index={index}
          />
        );
      case "phone":
        return (
          <InputPhone
            id={dropItem.id}
            label={dropItem.type.label}
            placeholder={dropItem.type.placeholder}
            type={dropItem.type.field_type}
            value={dropItem.type.field_value}
            name={dropItem.name}
            moveCard={moveCard}
            index={index}
          />
        );
      case "number":
        return (
          <InputNumber
            id={dropItem.id}
            label={dropItem.type.label}
            placeholder={dropItem.type.placeholder}
            type={dropItem.type.field_type}
            value={dropItem.type.field_value}
            name={dropItem.name}
            moveCard={moveCard}
            index={index}
          />
        );
      case "date":
        return (
          <InputDate
            id={dropItem.id}
            label={dropItem.type.label}
            type={dropItem.type.field_type}
            value={dropItem.type.field_value}
            name={dropItem.name}
            moveCard={moveCard}
            index={index}
          />
        );
      case "textarea":
        return (
          <TextArea
            id={dropItem.id}
            label={dropItem.type.label}
            type={dropItem.type.field_type}
            value={dropItem.type.field_value}
            placeholder={dropItem.type.placeholder}
            name={dropItem.name}
            moveCard={moveCard}
            index={index}
          />
        );
      case "select":
        return (
          <SelectField
            id={dropItem.id}
            label={dropItem.type.label}
            type={dropItem.type.field_type}
            value={dropItem.type.field_value}
            placeholder={dropItem.type.placeholder}
            name={dropItem.name}
            two_col={""}
            col_name={""}
            moveCard={moveCard}
            index={index}
          />
        );

      case "info":
        return (
          <RecruiterInfo
            id={dropItem.id}
            name={dropItem.name}
            type={dropItem.type.field_type}
            moveCard={moveCard}
            index={index}
          />
        );
      case "radio":
        return (
          <RadioField
            id={dropItem.id}
            label={dropItem.type.label}
            type={dropItem.type.field_type}
            name={dropItem.name}
            moveCard={moveCard}
            index={index}
          />
        );
      case "checkbox":
        return (
          <CheckboxField
            id={dropItem.id}
            label={dropItem.type.label}
            type={dropItem.type.field_type}
            name={dropItem.name}
            moveCard={moveCard}
            index={index}
          />
        );
      case "upload_file":
        return (
          <UploadFileField
            id={dropItem.id}
            label={dropItem.type.label}
            type={dropItem.type.field_type}
            button_text={dropItem.type.button_text}
            name={dropItem.name}
            moveCard={moveCard}
            index={index}
          />
        );
      case "rating":
        return (
          <StarRating
            id={dropItem.id}
            label={dropItem.type.label}
            type={dropItem.type.field_type}
            name={dropItem.name}
            selectedvalue=""
            moveCard={moveCard}
            index={index}
          />
        );
      case "signature":
        return (
          <SignatureField
            id={dropItem.id}
            label={dropItem.type.label}
            type={dropItem.type.field_type}
            name={dropItem.name}
            moveCard={moveCard}
            index={index}
          />
        );

      case "autocomplete":
        return (
          <AutocompleteField
            id={dropItem.id}
            label={dropItem.type.label}
            type={dropItem.type.field_type}
            selectedvalue=""
            placeholder={dropItem.type.placeholder}
            name={dropItem.name}
            options={dropItem.type.options}
          />
        );

      case "url":
        return (
          <InputURL
            id={dropItem.id}
            label={dropItem.type.label}
            type={dropItem.type.field_type}
            value={dropItem.type.field_value}
            placeholder={dropItem.type.placeholder}
            name={dropItem.name}
            moveCard={moveCard}
            index={index}
          />
        );

      case "hyperlink":
        return (
          <HyperLinkField
            id={dropItem.id}
            label={dropItem.type.label}
            type={dropItem.type.field_type}
            placeholder={dropItem.type.placeholder}
            name={dropItem.name}
            moveCard={moveCard}
            index={index}
          />
        );

      case "image":
        return (
          <ImageField
            id={dropItem.id}
            type={dropItem.type.field_type}
            img_url={dropItem.type.img_url}
            name={dropItem.name}
            field_style={dropItem.type.style}
            moveCard={moveCard}
            index={index}
          />
        );

      case "two_col":
        return (
          <TwoColGrid
            id={dropItem.id}
            type={dropItem.type.field_type}
            col1={dropItem.type.col_1}
            col2={dropItem.type.col_2}
            col1_ID={dropItem.col_1_id}
            col2_ID={dropItem.col_2_id}
            moveCard={moveCard}
            index={index}
          ></TwoColGrid>
        );

      // case "two_col":
      //   return (
      //     <TwoColGrid
      //       id={dropItem.id}
      //       type={dropItem.type.field_type}
      //     ></TwoColGrid>
      //   );
      case "line":
        return (
          <Line
            type={dropItem.type.field_type}
            id={dropItem.id}
            moveCard={moveCard}
            index={index}
          ></Line>
        );
      case "table":
        return (
          <TableField
            id={dropItem.id}
            type={dropItem.type.field_type}
            header={dropItem.type.header}
            table={dropItem.type.table.data}
            moveCard={moveCard}
            index={index}
          ></TableField>
        );

      // case "vertical_table":
      //   return (
      //     <VerticalTableField
      //       id={dropItem.id}
      //       type={dropItem.type.field_type}
      //       header={dropItem.type.header}
      //       table={dropItem.type.table.data}
      //       moveCard={moveCard}
      //       index={index}
      //     ></VerticalTableField>
      //   );

      default:
    }
  });
  return (
    <form action="">
      <div ref={drop} style={getStyle(backgroundColor)}>
        {list}
        <p className="text-center" style={getParaStyle(color)}>
          Drop Here
        </p>
      </div>

      <div>{children}</div>
    </form>
  );
};
