import {
  FORM_STRUCTURE,
  FORM_STRUCTURE_DRAGGED,
  FORM_STRUCTURE_UPDATE,
} from "../constants/constants";

export const storeFormStructure = (type, value) => {
  switch (type) {
    case FORM_STRUCTURE:
      return {
        type: FORM_STRUCTURE,
        payload: value,
      };
    case FORM_STRUCTURE_UPDATE:
      return {
        type: FORM_STRUCTURE_UPDATE,
        payload: value,
      };

    default:
      return value;
  }
};
