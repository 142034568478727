import {
  Box,
  TableBody,
  TableCell,
  TableFooter,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import styles from "./styles.module.scss";
import {
  candidateLicenseVerify,
  editProfileLicense,
} from "../../../api/candidate/candidate.class";
import { ReactComponent as DeleteIcon } from "../../../assets/candidates/delete.svg";
import { ReactComponent as EditIcon } from "../../../assets/candidates/edit.svg";
import { ReactComponent as Verification } from "../../../assets/new-icons/verification.svg";
import UpdateLicense from "./Modal/licenses/UpdateLicense";
import DeleteLicense from "./Modal/licenses/DeleteLicense";
import CheckoutForm from "./Modal/licenses/checkout/checkout";
import Modal from "../../candidates/model";
import { HiOutlineClipboardDocumentList } from "react-icons/hi2";
import ResponsePopup from "./Modal/licenses/responsePopup";
import UpdateVisaLicense from "./Modal/licenses/UpdateVisaLicense";
import UpdateBackyCheck from "./Modal/licenses/UpdateBackyCheck";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    width: "90px",
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "10px",
    background: "#00CFC5",
    color: "white",
  },
}));

function LicenseDataTable({ licenseData, userDetailData, setUpdation }) {
  const [licenseDelete, setLicenseDelete] = useState(false);
  const [licenseDeleteUUID, setLicenseDeleteUUID] = useState();
  const [licenseEdit, setLicenseEdit] = useState(false);
  const [showVisa, setShowVisa] = useState(false);
  const [showBackyCheck, setShowBackyCheck] = useState(false);
  const [editLicense, setEditLicense] = useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [open2, setOpen2] = useState(false);
  const [data, setData] = useState();
  const [page, setPage] = React.useState(0);
  const [openResponse, setOpenResponse] = useState(false);
  const [responseData, setResponseData] = useState();

  const handleLicense = async (item) => {
    try {
      const response = await editProfileLicense(item?.uuid).then(function (
        res
      ) {
        return res?.data?.results;
      });
      // console.log("License", response);
      if (response?.uuid) {
        if (item?.is_visa) {
          setShowVisa(true);
        } else if (item?.is_backy_check) {
          setShowBackyCheck(true);
        } else {
          setLicenseEdit(true);
        }
      }
      setEditLicense(response);
    } catch (error) {
      console.log(error);
    }
  };
  const handleDeleteLicense = async (uuid) => {
    setLicenseDeleteUUID(uuid);
    setLicenseDelete(true);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const showResponseCertificate = (item) => {
    setOpenResponse(true);
    setResponseData(item);
  };

  const verifyCertificate = async (item) => {
    setOpen2(true);
    setData(item);
  };
  return (
    <>
      {licenseData !== undefined ? (
        <TableBody>
          {licenseData
            ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            ?.map((row, i) => (
              <TableRow
                key={i}
                sx={{ "& td, & th": { border: 0 } }}
                className={styles.contentBox}
              >
                <TableCell align="left">
                  {row?.recruiter_industry?.name || "Industry Name"}
                </TableCell>
                <TableCell align="">
                  {row?.industry_certification_license?.name || "License Name"}
                </TableCell>
                {/* <TableCell align="center">
                  {row?.response === "Completed" &&
                  row?.verified_on !== null ? (
                    <StyledBadge badgeContent={"Verified"} />
                  ) : row?.is_expired === 1 ? (
                    "Expired"
                  ) : row?.response === "Completed" &&
                    row?.verified_on === null &&
                    row?.verification_data?.status !== "" ? (
                    "Rejected"
                  ) : row?.response === "Completed" &&
                    row?.verified_on === null ? (
                    "In Review"
                  ) : row?.is_automated_checks !== 1 ? (
                    "-"
                  ) : (
                    row?.response
                  )}
                </TableCell> */}
                <TableCell align="center">
                  {row?.is_automated_checks === 1 &&
                  row?.verification_status === 1 ? (
                    <StyledBadge badgeContent={"Verified"} />
                  ) : row?.is_automated_checks === 1 &&
                    row?.is_expired === 1 ? (
                    "Expired"
                  ) : row?.is_automated_checks === 1 &&
                    row?.verification_status === 3 ? (
                    "Rejected"
                  ) : row?.is_automated_checks === 1 &&
                    row?.verification_status === 2 ? (
                    "In Review"
                  ) : row?.is_automated_checks === 1 &&
                    row?.verification_status === 0 ? (
                    "Pending"
                  ) : row?.is_automated_checks !== 1 ? (
                    "-"
                  ) : (
                    ""
                  )}
                </TableCell>

                <TableCell align="center" className={styles.link}>
                  <Box
                    className={styles.button}
                    style={{ justifyContent: "start" }}
                  >
                    <Box
                      className={styles.editicon}
                      onClick={() => {
                        handleLicense(row);
                      }}
                    >
                      <EditIcon />
                    </Box>

                    {(row?.is_automated_checks === 1 &&
                      row?.verification_status === 0) ||
                    row?.is_expired === 1 ? (
                      <Box
                        className={styles.editicon}
                        onClick={() => verifyCertificate(row)}
                      >
                        <Verification />
                      </Box>
                    ) : (
                      ""
                    )}

                    {row?.is_automated_checks === 1 &&
                    row?.verification_status === 1 &&
                    row?.verification_status === 3 ? (
                      <Box
                        className={styles.editicon}
                        onClick={() => showResponseCertificate(row)}
                      >
                        <HiOutlineClipboardDocumentList color="#00cfc5" />
                      </Box>
                    ) : (
                      ""
                    )}
                    <Box
                      className={styles.editicon}
                      onClick={() => {
                        handleDeleteLicense(row?.uuid);
                      }}
                    >
                      <DeleteIcon />
                    </Box>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      ) : (
        <TableBody>
          <TableRow>
            <TableCell colSpan={4} className="border-0">
              <Typography variant="h5" className="text-center">
                No Data Found
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      )}
      <TableFooter>
        <TableRow>
          <TableCell colSpan={4} className="border-0">
            {licenseData?.length > 10 && (
              <TablePagination
                className={styles.tablePagination}
                rowsPerPageOptions={[]}
                component="Box"
                count={licenseData?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            )}
          </TableCell>
        </TableRow>
      </TableFooter>
      <UpdateLicense
        openModal={licenseEdit}
        setOpenModal={setLicenseEdit}
        setUpdation={setUpdation}
        data={editLicense}
        FrontImage={editLicense?.front_image}
        BackImage={editLicense?.back_image}
        userDetailData={userDetailData}
      />
      {editLicense && (
        <UpdateVisaLicense
          openModal={showVisa}
          setOpenModal={setShowVisa}
          type={"request"}
          data={editLicense}
          setUpdation={setUpdation}
        />
      )}
      {editLicense && (
        <UpdateBackyCheck
          openModal={showBackyCheck}
          setOpenModal={setShowBackyCheck}
          type={"request"}
          data={editLicense}
          setUpdation={setUpdation}
        />
      )}
      <DeleteLicense
        openModal={licenseDelete}
        setOpenModal={setLicenseDelete}
        uuid={licenseDeleteUUID}
        setDeletion={setUpdation}
      />
      <Modal open={open2} setOpen={setOpen2} data={data}>
        <CheckoutForm
          open={open2}
          setOpen={setOpen2}
          data={data}
          setUpdation={setUpdation}
          isCandidate={true}
        />
      </Modal>
      <Modal open={openResponse} setOpen={setOpenResponse}>
        <ResponsePopup
          open={openResponse}
          setOpen={setOpenResponse}
          name={"Response for background checks"}
          data={responseData}
          certificate={true}
        />
      </Modal>
    </>
  );
}

export default LicenseDataTable;
