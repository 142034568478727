import {
  Button,
  Checkbox,
  Grid,
  InputBase,
  Typography,
  CircularProgress,
} from "@mui/material";
import { useEffect } from "react";
import { Box, Container } from "@mui/system";
import { useHistory } from "react-router-dom";
import React, { useState } from "react";
import Index from "./Modals/Index";
import Content_footer from "../content-footer/Content_footer";
import Navbar from "../navbar/Navbar";
import ContactBtn from "../../../assets/icons/contact_btn.png";
import axios from "axios";
import $ from "jquery";
import { useDispatch, useSelector } from "react-redux";
import { ShowAlert } from "../../../store/actions/alertActions";
import authHeader from "../../../api/authToken";
import { ValidateEmail } from "../../../customHooks/utils";
// import Checkbox from '@mui/material/Checkbox';
import "./style.css";
import {
  uploadFilecontact,
  resetContact,
} from "../../../store/actions/profileActions";
// ----------------------
import CustomSelect from "../../common/Select";
import CustomModel from "../model/index";

import styles from "./styles.module.scss";

// ------------------------
const Contact_Us = (props) => {
  const [open, setOpen] = useState(false);
  const [tc, setTc] = useState(1);

  const options1 = [
    { value: "Business Enquiry", label: "Business Enquiry" },
    { value: "Trouble Shooting Enquiry", label: "Trouble Shooting Enquiry" },
  ];

  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    name: "",
    nature_of_business: "",
    company_name: "",
    contact: "",
    email: "",
    // documents: "",
    commentbox1: "",
    terms_condition: "",
  });

  const [fileName, setFileName] = useState();

  const dispatch = useDispatch();
  const alertType = useSelector((state) => state.alert);

  const [Customerror, seterrors] = useState({});

  const [error, setErrors] = useState({});

  const onChange = (e) => {
    const { name, value } = e?.target;

    if (name !== "terms_condition") {
      setValues({
        ...values,
        [name]: value,
      });
    } else {
      setValues({
        ...values,
        [name]: !values?.terms_condition,
      });
      if (e?.target.checked === true) {
        setValues({ ...values, [e?.target?.name]: 1 });
      }
    }

    if (error.hasOwnProperty(name)) {
      delete error[name];
      setErrors(error);
    }
  };

  function MyForm() {
    const submitData = (e) => {
      e.preventDefault();
      // Perform data submission logic
      window.location.href = "/index.js"; // Redirect to index.js page
    };
  }

  // const filecontact = (e) => {
  //   if (!e.target.files[0]) return;
  //   if (e.target?.files[0]?.size <= "10000000") {
  //     setValues({
  //       ...values,
  //       documents: e?.target?.files[0],
  //     });
  //     let name = e.target?.files[0]?.name;
  //     setFileName(name);
  //   } else {
  //     dispatch(ShowAlert("Upload file max size is 10MB.", "error"));
  //   }
  // };

  useEffect(() => {
    if (alertType[0]?.alertType === "success") {
      setOpen(true);
      setLoading(false);
      setValues({
        name: "",
        nature_of_business: "",
        company_name: "",
        contact: "",
        email: "",
        // documents: "",
        commentbox1: "",
        terms_condition: false,
      });
    } else if (alertType[0]?.alertType === "error") {
      setLoading(false);
    }
  }, [alertType]);

  // const validate = (data) => {
  //   const newError = {};

  //   for (let key in data) {
  //     let value = data[key];
  //     switch (key) {
  //       case "name":
  //         if (value.length === 0 || value.toString() === "")
  //           newError[key] = "fields is Required";
  //         break;
  //       case "email":
  //         if (value.length === 0 || value.toString() === "")
  //           newError[key] = "fields is Required";
  //         else if (!ValidateEmail(value)) {
  //           newError[key] = "Email is not correct pattern";
  //         }
  //         break;
  //       case "contact":
  //         if (value.length === 0 || value.toString() === "")
  //           newError[key] = "fields is Required";
  //         break;

  //       case "terms_condition":
  //         if (value === 0) newError[key] = "fields is Required";
  //         break;

  //       default:
  //     }
  //   }
  //   return newError;
  // };

  const submitData = async (e) => {
    setLoading(true);
    e.preventDefault();

    const payload = {
      ...values,
    };

    dispatch(uploadFilecontact(payload, {}));

    $("#policy_check").prop("checked", false);

    // setLoading(false);
  };

  return (
    <>
      <Navbar />
      <Container>
        <Grid container spacing={2} marginTop={9}>
          <Grid item xs={12}>
            <Box className="contact_main_page_header">
              <Typography variant="h1" className="resource_heading_h4">
                Schedule a demo
              </Typography>
              <Box marginTop={1}>
                <Typography variant="body1">
                  Have questions? We’re ready to help!
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Grid container className="con_main_box_center">
          <Box>
            <Grid item md={12}>
              <Box>
                <Box>
                  <Typography
                    variant="h6"
                    className="contact_main_page_header_h6"
                  >
                    Contact us
                  </Typography>
                </Box>
                <Box paddingY={2}>
                  <Typography
                    variant="body1"
                    className="contact_main_page_header_para"
                  >
                    {/* Need an experienced and skilled hand with custom IT projects? <br/> */}
                    We answer all queries within 24 hours.
                  </Typography>
                </Box>
              </Box>
              <Grid container>
                <Grid item sm={6}>
                  <Box>
                    <Box>
                      <Typography variant="body1">Name*</Typography>
                      <InputBase
                        required
                        onChange={onChange}
                        className="contact_form_base_field"
                        type="text"
                        name="name"
                        value={values.name}
                      />
                    </Box>
                    <Box>
                      <Typography variant="body1">Company Name*</Typography>
                      <InputBase
                        onChange={onChange}
                        className="contact_form_base_field"
                        type="text"
                        name="company_name"
                        value={values.company_name}
                      />
                    </Box>
                    {/* <Box>
                  <Typography variant='body1'>
                  Postcode
                    </Typography>
                    <InputBase
                    className='contact_form_base_field'
                    type='text'
                    
                    />
                  </Box> */}
                    <Box>
                      <Typography variant="body1">Email Address*</Typography>
                      <InputBase
                        className="contact_form_base_field"
                        type="email"
                        onChange={onChange}
                        required
                        name="email"
                        value={values.email}
                      />
                    </Box>

                    <Box>
                      {/* {values?.subject?.value === "Business Enquiry" ||
                      values?.subject?.value === "Trouble Shooting Enquiry" ? ( */}
                      <Grid item xs={12} lg={6.25}>
                        <Typography variant="body1">Description</Typography>
                        <Typography variant="body1">
                          <textarea
                            onChange={onChange}
                            id="commentbox1"
                            name="commentbox1"
                            rows="4"
                            cols="108.1"
                            placeholder="Give a Valuable Comment"
                          ></textarea>
                        </Typography>
                      </Grid>
                      {/* ) : (
                        ""
                      )} */}

                      {/* {values?.subject?.value ===
                        "Trouble Shooting Enquiry" && (
                        <>
                          <Grid item xs={12} lg={6.25}>
                            <Typography variant="body1">
                              Let’s talk about your idea"
                            </Typography>
                            <Button
                              className="attach_btn_input"
                              variant="outlined"
                              component="label"
                              name="documents"
                              id="document_file"
                              style={{
                                marginBottom: "16px",
                                padding: "5px",
                                marginTop: "9px",
                              }}
                            >
                              <span>{fileName ? fileName : "upload file"}</span>
                              Attach File
                              <input
                                accept="image/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                style={{ display: "none" }}
                                type="file"
                                onChange={filecontact}
                              />
                            </Button>
                          </Grid>
                          <Box>
                            <Typography
                              variant="body1"
                              className="attach_field_name"
                            >
                              File size of your documents should not exceed 10MB
                            </Typography>
                          </Box>
                        </>
                      )} */}
                    </Box>
                  </Box>
                </Grid>

                <Grid item sm={6}>
                  <Box>
                    <Box>
                      <Typography variant="body1">
                        Nature of Business*
                      </Typography>
                      <InputBase
                        className="contact_form_base_field"
                        type="text"
                        name="nature_of_business"
                        onChange={onChange}
                        value={values.nature_of_business}
                      />
                    </Box>
                    <Box>
                      <Typography variant="body1">Contact No.*</Typography>
                      <InputBase
                        required
                        onChange={onChange}
                        className="contact_form_base_field"
                        type="text"
                        name="contact"
                        value={values.contact}
                      />
                    </Box>

                    {/* -------------------------- */}

                    {/* <Box>
                      <Grid item xs={12} lg={6.25}>
                        <Typography variant="body1"></Typography>
                        <CustomSelect
                          options={options1}
                          label="Choose Enquiry"
                          name="subject"
                          placeholder="Select Form Subject"
                          values={values?.subject}
                          customClass={`${styles.select} jobSalaryy mt-0`}
                          onChange={(e) => {
                            setValues({ ...values, subject: e });
                            if (Customerror.hasOwnProperty("subject")) {
                              delete Customerror["subject"];
                              seterrors(Customerror);
                            }
                          }}
                        />
                      </Grid>
                    </Box> */}

                    {/* ----------------------option rendering----------------------- */}

                    {/* ----------------------option rendering----------------------- */}

                    {/* ------------------------------ */}

                    {/* <Box>
                  <Typography variant='body1'>
                  Contact No.
                    </Typography>
                    <InputBase
                    className='contact_form_base_field'
                    type='text'
                    
                    />
                  </Box> */}
                  </Box>
                </Grid>

                <Grid item sm={12}>
                  <Box display="flex" style={{ alignItems: "center" }}>
                    <Box>
                      <Checkbox
                        required
                        id="policy_check"
                        onChange={onChange}
                        checked={values?.terms_condition}
                        name="terms_condition"
                        sx={{
                          color: "#00CFC5",
                          "&.Mui-checked": {
                            color: "#00CFC5",
                          },
                        }}
                      />
                    </Box>
                    <Box>
                      {/* <label className="labelContainer">
                        I have read and agree to the{" "}
                        <span className="text-primary">Privacy Policy</span> &{" "}
                        <span className="text-primary">Terms of Service</span>
                        <input
                          type="checkbox"
                          name="terms_condition"
                          onChange={onChange}
                        />
                        <span className="checkmark"></span>
                      </label> */}
                      {/* <Typography
                        variant="body1"
                        className="attach_field_name_checkbox"
                      >
                        I have reviewed{" "}
                        <a href="" className="contact_form_general_heading_clr">
                          terms and conditions
                        </a>{" "}
                        and{" "}
                        <a href="" className="contact_form_general_heading_clr">
                          privacy policy
                        </a>
                      </Typography> */}
                      <label
                        htmlFor="policy_check"
                        style={{ paddingLeft: 0, margin: 0 }}
                        className="labelContainer"
                      >
                        I have read and agree to the{" "}
                        <span className="text-primary">Privacy Policy</span> &{" "}
                        <span className="text-primary">Terms of Service</span>
                      </label>
                    </Box>
                  </Box>
                </Grid>
                <Box marginTop={2} className="contact_form_base_field_btn_box">
                  <Button
                    className="contact_form_base_field_btn"
                    variant="contained"
                    onClick={(e) => submitData(e)}
                    // onClick={()=>{setOpen(true)}}
                    disabled={loading}
                  >
                    {loading ? (
                      <CircularProgress sx={{ color: "white" }} />
                    ) : (
                      "Submits"
                    )}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
          {/* <Grid item md={4}>
            <Box marginTop={6}>
              <Box>
                <Typography variant='h6' className='contact_form_general_heading'>
                General questions
                </Typography>
              </Box>
              <Box>
                <Box>
                  <Typography variant='body1' className='contact_form_general_para'>
                  For general queries, including questions about the platform, please email us at <br/><a href="mailto:support@worecs.com.au" className="contact_form_general_heading_clr">support@worecs.com.au</a>
                  </Typography>
                </Box>
                <Box>
                  <Typography variant='body1' className='contact_form_general_para'>
                  To schedule a demo of Worecs, please email us at <a href="mailto:support@worecs.com.au" className="contact_form_general_heading_clr">support@worecs.com.au</a>
                  </Typography>
                </Box>
              </Box>
              <Box>
                <Box marginTop={4}>
                  <Typography variant='h6'>
                  Would you like to join our newsletter?
                  </Typography>
                </Box>
                <Box display="flex" marginTop={2}>
                  <Box>
                    <InputBase
                    className='contact_form_base_field_email'
                    type='text'
                    placeholder='Email'
                    />
                  </Box>
                  <Box>
                    <img className='contact_form_base_field_img' src={ContactBtn} alt="" />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid> */}
        </Grid>
        <Grid container marginTop={2}>
          <Grid item xs={12}></Grid>
        </Grid>
      </Container>
      <Content_footer />
      <CustomModel setOpen={setOpen} open={open}>
        <Index open={open} setOpen={setOpen} />
      </CustomModel>
    </>
  );
};

export default Contact_Us;
