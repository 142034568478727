import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import React, { useRef } from "react";
import ColDropBox from "../ColDropBox";
import ColDropBox2 from "../ColDropBox2";
import { DropBox } from "../DropBox";
import Actions from "../FormComponents/Actions";
import SignatureField from "../FormComponents/SignatureField";
import style from "../FormComponents/style.module.scss";
import { useSelector } from "react-redux";
import useDragField from "../../../../customHook/useDragField";

function TwoColGrid({
  id,
  type,
  col1_ID,
  col2_ID,
  col_name,
  showPreview,
  index,
  moveCard,
}) {
  const ref = useRef(null);
  const dragField = useDragField(ref, index, moveCard, id);
  const opacity = dragField.opacity;
  return (
    <Box
      ref={ref}
      style={{ ...style, opacity }}
      data-handler-id={dragField?.handlerId}
      className={`${style.gridBox} dropItem`}
    >
      <Actions
        id={id}
        type={type}
        className={`${style.editbutton} editbutton`}
      />
      <Grid
        container
        spacing={{ lg: 2, xs: 1 }}
        className={style.gridRow}
        id={id}
      >
        <Grid item md={6} id={col1_ID}>
          <ColDropBox
            id={id}
            col_ID={col1_ID}
            col_name={"col_1"}
            showPreview={showPreview}
          />
        </Grid>
        <Grid item md={6} id={col2_ID}>
          <ColDropBox
            id={id}
            col_ID={col2_ID}
            col_name={"col_2"}
            showPreview={showPreview}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default TwoColGrid;
