import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import InputField from "./FormComponents/InputField.js";
import ParagraphField from "./FormComponents/ParagraphField.js";
import HeadingField from "./FormComponents/HeadingField.js";
import InputEmail from "./FormComponents/InputEmail.js";
import InputPhone from "./FormComponents/InputPhone.js";
import InputNumber from "./FormComponents/InputNumber.js";
import InputDate from "./FormComponents/InputDate.js";
import TextArea from "./FormComponents/TextArea.js";
import SelectField from "./FormComponents/SelectField.js";

import RadioField from "./FormComponents/RadioField.js";
import CheckboxField from "./FormComponents/CheckboxField.js";
import UploadFileField from "./FormComponents/UploadFileField.js";
import StarRating from "./FormComponents/StarRating";
import SignatureField from "./FormComponents/SignatureField";
import AutocompleteField from "./FormComponents/AutocompleteField";
import TwoColGrid from "./GridLayout/TwoColGrid.js";
import style from "./FormComponents/style.module.scss";
import InputURL from "./FormComponents/InputURL.js";
import HyperLinkField from "./FormComponents/HyperLinkField.js";
import Line from "./FormComponents/Line.js";
import TableField from "./FormComponents/TableField.js";
import ImageField from "./FormComponents/ImageField.js";
import RecruiterInfo from "./FormComponents/RecruiterInfo.js";
import VerticalTableField from "./FormComponents/VerticalTableField.js";

function PreviewForm({ getpreviewform, showPreview }) {
  getpreviewform = useSelector((state) => state?.getpreviewform);

  return (
    <div
      className={style.previewForm}
      style={{
        boxShadow: "0 0 10px 5px rgba(0, 0, 0, 0.08)",
        borderRadius: 20,
        border: "1px solid #00cfc5",
        padding: 20,
      }}
    >
      {getpreviewform.map((dropItem, index) => {
        switch (dropItem?.type?.field_type) {
          case "input":
            return (
              <InputField
                id={dropItem.id}
                label={dropItem.type.label}
                placeholder={dropItem.type.placeholder}
                type={dropItem.type.field_type}
                name={dropItem.name}
              ></InputField>
            );
          case "paragraph":
            return (
              <ParagraphField
                type={dropItem.type.field_type}
                id={dropItem.id}
                value={dropItem.type.field_value}
                field_style={dropItem.type.style}
              />
            );
          case "heading":
            return (
              <HeadingField
                type={dropItem.type.field_type}
                id={dropItem.id}
                value={dropItem.type.field_value}
                field_style={dropItem.type.style}
              />
            );
          case "email":
            return (
              <InputEmail
                id={dropItem.id}
                label={dropItem.type.label}
                placeholder={dropItem.type.placeholder}
                type={dropItem.type.field_type}
                value={dropItem.type.field_value}
                name={dropItem.name}
              />
            );
          case "phone":
            return (
              <InputPhone
                id={dropItem.id}
                label={dropItem.type.label}
                placeholder={dropItem.type.placeholder}
                type={dropItem.type.field_type}
                value={dropItem.type.field_value}
                name={dropItem.name}
              />
            );
          case "number":
            return (
              <InputNumber
                id={dropItem.id}
                label={dropItem.type.label}
                placeholder={dropItem.type.placeholder}
                type={dropItem.type.field_type}
                value={dropItem.type.field_value}
                name={dropItem.name}
              />
            );
          case "date":
            return (
              <InputDate
                id={dropItem.id}
                label={dropItem.type.label}
                type={dropItem.type.field_type}
                value={dropItem.type.field_value}
                name={dropItem.name}
              />
            );
          case "textarea":
            return (
              <TextArea
                id={dropItem.id}
                label={dropItem.type.label}
                type={dropItem.type.field_type}
                value={dropItem.type.field_value}
                placeholder={dropItem.type.placeholder}
                name={dropItem.name}
              />
            );
          case "select":
            return (
              <SelectField
                id={dropItem.id}
                label={dropItem.type.label}
                type={dropItem.type.field_type}
                value={dropItem.type.field_value}
                placeholder={dropItem.type.placeholder}
                name={dropItem.name}
              />
            );
          case "info":
            return (
              <RecruiterInfo
                id={dropItem.id}
                name={dropItem.name}
                type={dropItem.type.field_type}
              />
            );
          case "radio":
            return (
              <RadioField
                id={dropItem.id}
                label={dropItem.type.label}
                type={dropItem.type.field_type}
                name={dropItem.name}
              />
            );
          case "checkbox":
            return (
              <CheckboxField
                id={dropItem.id}
                label={dropItem.type.label}
                type={dropItem.type.field_type}
                name={dropItem.name}
              />
            );
          case "upload_file":
            return (
              <UploadFileField
                id={dropItem.id}
                label={dropItem.type.label}
                type={dropItem.type.field_type}
                button_text={dropItem.type.button_text}
                name={dropItem.name}
              />
            );
          case "rating":
            return (
              <StarRating
                id={dropItem.id}
                label={dropItem.type.label}
                type={dropItem.id}
              />
            );
          case "signature":
            return (
              <SignatureField
                id={dropItem.id}
                label={dropItem.type.label}
                type={dropItem.type.field_type}
              />
            );

          case "autocomplete":
            return (
              <AutocompleteField
                id={dropItem.id}
                label={dropItem.type.label}
                type={dropItem.type.field_type}
                value={dropItem.type.field_value}
                placeholder={dropItem.type.placeholder}
                name={dropItem.name}
              />
            );

          case "url":
            return (
              <InputURL
                id={dropItem.id}
                label={dropItem.type.label}
                type={dropItem.type.field_type}
                value={dropItem.type.field_value}
                placeholder={dropItem.type.placeholder}
                name={dropItem.name}
              />
            );

          case "hyperlink":
            return (
              <HyperLinkField
                id={dropItem.id}
                label={dropItem.type.label}
                type={dropItem.type.field_type}
                placeholder={dropItem.type.placeholder}
                name={dropItem.name}
              />
            );
          case "image":
            return (
              <ImageField
                id={dropItem.id}
                type={dropItem.type.field_type}
                img_url={dropItem.type.img_url}
                name={dropItem.name}
                field_style={dropItem.type.style}
                preview={1}
              />
            );
          case "two_col":
            return (
              <TwoColGrid
                id={dropItem.id}
                type={dropItem.type.field_type}
                col1={dropItem.type.col_1}
                col2={dropItem.type.col_2}
                col1_ID={dropItem.col_1_id}
                col2_ID={dropItem.col_2_id}
                showPreview={showPreview}
              ></TwoColGrid>
            );
          case "line":
            return (
              <Line type={dropItem.type.field_type} id={dropItem.id}></Line>
            );
          case "table":
            return (
              <TableField
                id={dropItem.id}
                type={dropItem.type.field_type}
                header={dropItem.type.header}
                table={dropItem.type.table.data}
              ></TableField>
            );

          // case "vertical_table":
          //   return (
          //     <VerticalTableField
          //       id={dropItem.id}
          //       type={dropItem.type.field_type}
          //       header={dropItem.type.header}
          //       table={dropItem.type.table.data}
          //     ></VerticalTableField>
          //   );

          default:
        }
      })}
    </div>
  );
}
export default PreviewForm;
