import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { ReactComponent as Cross } from "../../../../assets/industries/model/x.svg";
import Modal from "@mui/material/Modal";
import styles from "./stylesUpload.module.scss";
import { Button } from "@mui/material";
import Alerts from "../../../common/Alert";
import SignatureCanvas from "react-signature-canvas";
import OptionTable from "../OptionTable/OptionTable";
import OptionTableRadio from "../OptionTable/OptiontableRadio";
import { Getcheckboxoption } from "../../../../store/actions/getcheckboxoption";
import { useDispatch, useSelector } from "react-redux";
import style from "../FormComponents/style.module.scss";
import $ from "jquery";
import { storeFormStructure } from "../../../../store/actions/storeFormStructure";
import { storeFormbuilderimage } from "../../../../store/actions/storeFormbuilderimage";
import { FORM_STRUCTURE_UPDATE } from "../../../../store/constants/constants.js";
import { FORM_BUILDER_IMAGE } from "../../../../store/constants/constants.js";
import { Grid, TableRow } from "@mui/material";
import shortid from "shortid";
import OptionTableRow from "../OptionTable/OptionTableRow";
import { SketchPicker } from "react-color";
import { uploadImagesformbuilder } from "../../../../api/uploadFile/uploadFile.class";
import { ShowAlert } from "../../../../store/actions/alertActions";

function EditModel({
  openModal,
  setOpenModal,
  type,
  id,
  main_id,
  two_col,
  col_name,
}) {
  let dispatch = useDispatch();

  const getpreviewform = useSelector((state) => state?.getpreviewform);

  const auth = useSelector((state) => state.auth);
  const [indexcount, setIndex] = useState(0);
  const [subindexcount, setsubIndex] = useState(0);

  const [optioncount, Setoptioncount] = useState(2);
  const [colcount, Setcolcount] = useState(4);
  const [vColCount, setVColCount] = useState(4);
  const [optioncount2, Setoptioncount2] = useState(3);

  useEffect(() => {
    getpreviewform?.map((item, count) => {
      if (two_col === 1) {
        if (item?.id === main_id) {
          setIndex(count);
        }
      } else {
        if (item?.id === id) {
          setIndex(count);
        }
      }
    });
  }, [indexcount]);

  useEffect(() => {
    if (two_col == 1) {
      getpreviewform.map((item) => {
        item?.type[col_name]?.data?.map((subdata, count2) => {
          if (subdata?.id === id) {
            setsubIndex(count2);
          }
        });
      });
    } else {
      setsubIndex(0);
    }
  }, [subindexcount]);

  const handelCondition = (valuesss) => {
    if (getpreviewform[indexcount]?.type[col_name]?.data !== undefined) {
      return getpreviewform[indexcount]?.type[col_name]?.data[subindexcount]
        ?.type?.[valuesss];
    } else {
      return "";
    }
  };

  // console.log("edit_id", id);
  // console.log("edit_main_id", main_id);
  // console.log("edit_two_col", two_col);
  // console.log("edit_indexcount", indexcount);
  // console.log("edit_subindexcount", subindexcount);
  // console.log("edit_getpreviewform", getpreviewform);

  const handleClose = () => {
    setOpenModal(false);
  };

  const setInputLabel = (val, filedtype) => {
    if (two_col === 1) {
      const newState = getpreviewform.map((obj) => {
        return {
          ...obj,
          type: {
            ...obj.type,
            [col_name]: {
              ...obj.type[col_name],
              data: obj?.type[col_name]?.data?.map((subdata) => {
                if (subdata?.id === id) {
                  if (filedtype == "paragraph" || filedtype == "heading") {
                    return {
                      ...subdata,
                      type: { ...subdata.type, field_value: val },
                    };
                  } else if (
                    filedtype == "input" ||
                    filedtype == "email" ||
                    filedtype == "phone" ||
                    filedtype == "number" ||
                    filedtype == "date" ||
                    filedtype == "textarea" ||
                    filedtype == "upload_file" ||
                    filedtype == "rating" ||
                    filedtype == "checkbox" ||
                    filedtype == "select" ||
                    filedtype == "autocomplete" ||
                    filedtype == "radio" ||
                    filedtype == "signature" ||
                    filedtype == "url" ||
                    filedtype == "hyperlink"
                  ) {
                    return {
                      ...subdata,
                      type: { ...subdata.type, label: val },
                    };
                  }
                }
                return subdata;
              }),
            },
          },
        };
      });
      dispatch(storeFormStructure(FORM_STRUCTURE_UPDATE, newState));
    } else {
      const newState = getpreviewform.map((obj) => {
        if (obj.id === id) {
          if (filedtype == "paragraph" || filedtype == "heading") {
            return { ...obj, type: { ...obj.type, field_value: val } };
          } else if (
            filedtype == "input" ||
            filedtype == "email" ||
            filedtype == "phone" ||
            filedtype == "number" ||
            filedtype == "date" ||
            filedtype == "textarea" ||
            filedtype == "upload_file" ||
            filedtype == "rating" ||
            filedtype == "checkbox" ||
            filedtype == "select" ||
            filedtype == "autocomplete" ||
            filedtype == "radio" ||
            filedtype == "signature" ||
            filedtype == "url" ||
            filedtype == "hyperlink"
          ) {
            return { ...obj, type: { ...obj.type, label: val } };
          }
        }

        return obj;
      });

      dispatch(storeFormStructure(FORM_STRUCTURE_UPDATE, newState));
    }
  };

  const setInputPlaceholder = (val, filedtype) => {
    if (two_col === 1) {
      const newState = getpreviewform.map((obj) => {
        return {
          ...obj,
          type: {
            ...obj.type,
            [col_name]: {
              ...obj.type[col_name],
              data: obj?.type[col_name]?.data?.map((subdata) => {
                if (subdata?.id === id) {
                  if (
                    filedtype == "input" ||
                    filedtype == "email" ||
                    filedtype == "phone" ||
                    filedtype == "number" ||
                    filedtype == "textarea" ||
                    filedtype == "url" ||
                    filedtype == "hyperlink"
                  ) {
                    return {
                      ...subdata,
                      type: { ...subdata.type, placeholder: val },
                    };
                  }
                }
                return subdata;
              }),
            },
          },
        };
      });

      dispatch(storeFormStructure(FORM_STRUCTURE_UPDATE, newState));
    } else {
      const newState = getpreviewform.map((obj) => {
        if (obj.id === id) {
          if (
            filedtype == "input" ||
            filedtype == "email" ||
            filedtype == "phone" ||
            filedtype == "number" ||
            filedtype == "textarea" ||
            filedtype == "url" ||
            filedtype == "hyperlink"
          ) {
            return { ...obj, type: { ...obj.type, placeholder: val } };
          }
        }

        return obj;
      });

      dispatch(storeFormStructure(FORM_STRUCTURE_UPDATE, newState));
    }
  };

  const setRequiredField = (val, filedtype) => {
    if (two_col === 1) {
      const newState = getpreviewform.map((obj) => {
        return {
          ...obj,
          type: {
            ...obj.type,
            [col_name]: {
              ...obj.type[col_name],
              data: obj?.type[col_name]?.data?.map((subdata) => {
                if (subdata?.id === id) {
                  if (
                    filedtype == "input" ||
                    filedtype == "email" ||
                    filedtype == "phone" ||
                    filedtype == "number" ||
                    filedtype == "textarea" ||
                    filedtype == "url" ||
                    filedtype == "date" ||
                    filedtype == "select" ||
                    filedtype == "radio" ||
                    filedtype == "checkbox"
                  ) {
                    return {
                      ...subdata,
                      type: { ...subdata.type, require: val },
                    };
                  }
                }
                return subdata;
              }),
            },
          },
        };
      });

      dispatch(storeFormStructure(FORM_STRUCTURE_UPDATE, newState));
    } else {
      const newState = getpreviewform.map((obj) => {
        if (obj.id === id) {
          if (
            filedtype == "input" ||
            filedtype == "email" ||
            filedtype == "phone" ||
            filedtype == "number" ||
            filedtype == "textarea" ||
            filedtype == "url" ||
            filedtype == "date" ||
            filedtype == "select" ||
            filedtype == "radio" ||
            filedtype == "checkbox"
          ) {
            return { ...obj, type: { ...obj.type, require: val } };
          }
        }

        return obj;
      });

      dispatch(storeFormStructure(FORM_STRUCTURE_UPDATE, newState));
    }
  };

  const setFontSize = (val, filedtype) => {
    if (two_col === 1) {
      const newState = getpreviewform.map((obj) => {
        return {
          ...obj,
          type: {
            ...obj.type,
            [col_name]: {
              ...obj.type[col_name],
              data: obj?.type[col_name]?.data?.map((subdata) => {
                if (subdata?.id === id) {
                  if (filedtype == "paragraph" || filedtype == "heading") {
                    return {
                      ...subdata,
                      type: {
                        ...subdata.type,
                        style: {
                          ...subdata.type.style,
                          font_size: val,
                        },
                      },
                    };
                  }
                  if (filedtype == "image") {
                    return {
                      ...subdata,
                      type: {
                        ...subdata.type,
                        style: {
                          ...subdata.type.style,
                          display: val,
                        },
                      },
                    };
                  }
                }
                return subdata;
              }),
            },
          },
        };
      });

      dispatch(storeFormStructure(FORM_STRUCTURE_UPDATE, newState));
    } else {
      const newState = getpreviewform.map((obj) => {
        if (obj.id === id) {
          if (filedtype == "paragraph" || filedtype == "heading") {
            return {
              ...obj,
              type: {
                ...obj.type,
                style: {
                  ...obj.type.style,
                  font_size: val,
                },
              },
            };
          }

          if (filedtype == "image") {
            return {
              ...obj,
              type: {
                ...obj.type,
                style: {
                  ...obj.type.style,
                  display: val,
                },
              },
            };
          }
        }
        return obj;
      });

      dispatch(storeFormStructure(FORM_STRUCTURE_UPDATE, newState));
    }
  };

  const setFontColor = (val, filedtype) => {
    if (two_col === 1) {
      const newState = getpreviewform.map((obj) => {
        return {
          ...obj,
          type: {
            ...obj.type,
            [col_name]: {
              ...obj.type[col_name],
              data: obj.type[col_name].data.map((subdata) => {
                if (subdata?.id === id) {
                  if (filedtype == "paragraph" || filedtype == "heading") {
                    return {
                      ...subdata,
                      type: {
                        ...subdata.type,
                        style: {
                          ...subdata.type.style,
                          text_color: val,
                        },
                      },
                    };
                  }
                }
                return subdata;
              }),
            },
          },
        };
      });
      dispatch(storeFormStructure(FORM_STRUCTURE_UPDATE, newState));
    } else {
      const newState = getpreviewform.map((obj) => {
        if (obj.id === id) {
          if (filedtype == "paragraph" || filedtype == "heading") {
            return {
              ...obj,
              type: {
                ...obj.type,
                style: {
                  ...obj.type.style,
                  text_color: val,
                },
              },
            };
          }
        }
        return obj;
      });
      dispatch(storeFormStructure(FORM_STRUCTURE_UPDATE, newState));
    }
  };

  const addTableFieldRows = (val) => {
    const addtablerow = getpreviewform.map((item) => {
      const Addar = {
        col: {
          value: "column" + optioncount,
        },
        id: shortid.generate() + Math.random(),
      };

      if (item?.id === val) {
        Setoptioncount(optioncount + 1);

        return {
          ...item,
          type: {
            ...item.type,
            data: { ...item.type.data, col: [...item.type.data.col, Addar] },
          },
        };
      }
      return item;
    });

    dispatch(storeFormStructure(FORM_STRUCTURE_UPDATE, addtablerow));
  };

  const addTableRows = (val) => {
    if (two_col === 1) {
      const addtablerow = getpreviewform.map((item) => {
        const Addar = {
          label: "Option " + optioncount,
          value: "Option " + optioncount,
          id: shortid.generate() + Math.random(),
        };

        return {
          ...item,
          type: {
            ...item.type,
            [col_name]: {
              ...item.type[col_name],
              data: item?.type[col_name]?.data?.map((subdata) => {
                if (subdata?.id === id) {
                  Setoptioncount(optioncount + 1);
                  return {
                    ...subdata,
                    type: {
                      ...subdata.type,
                      options: [...subdata.type.options, Addar],
                    },
                  };
                }
                return subdata;
              }),
            },
          },
        };
      });

      dispatch(storeFormStructure(FORM_STRUCTURE_UPDATE, addtablerow));
    } else {
      const addtablerow = getpreviewform.map((item) => {
        const Addar = {
          label: "Option " + optioncount,
          value: "Option " + optioncount,
          id: shortid.generate() + Math.random(),
        };

        if (item?.id === val) {
          Setoptioncount(optioncount + 1);

          return {
            ...item,
            type: { ...item.type, options: [...item.type.options, Addar] },
          };
        }

        return item;
      });

      dispatch(storeFormStructure(FORM_STRUCTURE_UPDATE, addtablerow));
    }
  };

  const addTableRows2 = (val) => {
    if (two_col === 1) {
      const addtablerow = getpreviewform.map((item) => {
        const Addar = {
          label: "Option " + optioncount2,
          value: "Option " + optioncount2,
          id: shortid.generate() + Math.random(),
        };

        return {
          ...item,
          type: {
            ...item.type,
            [col_name]: {
              ...item.type[col_name],
              data: item?.type[col_name]?.data?.map((subdata) => {
                if (subdata?.id === id) {
                  Setoptioncount2(optioncount2 + 1);
                  return {
                    ...subdata,
                    type: {
                      ...subdata.type,
                      options: [...subdata.type.options, Addar],
                    },
                  };
                }
                return subdata;
              }),
            },
          },
        };
      });

      dispatch(storeFormStructure(FORM_STRUCTURE_UPDATE, addtablerow));
    } else {
      const addtablerow = getpreviewform.map((item) => {
        const Addar = {
          label: "Option " + optioncount2,
          value: "Option " + optioncount2,
          id: shortid.generate() + Math.random(),
        };

        if (item?.id === val) {
          Setoptioncount2(optioncount2 + 1);

          return {
            ...item,
            type: { ...item.type, options: [...item.type.options, Addar] },
          };
        }

        return item;
      });

      dispatch(storeFormStructure(FORM_STRUCTURE_UPDATE, addtablerow));
    }
  };

  const addTableHeader = async (val) => {
    if (two_col === 1) {
      const addtablerow = getpreviewform.map((item) => {
        const Addar = {
          name: "Col " + colcount,
          id: shortid.generate() + Math.random(),
        };

        return {
          ...item,
          type: {
            ...item.type,
            [col_name]: {
              ...item.type[col_name],
              data: item?.type[col_name]?.data?.map((subdata) => {
                if (subdata?.id === id) {
                  Setcolcount(colcount + 1);
                  return {
                    ...subdata,
                    type: {
                      ...subdata.type,
                      header: {
                        ...subdata.type.header,
                        data: [...subdata.type.header.data, Addar],
                      },
                    },
                  };
                }
                return subdata;
              }),
            },
          },
        };
      });

      if (addtablerow?.length > 0) {
        const addtablerow2 = addtablerow.map((item) => {
          const Addar2 = "";
          return {
            ...item,
            type: {
              ...item.type,
              [col_name]: {
                ...item.type[col_name],
                data: item?.type[col_name]?.data?.map((subdata) => {
                  if (subdata?.id === id) {
                    return {
                      ...subdata,
                      type: {
                        ...subdata.type,
                        table: {
                          ...subdata.type.table,
                          data: subdata.type.table.data.map((subdata) => {
                            return [...subdata, Addar2];
                          }),
                        },
                      },
                    };
                  }
                  return subdata;
                }),
              },
            },
          };
        });

        if (addtablerow2?.length > 0) {
          dispatch(storeFormStructure(FORM_STRUCTURE_UPDATE, addtablerow2));
        }
      }
    } else {
      const addtablerow = getpreviewform.map((item) => {
        console.log("item", item);
        const Addar = {
          name: "Col " + colcount,
          id: shortid.generate() + Math.random(),
        };

        if (item?.id === val) {
          Setcolcount(colcount + 1);

          return {
            ...item,
            type: {
              ...item.type,
              header: {
                ...item.type.header,
                data: [...item.type.header.data, Addar],
              },
            },
          };
        }
        return item;
      });

      if (addtablerow?.length > 0) {
        const addtablerow2 = addtablerow.map((item) => {
          const Addar2 = "";
          if (item?.id === val) {
            return {
              ...item,
              type: {
                ...item.type,
                table: {
                  ...item.type.table,
                  data: item.type.table.data.map((subdata) => {
                    return [...subdata, Addar2];
                  }),
                },
              },
            };
          }
          return item;
        });

        if (addtablerow2?.length > 0) {
          dispatch(storeFormStructure(FORM_STRUCTURE_UPDATE, addtablerow2));
        }
      }
    }
  };

  const addVerticalTableHeader = async (val) => {
    if (two_col === 1) {
      const addtablerow = getpreviewform.map((item) => {
        const Addar = {
          name: "Row " + vColCount,
          id: shortid.generate() + Math.random(),
        };
        return {
          ...item,
          type: {
            ...item.type,
            [col_name]: {
              ...item.type[col_name],
              data: item?.type[col_name]?.data?.map((subdata) => {
                if (subdata?.id === id) {
                  setVColCount(vColCount + 1);
                  return {
                    ...subdata,
                    type: {
                      ...subdata.type,
                      header: {
                        ...subdata.type.header,
                        data: [...subdata.type.header.data, Addar],
                      },
                    },
                  };
                }
                return subdata;
              }),
            },
          },
        };
      });

      if (addtablerow?.length > 0) {
        const addtablerow2 = addtablerow.map((item) => {
          let length = item?.type?.table?.data?.length;
          const Addar2 = [];
          for (var i = 0; i < length; i++) {
            Addar2.push("");
          }
          console.log("item?.id", item?.id);
          console.log("val", val);
          if (item?.id === val) {
            return {
              ...item,
              type: {
                ...item.type,
                table: {
                  ...item.type.table,
                  data: [...item.type.table.data, Addar2],
                },
              },
            };
          }
          return item;
        });

        if (addtablerow2?.length > 0) {
          dispatch(storeFormStructure(FORM_STRUCTURE_UPDATE, addtablerow2));
        }
      }
    } else {
      const addtablerow = getpreviewform.map((item) => {
        console.log("item", item);
        const Addar = {
          name: "Row " + vColCount,
          id: shortid.generate() + Math.random(),
        };

        if (item?.id === val) {
          setVColCount(vColCount + 1);

          return {
            ...item,
            type: {
              ...item.type,
              header: {
                ...item.type.header,
                data: [...item.type.header.data, Addar],
              },
            },
          };
        }
        return item;
      });

      if (addtablerow?.length > 0) {
        const addtablerow2 = addtablerow.map((item) => {
          let length = item?.type?.table?.data?.length;
          const Addar2 = [];
          for (var i = 0; i < length; i++) {
            Addar2.push("");
          }
          console.log("item?.id", item?.id);
          console.log("val", val);
          if (item?.id === val) {
            return {
              ...item,
              type: {
                ...item.type,
                table: {
                  ...item.type.table,
                  data: [...item.type.table.data, Addar2],
                },
              },
            };
          }
          return item;
        });

        if (addtablerow2?.length > 0) {
          dispatch(storeFormStructure(FORM_STRUCTURE_UPDATE, addtablerow2));
        }
      }
    }
  };

  const addTablesingleRows = async (val) => {
    if (two_col === 1) {
      const addtablerow = getpreviewform.map((item) => {
        return {
          ...item,
          type: {
            ...item.type,
            [col_name]: {
              ...item.type[col_name],
              data: item?.type[col_name]?.data?.map((subdata) => {
                let length = subdata?.type?.header?.data?.length;
                const Addar = [];
                for (var i = 0; i < length; i++) {
                  Addar.push("");
                }

                if (subdata?.id === id) {
                  return {
                    ...subdata,
                    type: {
                      ...subdata.type,
                      table: {
                        ...subdata.type.table,
                        data: [...subdata.type.table.data, Addar],
                      },
                    },
                  };
                }
                return subdata;
              }),
            },
          },
        };
      });

      if (addtablerow?.length > 0) {
        dispatch(storeFormStructure(FORM_STRUCTURE_UPDATE, addtablerow));
      }
    } else {
      const addtablerow = getpreviewform.map((item) => {
        let length = item?.type?.header?.data?.length;
        const Addar = [];
        for (var i = 0; i < length; i++) {
          Addar.push("");
        }

        if (item?.id === val) {
          return {
            ...item,
            type: {
              ...item.type,
              table: {
                ...item.type.table,
                data: [...item.type.table.data, Addar],
              },
            },
          };
        }
        return item;
      });

      if (addtablerow?.length > 0) {
        dispatch(storeFormStructure(FORM_STRUCTURE_UPDATE, addtablerow));
      }
    }
  };
  const addVerticalTableSingleRows = async (val) => {
    if (two_col === 1) {
      const addtablerow = getpreviewform.map((item) => {
        return {
          ...item,
          type: {
            ...item.type,
            [col_name]: {
              ...item.type[col_name],
              data: item?.type[col_name]?.data?.map((subdata) => {
                let length = subdata?.type?.header?.data?.length;
                const Addar = [];
                for (var i = 0; i < length; i++) {
                  Addar.push("");
                }

                if (subdata?.id === id) {
                  return {
                    ...subdata,
                    type: {
                      ...subdata.type,
                      table: {
                        ...subdata.type.table,
                        data: [...subdata.type.table.data, Addar],
                      },
                    },
                  };
                }
                return subdata;
              }),
            },
          },
        };
      });

      if (addtablerow?.length > 0) {
        dispatch(storeFormStructure(FORM_STRUCTURE_UPDATE, addtablerow));
      }
    } else {
      const addtablerow = getpreviewform.map((item) => {
        const Addar = "";
        if (item?.id === val) {
          return {
            ...item,
            type: {
              ...item.type,
              table: {
                ...item.type.table,
                data: item.type.table.data.map((subdata) => {
                  return [...subdata, Addar];
                }),
              },
            },
          };
        }
        return item;
      });

      if (addtablerow?.length > 0) {
        dispatch(storeFormStructure(FORM_STRUCTURE_UPDATE, addtablerow));
      }
    }
  };

  const setTablerowsvalue = async (val, i, j, val_id) => {
    if (two_col === 1) {
      const addtablerow2 = getpreviewform.map((item) => {
        return {
          ...item,
          type: {
            ...item.type,
            [col_name]: {
              ...item.type[col_name],
              data: item?.type[col_name]?.data?.map((subdata) => {
                if (subdata?.id === id) {
                  return {
                    ...subdata,
                    type: {
                      ...subdata.type,
                      table: {
                        ...subdata.type.table,
                        data: subdata.type.table.data.map((subdatatt, k) => {
                          if (k === i) {
                            subdatatt[j] = val;
                            return [...subdatatt];
                          } else {
                            return [...subdatatt];
                          }
                        }),
                      },
                    },
                  };
                }
                return subdata;
              }),
            },
          },
        };
      });

      if (addtablerow2?.length > 0) {
        dispatch(storeFormStructure(FORM_STRUCTURE_UPDATE, addtablerow2));
      }
    } else {
      const addtablerow2 = getpreviewform.map((item) => {
        if (item?.id === val_id) {
          return {
            ...item,
            type: {
              ...item.type,
              table: {
                ...item.type.table,
                data: item.type.table.data.map((subdata, k) => {
                  if (k === i) {
                    subdata[j] = val;
                    return [...subdata];
                  } else {
                    return [...subdata];
                  }
                }),
              },
            },
          };
        }
        return item;
      });

      if (addtablerow2?.length > 0) {
        dispatch(storeFormStructure(FORM_STRUCTURE_UPDATE, addtablerow2));
      }
    }
  };
  const setVerticalTableRowsValue = async (val, i, j, val_id) => {
    if (two_col === 1) {
      const addtablerow2 = getpreviewform.map((item) => {
        return {
          ...item,
          type: {
            ...item.type,
            [col_name]: {
              ...item.type[col_name],
              data: item?.type[col_name]?.data?.map((subdata) => {
                if (subdata?.id === id) {
                  return {
                    ...subdata,
                    type: {
                      ...subdata.type,
                      table: {
                        ...subdata.type.table,
                        data: subdata.type.table.data.map((subdatatt, k) => {
                          if (k === i) {
                            subdatatt[j] = val;
                            return [...subdatatt];
                          } else {
                            return [...subdatatt];
                          }
                        }),
                      },
                    },
                  };
                }
                return subdata;
              }),
            },
          },
        };
      });

      if (addtablerow2?.length > 0) {
        dispatch(storeFormStructure(FORM_STRUCTURE_UPDATE, addtablerow2));
      }
    } else {
      const addtablerow2 = getpreviewform.map((item) => {
        if (item?.id === val_id) {
          return {
            ...item,
            type: {
              ...item.type,
              table: {
                ...item.type.table,
                data: item.type.table.data.map((subdata, k) => {
                  if (k === i) {
                    subdata[j] = val;
                    return [...subdata];
                  } else {
                    return [...subdata];
                  }
                }),
              },
            },
          };
        }
        return item;
      });

      if (addtablerow2?.length > 0) {
        dispatch(storeFormStructure(FORM_STRUCTURE_UPDATE, addtablerow2));
      }
    }
  };

  const deleteTablesingleRows = async (val, i) => {
    if (two_col === 1) {
      const addtablerow2 = getpreviewform.map((item) => {
        return {
          ...item,
          type: {
            ...item.type,
            [col_name]: {
              ...item.type[col_name],
              data: item?.type[col_name]?.data?.map((subdata) => {
                if (subdata?.id === id) {
                  subdata.type.table.data.splice(i, 1);
                  return {
                    ...subdata,
                  };
                }
                return subdata;
              }),
            },
          },
        };
      });

      if (addtablerow2?.length > 0) {
        dispatch(storeFormStructure(FORM_STRUCTURE_UPDATE, addtablerow2));
      }
    } else {
      const addtablerow2 = getpreviewform.map((item) => {
        if (item?.id === val) {
          item.type.table.data.splice(i, 1);
          return {
            ...item,
          };
        }
        return item;
      });

      if (addtablerow2?.length > 0) {
        dispatch(storeFormStructure(FORM_STRUCTURE_UPDATE, addtablerow2));
      }
    }
  };
  const deleteVerticalTableSingleRows = async (val, i) => {
    if (two_col === 1) {
      const addtablerow2 = getpreviewform.map((item) => {
        return {
          ...item,
          type: {
            ...item.type,
            [col_name]: {
              ...item.type[col_name],
              data: item?.type[col_name]?.data?.map((subdata) => {
                if (subdata?.id === id) {
                  subdata.type.table.data.splice(i, 1);
                  return {
                    ...subdata,
                  };
                }
                return subdata;
              }),
            },
          },
        };
      });

      if (addtablerow2?.length > 0) {
        dispatch(storeFormStructure(FORM_STRUCTURE_UPDATE, addtablerow2));
      }
    } else {
      // const addtablerow2 = getpreviewform.map((item) => {
      //   if (item?.id === val) {
      //     item.type.table.data.splice(i, 1);
      //     return {
      //       ...item,
      //     };
      //   }
      //   return item;
      // });

      const addtablerow2 = getpreviewform.map((item) => {
        return {
          ...item,
          type: {
            ...item.type,
            table: {
              ...item.type.table,
              data: item?.type?.table?.data?.map((subdata) => {
                const push = subdata.pop();

                return subdata;
              }),
            },
          },
        };
      });

      if (addtablerow2?.length > 0) {
        dispatch(storeFormStructure(FORM_STRUCTURE_UPDATE, addtablerow2));
      }
    }
  };

  const setImageURL = async (val, filedtype) => {
    if (two_col === 1) {
      try {
        let filetype = val.name.split(".").pop();
        if (filetype === "jpg" || filetype === "jpeg" || filetype === "png") {
          const payload = {
            image: val,
            recruiter_id: auth.results.recruiter_id,
            workspace_id: auth.results.workspace_id,
          };

          const response = await uploadImagesformbuilder(payload);

          const newState = getpreviewform.map((obj) => {
            return {
              ...obj,
              type: {
                ...obj.type,
                [col_name]: {
                  ...obj.type[col_name],
                  data: obj?.type[col_name]?.data?.map((subdata) => {
                    if (subdata?.id === id) {
                      return {
                        ...subdata,
                        type: {
                          ...subdata.type,
                          img_url: response.data.results["image_path"],
                        },
                      };
                    }
                    return subdata;
                  }),
                },
              },
            };
          });

          dispatch(storeFormStructure(FORM_STRUCTURE_UPDATE, newState));
        } else {
          dispatch(ShowAlert("Supported file jpg,jpeg,png.", "error"));
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        let filetype = val.name.split(".").pop();
        if (filetype === "jpg" || filetype === "jpeg" || filetype === "png") {
          const payload = {
            image: val,
            recruiter_id: auth.results.recruiter_id,
            workspace_id: auth.results.workspace_id,
          };

          const response = await uploadImagesformbuilder(payload);

          const newState = getpreviewform.map((obj) => {
            if (obj.id === id) {
              return {
                ...obj,
                type: {
                  ...obj.type,
                  img_url: response.data.results["image_path"],
                },
              };
            }

            return obj;
          });

          dispatch(storeFormStructure(FORM_STRUCTURE_UPDATE, newState));
        } else {
          dispatch(ShowAlert("Supported file jpg,jpeg,png.", "error"));
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const [sketchPickerColor, setSketchPickerColor] = useState("#000");
  const [openColorPicker, setOpenColorPicker] = useState(false);
  const handleColorPickerBox = () => {
    setOpenColorPicker(!openColorPicker);
  };

  const setInputPlaceholdercheckbox = (val, idss) => {
    if (two_col === 1) {
      const newState = getpreviewform.map((obj) => {
        return {
          ...obj,
          type: {
            ...obj.type,
            [col_name]: {
              ...obj.type[col_name],
              data: obj?.type[col_name]?.data?.map((subdata) => {
                if (subdata?.id === id) {
                  return {
                    ...subdata,
                    type: {
                      ...subdata.type,
                      options: [
                        ...subdata.type.options.map((subitem, subitemindex) => {
                          if (subitem.id === idss) {
                            return {
                              ...subitem,
                              label: val,
                              value: val,
                            };
                          }
                          return subitem;
                        }),
                      ],
                    },
                  };
                }
                return subdata;
              }),
            },
          },
        };
      });

      dispatch(storeFormStructure(FORM_STRUCTURE_UPDATE, newState));
    } else {
      const newState = getpreviewform.map((obj) => {
        if (obj.id === id) {
          return {
            ...obj,
            type: {
              ...obj.type,
              options: [
                ...obj.type.options.map((subitem, subitemindex) => {
                  if (subitem.id === idss) {
                    return {
                      ...subitem,
                      label: val,
                      value: val,
                    };
                  }
                  return subitem;
                }),
              ],
            },
          };
        }

        return obj;
      });

      dispatch(storeFormStructure(FORM_STRUCTURE_UPDATE, newState));
    }
  };

  const setInputPlaceholdertableheader = (val, idss) => {
    if (two_col === 1) {
      const newState = getpreviewform.map((obj) => {
        return {
          ...obj,
          type: {
            ...obj.type,
            [col_name]: {
              ...obj.type[col_name],
              data: obj.type[col_name].data.map((subdata) => {
                if (subdata?.id === id) {
                  return {
                    ...subdata,
                    type: {
                      ...subdata.type,
                      header: {
                        ...subdata.type.header,
                        data: [
                          ...subdata.type.header.data.map(
                            (subitem, subitemindex) => {
                              if (subitem.id === idss) {
                                return {
                                  ...subitem,
                                  name: val,
                                };
                              }
                              return subitem;
                            }
                          ),
                        ],
                      },
                    },
                  };
                }
                return subdata;
              }),
            },
          },
        };
      });

      dispatch(storeFormStructure(FORM_STRUCTURE_UPDATE, newState));
    } else {
      const newState = getpreviewform.map((obj) => {
        if (obj.id === id) {
          return {
            ...obj,
            type: {
              ...obj.type,
              header: {
                ...obj.type.header,
                data: [
                  ...obj.type.header.data.map((subitem, subitemindex) => {
                    if (subitem.id === idss) {
                      return {
                        ...subitem,
                        name: val,
                      };
                    }
                    return subitem;
                  }),
                ],
              },
            },
          };
        }

        return obj;
      });

      dispatch(storeFormStructure(FORM_STRUCTURE_UPDATE, newState));
    }
  };

  const setInputValuecheckbox = (val, idss) => {
    const newState = getpreviewform.map((obj) => {
      if (obj.id === id) {
        return {
          ...obj,
          type: {
            ...obj.type,
            options: [
              ...obj.type.options.map((subitem, subitemindex) => {
                if (subitem.id === idss) {
                  return {
                    ...subitem,
                    value: val,
                  };
                }
                return subitem;
              }),
            ],
          },
        };
      }

      return obj;
    });

    dispatch(storeFormStructure(FORM_STRUCTURE_UPDATE, newState));
  };

  const deletecheckbox = (idss) => {
    {
      if (two_col === 1) {
        const New = getpreviewform.map((obj) => {
          return {
            ...obj,
            type: {
              ...obj.type,
              [col_name]: {
                ...obj.type[col_name],
                data: obj?.type[col_name]?.data?.map((subdata) => {
                  if (subdata?.id === id) {
                    const Newa = subdata.type.options.filter(checID);
                    function checID(newid) {
                      return idss !== newid.id;
                    }
                    return {
                      ...subdata,
                      type: { ...subdata.type, options: Newa },
                    };
                  }
                  return subdata;
                }),
              },
            },
          };
        });

        dispatch(storeFormStructure(FORM_STRUCTURE_UPDATE, New));
      } else {
        const New = getpreviewform.map((obj) => {
          if (obj.id === id) {
            const Newa = obj.type.options.filter(checID);
            function checID(newid) {
              return idss !== newid.id;
            }
            return {
              ...obj,
              type: { ...obj.type, options: Newa },
            };
          }
          return obj;
        });
        dispatch(storeFormStructure(FORM_STRUCTURE_UPDATE, New));
      }
    }
  };

  const deleteTableHeader = (idss) => {
    if (two_col === 1) {
      {
        const New = getpreviewform.map((obj) => {
          return {
            ...obj,
            type: {
              ...obj.type,
              [col_name]: {
                ...obj.type[col_name],
                data: obj?.type[col_name]?.data?.map((subdata) => {
                  if (subdata?.id === id) {
                    const Newa = subdata.type.header.data.filter(checID);
                    function checID(newid) {
                      return idss !== newid.id;
                    }
                    return {
                      ...subdata,
                      type: {
                        ...subdata.type,
                        header: {
                          ...subdata.type.header,
                          data: Newa,
                        },
                      },
                    };
                  }
                  return subdata;
                }),
              },
            },
          };
        });

        if (New?.length > 0) {
          const addtablerow2 = New.map((item) => {
            return {
              ...item,
              type: {
                ...item.type,
                [col_name]: {
                  ...item.type[col_name],
                  data: item?.type[col_name]?.data?.map((subdata) => {
                    if (subdata?.id === id) {
                      return {
                        ...subdata,
                        type: {
                          ...subdata.type,
                          table: {
                            ...subdata.type.table,
                            data: subdata?.type?.table?.data?.map(
                              (subdatat) => {
                                const push = subdatat.pop();

                                return subdatat;
                              }
                            ),
                          },
                        },
                      };
                    }
                    return subdata;
                  }),
                },
              },
            };
          });

          if (addtablerow2?.length > 0) {
            dispatch(storeFormStructure(FORM_STRUCTURE_UPDATE, addtablerow2));
          }
        }
      }
    } else {
      {
        const New = getpreviewform.map((obj) => {
          if (obj.id === id) {
            const Newa = obj.type.header.data.filter(checID);
            function checID(newid) {
              return idss !== newid.id;
            }
            return {
              ...obj,
              type: {
                ...obj.type,
                header: {
                  ...obj.type.header,
                  data: Newa,
                },
              },
            };
          }
          return obj;
        });

        if (New?.length > 0) {
          const addtablerow2 = New.map((item) => {
            return {
              ...item,
              type: {
                ...item.type,
                table: {
                  ...item.type.table,
                  data: item?.type?.table?.data?.map((subdata) => {
                    const push = subdata.pop();

                    return subdata;
                  }),
                },
              },
            };
          });

          if (addtablerow2?.length > 0) {
            dispatch(storeFormStructure(FORM_STRUCTURE_UPDATE, addtablerow2));
          }
        }
      }
    }
  };
  const deleteVerticalTableHeader = (idss, i) => {
    if (two_col === 1) {
      {
        const New = getpreviewform.map((obj) => {
          return {
            ...obj,
            type: {
              ...obj.type,
              [col_name]: {
                ...obj.type[col_name],
                data: obj?.type[col_name]?.data?.map((subdata) => {
                  if (subdata?.id === id) {
                    const Newa = subdata.type.header.data.filter(checID);
                    function checID(newid) {
                      return idss !== newid.id;
                    }
                    return {
                      ...subdata,
                      type: {
                        ...subdata.type,
                        header: {
                          ...subdata.type.header,
                          data: Newa,
                        },
                      },
                    };
                  }
                  return subdata;
                }),
              },
            },
          };
        });

        if (New?.length > 0) {
          const addtablerow2 = New.map((item) => {
            return {
              ...item,
              type: {
                ...item.type,
                [col_name]: {
                  ...item.type[col_name],
                  data: item?.type[col_name]?.data?.map((subdata) => {
                    if (subdata?.id === id) {
                      return {
                        ...subdata,
                        type: {
                          ...subdata.type,
                          table: {
                            ...subdata.type.table,
                            data: subdata?.type?.table?.data?.map(
                              (subdatat) => {
                                const push = subdatat.pop();

                                return subdatat;
                              }
                            ),
                          },
                        },
                      };
                    }
                    return subdata;
                  }),
                },
              },
            };
          });

          if (addtablerow2?.length > 0) {
            dispatch(storeFormStructure(FORM_STRUCTURE_UPDATE, addtablerow2));
          }
        }
      }
    } else {
      {
        const New = getpreviewform.map((item) => {
          if (item?.id === idss) {
            item.type.header.data.splice(i, 1);
            return {
              ...item,
            };
          }
          return item;
        });

        if (New?.length > 0) {
          const addtablerow2 = New.map((item) => {
            if (item?.id === idss) {
              item.type.table.data.splice(i, 1);
              return {
                ...item,
              };
            }
            return item;
          });

          if (addtablerow2?.length > 0) {
            dispatch(storeFormStructure(FORM_STRUCTURE_UPDATE, addtablerow2));
          }
        }
      }
    }
  };

  useEffect(() => {}, []);
  return (
    <>
      <Alerts />
      {two_col === 1 ? (
        type === "input" ? (
          <Modal
            open={openModal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className={`${styles.industryModel}`}
          >
            <Box className={`${styles.boxModel} xym1`}>
              <Box className={styles.modalwapper}>
                <Box className={styles.cross} onClick={handleClose}>
                  <Cross />
                </Box>
                <Typography variant="h5" className={styles.heading}>
                  Text Input
                </Typography>
                <Box className={styles.statusbox}>
                  <Box className={styles.offermodelDetail}>
                    <label>Label text</label>
                    <input
                      type="text"
                      value={handelCondition("label")}
                      onChange={(e) => {
                        setInputLabel(e?.target?.value, type);
                      }}
                    />
                  </Box>
                  <Box className={styles.offermodelDetail}>
                    <label>Placeholder text</label>
                    <input
                      type="text"
                      value={handelCondition("placeholder")}
                      onChange={(e) => {
                        setInputPlaceholder(e?.target?.value, type);
                      }}
                    />
                  </Box>
                  <Box className={styles.offermodelDetail}>
                    <label className="labelContainer">
                      This field is Required.
                      {handelCondition("require") === true ? (
                        <input
                          type="checkbox"
                          checked
                          onChange={(e) => {
                            setRequiredField(false, type);
                          }}
                        />
                      ) : (
                        <input
                          type="checkbox"
                          onChange={(e) => {
                            setRequiredField(true, type);
                          }}
                        />
                      )}
                      <span className="checkbox"></span>
                    </label>
                  </Box>
                  <Box
                    className={`${styles.button} button-primary d-flex justify-end`}
                  >
                    <Button className="" onClick={handleClose}>
                      Update
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Modal>
        ) : type === "paragraph" ? (
          <Modal
            open={openModal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className={`${styles.industryModel}`}
          >
            <Box className={`${styles.boxModel} xym1`}>
              <Box className={styles.modalwapper}>
                <Box className={styles.cross} onClick={handleClose}>
                  <Cross />
                </Box>
                <Typography variant="h5" className={styles.heading}>
                  Paragraph
                </Typography>
                <Box className={styles.statusbox}>
                  <Box className={styles.offermodelDetail}>
                    <label>Paragraph text</label>
                    <textarea
                      value={handelCondition("field_value")}
                      onChange={(e) => {
                        setInputLabel(e?.target?.value, type);
                      }}
                    />
                  </Box>
                  <Box className={styles.offermodelDetail}>
                    <label>Paragraph font size</label>
                    <input
                      type="number"
                      value={handelCondition("font_size")}
                      onChange={(e) => {
                        const value = Math.max(
                          0,
                          Math.min(50, parseInt(e.target.value))
                        );
                        setFontSize(value, type);
                      }}
                    />
                  </Box>
                  <Box className={styles.offermodelDetail}>
                    <label>Paragraph font color</label>
                    <div
                      onClick={handleColorPickerBox}
                      style={{
                        backgroundColor: `${sketchPickerColor}`,
                        border: "2px solid #e1e1e1",
                        width: "40px",
                        height: "20px",
                        borderRadius: "2px",
                      }}
                    ></div>
                    {openColorPicker ? (
                      <div style={{}}>
                        <SketchPicker
                          onChange={(color) => {
                            setSketchPickerColor(color.hex);
                            setFontColor(sketchPickerColor, type);
                          }}
                          color={sketchPickerColor}
                        />
                      </div>
                    ) : null}
                  </Box>

                  <Box
                    className={`${styles.button} button-primary d-flex justify-end`}
                  >
                    <Button className="" onClick={handleClose}>
                      Update
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Modal>
        ) : type === "heading" ? (
          <Modal
            open={openModal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className={`${styles.industryModel}`}
          >
            <Box className={`${styles.boxModel} xym1`}>
              <Box className={styles.modalwapper}>
                <Box className={styles.cross} onClick={handleClose}>
                  <Cross />
                </Box>
                <Typography variant="h5" className={styles.heading}>
                  Heading
                </Typography>
                <Box className={styles.statusbox}>
                  <Box className={styles.offermodelDetail}>
                    <label>Heading text</label>
                    <input
                      type="text"
                      value={handelCondition("field_value")}
                      onChange={(e) => {
                        setInputLabel(e?.target?.value, type);
                      }}
                    />
                  </Box>
                  <Box className={styles.offermodelDetail}>
                    <label>Heading font size</label>
                    <input
                      type="number"
                      value={handelCondition("font_size")}
                      onChange={(e) => {
                        const value = Math.max(
                          0,
                          Math.min(100, parseInt(e.target.value))
                        );
                        setFontSize(value, type);
                      }}
                    />
                  </Box>

                  <Box className={styles.offermodelDetail}>
                    <label>Heading font color</label>
                    <div
                      onClick={handleColorPickerBox}
                      style={{
                        backgroundColor: `${sketchPickerColor}`,
                        border: "2px solid #e1e1e1",
                        width: "40px",
                        height: "20px",
                        borderRadius: "2px",
                      }}
                    ></div>
                    {openColorPicker ? (
                      <div style={{}}>
                        <SketchPicker
                          onChange={(color) => {
                            setSketchPickerColor(color.hex);
                            setFontColor(sketchPickerColor, type);
                          }}
                          color={sketchPickerColor}
                        />
                      </div>
                    ) : null}
                  </Box>

                  <Box
                    className={`${styles.button} button-primary d-flex justify-end`}
                  >
                    <Button className="" onClick={handleClose}>
                      Update
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Modal>
        ) : type === "email" ? (
          <Modal
            open={openModal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className={`${styles.industryModel}`}
          >
            <Box className={`${styles.boxModel} xym1`}>
              <Box className={styles.modalwapper}>
                <Box className={styles.cross} onClick={handleClose}>
                  <Cross />
                </Box>
                <Typography variant="h5" className={styles.heading}>
                  Email Address
                </Typography>
                <Box className={styles.statusbox}>
                  <Box className={styles.offermodelDetail}>
                    <label>Label text</label>
                    <input
                      type="text"
                      value={handelCondition("label")}
                      onChange={(e) => {
                        setInputLabel(e?.target?.value, type);
                      }}
                    />
                  </Box>
                  <Box className={styles.offermodelDetail}>
                    <label>Placeholder text</label>
                    <input
                      type="text"
                      value={handelCondition("placeholder")}
                      onChange={(e) => {
                        setInputPlaceholder(e?.target?.value, type);
                      }}
                    />
                  </Box>
                  <Box className={styles.offermodelDetail}>
                    <label className="labelContainer">
                      This field is Required.
                      {handelCondition("require") === true ? (
                        <input
                          type="checkbox"
                          checked
                          onChange={(e) => {
                            setRequiredField(false, type);
                          }}
                        />
                      ) : (
                        <input
                          type="checkbox"
                          onChange={(e) => {
                            setRequiredField(true, type);
                          }}
                        />
                      )}
                      <span className="checkbox"></span>
                    </label>
                  </Box>
                  <Box
                    className={`${styles.button} button-primary d-flex justify-end`}
                  >
                    <Button className="" onClick={handleClose}>
                      Update
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Modal>
        ) : type === "phone" ? (
          <Modal
            open={openModal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className={`${styles.industryModel}`}
          >
            <Box className={`${styles.boxModel} xym1`}>
              <Box className={styles.modalwapper}>
                <Box className={styles.cross} onClick={handleClose}>
                  <Cross />
                </Box>
                <Typography variant="h5" className={styles.heading}>
                  Phone
                </Typography>
                <Box className={styles.statusbox}>
                  <Box className={styles.offermodelDetail}>
                    <label>Label text</label>
                    <input
                      type="text"
                      value={handelCondition("label")}
                      onChange={(e) => {
                        setInputLabel(e?.target?.value, type);
                      }}
                    />
                  </Box>
                  <Box className={styles.offermodelDetail}>
                    <label>Placeholder text</label>
                    <input
                      type="text"
                      value={handelCondition("placeholder")}
                      onChange={(e) => {
                        setInputPlaceholder(e?.target?.value, type);
                      }}
                    />
                  </Box>
                  <Box className={styles.offermodelDetail}>
                    <label className="labelContainer">
                      This field is Required.
                      {handelCondition("require") === true ? (
                        <input
                          type="checkbox"
                          checked
                          onChange={(e) => {
                            setRequiredField(false, type);
                          }}
                        />
                      ) : (
                        <input
                          type="checkbox"
                          onChange={(e) => {
                            setRequiredField(true, type);
                          }}
                        />
                      )}
                      <span className="checkbox"></span>
                    </label>
                  </Box>
                  <Box
                    className={`${styles.button} button-primary d-flex justify-end`}
                  >
                    <Button className="" onClick={handleClose}>
                      Update
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Modal>
        ) : type === "number" ? (
          <Modal
            open={openModal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className={`${styles.industryModel}`}
          >
            <Box className={`${styles.boxModel} xym1`}>
              <Box className={styles.modalwapper}>
                <Box className={styles.cross} onClick={handleClose}>
                  <Cross />
                </Box>
                <Typography variant="h5" className={styles.heading}>
                  Number
                </Typography>
                <Box className={styles.statusbox}>
                  <Box className={styles.offermodelDetail}>
                    <label>Label text</label>
                    <input
                      type="text"
                      value={handelCondition("label")}
                      onChange={(e) => {
                        setInputLabel(e?.target?.value, type);
                      }}
                    />
                  </Box>
                  <Box className={styles.offermodelDetail}>
                    <label>Placeholder text</label>
                    <input
                      type="text"
                      value={handelCondition("placeholder")}
                      onChange={(e) => {
                        setInputPlaceholder(e?.target?.value, type);
                      }}
                    />
                  </Box>
                  <Box className={styles.offermodelDetail}>
                    <label className="labelContainer">
                      This field is Required.
                      {handelCondition("require") === true ? (
                        <input
                          type="checkbox"
                          checked
                          onChange={(e) => {
                            setRequiredField(false, type);
                          }}
                        />
                      ) : (
                        <input
                          type="checkbox"
                          onChange={(e) => {
                            setRequiredField(true, type);
                          }}
                        />
                      )}
                      <span className="checkbox"></span>
                    </label>
                  </Box>
                  <Box
                    className={`${styles.button} button-primary d-flex justify-end`}
                  >
                    <Button className="" onClick={handleClose}>
                      Update
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Modal>
        ) : type === "date" ? (
          <Modal
            open={openModal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className={`${styles.industryModel}`}
          >
            <Box className={`${styles.boxModel} xym1`}>
              <Box className={styles.modalwapper}>
                <Box className={styles.cross} onClick={handleClose}>
                  <Cross />
                </Box>
                <Typography variant="h5" className={styles.heading}>
                  Date
                </Typography>
                <Box className={styles.statusbox}>
                  <Box className={styles.offermodelDetail}>
                    <label>Date text</label>
                    <input
                      type=""
                      value={handelCondition("label")}
                      onChange={(e) => {
                        setInputLabel(e?.target?.value, type);
                      }}
                    />
                  </Box>
                  <Box className={styles.offermodelDetail}>
                    <label className="labelContainer">
                      This field is Required.
                      {handelCondition("require") === true ? (
                        <input
                          type="checkbox"
                          checked
                          onChange={(e) => {
                            setRequiredField(false, type);
                          }}
                        />
                      ) : (
                        <input
                          type="checkbox"
                          onChange={(e) => {
                            setRequiredField(true, type);
                          }}
                        />
                      )}
                      <span className="checkbox"></span>
                    </label>
                  </Box>

                  <Box
                    className={`${styles.button} button-primary d-flex justify-end`}
                  >
                    <Button className="" onClick={handleClose}>
                      Update
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Modal>
        ) : type === "textarea" ? (
          <Modal
            open={openModal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className={`${styles.industryModel}`}
          >
            <Box className={`${styles.boxModel} xym1`}>
              <Box className={styles.modalwapper}>
                <Box className={styles.cross} onClick={handleClose}>
                  <Cross />
                </Box>
                <Typography variant="h5" className={styles.heading}>
                  Text Area
                </Typography>
                <Box className={styles.statusbox}>
                  <Box className={styles.offermodelDetail}>
                    <label>Label text</label>
                    <input
                      type="text"
                      value={handelCondition("label")}
                      onChange={(e) => {
                        setInputLabel(e?.target?.value, type);
                      }}
                    />
                  </Box>
                  <Box className={styles.offermodelDetail}>
                    <label>Placeholder text</label>
                    <input
                      type="text"
                      value={handelCondition("placeholder")}
                      onChange={(e) => {
                        setInputPlaceholder(e?.target?.value, type);
                      }}
                    />
                  </Box>
                  <Box className={styles.offermodelDetail}>
                    <label className="labelContainer">
                      This field is Required.
                      {handelCondition("require") === true ? (
                        <input
                          type="checkbox"
                          checked
                          onChange={(e) => {
                            setRequiredField(false, type);
                          }}
                        />
                      ) : (
                        <input
                          type="checkbox"
                          onChange={(e) => {
                            setRequiredField(true, type);
                          }}
                        />
                      )}
                      <span className="checkbox"></span>
                    </label>
                  </Box>
                  <Box
                    className={`${styles.button} button-primary d-flex justify-end`}
                  >
                    <Button className="" onClick={handleClose}>
                      Update
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Modal>
        ) : type === "checkbox" ? (
          <Modal
            open={openModal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className={`${styles.industryModel}`}
          >
            <Box className={`${styles.boxModel} xym1`}>
              <Box className={styles.modalwapper}>
                <Box className={styles.cross} onClick={handleClose}>
                  <Cross />
                </Box>
                <Typography variant="h5" className={styles.heading}>
                  Checkbox
                </Typography>
                <Box className={styles.statusbox}>
                  <Box className={styles.offermodelDetail}>
                    <label>Label text</label>
                    <input
                      type="text"
                      value={handelCondition("label")}
                      onChange={(e) => {
                        setInputLabel(e?.target?.value, type);
                      }}
                    />
                  </Box>
                  <Grid container spacing={2}>
                    <Grid item md={12}>
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Option Label</th>

                            <th>
                              <button
                                className="btn btn-outline-success"
                                onClick={(e) => {
                                  addTableRows(id);
                                }}
                              >
                                +
                              </button>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {getpreviewform.map((item) => {
                            {
                              return item?.type[col_name]?.data?.map(
                                (subcol) => {
                                  if (subcol?.id === id) {
                                    {
                                      return subcol?.type?.options.map(
                                        (subitem) => {
                                          return (
                                            <tr>
                                              <td>
                                                <input
                                                  type="text"
                                                  placeholder="Enter label"
                                                  name="olabel"
                                                  value={subitem.label}
                                                  onChange={(e) => {
                                                    setInputPlaceholdercheckbox(
                                                      e?.target?.value,
                                                      subitem.id
                                                    );
                                                  }}
                                                  className="form-control"
                                                />
                                              </td>

                                              <td>
                                                <button
                                                  className="btn btn-outline-danger"
                                                  onClick={(e) => {
                                                    deletecheckbox(subitem.id);
                                                  }}
                                                >
                                                  x
                                                </button>
                                              </td>
                                            </tr>
                                          );
                                        }
                                      );
                                    }
                                  }
                                }
                              );
                            }
                          })}
                        </tbody>
                      </table>
                    </Grid>
                  </Grid>
                  <Box className={styles.offermodelDetail}>
                    <label className="labelContainer">
                      This field is Required.
                      {handelCondition("require") === true ? (
                        <input
                          type="checkbox"
                          checked
                          onChange={(e) => {
                            setRequiredField(false, type);
                          }}
                        />
                      ) : (
                        <input
                          type="checkbox"
                          onChange={(e) => {
                            setRequiredField(true, type);
                          }}
                        />
                      )}
                      <span className="checkbox"></span>
                    </label>
                  </Box>
                  <Box
                    className={`${styles.button} button-primary d-flex justify-end`}
                  >
                    <Button className="" onClick={handleClose}>
                      Update
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Modal>
        ) : type === "upload_file" ? (
          <Modal
            open={openModal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className={`${styles.industryModel}`}
          >
            <Box className={`${styles.boxModel} xym1`}>
              <Box className={styles.modalwapper}>
                <Box className={styles.cross} onClick={handleClose}>
                  <Cross />
                </Box>
                <Typography variant="h5" className={styles.heading}>
                  Upload File
                </Typography>
                <Box className={styles.statusbox}>
                  <Box className={styles.offermodelDetail}>
                    <label>Label text</label>
                    <input
                      type="text"
                      value={handelCondition("label")}
                      onChange={(e) => {
                        setInputLabel(e?.target?.value, type);
                      }}
                    />
                  </Box>
                  <Box className={styles.offermodelDetail}>
                    <label className="labelContainer">
                      This field is Required.
                      {handelCondition("require") === true ? (
                        <input
                          type="checkbox"
                          checked
                          onChange={(e) => {
                            setRequiredField(false, type);
                          }}
                        />
                      ) : (
                        <input
                          type="checkbox"
                          onChange={(e) => {
                            setRequiredField(true, type);
                          }}
                        />
                      )}
                      <span className="checkbox"></span>
                    </label>
                  </Box>
                  <Box
                    className={`${styles.button} button-primary d-flex justify-end`}
                  >
                    <Button className="" onClick={handleClose}>
                      Update
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Modal>
        ) : type === "rating" ? (
          <Modal
            open={openModal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className={`${styles.industryModel}`}
          >
            <Box className={`${styles.boxModel} xym1`}>
              <Box className={styles.modalwapper}>
                <Box className={styles.cross} onClick={handleClose}>
                  <Cross />
                </Box>
                <Typography variant="h5" className={styles.heading}>
                  Rating
                </Typography>
                <Box className={styles.statusbox}>
                  <Box className={styles.offermodelDetail}>
                    <label>Label text</label>
                    <input
                      type="text"
                      value={handelCondition("label")}
                      onChange={(e) => {
                        setInputLabel(e?.target?.value, type);
                      }}
                    />
                  </Box>
                  <Box
                    className={`${styles.button} button-primary d-flex justify-end`}
                  >
                    <Button className="" onClick={handleClose}>
                      Update
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Modal>
        ) : type === "signature" ? (
          <Modal
            open={openModal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className={`${styles.industryModel}`}
          >
            <Box className={`${styles.boxModel} xym1`}>
              <Box className={styles.modalwapper}>
                <Box className={styles.cross} onClick={handleClose}>
                  <Cross />
                </Box>
                <Typography variant="h5" className={styles.heading}>
                  Signature
                </Typography>
                <Box className={styles.statusbox}>
                  <Box className={styles.offermodelDetail}>
                    <label htmlFor="">Label text</label>
                    <input
                      type="text"
                      value={handelCondition("label")}
                      onChange={(e) => {
                        setInputLabel(e?.target?.value, type);
                      }}
                    />
                  </Box>
                  <Box className={styles.offermodelDetail}>
                    <label className="labelContainer">
                      This field is Required.
                      {handelCondition("require") === true ? (
                        <input
                          type="checkbox"
                          checked
                          onChange={(e) => {
                            setRequiredField(false, type);
                          }}
                        />
                      ) : (
                        <input
                          type="checkbox"
                          onChange={(e) => {
                            setRequiredField(true, type);
                          }}
                        />
                      )}
                      <span className="checkbox"></span>
                    </label>
                  </Box>
                  <Box
                    className={`${styles.button} button-primary d-flex justify-end`}
                  >
                    <Button className="" onClick={handleClose}>
                      Update
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Modal>
        ) : type === "radio" ? (
          <Modal
            open={openModal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className={`${styles.industryModel}`}
          >
            <Box className={`${styles.boxModel} xym1`}>
              <Box className={styles.modalwapper}>
                <Box className={styles.cross} onClick={handleClose}>
                  <Cross />
                </Box>
                <Typography variant="h5" className={styles.heading}>
                  Multiple Choice
                </Typography>
                <Box className={styles.statusbox}>
                  <Box className={styles.offermodelDetail}>
                    <label>Label text</label>
                    <input
                      type="text"
                      value={handelCondition("label")}
                      onChange={(e) => {
                        setInputLabel(e?.target?.value, type);
                      }}
                    />
                  </Box>
                  <Grid container spacing={2}>
                    <Grid item md={12}>
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Option Label</th>

                            <th>
                              <button
                                className="btn btn-outline-success"
                                onClick={(e) => {
                                  addTableRows2(id);
                                }}
                              >
                                +
                              </button>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {getpreviewform.map((item) => {
                            return item?.type[col_name]?.data?.map((subcol) => {
                              if (subcol?.id === id) {
                                {
                                  return subcol?.type?.options.map(
                                    (subitem) => {
                                      return (
                                        <tr>
                                          <td>
                                            <input
                                              type="text"
                                              placeholder="Enter label"
                                              name="olabel"
                                              value={subitem.label}
                                              onChange={(e) => {
                                                setInputPlaceholdercheckbox(
                                                  e?.target?.value,
                                                  subitem.id
                                                );
                                              }}
                                              className="form-control"
                                            />
                                          </td>

                                          <td>
                                            <button
                                              className="btn btn-outline-danger"
                                              onClick={(e) => {
                                                deletecheckbox(subitem.id);
                                              }}
                                            >
                                              x
                                            </button>
                                          </td>
                                        </tr>
                                      );
                                    }
                                  );
                                }
                              }
                            });
                          })}
                        </tbody>
                      </table>
                    </Grid>
                  </Grid>
                  <Box className={styles.offermodelDetail}>
                    <label className="labelContainer">
                      This field is Required.
                      {handelCondition("require") === true ? (
                        <input
                          type="checkbox"
                          checked
                          onChange={(e) => {
                            setRequiredField(false, type);
                          }}
                        />
                      ) : (
                        <input
                          type="checkbox"
                          onChange={(e) => {
                            setRequiredField(true, type);
                          }}
                        />
                      )}
                      <span className="checkbox"></span>
                    </label>
                  </Box>
                  <Box
                    className={`${styles.button} button-primary d-flex justify-end`}
                  >
                    <Button className="" onClick={handleClose}>
                      Update
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Modal>
        ) : type === "select" ? (
          <Modal
            open={openModal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className={`${styles.industryModel}`}
          >
            <Box className={`${styles.boxModel} xym1`}>
              <Box className={styles.modalwapper}>
                <Box className={styles.cross} onClick={handleClose}>
                  <Cross />
                </Box>
                <Typography variant="h5" className={styles.heading}>
                  Select
                </Typography>
                <Box className={styles.statusbox}>
                  <Box className={styles.offermodelDetail}>
                    <label>Label text</label>
                    <input
                      type="text"
                      value={handelCondition("label")}
                      onChange={(e) => {
                        setInputLabel(e?.target?.value, type);
                      }}
                    />
                  </Box>
                  <Grid container spacing={2}>
                    <Grid item md={12}>
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Select Option Label</th>

                            <th>
                              <button
                                className="btn btn-outline-success"
                                onClick={(e) => {
                                  addTableRows(id);
                                }}
                              >
                                +
                              </button>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {getpreviewform.map((item) => {
                            return item?.type[col_name]?.data?.map((subcol) => {
                              if (subcol?.id === id) {
                                {
                                  return subcol?.type?.options.map(
                                    (subitem) => {
                                      return (
                                        <tr>
                                          <td>
                                            <input
                                              type="text"
                                              placeholder="Enter label"
                                              name="olabel"
                                              value={subitem.label}
                                              onChange={(e) => {
                                                setInputPlaceholdercheckbox(
                                                  e?.target?.value,
                                                  subitem.id
                                                );
                                              }}
                                              className="form-control"
                                            />
                                          </td>

                                          <td>
                                            <button
                                              className="btn btn-outline-danger"
                                              onClick={(e) => {
                                                deletecheckbox(subitem.id);
                                              }}
                                            >
                                              x
                                            </button>
                                          </td>
                                        </tr>
                                      );
                                    }
                                  );
                                }
                              }
                            });
                          })}
                        </tbody>
                      </table>
                    </Grid>
                  </Grid>
                  <Box className={styles.offermodelDetail}>
                    <label className="labelContainer">
                      This field is Required.
                      {handelCondition("require") === true ? (
                        <input
                          type="checkbox"
                          checked
                          onChange={(e) => {
                            setRequiredField(false, type);
                          }}
                        />
                      ) : (
                        <input
                          type="checkbox"
                          onChange={(e) => {
                            setRequiredField(true, type);
                          }}
                        />
                      )}
                      <span className="checkbox"></span>
                    </label>
                  </Box>
                  <Box
                    className={`${styles.button} button-primary d-flex justify-end`}
                  >
                    <Button className="" onClick={handleClose}>
                      Update
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Modal>
        ) : type === "autocomplete" ? (
          <Modal
            open={openModal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className={`${styles.industryModel}`}
          >
            <Box className={`${styles.boxModel} xym1`}>
              <Box className={styles.modalwapper}>
                <Box className={styles.cross} onClick={handleClose}>
                  <Cross />
                </Box>
                <Typography variant="h5" className={styles.heading}>
                  Auto Complete
                </Typography>
                <Box className={styles.statusbox}>
                  <Box className={styles.offermodelDetail}>
                    <label>Label text</label>
                    <input
                      type="text"
                      value={handelCondition("label")}
                      onChange={(e) => {
                        setInputLabel(e?.target?.value, type);
                      }}
                    />
                  </Box>
                  <Grid container spacing={2}>
                    <Grid item md={12}>
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Select Option Label</th>

                            <th>
                              <button
                                className="btn btn-outline-success"
                                onClick={(e) => {
                                  addTableRows2(id);
                                }}
                              >
                                +
                              </button>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {getpreviewform.map((item) => {
                            return item?.type[col_name]?.data?.map((subcol) => {
                              if (subcol?.id === id) {
                                {
                                  return subcol?.type?.options.map(
                                    (subitem) => {
                                      return (
                                        <tr>
                                          <td>
                                            <input
                                              type="text"
                                              placeholder="Enter label"
                                              name="olabel"
                                              value={subitem.label}
                                              onChange={(e) => {
                                                setInputPlaceholdercheckbox(
                                                  e?.target?.value,
                                                  subitem.id
                                                );
                                              }}
                                              className="form-control"
                                            />
                                          </td>

                                          <td>
                                            <button
                                              className="btn btn-outline-danger"
                                              onClick={(e) => {
                                                deletecheckbox(subitem.id);
                                              }}
                                            >
                                              x
                                            </button>
                                          </td>
                                        </tr>
                                      );
                                    }
                                  );
                                }
                              }
                            });
                          })}
                        </tbody>
                      </table>
                    </Grid>
                  </Grid>
                  <Box className={styles.offermodelDetail}>
                    <label className="labelContainer">
                      This field is Required.
                      {handelCondition("require") === true ? (
                        <input
                          type="checkbox"
                          checked
                          onChange={(e) => {
                            setRequiredField(false, type);
                          }}
                        />
                      ) : (
                        <input
                          type="checkbox"
                          onChange={(e) => {
                            setRequiredField(true, type);
                          }}
                        />
                      )}
                      <span className="checkbox"></span>
                    </label>
                  </Box>
                  <Box
                    className={`${styles.button} button-primary d-flex justify-end`}
                  >
                    <Button className="" onClick={handleClose}>
                      Update
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Modal>
        ) : type === "url" ? (
          <Modal
            open={openModal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className={`${styles.industryModel}`}
          >
            <Box className={`${styles.boxModel} xym1`}>
              <Box className={styles.modalwapper}>
                <Box className={styles.cross} onClick={handleClose}>
                  <Cross />
                </Box>
                <Typography variant="h5" className={styles.heading}>
                  URL
                </Typography>
                <Box className={styles.statusbox}>
                  <Box className={styles.offermodelDetail}>
                    <label>Label text</label>
                    <input
                      type="text"
                      value={handelCondition("label")}
                      onChange={(e) => {
                        setInputLabel(e?.target?.value, type);
                      }}
                    />
                  </Box>
                  <Box className={styles.offermodelDetail}>
                    <label>Placeholder text</label>
                    <input
                      type="text"
                      value={handelCondition("placeholder")}
                      onChange={(e) => {
                        setInputPlaceholder(e?.target?.value, type);
                      }}
                    />
                  </Box>
                  <p className="align-self-start">
                    This field is used for get some kind of link from candidate.
                  </p>
                  {/* <Box className={styles.offermodelDetail}>
                    <label className="labelContainer">
                      This field is Required.
                      {handelCondition("require") === true ? (
                        <input
                          type="checkbox"
                          checked
                          onChange={(e) => {
                            setRequiredField(false, type);
                          }}
                        />
                      ) : (
                        <input
                          type="checkbox"
                          onChange={(e) => {
                            setRequiredField(true, type);
                          }}
                        />
                      )}
                      <span className="checkbox"></span>
                    </label>
                  </Box> */}
                  <Box
                    className={`${styles.button} button-primary d-flex justify-end`}
                  >
                    <Button className="" onClick={handleClose}>
                      Update
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Modal>
        ) : type === "hyperlink" ? (
          <Modal
            open={openModal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className={`${styles.industryModel}`}
          >
            <Box className={`${styles.boxModel} xym1`}>
              <Box className={styles.modalwapper}>
                <Box className={styles.cross} onClick={handleClose}>
                  <Cross />
                </Box>
                <Typography variant="h5" className={styles.heading}>
                  Hyperlink
                </Typography>
                <Box className={styles.statusbox}>
                  <Box className={styles.offermodelDetail}>
                    <label>Link Title</label>
                    <input
                      type="text"
                      value={handelCondition("label")}
                      onChange={(e) => {
                        setInputLabel(e?.target?.value, type);
                      }}
                    />
                  </Box>
                  <Box className={styles.offermodelDetail}>
                    <label>URL</label>
                    <input
                      type="url"
                      value={handelCondition("placeholder")}
                      onChange={(e) => {
                        setInputPlaceholder(e?.target?.value, type);
                      }}
                    />
                  </Box>
                  <p className="align-self-start">
                    This field can be used for send your info as in some kind of
                    link.
                  </p>
                  <Box
                    className={`${styles.button} button-primary d-flex justify-end`}
                  >
                    <Button className="" onClick={handleClose}>
                      Update
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Modal>
        ) : type === "image" ? (
          <Modal
            open={openModal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className={`${styles.industryModel}`}
          >
            <Box className={`${styles.boxModel} xym1`}>
              <Box className={styles.modalwapper}>
                <Box className={styles.cross} onClick={handleClose}>
                  <Cross />
                </Box>
                <Typography variant="h5" className={styles.heading}>
                  Image
                </Typography>
                <Box className={styles.statusbox}>
                  <Box className={styles.offermodelDetail}>
                    <label>Upload your image</label>
                    <input
                      type="file"
                      value={handelCondition("label")}
                      accept="jpg,jpeg,png,svg"
                      onChange={(e) => {
                        setImageURL(e?.target?.files[0], type);
                      }}
                    />
                    <p className="mt-2">Supported file : JPG,JPEG,PNG,SVG</p>
                    <label htmlFor="" className="mt-2">
                      Select Position
                    </label>
                    <select
                      name=""
                      id=""
                      style={{
                        display: "block",
                        width: "100%",
                        border: "1px solid rgba(0, 0, 0, 0.23)",
                        borderRadius: "4px",
                        padding: "8px",
                      }}
                      className={styles.selectBox}
                      onChange={(e) => {
                        setFontSize(e?.target?.value, type);
                      }}
                    >
                      <option value="flex-start">Left</option>
                      <option value="center">Center</option>
                      <option value="flex-end">Right</option>
                    </select>
                  </Box>

                  <Box
                    className={`${styles.button} button-primary d-flex justify-end`}
                  >
                    <Button className="" onClick={handleClose}>
                      Update
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Modal>
        ) : type === "table" ? (
          <Modal
            open={openModal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className={`${styles.industryModel}`}
          >
            <Box className={`${styles.boxModel} xym1`}>
              <Box className={styles.modalwapper}>
                <Box className={styles.cross} onClick={handleClose}>
                  <Cross />
                </Box>
                <Typography variant="h5" className={styles.heading}>
                  Table
                </Typography>
                <Box className={styles.statusbox}>
                  <Box className={styles.offermodelDetail}></Box>
                  <Grid container spacing={2}>
                    <Grid item md={12}>
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Column Header</th>

                            <th>
                              <button
                                className="btn btn-outline-success"
                                onClick={(e) => {
                                  addTableHeader(id);
                                }}
                              >
                                +
                              </button>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {getpreviewform.map((item, j) => {
                            return item?.type[col_name]?.data?.map((subcol) => {
                              if (subcol?.id === id) {
                                {
                                  return subcol?.type?.header?.data?.map(
                                    (subitem, i) => {
                                      return (
                                        <tr>
                                          <td>
                                            <input
                                              type="text"
                                              placeholder="Enter label"
                                              name="olabel"
                                              value={subitem.name}
                                              onChange={(e) => {
                                                setInputPlaceholdertableheader(
                                                  e?.target?.value,
                                                  subitem?.id
                                                );
                                              }}
                                              className="form-control"
                                            />
                                          </td>

                                          <td>
                                            <button
                                              className="btn btn-outline-danger"
                                              onClick={(e) => {
                                                deleteTableHeader(subitem?.id);
                                              }}
                                            >
                                              x
                                            </button>
                                          </td>
                                        </tr>
                                      );
                                    }
                                  );
                                }
                              }
                            });
                          })}
                        </tbody>
                      </table>
                      <Typography variant="h5" className={styles.heading}>
                        Update Table
                      </Typography>
                      <table className="table">
                        <thead>
                          <tr>
                            {getpreviewform.map((item) => {
                              return item?.type[col_name]?.data?.map(
                                (subcol) => {
                                  if (subcol?.id === id) {
                                    return subcol?.type?.header?.data.map(
                                      (hitem, i) => {
                                        return <th></th>;
                                      }
                                    );
                                  }
                                }
                              );
                            })}

                            <th>
                              <button
                                className="btn btn-outline-success"
                                onClick={(e) => {
                                  addTablesingleRows(id);
                                }}
                              >
                                +
                              </button>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {getpreviewform.map((item) => {
                            return item?.type[col_name]?.data?.map((subcol) => {
                              if (subcol?.id === id) {
                                {
                                  return subcol?.type?.table?.data?.map(
                                    (subtable, i) => {
                                      return (
                                        <tr>
                                          {subtable?.map((value, j) => {
                                            return (
                                              <td>
                                                <input
                                                  type="text"
                                                  placeholder="Enter Value"
                                                  name="rowvalue"
                                                  value={value}
                                                  onChange={(e) => {
                                                    setTablerowsvalue(
                                                      e?.target?.value,
                                                      i,
                                                      j,
                                                      id
                                                    );
                                                  }}
                                                  className="form-control"
                                                />
                                              </td>
                                            );
                                          })}
                                          <td>
                                            <button
                                              className="btn btn-outline-danger"
                                              onClick={(e) => {
                                                deleteTablesingleRows(id, i);
                                              }}
                                            >
                                              x
                                            </button>
                                          </td>
                                        </tr>
                                      );
                                    }
                                  );
                                }
                              }
                            });
                          })}
                        </tbody>
                      </table>
                    </Grid>
                  </Grid>
                  <Box
                    className={`${styles.button} button-primary d-flex justify-end`}
                  >
                    <Button className="" onClick={handleClose}>
                      Update
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Modal>
        ) : type === "vertical_table" ? (
          <Modal
            open={openModal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className={`${styles.industryModel}`}
          >
            <Box className={`${styles.boxModel} xym1`}>
              <Box className={styles.modalwapper}>
                <Box className={styles.cross} onClick={handleClose}>
                  <Cross />
                </Box>
                <Typography variant="h5" className={styles.heading}>
                  Vertical Table
                </Typography>
                <Box className={styles.statusbox}>
                  <Box className={styles.offermodelDetail}></Box>
                  <Grid container spacing={2}>
                    <Grid item md={12}>
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Column Header</th>

                            <th>
                              <button
                                className="btn btn-outline-success"
                                onClick={(e) => {
                                  addVerticalTableHeader(id);
                                }}
                              >
                                +
                              </button>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {getpreviewform.map((item, j) => {
                            if (item?.id === id) {
                              {
                                return item?.type?.header?.data?.map(
                                  (subitem, i) => {
                                    return (
                                      <tr>
                                        <td>
                                          <input
                                            type="text"
                                            placeholder="Enter label"
                                            name="olabel"
                                            value={subitem.name}
                                            onChange={(e) => {
                                              setInputPlaceholdertableheader(
                                                e?.target?.value,
                                                subitem?.id
                                              );
                                            }}
                                            className="form-control"
                                          />
                                        </td>

                                        <td>
                                          <button
                                            className="btn btn-outline-danger"
                                            onClick={(e) => {
                                              deleteVerticalTableHeader(id, i);
                                            }}
                                          >
                                            x
                                          </button>
                                        </td>
                                      </tr>
                                    );
                                  }
                                );
                              }
                            }
                          })}
                        </tbody>
                      </table>
                      <Typography variant="h5" className={styles.heading}>
                        Update Table
                      </Typography>
                      <table className="table">
                        <thead>
                          <tr>
                            {getpreviewform.map((item) => {
                              {
                                if (item?.id === id) {
                                  return item?.type?.table?.data[0].map(
                                    (hitem, i) => {
                                      return (
                                        <th>
                                          <button
                                            className="btn btn-outline-danger"
                                            onClick={(e) => {
                                              deleteVerticalTableSingleRows(
                                                id,
                                                i
                                              );
                                            }}
                                          >
                                            x
                                          </button>
                                        </th>
                                      );
                                    }
                                  );
                                }
                              }
                            })}
                            {/* {getpreviewform.map((item) => {
                            if (item.id === id) {
                              return (
                                <th
                                  colSpan={item?.type?.table?.data[0].length}
                                ></th>
                              );
                            }
                          })} */}

                            <th>
                              <button
                                className="btn btn-outline-success"
                                onClick={(e) => {
                                  addVerticalTableSingleRows(id);
                                }}
                              >
                                +
                              </button>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {getpreviewform.map((item) => {
                            if (item?.id === id) {
                              {
                                return item?.type?.table?.data?.map(
                                  (subtable, i) => {
                                    return (
                                      <tr>
                                        {subtable?.map((value, j) => {
                                          return (
                                            <td>
                                              <input
                                                type="text"
                                                placeholder="Enter Value"
                                                name="rowvalue"
                                                value={value}
                                                onChange={(e) => {
                                                  setVerticalTableRowsValue(
                                                    e?.target?.value,
                                                    i,
                                                    j,
                                                    id
                                                  );
                                                }}
                                                className="form-control"
                                              />
                                            </td>
                                          );
                                        })}
                                        {i === 0 ? (
                                          <td
                                            rowSpan={
                                              item?.type?.table?.data.length
                                            }
                                          ></td>
                                        ) : null}
                                      </tr>
                                    );
                                  }
                                );
                              }
                            }
                          })}
                        </tbody>
                      </table>
                    </Grid>
                  </Grid>
                  <Box
                    className={`${styles.button} button-primary d-flex justify-end`}
                  >
                    <Button className="" onClick={handleClose}>
                      Update
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Modal>
        ) : (
          ""
        )
      ) : type === "input" ? (
        <Modal
          open={openModal}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className={`${styles.industryModel}`}
        >
          <Box className={`${styles.boxModel} xym1`}>
            <Box className={styles.modalwapper}>
              <Box className={styles.cross} onClick={handleClose}>
                <Cross />
              </Box>
              <Typography variant="h5" className={styles.heading}>
                Text Input
              </Typography>
              <Box className={styles.statusbox}>
                <Box className={styles.offermodelDetail}>
                  <label>Label text</label>
                  <input
                    type="text"
                    value={getpreviewform[indexcount]?.type?.label}
                    onChange={(e) => {
                      setInputLabel(e?.target?.value, type);
                    }}
                  />
                </Box>
                <Box className={styles.offermodelDetail}>
                  <label>Placeholder text</label>
                  <input
                    type="text"
                    value={getpreviewform[indexcount]?.type?.placeholder}
                    onChange={(e) => {
                      setInputPlaceholder(e?.target?.value, type);
                    }}
                  />
                </Box>

                <Box className={styles.offermodelDetail}>
                  <label className="labelContainer">
                    This field is Required.
                    {getpreviewform[indexcount]?.type?.require === true ? (
                      <input
                        type="checkbox"
                        checked
                        onChange={(e) => {
                          setRequiredField(false, type);
                        }}
                      />
                    ) : (
                      <input
                        type="checkbox"
                        onChange={(e) => {
                          setRequiredField(true, type);
                        }}
                      />
                    )}
                    <span className="checkbox"></span>
                  </label>
                </Box>

                <Box
                  className={`${styles.button} button-primary d-flex justify-end`}
                >
                  <Button className="" onClick={handleClose}>
                    Update
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Modal>
      ) : type === "paragraph" ? (
        <Modal
          open={openModal}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className={`${styles.industryModel}`}
        >
          <Box className={`${styles.boxModel} xym1`}>
            <Box className={styles.modalwapper}>
              <Box className={styles.cross} onClick={handleClose}>
                <Cross />
              </Box>
              <Typography variant="h5" className={styles.heading}>
                Paragraph
              </Typography>
              <Box className={styles.statusbox}>
                <Box className={styles.offermodelDetail}>
                  <label>Paragraph text</label>
                  <textarea
                    value={getpreviewform[indexcount]?.type?.field_value}
                    onChange={(e) => {
                      setInputLabel(e?.target?.value, type);
                    }}
                  />
                </Box>
                <Box className={styles.offermodelDetail}>
                  <label>Paragraph font size</label>
                  <input
                    type="number"
                    value={getpreviewform[indexcount]?.type?.style?.font_size}
                    onChange={(e) => {
                      const value = Math.max(
                        0,
                        Math.min(50, parseInt(e.target.value))
                      );
                      setFontSize(value, type);
                    }}
                  />
                </Box>
                <Box className={styles.offermodelDetail}>
                  <label>Paragraph font color</label>
                  <div
                    onClick={handleColorPickerBox}
                    style={{
                      backgroundColor: `${sketchPickerColor}`,
                      border: "2px solid #e1e1e1",
                      width: "40px",
                      height: "20px",
                      borderRadius: "2px",
                    }}
                  ></div>
                  {openColorPicker ? (
                    <div style={{}}>
                      <SketchPicker
                        onChange={(color) => {
                          setSketchPickerColor(color.hex);
                          setFontColor(sketchPickerColor, type);
                        }}
                        color={sketchPickerColor}
                      />
                    </div>
                  ) : null}
                </Box>

                <Box
                  className={`${styles.button} button-primary d-flex justify-end`}
                >
                  <Button className="" onClick={handleClose}>
                    Update
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Modal>
      ) : type === "heading" ? (
        <Modal
          open={openModal}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className={`${styles.industryModel}`}
        >
          <Box className={`${styles.boxModel} xym1`}>
            <Box className={styles.modalwapper}>
              <Box className={styles.cross} onClick={handleClose}>
                <Cross />
              </Box>
              <Typography variant="h5" className={styles.heading}>
                Heading
              </Typography>
              <Box className={styles.statusbox}>
                <Box className={styles.offermodelDetail}>
                  <label>Heading text</label>
                  <input
                    type="text"
                    value={getpreviewform[indexcount]?.type?.field_value}
                    onChange={(e) => {
                      setInputLabel(e?.target?.value, type);
                    }}
                  />
                </Box>
                <Box className={styles.offermodelDetail}>
                  <label>Heading font size</label>
                  <input
                    type="number"
                    value={getpreviewform[indexcount]?.type?.style?.font_size}
                    onChange={(e) => {
                      const value = Math.max(
                        0,
                        Math.min(100, parseInt(e.target.value))
                      );
                      setFontSize(value, type);
                    }}
                  />
                </Box>

                <Box className={styles.offermodelDetail}>
                  <label>Heading font color</label>
                  <div
                    onClick={handleColorPickerBox}
                    style={{
                      backgroundColor: `${sketchPickerColor}`,
                      border: "2px solid #e1e1e1",
                      width: "40px",
                      height: "20px",
                      borderRadius: "2px",
                    }}
                  ></div>
                  {openColorPicker ? (
                    <div style={{}}>
                      <SketchPicker
                        onChange={(color) => {
                          setSketchPickerColor(color.hex);
                          setFontColor(sketchPickerColor, type);
                        }}
                        color={sketchPickerColor}
                      />
                    </div>
                  ) : null}
                </Box>

                <Box
                  className={`${styles.button} button-primary d-flex justify-end`}
                >
                  <Button className="" onClick={handleClose}>
                    Update
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Modal>
      ) : type === "email" ? (
        <Modal
          open={openModal}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className={`${styles.industryModel}`}
        >
          <Box className={`${styles.boxModel} xym1`}>
            <Box className={styles.modalwapper}>
              <Box className={styles.cross} onClick={handleClose}>
                <Cross />
              </Box>
              <Typography variant="h5" className={styles.heading}>
                Email Address
              </Typography>
              <Box className={styles.statusbox}>
                <Box className={styles.offermodelDetail}>
                  <label>Label text</label>
                  <input
                    type="text"
                    value={getpreviewform[indexcount]?.type?.label}
                    onChange={(e) => {
                      setInputLabel(e?.target?.value, type);
                    }}
                  />
                </Box>
                <Box className={styles.offermodelDetail}>
                  <label>Placeholder text</label>
                  <input
                    type="text"
                    value={getpreviewform[indexcount]?.type.placeholder}
                    onChange={(e) => {
                      setInputPlaceholder(e?.target?.value, type);
                    }}
                  />
                </Box>
                <Box className={styles.offermodelDetail}>
                  <label className="labelContainer">
                    This field is Required.
                    {getpreviewform[indexcount]?.type?.require === true ? (
                      <input
                        type="checkbox"
                        checked
                        onChange={(e) => {
                          setRequiredField(false, type);
                        }}
                      />
                    ) : (
                      <input
                        type="checkbox"
                        onChange={(e) => {
                          setRequiredField(true, type);
                        }}
                      />
                    )}
                    <span className="checkbox"></span>
                  </label>
                </Box>
                <Box
                  className={`${styles.button} button-primary d-flex justify-end`}
                >
                  <Button className="" onClick={handleClose}>
                    Update
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Modal>
      ) : type === "phone" ? (
        <Modal
          open={openModal}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className={`${styles.industryModel}`}
        >
          <Box className={`${styles.boxModel} xym1`}>
            <Box className={styles.modalwapper}>
              <Box className={styles.cross} onClick={handleClose}>
                <Cross />
              </Box>
              <Typography variant="h5" className={styles.heading}>
                Phone
              </Typography>
              <Box className={styles.statusbox}>
                <Box className={styles.offermodelDetail}>
                  <label>Label text</label>
                  <input
                    type="text"
                    value={getpreviewform[indexcount]?.type?.label}
                    onChange={(e) => {
                      setInputLabel(e?.target?.value, type);
                    }}
                  />
                </Box>
                <Box className={styles.offermodelDetail}>
                  <label>Placeholder text</label>
                  <input
                    type="text"
                    value={getpreviewform[indexcount]?.type?.placeholder}
                    onChange={(e) => {
                      setInputPlaceholder(e?.target?.value, type);
                    }}
                  />
                </Box>
                <Box className={styles.offermodelDetail}>
                  <label className="labelContainer">
                    This field is Required.
                    {getpreviewform[indexcount]?.type?.require === true ? (
                      <input
                        type="checkbox"
                        checked
                        onChange={(e) => {
                          setRequiredField(false, type);
                        }}
                      />
                    ) : (
                      <input
                        type="checkbox"
                        onChange={(e) => {
                          setRequiredField(true, type);
                        }}
                      />
                    )}
                    <span className="checkbox"></span>
                  </label>
                </Box>
                <Box
                  className={`${styles.button} button-primary d-flex justify-end`}
                >
                  <Button className="" onClick={handleClose}>
                    Update
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Modal>
      ) : type === "number" ? (
        <Modal
          open={openModal}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className={`${styles.industryModel}`}
        >
          <Box className={`${styles.boxModel} xym1`}>
            <Box className={styles.modalwapper}>
              <Box className={styles.cross} onClick={handleClose}>
                <Cross />
              </Box>
              <Typography variant="h5" className={styles.heading}>
                Number
              </Typography>
              <Box className={styles.statusbox}>
                <Box className={styles.offermodelDetail}>
                  <label>Label text</label>
                  <input
                    type="text"
                    value={getpreviewform[indexcount]?.type?.label}
                    onChange={(e) => {
                      setInputLabel(e?.target?.value, type);
                    }}
                  />
                </Box>
                <Box className={styles.offermodelDetail}>
                  <label>Placeholder text</label>
                  <input
                    type="text"
                    value={getpreviewform[indexcount]?.type?.placeholder}
                    onChange={(e) => {
                      setInputPlaceholder(e?.target?.value, type);
                    }}
                  />
                </Box>
                <Box className={styles.offermodelDetail}>
                  <label className="labelContainer">
                    This field is Required.
                    {getpreviewform[indexcount]?.type?.require === true ? (
                      <input
                        type="checkbox"
                        checked
                        onChange={(e) => {
                          setRequiredField(false, type);
                        }}
                      />
                    ) : (
                      <input
                        type="checkbox"
                        onChange={(e) => {
                          setRequiredField(true, type);
                        }}
                      />
                    )}
                    <span className="checkbox"></span>
                  </label>
                </Box>
                <Box
                  className={`${styles.button} button-primary d-flex justify-end`}
                >
                  <Button className="" onClick={handleClose}>
                    Update
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Modal>
      ) : type === "date" ? (
        <Modal
          open={openModal}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className={`${styles.industryModel}`}
        >
          <Box className={`${styles.boxModel} xym1`}>
            <Box className={styles.modalwapper}>
              <Box className={styles.cross} onClick={handleClose}>
                <Cross />
              </Box>
              <Typography variant="h5" className={styles.heading}>
                Date
              </Typography>
              <Box className={styles.statusbox}>
                <Box className={styles.offermodelDetail}>
                  <label>Date text</label>
                  <input
                    type=""
                    value={getpreviewform[indexcount]?.type?.label}
                    onChange={(e) => {
                      setInputLabel(e?.target?.value, type);
                    }}
                  />
                </Box>
                <Box className={styles.offermodelDetail}>
                  <label className="labelContainer">
                    This field is Required.
                    {getpreviewform[indexcount]?.type?.require === true ? (
                      <input
                        type="checkbox"
                        checked
                        onChange={(e) => {
                          setRequiredField(false, type);
                        }}
                      />
                    ) : (
                      <input
                        type="checkbox"
                        onChange={(e) => {
                          setRequiredField(true, type);
                        }}
                      />
                    )}
                    <span className="checkbox"></span>
                  </label>
                </Box>

                <Box
                  className={`${styles.button} button-primary d-flex justify-end`}
                >
                  <Button className="" onClick={handleClose}>
                    Update
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Modal>
      ) : type === "textarea" ? (
        <Modal
          open={openModal}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className={`${styles.industryModel}`}
        >
          <Box className={`${styles.boxModel} xym1`}>
            <Box className={styles.modalwapper}>
              <Box className={styles.cross} onClick={handleClose}>
                <Cross />
              </Box>
              <Typography variant="h5" className={styles.heading}>
                Text Area
              </Typography>
              <Box className={styles.statusbox}>
                <Box className={styles.offermodelDetail}>
                  <label>Label text</label>
                  <input
                    type="text"
                    value={getpreviewform[indexcount]?.type?.label}
                    onChange={(e) => {
                      setInputLabel(e?.target?.value, type);
                    }}
                  />
                </Box>
                <Box className={styles.offermodelDetail}>
                  <label>Placeholder text</label>
                  <input
                    type="text"
                    value={getpreviewform[indexcount]?.type?.placeholder}
                    onChange={(e) => {
                      setInputPlaceholder(e?.target?.value, type);
                    }}
                  />
                </Box>
                <Box className={styles.offermodelDetail}>
                  <label className="labelContainer">
                    This field is Required.
                    {getpreviewform[indexcount]?.type?.require === true ? (
                      <input
                        type="checkbox"
                        checked
                        onChange={(e) => {
                          setRequiredField(false, type);
                        }}
                      />
                    ) : (
                      <input
                        type="checkbox"
                        onChange={(e) => {
                          setRequiredField(true, type);
                        }}
                      />
                    )}
                    <span className="checkbox"></span>
                  </label>
                </Box>
                <Box
                  className={`${styles.button} button-primary d-flex justify-end`}
                >
                  <Button className="" onClick={handleClose}>
                    Update
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Modal>
      ) : type === "checkbox" ? (
        <Modal
          open={openModal}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className={`${styles.industryModel}`}
        >
          <Box className={`${styles.boxModel} xym1`}>
            <Box className={styles.modalwapper}>
              <Box className={styles.cross} onClick={handleClose}>
                <Cross />
              </Box>
              <Typography variant="h5" className={styles.heading}>
                Checkbox
              </Typography>
              <Box className={styles.statusbox}>
                <Box className={styles.offermodelDetail}>
                  <label>Label text</label>
                  <input
                    type="text"
                    value={getpreviewform[indexcount]?.type?.label}
                    onChange={(e) => {
                      setInputLabel(e?.target?.value, type);
                    }}
                  />
                </Box>
                <Grid container spacing={2}>
                  <Grid item md={12}>
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Option Label</th>

                          <th>
                            <button
                              className="btn btn-outline-success"
                              onClick={(e) => {
                                addTableRows(id);
                              }}
                            >
                              +
                            </button>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {getpreviewform.map((item) => {
                          if (item.id === id) {
                            {
                              return item.type.options.map((subitem) => {
                                return (
                                  <tr>
                                    <td>
                                      <input
                                        type="text"
                                        placeholder="Enter label"
                                        name="olabel"
                                        value={subitem.label}
                                        onChange={(e) => {
                                          setInputPlaceholdercheckbox(
                                            e?.target?.value,
                                            subitem.id
                                          );
                                        }}
                                        className="form-control"
                                      />
                                    </td>

                                    <td>
                                      <button
                                        className="btn btn-outline-danger"
                                        onClick={(e) => {
                                          deletecheckbox(subitem.id);
                                        }}
                                      >
                                        x
                                      </button>
                                    </td>
                                  </tr>
                                );
                              });
                            }
                          }
                        })}
                      </tbody>
                    </table>
                  </Grid>
                </Grid>
                <Box className={styles.offermodelDetail}>
                  <label className="labelContainer">
                    This field is Required.
                    {getpreviewform[indexcount]?.type?.require === true ? (
                      <input
                        type="checkbox"
                        checked
                        onChange={(e) => {
                          setRequiredField(false, type);
                        }}
                      />
                    ) : (
                      <input
                        type="checkbox"
                        onChange={(e) => {
                          setRequiredField(true, type);
                        }}
                      />
                    )}
                    <span className="checkbox"></span>
                  </label>
                </Box>
                <Box
                  className={`${styles.button} button-primary d-flex justify-end`}
                >
                  <Button className="" onClick={handleClose}>
                    Update
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Modal>
      ) : type === "upload_file" ? (
        <Modal
          open={openModal}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className={`${styles.industryModel}`}
        >
          <Box className={`${styles.boxModel} xym1`}>
            <Box className={styles.modalwapper}>
              <Box className={styles.cross} onClick={handleClose}>
                <Cross />
              </Box>
              <Typography variant="h5" className={styles.heading}>
                Upload File
              </Typography>
              <Box className={styles.statusbox}>
                <Box className={styles.offermodelDetail}>
                  <label>Label text</label>
                  <input
                    type="text"
                    value={getpreviewform[indexcount]?.type?.label}
                    onChange={(e) => {
                      setInputLabel(e?.target?.value, type);
                    }}
                  />
                </Box>
                <Box className={styles.offermodelDetail}>
                  <label className="labelContainer">
                    This field is Required.
                    {getpreviewform[indexcount]?.type?.require === true ? (
                      <input
                        type="checkbox"
                        checked
                        onChange={(e) => {
                          setRequiredField(false, type);
                        }}
                      />
                    ) : (
                      <input
                        type="checkbox"
                        onChange={(e) => {
                          setRequiredField(true, type);
                        }}
                      />
                    )}
                    <span className="checkbox"></span>
                  </label>
                </Box>
                <Box
                  className={`${styles.button} button-primary d-flex justify-end`}
                >
                  <Button className="" onClick={handleClose}>
                    Update
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Modal>
      ) : type === "rating" ? (
        <Modal
          open={openModal}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className={`${styles.industryModel}`}
        >
          <Box className={`${styles.boxModel} xym1`}>
            <Box className={styles.modalwapper}>
              <Box className={styles.cross} onClick={handleClose}>
                <Cross />
              </Box>
              <Typography variant="h5" className={styles.heading}>
                Rating
              </Typography>
              <Box className={styles.statusbox}>
                <Box className={styles.offermodelDetail}>
                  <label>Label text</label>
                  <input
                    type="text"
                    value={getpreviewform[indexcount]?.type?.label}
                    onChange={(e) => {
                      setInputLabel(e?.target?.value, type);
                    }}
                  />
                </Box>
                <Box
                  className={`${styles.button} button-primary d-flex justify-end`}
                >
                  <Button className="" onClick={handleClose}>
                    Update
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Modal>
      ) : type === "signature" ? (
        <Modal
          open={openModal}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className={`${styles.industryModel}`}
        >
          <Box className={`${styles.boxModel} xym1`}>
            <Box className={styles.modalwapper}>
              <Box className={styles.cross} onClick={handleClose}>
                <Cross />
              </Box>
              <Typography variant="h5" className={styles.heading}>
                Signature
              </Typography>
              <Box className={styles.statusbox}>
                <Box className={styles.offermodelDetail}>
                  <label htmlFor="">Label text</label>
                  <input
                    type="text"
                    value={getpreviewform[indexcount]?.type?.label}
                    onChange={(e) => {
                      setInputLabel(e?.target?.value, type);
                    }}
                  />
                </Box>
                <Box className={styles.offermodelDetail}>
                  <label className="labelContainer">
                    This field is Required.
                    {getpreviewform[indexcount]?.type?.require === true ? (
                      <input
                        type="checkbox"
                        checked
                        onChange={(e) => {
                          setRequiredField(false, type);
                        }}
                      />
                    ) : (
                      <input
                        type="checkbox"
                        onChange={(e) => {
                          setRequiredField(true, type);
                        }}
                      />
                    )}
                    <span className="checkbox"></span>
                  </label>
                </Box>
                <Box
                  className={`${styles.button} button-primary d-flex justify-end`}
                >
                  <Button className="" onClick={handleClose}>
                    Update
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Modal>
      ) : type === "radio" ? (
        <Modal
          open={openModal}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className={`${styles.industryModel}`}
        >
          <Box className={`${styles.boxModel} xym1`}>
            <Box className={styles.modalwapper}>
              <Box className={styles.cross} onClick={handleClose}>
                <Cross />
              </Box>
              <Typography variant="h5" className={styles.heading}>
                Multiple Choice
              </Typography>
              <Box className={styles.statusbox}>
                <Box className={styles.offermodelDetail}>
                  <label>Label text</label>
                  <input
                    type="text"
                    value={getpreviewform[indexcount]?.type?.label}
                    onChange={(e) => {
                      setInputLabel(e?.target?.value, type);
                    }}
                  />
                </Box>
                <Grid container spacing={2}>
                  <Grid item md={12}>
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Option Label</th>

                          <th>
                            <button
                              className="btn btn-outline-success"
                              onClick={(e) => {
                                addTableRows2(id);
                              }}
                            >
                              +
                            </button>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {getpreviewform.map((item) => {
                          if (item.id === id) {
                            {
                              return item.type.options.map((subitem) => {
                                return (
                                  <tr>
                                    <td>
                                      <input
                                        type="text"
                                        placeholder="Enter label"
                                        name="olabel"
                                        value={subitem.label}
                                        onChange={(e) => {
                                          setInputPlaceholdercheckbox(
                                            e?.target?.value,
                                            subitem.id
                                          );
                                        }}
                                        className="form-control"
                                      />
                                    </td>

                                    <td>
                                      <button
                                        className="btn btn-outline-danger"
                                        onClick={(e) => {
                                          deletecheckbox(subitem.id);
                                        }}
                                      >
                                        x
                                      </button>
                                    </td>
                                  </tr>
                                );
                              });
                            }
                          }
                        })}
                      </tbody>
                    </table>
                  </Grid>
                </Grid>
                <Box className={styles.offermodelDetail}>
                  <label className="labelContainer">
                    This field is Required.
                    {getpreviewform[indexcount]?.type?.require === true ? (
                      <input
                        type="checkbox"
                        checked
                        onChange={(e) => {
                          setRequiredField(false, type);
                        }}
                      />
                    ) : (
                      <input
                        type="checkbox"
                        onChange={(e) => {
                          setRequiredField(true, type);
                        }}
                      />
                    )}
                    <span className="checkbox"></span>
                  </label>
                </Box>
                <Box
                  className={`${styles.button} button-primary d-flex justify-end`}
                >
                  <Button className="" onClick={handleClose}>
                    Update
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Modal>
      ) : type === "select" ? (
        <Modal
          open={openModal}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className={`${styles.industryModel}`}
        >
          <Box className={`${styles.boxModel} xym1`}>
            <Box className={styles.modalwapper}>
              <Box className={styles.cross} onClick={handleClose}>
                <Cross />
              </Box>
              <Typography variant="h5" className={styles.heading}>
                Select
              </Typography>
              <Box className={styles.statusbox}>
                <Box className={styles.offermodelDetail}>
                  <label>Label text</label>
                  <input
                    type="text"
                    value={getpreviewform[indexcount]?.type?.label}
                    onChange={(e) => {
                      setInputLabel(e?.target?.value, type);
                    }}
                  />
                </Box>
                <Grid container spacing={2}>
                  <Grid item md={12}>
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Select Option Label</th>

                          <th>
                            <button
                              className="btn btn-outline-success"
                              onClick={(e) => {
                                addTableRows(id);
                              }}
                            >
                              +
                            </button>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {getpreviewform.map((item) => {
                          if (item.id === id) {
                            {
                              return item.type.options.map((subitem) => {
                                return (
                                  <tr>
                                    <td>
                                      <input
                                        type="text"
                                        placeholder="Enter label"
                                        name="olabel"
                                        value={subitem.label}
                                        onChange={(e) => {
                                          setInputPlaceholdercheckbox(
                                            e?.target?.value,
                                            subitem.id
                                          );
                                        }}
                                        className="form-control"
                                      />
                                    </td>

                                    <td>
                                      <button
                                        className="btn btn-outline-danger"
                                        onClick={(e) => {
                                          deletecheckbox(subitem.id);
                                        }}
                                      >
                                        x
                                      </button>
                                    </td>
                                  </tr>
                                );
                              });
                            }
                          }
                        })}
                      </tbody>
                    </table>
                  </Grid>
                </Grid>
                <Box className={styles.offermodelDetail}>
                  <label className="labelContainer">
                    This field is Required.
                    {getpreviewform[indexcount]?.type?.require === true ? (
                      <input
                        type="checkbox"
                        checked
                        onChange={(e) => {
                          setRequiredField(false, type);
                        }}
                      />
                    ) : (
                      <input
                        type="checkbox"
                        onChange={(e) => {
                          setRequiredField(true, type);
                        }}
                      />
                    )}
                    <span className="checkbox"></span>
                  </label>
                </Box>
                <Box
                  className={`${styles.button} button-primary d-flex justify-end`}
                >
                  <Button className="" onClick={handleClose}>
                    Update
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Modal>
      ) : type === "autocomplete" ? (
        <Modal
          open={openModal}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className={`${styles.industryModel}`}
        >
          <Box className={`${styles.boxModel} xym1`}>
            <Box className={styles.modalwapper}>
              <Box className={styles.cross} onClick={handleClose}>
                <Cross />
              </Box>
              <Typography variant="h5" className={styles.heading}>
                Auto Complete
              </Typography>
              <Box className={styles.statusbox}>
                <Box className={styles.offermodelDetail}>
                  <label>Label text</label>
                  <input
                    type="text"
                    value={getpreviewform[indexcount]?.type?.label}
                    onChange={(e) => {
                      setInputLabel(e?.target?.value, type);
                    }}
                  />
                </Box>
                <Grid container spacing={2}>
                  <Grid item md={12}>
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Select Option Label</th>

                          <th>
                            <button
                              className="btn btn-outline-success"
                              onClick={(e) => {
                                addTableRows2(id);
                              }}
                            >
                              +
                            </button>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {getpreviewform.map((item) => {
                          if (item.id === id) {
                            {
                              return item.type.options.map((subitem) => {
                                return (
                                  <tr>
                                    <td>
                                      <input
                                        type="text"
                                        placeholder="Enter label"
                                        name="olabel"
                                        value={subitem.label}
                                        onChange={(e) => {
                                          setInputPlaceholdercheckbox(
                                            e?.target?.value,
                                            subitem.id
                                          );
                                        }}
                                        className="form-control"
                                      />
                                    </td>

                                    <td>
                                      <button
                                        className="btn btn-outline-danger"
                                        onClick={(e) => {
                                          deletecheckbox(subitem.id);
                                        }}
                                      >
                                        x
                                      </button>
                                    </td>
                                  </tr>
                                );
                              });
                            }
                          }
                        })}
                      </tbody>
                    </table>
                  </Grid>
                </Grid>
                <Box className={styles.offermodelDetail}>
                  <label className="labelContainer">
                    This field is Required.
                    {getpreviewform[indexcount]?.type?.require === true ? (
                      <input
                        type="checkbox"
                        checked
                        onChange={(e) => {
                          setRequiredField(false, type);
                        }}
                      />
                    ) : (
                      <input
                        type="checkbox"
                        onChange={(e) => {
                          setRequiredField(true, type);
                        }}
                      />
                    )}
                    <span className="checkbox"></span>
                  </label>
                </Box>
                <Box
                  className={`${styles.button} button-primary d-flex justify-end`}
                >
                  <Button className="" onClick={handleClose}>
                    Update
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Modal>
      ) : type === "url" ? (
        <Modal
          open={openModal}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className={`${styles.industryModel}`}
        >
          <Box className={`${styles.boxModel} xym1`}>
            <Box className={styles.modalwapper}>
              <Box className={styles.cross} onClick={handleClose}>
                <Cross />
              </Box>
              <Typography variant="h5" className={styles.heading}>
                URL
              </Typography>
              <Box className={styles.statusbox}>
                <Box className={styles.offermodelDetail}>
                  <label>Label text</label>
                  <input
                    type="text"
                    value={getpreviewform[indexcount]?.type?.label}
                    onChange={(e) => {
                      setInputLabel(e?.target?.value, type);
                    }}
                  />
                </Box>
                <Box className={styles.offermodelDetail}>
                  <label>Placeholder text</label>
                  <input
                    type="text"
                    value={getpreviewform[indexcount]?.type?.placeholder}
                    onChange={(e) => {
                      setInputPlaceholder(e?.target?.value, type);
                    }}
                  />
                </Box>
                <p className="align-self-start">
                  This field is used for get some kind of link from candidate.
                </p>

                <Box
                  className={`${styles.button} button-primary d-flex justify-end`}
                >
                  <Button className="" onClick={handleClose}>
                    Update
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Modal>
      ) : type === "hyperlink" ? (
        <Modal
          open={openModal}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className={`${styles.industryModel}`}
        >
          <Box className={`${styles.boxModel} xym1`}>
            <Box className={styles.modalwapper}>
              <Box className={styles.cross} onClick={handleClose}>
                <Cross />
              </Box>
              <Typography variant="h5" className={styles.heading}>
                Hyperlink
              </Typography>
              <Box className={styles.statusbox}>
                <Box className={styles.offermodelDetail}>
                  <label>Link Title</label>
                  <input
                    type="text"
                    value={getpreviewform[indexcount]?.type?.label}
                    onChange={(e) => {
                      setInputLabel(e?.target?.value, type);
                    }}
                  />
                </Box>
                <Box className={styles.offermodelDetail}>
                  <label>URL</label>
                  <input
                    type="url"
                    value={getpreviewform[indexcount]?.type?.placeholder}
                    onChange={(e) => {
                      setInputPlaceholder(e?.target?.value, type);
                    }}
                  />
                </Box>
                <p className="align-self-start">
                  This field can be used for send your info as in some kind of
                  link.
                </p>
                <Box
                  className={`${styles.button} button-primary d-flex justify-end`}
                >
                  <Button className="" onClick={handleClose}>
                    Update
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Modal>
      ) : type === "image" ? (
        <Modal
          open={openModal}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className={`${styles.industryModel}`}
        >
          <Box className={`${styles.boxModel} xym1`}>
            <Box className={styles.modalwapper}>
              <Box className={styles.cross} onClick={handleClose}>
                <Cross />
              </Box>
              <Typography variant="h5" className={styles.heading}>
                Image
              </Typography>
              <Box className={styles.statusbox}>
                <Box className={styles.offermodelDetail}>
                  <label>Upload your image</label>
                  <input
                    type="file"
                    accept="jpg,jpeg,png,svg"
                    value={getpreviewform[indexcount]?.type?.label}
                    onChange={(e) => {
                      setImageURL(e?.target?.files[0], type);
                    }}
                  />
                  <p className="mt-2">Supported file : JPG,JPEG,PNG,SVG</p>
                  <label htmlFor="" className="mt-2">
                    Select Position
                  </label>
                  <select
                    name=""
                    id=""
                    style={{
                      display: "block",
                      width: "100%",
                      border: "1px solid rgba(0, 0, 0, 0.23)",
                      borderRadius: "4px",
                      padding: "8px",
                    }}
                    onChange={(e) => {
                      setFontSize(e?.target?.value, type);
                    }}
                  >
                    <option value="flex-start">Left</option>
                    <option value="center">Center</option>
                    <option value="flex-end">Right</option>
                  </select>
                </Box>

                <Box
                  className={`${styles.button} button-primary d-flex justify-end`}
                >
                  <Button className="" onClick={handleClose}>
                    Update
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Modal>
      ) : type === "table" ? (
        <Modal
          open={openModal}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className={`${styles.industryModel}`}
        >
          <Box className={`${styles.boxModel} xym1`}>
            <Box className={styles.modalwapper}>
              <Box className={styles.cross} onClick={handleClose}>
                <Cross />
              </Box>
              <Typography variant="h5" className={styles.heading}>
                Table
              </Typography>
              <Box className={styles.statusbox}>
                <Box className={styles.offermodelDetail}></Box>
                <Grid container spacing={2}>
                  <Grid item md={12}>
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Column Header</th>

                          <th>
                            <button
                              className="btn btn-outline-success"
                              onClick={(e) => {
                                addTableHeader(id);
                              }}
                            >
                              +
                            </button>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {getpreviewform.map((item, j) => {
                          if (item?.id === id) {
                            {
                              return item?.type?.header?.data?.map(
                                (subitem, i) => {
                                  return (
                                    <tr>
                                      <td>
                                        <input
                                          type="text"
                                          placeholder="Enter label"
                                          name="olabel"
                                          value={subitem.name}
                                          onChange={(e) => {
                                            setInputPlaceholdertableheader(
                                              e?.target?.value,
                                              subitem?.id
                                            );
                                          }}
                                          className="form-control"
                                        />
                                      </td>

                                      <td>
                                        <button
                                          className="btn btn-outline-danger"
                                          onClick={(e) => {
                                            deleteTableHeader(subitem?.id);
                                          }}
                                        >
                                          x
                                        </button>
                                      </td>
                                    </tr>
                                  );
                                }
                              );
                            }
                          }
                        })}
                      </tbody>
                    </table>
                    <Typography variant="h5" className={styles.heading}>
                      Update Table
                    </Typography>
                    <table className="table">
                      <thead>
                        <tr>
                          {getpreviewform.map((item) => {
                            {
                              if (item?.id === id) {
                                return item?.type?.header?.data.map(
                                  (hitem, i) => {
                                    return <th></th>;
                                  }
                                );
                              }
                            }
                          })}

                          <th>
                            <button
                              className="btn btn-outline-success"
                              onClick={(e) => {
                                addTablesingleRows(id);
                              }}
                            >
                              +
                            </button>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {getpreviewform.map((item) => {
                          if (item?.id === id) {
                            {
                              return item?.type?.table?.data?.map(
                                (subtable, i) => {
                                  return (
                                    <tr>
                                      {subtable?.map((value, j) => {
                                        return (
                                          <td>
                                            <input
                                              type="text"
                                              placeholder="Enter Value"
                                              name="rowvalue"
                                              value={value}
                                              onChange={(e) => {
                                                setTablerowsvalue(
                                                  e?.target?.value,
                                                  i,
                                                  j,
                                                  id
                                                );
                                              }}
                                              className="form-control"
                                            />
                                          </td>
                                        );
                                      })}
                                      <td>
                                        <button
                                          className="btn btn-outline-danger"
                                          onClick={(e) => {
                                            deleteTablesingleRows(id, i);
                                          }}
                                        >
                                          x
                                        </button>
                                      </td>
                                    </tr>
                                  );
                                }
                              );
                            }
                          }
                        })}
                      </tbody>
                    </table>
                  </Grid>
                </Grid>
                <Box
                  className={`${styles.button} button-primary d-flex justify-end`}
                >
                  <Button className="" onClick={handleClose}>
                    Update
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Modal>
      ) : type === "vertical_table" ? (
        <Modal
          open={openModal}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className={`${styles.industryModel}`}
        >
          <Box className={`${styles.boxModel} xym1`}>
            <Box className={styles.modalwapper}>
              <Box className={styles.cross} onClick={handleClose}>
                <Cross />
              </Box>
              <Typography variant="h5" className={styles.heading}>
                Vertical Table
              </Typography>
              <Box className={styles.statusbox}>
                <Box className={styles.offermodelDetail}></Box>
                <Grid container spacing={2}>
                  <Grid item md={12}>
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Column Header</th>

                          <th>
                            <button
                              className="btn btn-outline-success"
                              onClick={(e) => {
                                addVerticalTableHeader(id);
                              }}
                            >
                              +
                            </button>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {getpreviewform.map((item, j) => {
                          if (item?.id === id) {
                            {
                              return item?.type?.header?.data?.map(
                                (subitem, i) => {
                                  return (
                                    <tr>
                                      <td>
                                        <input
                                          type="text"
                                          placeholder="Enter label"
                                          name="olabel"
                                          value={subitem.name}
                                          onChange={(e) => {
                                            setInputPlaceholdertableheader(
                                              e?.target?.value,
                                              subitem?.id
                                            );
                                          }}
                                          className="form-control"
                                        />
                                      </td>

                                      <td>
                                        <button
                                          className="btn btn-outline-danger"
                                          onClick={(e) => {
                                            deleteVerticalTableHeader(id, i);
                                          }}
                                        >
                                          x
                                        </button>
                                      </td>
                                    </tr>
                                  );
                                }
                              );
                            }
                          }
                        })}
                      </tbody>
                    </table>
                    <Typography variant="h5" className={styles.heading}>
                      Update Table
                    </Typography>
                    <table className="table">
                      <thead>
                        <tr>
                          {getpreviewform.map((item) => {
                            {
                              if (item?.id === id) {
                                return item?.type?.table?.data[0].map(
                                  (hitem, i) => {
                                    return (
                                      <th>
                                        <button
                                          className="btn btn-outline-danger"
                                          onClick={(e) => {
                                            deleteVerticalTableSingleRows(
                                              id,
                                              i
                                            );
                                          }}
                                        >
                                          x
                                        </button>
                                      </th>
                                    );
                                  }
                                );
                              }
                            }
                          })}
                          {/* {getpreviewform.map((item) => {
                            if (item.id === id) {
                              return (
                                <th
                                  colSpan={item?.type?.table?.data[0].length}
                                ></th>
                              );
                            }
                          })} */}

                          <th>
                            <button
                              className="btn btn-outline-success"
                              onClick={(e) => {
                                addVerticalTableSingleRows(id);
                              }}
                            >
                              +
                            </button>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {getpreviewform.map((item) => {
                          if (item?.id === id) {
                            {
                              return item?.type?.table?.data?.map(
                                (subtable, i) => {
                                  return (
                                    <tr>
                                      {subtable?.map((value, j) => {
                                        return (
                                          <td>
                                            <input
                                              type="text"
                                              placeholder="Enter Value"
                                              name="rowvalue"
                                              value={value}
                                              onChange={(e) => {
                                                setVerticalTableRowsValue(
                                                  e?.target?.value,
                                                  i,
                                                  j,
                                                  id
                                                );
                                              }}
                                              className="form-control"
                                            />
                                          </td>
                                        );
                                      })}
                                      {i === 0 ? (
                                        <td
                                          rowSpan={
                                            item?.type?.table?.data.length
                                          }
                                        ></td>
                                      ) : null}
                                    </tr>
                                  );
                                }
                              );
                            }
                          }
                        })}
                      </tbody>
                    </table>
                  </Grid>
                </Grid>
                <Box
                  className={`${styles.button} button-primary d-flex justify-end`}
                >
                  <Button className="" onClick={handleClose}>
                    Update
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Modal>
      ) : (
        ""
      )}
    </>
  );
}

export default EditModel;
