import {
  Badge,
  Box,
  Drawer,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  MenuList,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import styles from "./styles.module.scss";
import toggleMenu from "../../assets/icons/home_request.png";

import { ReactComponent as Notification } from "../../assets/dashboard/bell.svg";
import Home from "../../assets/icons/home.svg";
import NotificationsIcon from "../../assets/icons/notifications.svg";
import Shopping from "../../assets/icons/shopping.svg";
import { BsChevronDown } from "react-icons/bs";
import { routes } from "../../routes";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Logout } from "../../store/actions/authActions";
import { editCompany } from "../../store/actions/companyActions";
import ChangePasswordModal from "../../../src/components/candidate_screens/profile/Modal/change-password";
import LogoutModal from "../../../src/components/candidate_screens/profile/Modal/logout-popup";
import {
  notification,
  readNotification,
} from "../../api/notification/notification";
import { ShowCandidateFunc } from "../../store/actions/recruiterCandidateActions";
import { Button } from "bootstrap";
import Sidebar from "../sidebar/index";
import useWindowDimensions from "../../customHooks/useWindowDimensions";

const Header = () => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const [anchorEl3, setAnchorEl3] = React.useState(null);
  const [changePassword, setChangePassword] = React.useState(false);
  const [logoutPopUp, setLogoutPopUp] = React.useState(false);
  const company = useSelector((state) => state?.company);
  const auth = useSelector((state) => state?.auth);
  const profiles = useSelector((state) => state?.rprofile);
  // const pricing = useSelector((state) => state?.pricing);
  const navigate = useNavigate();
  const isMenuOpen = Boolean(anchorEl);
  const isNotificationMenu = Boolean(anchorEl2);
  const isCartMenu = Boolean(anchorEl3);

  const { width } = useWindowDimensions();
  const mobile = width <= 992;

  const [notificationData, setnotificationData] = useState([]);
  const [notificationCount, setnotificationCount] = useState([]);
  const [pages, setpages] = useState(1);
  // const [notifictionIconClick, setnotifictionIconClick] = useState(false)
  const scrollRef = useRef();
  let page = pages;
  const handleScroll = () => {
    if (scrollRef?.current) {
      const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;

      if (scrollTop + clientHeight === scrollHeight) {
        page++;
        setpages(page);

        getNotification(page);
      }
    }
  };

  const getNotification = async (page) => {
    try {
      let result = await notification(page);
      let data = result?.data?.results?.notification_data?.data;
      let notifiactionCountData = result?.data?.results?.unread_count;
      if (page === 1) {
        setnotificationData(data);
      }
      if (page > 1 && data?.length > 0) {
        setnotificationData((preVal) => {
          return [...preVal, ...data];
        });
      }

      setnotificationCount(notifiactionCountData);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getNotification(1);
  }, []);

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleCloseNotification = () => {
    setAnchorEl2(null);
    setpages(1);
  };
  const handleCloseCart = () => {
    setAnchorEl3(null);
  };
  const profile = () => {
    navigate(
      auth?.user === "candidate" ? routes.CANDIDATE_PROFILE : routes.PROFILE
    );
    setAnchorEl(null);
  };
  const logout = () => {
    dispatch(Logout()).then(() => {
      navigate(routes.LOGIN);
    });

    setAnchorEl(null);
  };
  const editCompanyy = () => {
    dispatch(editCompany(company.allCompany.results.uuid)).then(() => {
      navigate(routes.COMPANY);
    });

    setAnchorEl(null);
  };
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleProfileNotificationOpen = (event) => {
    getNotification(1);
    setAnchorEl2(event.currentTarget);
  };
  const handleCartMenu = (event) => {
    setAnchorEl3(event.currentTarget);
  };

  const handleNotificationClick = (e) => {
    readNotification(e);
    if (e.notifiable_type === "1") {
      navigate(`${routes.ORDER_DETAIL}/${e?.uuid}`, {
        state: {
          id: "#" + e.data.plan_id,
        },
      });
    } else if (
      e.notifiable_type === "2" ||
      e.notifiable_type === "19" ||
      e.notifiable_type === "18" ||
      e.notifiable_type === "3"
    ) {
      navigate(`${routes.REQUEST_LIST}/`);
    } else if (
      e.notifiable_type === "21" ||
      e.notifiable_type === "22" ||
      e.notifiable_type === "25" ||
      e.notifiable_type === "26" ||
      e.notifiable_type === "24" ||
      e.notifiable_type === "10" ||
      e.notifiable_type === "27" ||
      e.notifiable_type === "30"
    ) {
      dispatch(ShowCandidateFunc(e?.data?.candidate_uuid)).then(() =>
        navigate(`${routes.SINGLE_CANDIDATE}/${e?.data?.candidate_uuid}`)
      );
    } else if (e.notifiable_type === "4" || e.notifiable_type === "7") {
      navigate(`${routes.REF}/`);
    } else if (e.notifiable_type === "8") {
      navigate(`${routes.CANDIDATE_LICENSE}/`);
    } else if (e.notifiable_type === "20") {
      navigate(`${routes.CANDIDATE_INTERVIEW}/`);
    } else if (e.notifiable_type === "28") {
      navigate(`${routes.CONTACT}/`);
    } else if (
      e.notifiable_type === "44" ||
      e.notifiable_type === "43" ||
      e.notifiable_type === "42" ||
      e.notifiable_type === "41"
    ) {
      navigate(`${routes.ALL_DOCUMENTS}`);
    }
    setAnchorEl2();
  };
  const getMonthIndex = (month) => {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const monthIndex = monthNames.indexOf(month) + 1;
    // Pad single-digit months with a leading zero
    return monthIndex < 10 ? `0${monthIndex}` : `${monthIndex}`;
  };

  const formatDate = (timeAgo) => {
    // Check if the timeAgo string contains a date
    if (timeAgo.includes("ago")) {
      // If it's a relative time, return as is
      return timeAgo;
    } else {
      // If it's a specific date, convert to "dd/mm/yyyy" format
      const dateParts = timeAgo.split(" ");
      const day = dateParts[0];
      const month = dateParts[1];
      const year = dateParts[2];
      // Convert 12-hour time to 24-hour format
      const time = dateParts[3].split(":");
      let hours = parseInt(time[0]);
      const minutes = time[1];
      const meridiem = dateParts[4];
      if (meridiem === "PM" && hours < 12) {
        hours += 12;
      }
      if (meridiem === "AM" && hours === 12) {
        hours = 0;
      }
      // Format the date
      return `${day}/${getMonthIndex(month)}/${year} ${hours}:${minutes}`;
    }
  };

  const renderNotification = (
    <Menu
      sx={{ width: 420, maxWidth: "100%" }}
      anchorEl={anchorEl2}
      open={isNotificationMenu}
      onClick={() => getNotification(page)}
      onClose={handleCloseNotification}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <MenuList
        className="notification-list"
        onScroll={handleScroll}
        ref={scrollRef}
      >
        {notificationData?.length !== 0 ? (
          notificationData?.map((e, i) => {
            if (e.unread === 1) {
              return (
                <MenuItem
                  key={i}
                  onClick={() => handleNotificationClick(e)}
                  className="new"
                >
                  {e.message} <span>{formatDate(e.time_ago)}</span>
                </MenuItem>
              );
            }
            return (
              <MenuItem key={i} onClick={() => handleNotificationClick(e)}>
                {e.message} <span>{formatDate(e.time_ago)}</span>
              </MenuItem>
            );
          })
        ) : (
          <MenuItem>No Notification Yet!</MenuItem>
        )}
      </MenuList>
    </Menu>
  );

  const renderCart = (
    <Menu
      sx={{ width: 420, maxWidth: "100%" }}
      anchorEl={anchorEl3}
      open={isCartMenu}
      onClose={handleCloseCart}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      {/* <MenuList>
        <MenuItem>
          Price: <b> {pricing?.price?.toFixed(2)}</b>
        </MenuItem>
      </MenuList> */}
    </Menu>
  );
  const menuId = "primary-search-account-menu";

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={profile}>Profile</MenuItem>
      <MenuItem
        onClick={() => {
          setChangePassword(true);
        }}
      >
        Change Password
      </MenuItem>
      {/* <MenuItem onClick={handleMenuClose}>My account</MenuItem> */}

      {auth?.results?.workspace_type === "recruitment" && (
        <MenuItem onClick={editCompanyy}>Company Detail</MenuItem>
      )}

      <MenuItem
        onClick={() => {
          setLogoutPopUp(true);
        }}
      >
        Logout
      </MenuItem>
    </Menu>
  );

  const [open, setOpen] = useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  return (
    <>
      <Box className={styles.header}>
        <Box as="nav">
          <Grid
            container
            spacing={2}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Grid item xs={12} sm={6}>
              <div
                className="d-flex align-items-center"
                style={{ gap: "12px" }}
              >
                {mobile && (
                  <button
                    className={styles.toggleMenu}
                    onClick={toggleDrawer(true)}
                  >
                    <img src={toggleMenu} alt="toggleMenu" />
                  </button>
                )}
                <Typography component={"h4"}>
                  {auth?.user === "recruitment"
                    ? company?.allCompany?.results?.name || "Company Name"
                    : ""}
                </Typography>
              </div>
              <Drawer open={open} onClose={toggleDrawer(false)}>
                <Sidebar
                  className="open"
                  open={open}
                  onClose={toggleDrawer(false)}
                />
              </Drawer>
              {/* <img src={logo} alt="logo" /> */}
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              // paddingRight={1.7}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <IconButton sx={{ fontSize: "1rem", padding: "0 11px" }}>
                <Link to={routes?.HOME}>
                  <img src={Home} className={styles.homebtn} alt="homeIcon" />
                </Link>
              </IconButton>
              <IconButton
                size="large"
                aria-label="show 4 new mails"
                color="inherit"
                className={styles.notification}
                onClick={handleProfileNotificationOpen}
                sx={{ fontSize: "1rem", padding: "0 11px" }}
              >
                <Badge
                  badgeContent={notificationCount}
                  color="error"
                  className="badge"
                >
                  <img
                    src={NotificationsIcon}
                    className={styles.homebtn}
                    alt="notificationIcon"
                  />
                </Badge>{" "}
              </IconButton>
              {auth?.user !== "candidate" && (
                <IconButton sx={{ fontSize: "1rem", padding: "0 11px" }}>
                  <Link to={"/dashboard/pricing"}>
                    <img
                      src={Shopping}
                      className={styles.homebtn}
                      alt="shoppingIcon"
                    />
                  </Link>
                  {/* <Badge badgeContent={0} color="error">
    <img src={Shopping} className={styles.homebtn} />
  </Badge> */}{" "}
                </IconButton>
              )}

              <Box
                marginLeft={1.5}
                onClick={handleProfileMenuOpen}
                className={styles.profileMenu}
              >
                <Typography variant="h5">
                  {profiles?.profile?.results?.first_name}
                </Typography>
                <BsChevronDown />
              </Box>
            </Grid>
          </Grid>
        </Box>
        {renderMenu}
        {renderNotification}
        {renderCart}
        <ChangePasswordModal
          openModal={changePassword}
          setOpenModal={setChangePassword}
        />
        <LogoutModal openModal={logoutPopUp} setOpenModal={setLogoutPopUp} />
      </Box>
    </>
  );
};

export default Header;
