import * as flatted from "flatted";
import { useEffect, useState } from "react";
// import { toast } from 'react-toastify';

// import { getAxiosWithToken, getS3 } from '../../services/common.services';
// import { URL_PATH } from "../../services/constants";
import { EDH } from "../utils/EDH";
import { EDocView, getGuestDoc } from "../api/sinner/SinnerApi";
import { getFile } from "../utils/s3Config";
import { useParams } from "react-router-dom";

/******
 * this hook is used to get the metadata from server and aws s3 also parse and decrypt. in flow.
 * also it combine all fields from all users.
 */

// Document Signing
function useGetCombineDocMetadataForDocumentSigningFlow({ id, refs }) {
  const [docInfo, setDocInfo] = useState(null);
  const [error, setError] = useState(null);
  const [notfound, setNotfound] = useState(false);
  const [showPasswordPopup, setShowPasswordPopup] = useState(false);
  const [passError, setPassError] = useState(null);
  const [decPassword, setDecPassword] = useState("");
  const [mainMeta, setMainMeta] = useState(null);
  const [docResult, setDocResult] = useState(null);
  const [isProgress, setIsProgress] = useState(false);

  let user = JSON.parse(localStorage.getItem("auth"))?.results || {};
  const state = useParams();
  if (state?.email) {
    user = { email: state.email };
  }

  useEffect(() => {
    (async () => {
      try {
        setIsProgress(true);
        const query = `recruiter_id=${user?.recruiter_id || ""}&workspace_id=${
          user?.workspace_id || ""
        }&email=${""}&candidate_id=${user?.candidate_id || ""}`;
        let newResults;
        if (state?.email) {
          newResults = await getGuestDoc(id, state?.email);
        } else {
          newResults = await EDocView(query, id);
        }

        if (newResults?.data?.code === 404) {
          return setNotfound(true);
        }
        console.log("newResults", newResults);
        const responses = () => {
          const data = newResults?.data?.results;
          const doc = {};
          let signers = [];

          for (const key in data) {
            if (key !== "signers") {
              doc[key] = data[key];
              continue;
            }
            signers = [...data[key]];
          }
          return { doc, signers };
        };
        console.log(responses());
        const result = {
          data: { payload: responses(), code: newResults?.data?.code || {} },
        };

        if (result?.data?.code === 200) {
          console.log("result?.data", result?.data);
          setDocResult(result.data);
          let mainMetaS3 = await getFile(result.data.payload.doc.metadataUrl);
          mainMetaS3 = { data: mainMetaS3 };
          // = await getS3(result.data.payload.doc.metadataUrl);
          console.log(mainMetaS3.data);
          setMainMeta(mainMetaS3);
          if (mainMetaS3?.data?.sessionId) {
            setShowPasswordPopup(true);
          } else {
            setError(null);
          }

          setError(null);
        } else {
          console.log("result status null");
          setError(result.data);
          setDocInfo(null);
        }
      } catch (error) {
        console.error(error);
        // toast.error('There is some issue');
      } finally {
        setIsProgress(false);
      }
    })();
  }, [id]);

  const onPassSubmit = async () => {
    setDocInfo(null);
    const encreptionKey = decPassword;
    if (decPassword === "") {
      setPassError("Password is required");
      return;
    }
    try {
      setIsProgress(true);
      const decryptMainBlob = EDH.decryptString(
        mainMeta.data.blob,
        encreptionKey
      );
      const blob = flatted.parse(decryptMainBlob);
      console.log(blob);
      mainMeta.data.totalSigners = docResult.payload.doc.totalSigners;
      mainMeta.data.totalSigned = docResult.payload.doc.totalSigned;
      mainMeta.data.signers = [];
      mainMeta.data.rawSignersMeta = [];
      console.log(docResult.payload);
      const signersMeta = docResult.payload.signers;
      const signers = [];
      for (const s of signersMeta) {
        let sinersData = [];
        let signer = await getFile(s.metadataUrl);
        console.log(signer);
        if (signer?.sessionId) {
          try {
            const decryptBlob = EDH.decryptString(signer.blob, encreptionKey);
            const blob = flatted.parse(decryptBlob);
            signer.blob = blob;
            signers.push(signer);
            console.log("blobsinner", blob.signers);
            for (let index = 0; index < blob.signers?.length; index++) {
              mainMeta.data.signers.push(blob.signers[index]);
            }
          } catch (error) {
            console.error(error);
          }
          mainMeta.data.rawSignersMeta.push(signer);
        }
      }
      setShowPasswordPopup(false);

      console.log("mainMeta.data", mainMeta.data);
      setDocInfo({ ...mainMeta.data, blob: blob });
    } catch (error) {
      console.error(error);
      setPassError("Password is incorrect");
    } finally {
      setIsProgress(false);
    }
  };
  console.log(docResult);
  return {
    docInfo,
    setDocInfo,
    error,
    setError,
    notfound,
    showPasswordPopup,
    onPassSubmit,
    passError,
    setDecPassword,
    isProgress,
    docResult,
    setShowPasswordPopup,
  };
}

export default useGetCombineDocMetadataForDocumentSigningFlow;
