import React, { useRef, useState } from "react";
import { Box } from "@mui/system";
import Actions from "./Actions";
import style from "./style.module.scss";
import { uploadfile } from "../../../../store/actions/uploadfile";
import { UPLOAD_FILE } from "../../../../store/constants/constants";
import { useDispatch } from "react-redux";
import useDragField from "../../../../customHook/useDragField";
function UploadFileField({
  label,
  button_text,
  id,
  main_id,
  type,
  name,
  two_col,
  col_name,
  index,
  moveCard,
}) {
  let dispatch = useDispatch();
  const ref = useRef(null);
  const dragField = useDragField(ref, index, moveCard, id);
  const opacity = dragField.opacity;
  const handleupload = async (name, file) => {
    dispatch(uploadfile(name, file));
  };

  return (
    <Box
      ref={ref}
      style={{ ...style, opacity }}
      data-handler-id={dragField?.handlerId}
      className={`${style.inputBox} dropItem`}
    >
      <Actions
        type={type}
        id={id}
        two_col={two_col}
        col_name={col_name}
        main_id={main_id}
      />
      <label>{label}</label>
      <Box className={`${style.fileUpload} button-primary`}>
        <label className={style.customFileUpload}>
          <input
            required
            className={style.fileUploadInput}
            style={{ display: "" }}
            id="raised-button-file"
            type="file"
            name={name}
            onChange={(e) => {
              handleupload(name, e?.target?.files[0]);
            }}
          />
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="inherit"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7 1L10.25 4M7 1L4 4M7 1V10.5M13 6.5V13H1V6.5"
              stroke="currentColor"
              fill="transparent"
            ></path>
          </svg>
          <span className={style.uploadLabel}>{button_text}</span>
        </label>
      </Box>
    </Box>
  );
}

export default UploadFileField;
