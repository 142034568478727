import { Box, Grid } from "@mui/material";
import React from "react";
import TextInput from "../../../../common/TextInput";
import ImageUploader from "../../../../common/ImageUploader";
import DatePicker from "react-datepicker";
import styles from "./styles.module.scss";
import moment from "moment";
import { Field } from "formik";
import CountrySelect from "../../../../common/CountrySelect/CountrySelect";

export default function LicenseCertificateCommonForm({
  handleChange,
  values,
  errors,
  touched,
  file,
  data,
  edit1,
  setEdit1,
  setFile,
  edit2,
  setEdit2,
}) {
  const handleKeyDown = (event) => {
    event.preventDefault();
  };
  return (
    <>
      <Grid item md={6} xs={12}>
        <TextInput
          style={{ width: "100%" }}
          compolsory={true}
          customClass={styles.input}
          label="First Name"
          type="text"
          name="first_name"
          InputLabelProps={{
            shrink: true,
          }}
          placeholder="First Name"
          onChange={handleChange}
          value={values?.first_name}
        />
        <span>
          {errors.first_name && touched.first_name && errors.first_name}
        </span>
      </Grid>
      <Grid item md={6} xs={12}>
        <TextInput
          style={{ width: "100%" }}
          compolsory={true}
          customClass={styles.input}
          label="Last Name"
          type="text"
          name="last_name"
          // sx={{ mt: 5.6, pb: 1 }}
          InputLabelProps={{
            shrink: true,
          }}
          placeholder="Last Name"
          onChange={handleChange}
          value={values?.last_name}
        />
        <span>{errors.last_name && touched.last_name && errors.last_name}</span>
      </Grid>
      <Grid item xs={6}>
        <TextInput
          style={{ width: "100%" }}
          customClass={styles.input}
          label={
            <>
              Email
              <span style={{ color: "red" }}>*</span>
            </>
          }
          type="email"
          name="mail_id"
          // sx={{ mt: 5.6, pb: 1 }}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={handleChange}
          value={values?.mail_id}
        />
        <span>
          {errors.first_name && touched.first_name && errors.first_name}
        </span>
      </Grid>
      <Grid item xs={6}>
        <TextInput
          compolsory={true}
          label="Date of birth"
          type="date"
          name="dob"
          placeholder={"Date of birth"}
          max={new Date().toISOString().split("T")[0]}
          onChange={handleChange}
          value={values.dob}
          onKeyDown={handleKeyDown}
        />
        <span>{errors.dob && touched.dob && errors.dob}</span>
      </Grid>
      <Grid item xs={6}>
        <TextInput
          label="State"
          type="text"
          name="state"
          onChange={handleChange}
          value={values.state}
        />
        <span>{errors.state && touched.state && errors.state}</span>
      </Grid>
      <Grid item xs={6}>
        <Box className={styles.parentInput}>
          <Field
            compolsory={true}
            customClass={styles.input}
            label="Country of document "
            name="country"
            component={CountrySelect}
            placeholder="Country of document"
            style={{ marginTop: "19px", minHeight: "41px" }}
          />
          <span
            style={{
              color: "red",
              fontSize: "small",
            }}
          >
            {errors.country && touched.country && errors.country}
          </span>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <TextInput
          style={{ width: "100%" }}
          customClass={styles.input}
          label={
            <>
              Document ID&nbsp;
              <span style={{ color: "red" }}>*</span>
            </>
          }
          type="text"
          name="doc_id"
          InputLabelProps={{
            shrink: true,
          }}
          onChange={handleChange}
          value={values.doc_id}
        />
        <span>{errors.doc_id && touched.doc_id && errors.doc_id}</span>
      </Grid>
      <Grid item xs={6}>
        <TextInput
          compolsory={true}
          label="Issue Date"
          type="date"
          name="issue_date"
          placeholder={"Issue Date"}
          onChange={handleChange}
          max={
            values.expiry_date && values.expiry_date !== ""
              ? values.expiry_date.replace(
                  /(\d{2})\/(\d{2})\/(\d{4})/,
                  "$3-$2-$1"
                )
              : new Date().toISOString().split("T")[0]
          }
          value={values.issue_date.replace(
            /(\d{2})\/(\d{2})\/(\d{4})/,
            "$3-$2-$1"
          )}
          onKeyDown={handleKeyDown}
        />
        <span>
          {errors.issue_date && touched.issue_date && errors.issue_date}
        </span>
      </Grid>
      <Grid item xs={6}>
        <TextInput
          compolsory={true}
          label="Expiry Date"
          type="date"
          name="expiry_date"
          placeholder={"Expiry Date"}
          onChange={handleChange}
          min={
            values.issue_date && values.issue_date !== ""
              ? values.issue_date.replace(
                  /(\d{2})\/(\d{2})\/(\d{4})/,
                  "$3-$2-$1"
                )
              : new Date().toISOString().split("T")[0]
          }
          value={values.expiry_date.replace(
            /(\d{2})\/(\d{2})\/(\d{4})/,
            "$3-$2-$1"
          )}
          onKeyDown={handleKeyDown}
        />
        <span>
          {errors.expiry_date && touched.expiry_date && errors.expiry_date}
        </span>
      </Grid>
      <Grid item xs={6}>
        <Box className={styles.parentBox}>
          <ImageUploader
            label={
              <>
                <span>Front Side Image</span>
              </>
            }
            valuenew={file?.front_image || data?.front_image}
            accept={"image/*"}
            edit={edit1}
            onChange={(e) => {
              setEdit1(false);
              setFile({
                ...file,
                front_image: e.target.files[0],
              });
            }}
            name="front_image"
          />
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Box className={styles.parentBox}>
          <ImageUploader
            label={
              <>
                <span>Back Side Image</span>
              </>
            }
            valuenew={file?.back_image || data?.back_image}
            accept={"image/*"}
            edit={edit2}
            onChange={(e) => {
              setEdit2(false);
              setFile({
                ...file,
                back_image: e.target.files[0],
              });
            }}
            name="back_image"
          />
        </Box>
      </Grid>
    </>
  );
}
