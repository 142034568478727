import React, { useEffect, useState } from "react";
import { Box, Typography, Grid, Button, CircularProgress } from "@mui/material";
import styles from "./styles.module.scss";
import SwitchIOS from "../../common/Switch";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { routes } from "../../../routes";
import { ReactComponent as ReturnIcon } from "../../../assets/jobs/returnArrow.svg";
import TextInput from "../../common/TextInput";
import { AiOutlinePlus } from "react-icons/ai";
import { ReactComponent as Edit } from "../../../assets/candidates/edit.svg";
import CustomSelect from "../../common/Select";
import SalarySelect from "../../common/SalarySelect";
import ImageUploader from "../../common/ImageUploader";
// formik and yup for validation
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import ImageSection from "../../login/ImageSection";
import { ShowAlert } from "../../../store/actions/alertActions";
import {
  getAllCertificatesIndustry,
  getIndusTrySize,
} from "../../../store/actions/industryActions";
import {
  createCompany,
  isCompanyExisit,
  resetCompany,
  updateCompany,
} from "../../../store/actions/companyActions";
import {
  createjob,
  jobCateFunc,
  jobExpFunc,
  jobSalaryFunc,
  jobTypeFunc,
  resetJob,
  updateJobFunc,
} from "../../../store/actions/recruiterJobsActions";
import { REGEX } from "../../../customHooks/utils";
import ReactInputMask from "react-input-mask";
import { event } from "jquery";
import { MdTitle } from "react-icons/md";

const CreateJob = () => {
  const dispatch = useDispatch();
  const company = useSelector((state) => state?.company);

  // company?.existCompany?.results?.company_detail_id;
  const jobsRecruiter = useSelector((state) => state?.rjobs);
  // console.log("jobsRecruiter", jobsRecruiter);
  const auth = useSelector((state) => state?.auth);
  const [customLoading, setCustomLoading] = useState(false);
  const currancyOptions = [
    { value: "aud", label: "AUD" },
    { value: "usd", label: "USD" },
    { value: "eur", label: "EUR" },
    { value: "jpy", label: "JPY" },
    { value: "gbp", label: "GBP" },
    { value: "cad", label: "CAD" },
    { value: "chf", label: "CHF" },
    { value: "php", label: "PHP" },
  ];

  const navigate = useNavigate();

  useEffect(() => {
    if (company?.existCompany) {
      if (company?.existCompany?.success === false) {
        navigate(routes.COMPANY);
      }
    }
  }, [company?.existCompany, navigate]);

  //  create job

  const initialValues = {
    address: jobsRecruiter?.editedJob?.results?.address || "",
    job_title: jobsRecruiter?.editedJob?.results?.job_title || "",
    // job_contact_email: "",
    // job_contact_number: "",
    job_description: jobsRecruiter?.editedJob?.results?.job_description || "",
    min_salary: jobsRecruiter?.editedJob?.results?.min_salary || "",
    qualification: jobsRecruiter?.editedJob?.results?.qualification || "",
  };
  const [jobSelectValues, setJobSelectValues] = useState({
    job_type_id:
      {
        value: jobsRecruiter?.editedJob?.results?.job_type?.id,
        label: jobsRecruiter?.editedJob?.results?.job_type?.name,
      } || "",
    job_salary_type_id:
      {
        value: jobsRecruiter?.editedJob?.results?.job_salary_type?.id,
        label: jobsRecruiter?.editedJob?.results?.job_salary_type?.name,
      } || "",
    job_salary_currency:
      {
        value: jobsRecruiter?.editedJob?.results?.job_salary_currency?.id,
        label: jobsRecruiter?.editedJob?.results?.job_salary_currency?.name,
      } || "",
    employment_type_id:
      {
        value: jobsRecruiter?.editedJob?.results?.employment_type?.id,
        label: jobsRecruiter?.editedJob?.results?.employment_type?.name,
      } || "",
    job_experience_id:
      {
        value: jobsRecruiter?.editedJob?.results?.job_experience?.id,
        label: jobsRecruiter?.editedJob?.results?.job_experience?.name,
      } || "1",
    job_category_id:
      {
        value: jobsRecruiter?.editedJob?.results?.job_category?.id,
        label: jobsRecruiter?.editedJob?.results?.job_category?.name,
      } || "",
  });

  const [jobStatusValues, setJobStatusValues] = useState({
    job_status_id:
      {
        value: jobsRecruiter?.editedJob?.results?.job_status,
        label: jobsRecruiter?.editedJob?.results?.job_status_name,
      } || "",
  });

  const options_job_status = [
    {
      value: "1",
      label: "Active",
    },
    // {
    //   value: "3",
    //   label: "Archived",

    // },
    {
      value: "2",
      label: "job filled",
    },
  ];

  useEffect(() => {
    if (!jobsRecruiter?.editedJob?.results) {
      setJobSelectValues({
        ...jobSelectValues,
        job_category_id: {
          value: auth?.results?.company_detail?.industry?.id,
          label: auth?.results?.company_detail?.industry?.name,
        },
      });
    }
  }, []);

  const jobCreateValidation = Yup.object().shape({
    job_title: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
    // job_type_id: Yup.string().required("Required"),
    // job_salary_type_id: Yup.string().required("Required"),
    address: Yup.string().required("Required"),
    // job_contact_email: Yup.string()

    //   .required("Required")
    //   .email("Enter the valid Email"),
    // job_contact_number: Yup.string().required("Required"),

    job_description: Yup.string()
      .required("Required")

      .max(300, "Too Long!"),
    min_salary: Yup.string().required("Required"),
  });

  // API FOR SELECT JOB

  const jobSalaryOptions = jobsRecruiter?.jobSalary?.results?.map((e, i) => ({
    value: e?.id,
    label: e?.text,
  }));
  const jobCataOptions = jobsRecruiter?.jobCata?.results?.map((e, i) => ({
    value: e?.id,
    label: e?.text,
  }));
  const jobExpOptions = jobsRecruiter?.jobExp?.results?.map((e, i) => ({
    value: e?.id,
    label: e?.text,
  }));
  const jobTypeOptions = jobsRecruiter?.jobType?.results?.map((e, i) => ({
    value: e?.id,
    label: e?.text,
  }));

  const onSubmitJob = async (values, { restForm }) => {
    setCustomLoading(true);

    const local_data = {
      ...values,
      inputFields,
      min_salary: values?.min_salary?.toString(),
      job_type_id: jobSelectValues.job_type_id.value.toString(),
      job_salary_type_id: jobSelectValues.job_salary_type_id.value.toString(),
      job_salary_currency: jobSelectValues.job_salary_currency.value.toString(),
      employment_type_id: "1",
      job_experience_id: jobSelectValues.job_experience_id.value.toString(),
      job_category_id: jobSelectValues?.job_category_id?.value.toString(),
      workspace_id: auth?.results?.workspace_id.toString(),
      recruiter_id: auth?.results?.recruiter_id,
      company_detail_id: company?.existCompany?.results?.company_detail_id,
      job_contact_number: auth?.results?.contact_number,
      job_contact_email: auth?.results?.email,
      currency_id: "14",
      job_status_id: jobStatusValues?.job_status_id?.value || "",
    };
    {
      jobsRecruiter?.editedJob?.success
        ? dispatch(
            updateJobFunc({
              ...local_data,
              uuid: jobsRecruiter?.editedJob?.results?.uuid,
            })
          )
        : dispatch(createjob(local_data));
    }

    // workspace_id
    //   recruiter_id: "",
    // company_detail_id: "",
  };
  useEffect(() => {
    if (
      jobsRecruiter?.createdJob &&
      jobsRecruiter?.createdJob?.success === true
    ) {
      // dispatch(ShowAlert("Job Created ", "success"));
      dispatch(resetJob());
      navigate(routes.JOBS);
    }
  }, [dispatch, jobsRecruiter?.createdJob]);
  useEffect(() => {
    if (
      jobsRecruiter?.updateJob &&
      jobsRecruiter?.updateJob?.success === true
    ) {
      // dispatch(ShowAlert("Job Created ", "success"));
      dispatch(resetJob());
      navigate(routes.JOBS);
    }
  }, [dispatch, jobsRecruiter?.updateJob]);

  useEffect(() => {
    if (jobsRecruiter?.editedJob?.results?.input_fields.length > 0) {
      setInputFields(jobsRecruiter?.editedJob?.results?.input_fields);
    } else {
      setInputFields([
        {
          label: "",
          value: "",
        },
      ]);
    }
  }, []);

  const [inputFields, setInputFields] = useState([
    {
      label: "",
      value: "",
    },
  ]);

  const [newFieldLabel, setNewFieldLabel] = useState("");
  const addFieldHandle = () => {
    setInputFields([...inputFields, { label: "", value: "" }]);
  };
  const removeInputFields = (index) => {
    const rows = [...inputFields];
    rows.splice(index, 1);

    setInputFields(rows);
  };
  const handleFieldValue = (index, evnt) => {
    const { value } = evnt.target;
    const list = [...inputFields];
    list[index].value = value;
    setInputFields(list);
  };
  const handleFieldLabel = (index, e) => {
    const { value } = e.target;
    const list = [...inputFields];
    list[index].label = value;
    setInputFields(list);
  };

  return (
    <>
      <Box>
        <Box className={styles.parent}>
          <Box className={styles.head}>
            <Typography variant="h4">Jobs</Typography>
          </Box>
          <Box className={styles.buttonWrapper}>
            <Box className={styles.return}>
              <Link to={routes.JOBS}>
                <ReturnIcon /> Return To Jobs
              </Link>
            </Box>
            <Box className={`button-primary ${styles.button}`}>
              {/* <SwitchIOS /> */}
            </Box>
          </Box>

          <Box className={styles.form}>
            <>
              <Box className={styles.job}>
                <Typography variant="h6">Job Information</Typography>
                {/* <Edit /> */}
              </Box>

              <Formik
                initialValues={initialValues}
                validationSchema={jobCreateValidation}
                onSubmit={onSubmitJob}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleSubmit,
                  handleBlur,
                  setFieldValue,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Grid
                      container
                      spacing={1}
                      columnSpacing={15}
                      alignItems={"end"}
                    >
                      <Grid item xs={12} md={6}>
                        <Box className={styles.parentBox}>
                          <TextInput
                            autoComplete="off"
                            compolsory={true}
                            placeholder={""}
                            type="text"
                            name="job_title"
                            value={values.job_title}
                            onChange={handleChange}
                            label="Job Title"
                            customClass={styles.createJobInp}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <span
                          style={{
                            fontFamily: "poppins-bold",
                            fontSize: "14px",
                            color: "#9f9f9f",
                            marginTop: "19px",
                            display: "block",
                          }}
                        >
                          Salary Frequency
                          <span
                            style={{
                              color: "red",
                              marginLeft: "3px",
                            }}
                          >
                            *
                          </span>
                        </span>
                        <Grid container>
                          <Grid item lg={4} xs={6}>
                            <CustomSelect
                              required
                              customClass={`${styles.createJobSelect} createJob mt-0`}
                              value={jobSelectValues.job_salary_currency}
                              options={currancyOptions}
                              sx={{ maxWidth: "initial" }}
                              onChange={(e) =>
                                setJobSelectValues({
                                  ...jobSelectValues,
                                  job_salary_currency: e,
                                })
                              }
                            ></CustomSelect>
                          </Grid>
                          <Grid item lg={8} xs={6}>
                            <CustomSelect
                              options={jobSalaryOptions}
                              customClass={`${styles.createJobSelect} createJob mt-0 pl-2`}
                              value={jobSelectValues.job_salary_type_id}
                              onFocus={() => {
                                dispatch(jobSalaryFunc());
                              }}
                              onChange={(e) =>
                                setJobSelectValues({
                                  ...jobSelectValues,
                                  job_salary_type_id: e,
                                })
                              }
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Box className={styles.parentBox}>
                          <TextInput
                            autoComplete="off"
                            compolsory={true}
                            placeholder={""}
                            type="number"
                            min="0"
                            onKeyPress={(e) => {
                              if (
                                e.code === "Minus" ||
                                e.code === "NumpadSubtract" ||
                                e.code === "Comma" ||
                                e.code === "NumpadAdd" ||
                                e.code === "Period" ||
                                e.key === "e" ||
                                e.key === "E"
                              ) {
                                e.preventDefault();
                              }
                            }}
                            name="min_salary"
                            value={values.min_salary}
                            onChange={handleChange}
                            label="Salary"
                            customClass={`${styles.createJobInp} ${styles.addDollar}`}
                          />
                        </Box>

                        {/* <label>
                          Salary <span style={{ color: "red" }}>*</span>
                        </label> */}
                        {/* <ReactInputMask
                            blocks={}
                            blocks={{
                              num: {
                                // nested masks are available!
                                mask: Number,
                                thousandsSeparator: " ",
                              },
                            }}
                            mask={"$999999999999"}
                            maskChar=" "
                            name="min_salary"
                            value={values.min_salary}
                            onChange={handleChange}
                            className={`${styles.createJobInp} ${styles.input3}`}
                          /> */}
                        {/* {Customerror?.min_salary && (
                            <p className="error-class position-absolute">
                              {Customerror?.min_salary}
                            </p>
                          )} */}
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <CustomSelect
                          options={jobCataOptions}
                          customClass={`${styles.createJobSelect} createJob`}
                          value={jobSelectValues.job_category_id}
                          label={
                            <>
                              Job Category
                              <span
                                style={{
                                  color: "red",
                                  marginLeft: "3px",
                                }}
                              >
                                *
                              </span>
                            </>
                          }
                          onFocus={() => {
                            dispatch(jobCateFunc());
                          }}
                          onChange={(e) =>
                            setJobSelectValues({
                              ...jobSelectValues,
                              job_category_id: e,
                            })
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Box className={styles.parentBox}>
                          <CustomSelect
                            options={jobTypeOptions}
                            customClass={`${styles.createJobSelect} createJob`}
                            value={jobSelectValues.job_type_id}
                            label={
                              <>
                                Job Type
                                <span
                                  style={{
                                    color: "red",
                                    marginLeft: "3px",
                                  }}
                                >
                                  *
                                </span>
                              </>
                            }
                            onFocus={() => {
                              dispatch(jobTypeFunc());
                            }}
                            onChange={(e) =>
                              setJobSelectValues({
                                ...jobSelectValues,
                                job_type_id: e,
                              })
                            }
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Box className={styles.parentBox}>
                          <CustomSelect
                            options={jobExpOptions}
                            customClass={`${styles.createJobSelect} createJob`}
                            value={jobSelectValues.job_experience_id}
                            label={
                              <>
                                Job Experience
                                <span
                                  style={{
                                    color: "red",
                                    marginLeft: "3px",
                                  }}
                                >
                                  *
                                </span>
                              </>
                            }
                            onFocus={() => {
                              dispatch(jobExpFunc());
                            }}
                            onChange={(e) =>
                              setJobSelectValues({
                                ...jobSelectValues,
                                job_experience_id: e,
                              })
                            }
                          />
                        </Box>
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <Box className={styles.parentBox}>
                          <TextInput
                            autoComplete="off"
                            compolsory={true}
                            placeholder={""}
                            type="text"
                            name="address"
                            onChange={handleChange}
                            value={values.address}
                            label="Job Location"
                            customClass={styles.createJobInp}
                          />
                        </Box>
                      </Grid>
                      {jobsRecruiter?.edit !== null && (
                        <Grid item xs={12} md={6}>
                          <Box className={styles.parentBox}>
                            <CustomSelect
                              options={options_job_status}
                              customClass={`${styles.createJobSelect} createJob`}
                              value={jobStatusValues.job_status_id}
                              label={
                                <>
                                  Job Status
                                  <span
                                    style={{
                                      color: "red",
                                      marginLeft: "3px",
                                    }}
                                  >
                                    *
                                  </span>
                                </>
                              }
                              onFocus={() => {
                                dispatch(jobExpFunc());
                              }}
                              onChange={(e) =>
                                setJobStatusValues({
                                  ...jobStatusValues,
                                  job_status_id: e,
                                })
                              }
                            />
                          </Box>
                        </Grid>
                      )}

                      <Grid item xs={12} md={6}>
                        <Box className={styles.parentBox}>
                          <TextInput
                            compolsory={true}
                            placeholder={""}
                            type="text"
                            name="qualification"
                            label="Qualification"
                            value={values.qualification}
                            customClass={styles.createJobInp}
                            onChange={handleChange}
                          />
                        </Box>
                      </Grid>

                      {/* <Grid item xs={12} md={6}>
                      <Box className={styles.parentBox}>
                        <TextInput
                        autoComplete='off'
                        compolsory={true}
                          placeholder={""}
                          type="email"
                          name="job_contact_email"
                          value={values.job_contact_email}
                          onChange={handleChange}
                          label="Email"
                          customClass={styles.createJobInp}
                        />
                      </Box>
                    </Grid> */}
                      {/* <Grid item xs={12} md={6}>
                      <Box className={styles.parentBox}>
                        <TextInput
                        autoComplete='off'
                        compolsory={true}
                          placeholder={""}
                          type="number"
                          onChange={handleChange}
                          name="joblocation"
                          label="Contact Number"
                          customClass={styles.createJobInp}
                        />
                      </Box>
                    </Grid> */}

                      <Grid item xs={12} md={6} className="">
                        <Box className={styles.parentBox}>
                          <TextInput
                            autoComplete="off"
                            compolsory={true}
                            textarea
                            rows="1"
                            placeholder={""}
                            type="text"
                            name="job_description"
                            onChange={handleChange}
                            value={values.job_description}
                            label="Job Description"
                            customClass={styles.createJobInp}
                          />
                        </Box>
                      </Grid>

                      <Grid item xs={12}>
                        <Typography
                          variant="h5"
                          style={{
                            paddingTop: "12px",
                            fontSize: "18px",
                            fontWeight: "600",
                          }}
                        >
                          Summary Fields
                        </Typography>
                      </Grid>

                      {inputFields?.map((data, index) => {
                        const { fullName, emailAddress, salary } = data;
                        return (
                          <Grid
                            item
                            xs={12}
                            md={6}
                            className="d-flex align-items-end flex-sm-nowrap flex-wrap"
                            key={index}
                          >
                            <TextInput
                              autoComplete="off"
                              // compolsory={true}
                              placeholder={"Enter your field value"}
                              type="text"
                              onChange={(e) => handleFieldLabel(index, e)}
                              label="Field heading"
                              value={data.label}
                              customClass={`${styles.createJobInp} mw-100 w-100`}
                            />

                            <TextInput
                              autoComplete="off"
                              // compolsory={true}
                              placeholder={"Enter your field value"}
                              type="text"
                              name={newFieldLabel}
                              value={data.value}
                              onChange={(evnt) => handleFieldValue(index, evnt)}
                              label="Field Value"
                              customClass={`${styles.createJobInp} mw-100 w-100 ml-sm-2 ml-0`}
                            />
                            {inputFields.length !== 0 ? (
                              <button
                                className="btn btn-outline-danger ml-sm-2 ml-0 mt-sm-0 mt-2"
                                style={{ height: 42, width: 40 }}
                                onClick={(evnt) => removeInputFields(index)}
                              >
                                x
                              </button>
                            ) : (
                              ""
                            )}
                          </Grid>
                        );
                      })}

                      <Grid item xs={12} md={6}>
                        <Box className={`button-primary mx-0 mw-100`}>
                          <Button
                            onClick={addFieldHandle}
                            disabled={jobsRecruiter?.loading}
                          >
                            {customLoading && jobsRecruiter?.loading ? (
                              <p style={{ color: "white" }}>Loading...</p>
                            ) : (
                              <>
                                <AiOutlinePlus /> Add More Fields
                              </>
                            )}
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                    <Box className={`button-primary ${styles.button}`}>
                      <Button
                        onClick={handleSubmit}
                        disabled={jobsRecruiter?.loading}
                      >
                        {customLoading && jobsRecruiter?.loading ? (
                          <p style={{ color: "white" }}>Loading...</p>
                        ) : (
                          <>
                            {jobsRecruiter?.editedJob?.success ? (
                              <>
                                {" "}
                                <AiOutlinePlus /> Update
                              </>
                            ) : (
                              <>
                                {" "}
                                <AiOutlinePlus /> Create
                              </>
                            )}
                          </>
                        )}
                      </Button>
                    </Box>
                  </form>
                )}
              </Formik>
            </>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default CreateJob;
