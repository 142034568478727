export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_CANDIDATE = "LOGIN_CANDIDATE";
export const SIGNUP_USER = "SIGNUP_USER";
export const LOGOUT_USER = "LOGOUT_USER";
export const API_LOGIN = "API_LOGIN";
export const API_ADMIN_LOGIN = "API_ADMIN_LOGIN";
export const GET_DELETE_ID = "GET_DELETE_ID";
export const GET_CHECKBOX_OPTION = "GET_CHECKBOX_OPTION";
export const STORE_COMPONENT_ID = "STORE_COMPONENT_ID";
export const DELETE_CHECKBOX_OPTION = "DELETE_CHECKBOX_OPTION";
export const DELETE_CHECKBOX = "DELETE_CHECKBOX";
export const GET_CHECKBOX_OPTION_LIST = "GET_CHECKBOX_OPTION_LIST";
export const OPTION_LABEL = "OPTION_LABEL";
export const OPTION_LABEL_VALUE = "OPTION_LABEL_VALUE";
export const LOADING_TRUE = "LOADING_TRUE";
export const LOADING_FALSE = "LOADING_FALSE";
export const UPLOAD_FILE = "UPLOAD_FILE";
export const UPLOAD_SIGN = "UPLOAD_SIGN";
export const GET_ALL_INDUSTRY_START = "GET_ALL_INDUSTRY_START";
export const GET_ALL_INDUSTRY = "GET_ALL_INDUSTRY";
export const GET_ALL_INDUSTRY_FAil = "GET_ALL_INDUSTRY_FAil";
export const CREATE_INDUSTRY = "CREATE_INDUSTRY";
export const DELETE_INDUSTRY = "DELETE_INDUSTRY";
export const UPDATE_INDUSTRY = "UPDATE_INDUSTRY";
export const UNSELECT_INDUSTRY = "UNSELECT_INDUSTRY";
export const FAIL_ALL_INDUSTRY = "FAIL_ALL_INDUSTRY";
export const GET_CERTIFICATE = "GET_CERTIFICATE";
export const GET_CERTIFICATES_INDUSTRIES = "GET_CERTIFICATES_INDUSTRIES";
export const GET_ALL_CREATED_INDUSTIRES_START =
  "GET_ALL_CREATED_INDUSTIRES_START";
export const GET_ALL_CREATED_INDUSTIRES = "GET_ALL_CREATED_INDUSTIRES";
export const INDUSTRY_SIZE = "INDUSTRY_SIZE";
export const EDITED_TRUE = "EDITED_TRUE";
export const RESET_MODAL = "RESET_MODAL";
export const STATUS_CHANGE = "STATUS_CHANGE";

export const GET_SINGLE_INDUSTRY = "GET_SINGLE_INDUSTRY";

export const GET_ALL_MEMBER_RECRUITER_START = "GET_ALL_MEMBER_RECRUITER_START";
export const GET_ALL_MEMBER_RECRUITER = "GET_ALL_MEMBER_RECRUITER";
export const CREATE_MEMBER_RECRUITER = "CREATE_MEMBER_RECRUITER";
export const UPDATE_MEMBER_RECRUITER = "UPDATE_MEMBER_RECRUITER";
export const EDIT_MEMBER_RECRUITER = "EDIT_MEMBER_RECRUITER";
export const DELETE_MEMBER_RECRUITER = "DELETE_MEMBER_RECRUITER";
export const ALLOCATE_MEMBER_DATA = "ALLOCATE_MEMBER_DATA";
export const ALLOCATE_CREDITS = "ALLOCATE_CREDITS";

export const GET_COMPANY_LIST = "GET_COMPANY_LIST";
export const CREATE_COMPANY = "CREATE_COMPANY";
export const UPDATE_COMPANY = "UPDATE_COMPANY";
export const EDIT_COMPANY = "EDIT_COMPANY";
export const DELETE_COMPANY = "DELETE_COMPANY";
export const IS_COMPANY_EXISIT = "IS_COMPANY_EXISIT";

export const GET_ALL_JOB_ARCHIVED_RECRUITER_START =
  "GET_ALL_JOB_ARCHIVED_RECRUITER_START";
export const GET_ALL_JOB_RECRUITER_START = "GET_ALL_JOB_RECRUITER_START";
export const GET_ALL_JOB_DEACTIVATE_RECRUITER_START =
  "GET_ALL_JOB_DEACTIVATE_RECRUITER_START";
export const GET_ALL_JOB_RECRUITER = "GET_ALL_JOB_RECRUITER";
export const GET_ALL_JOB_ARCHIVED_RECRUITER = "GET_ALL_JOB_ARCHIVED_RECRUITER";
export const GET_ALL_JOB_DEACTIVATE_RECRUITER =
  "GET_ALL_JOB_DEACTIVATE_RECRUITER";
export const CREATE_JOB_RECRUITER = "CREATE_JOB_RECRUITER";
export const UPDATE_JOB_RECRUITER = "UPDATE_JOB_RECRUITER";
export const EDIT_JOB_RECRUITER = "EDIT_JOB_RECRUITER";
export const DELETE_JOB_RECRUITER = "DELETE_MEMBER_RECRUITER";
export const JOB_TYPE = "JOB_TYPE";
export const JOB_SALARY = "JOB_SALARY";
export const JOB_EXPERIENCE = "JOB_EXPERIENCE";
export const JOB_EMPLOYEMENT_SIZE = "JOB_EMPLOYEMENT_SIZE";
export const JOB_CATEGORY = "JOB_CATEGORY";

export const GET_ALL_CANDIDATE_RECRUITER_START =
  "GET_ALL_CANDIDATE_RECRUITER_START";

export const GET_ALL_CANDIDATE_RECRUITER = "GET_ALL_CANDIDATE_RECRUITER";

export const CREATE_CANDIDATE_RECRUITER = "CREATE_CANDIDATE_RECRUITER";
export const UPDATE_CANDIDATE_RECRUITER = "UPDATE_CANDIDATE_RECRUITER";
export const EDIT_CANDIDATE_RECRUITER = "EDIT_CANDIDATE_RECRUITER";
export const DELETE_CANDIDATE_RECRUITER = "DELETE_MEMBER_RECRUITER";
export const CREATE_EXISIT_CANDIDATE_RECRUITER =
  "  CREATE_EXISIT_CANDIDATE_RECRUITER";

export const GET_ALL_CREATED_CANDITDATE_INDUSTIRES =
  "  GET_ALL_CREATED_CANDITDATE_INDUSTIRES";
export const GET_ALL_CREATED_INDUSTIRES_CANDIDATE_START =
  "  GET_ALL_CREATED_INDUSTIRES_CANDIDATE_START";

export const SHOW_CANDIDATE_RECRUITER = "SHOW_CANDIDATE_RECRUITER";
export const GET_EXISITING_CANDIDATE = "GET_EXISITING_CANDIDATE";
export const GET_RECRUITER_CREATED_INDUSTRY = "GET_RECRUITER_CREATED_INDUSTRY";
export const GET_RECRUITER_CREATED_INDUSTRY_START =
  "GET_RECRUITER_CREATED_INDUSTRY_START";

export const SHOW_CANDIDATE_RECRUITER_REFF_START =
  "SHOW_CANDIDATE_RECRUITER_REFF_START";
export const SHOW_CANDIDATE_RECRUITER_REFF = "SHOW_CANDIDATE_RECRUITER_REFF";

export const CREATE_CANDIDATE_RECRUITER_REFF =
  "CREATE_CANDIDATE_RECRUITER_REFF";
export const SHOW_SINGLE_CANDIDATE_RECRUITER_REFF =
  "SHOW_SINGLE_CANDIDATE_RECRUITER_REFF";

export const STATUS_SINGLE_CANDIDATE_RECRUITER_REFF =
  "STATUS_SINGLE_CANDIDATE_RECRUITER_REFF";
export const RESEND_SINGLE_CANDIDATE_RECRUITER_REFF =
  "RESEND_SINGLE_CANDIDATE_RECRUITER_REFF";

export const REST_REFF = "REST_REFF";

export const SHOW_CANDIDATE_RECRUITER_CERTIFICATE_START =
  "SHOW_CANDIDATE_RECRUITER_CERTIFICATE_START";

export const SHOW_CANDIDATE_RECRUITER_CERTIFICATE_START2 =
  "SHOW_CANDIDATE_RECRUITER_CERTIFICATE_START";

export const SHOW_CANDIDATE_RECRUITER_CERTIFICATE =
  "SHOW_CANDIDATE_RECRUITER_CERTIFICATE";
export const SHOW_CANDIDATE_RECRUITER_CERTIFICATE2 =
  "SHOW_CANDIDATE_RECRUITER_CERTIFICATE2";

export const CREATE_CANDIDATE_RECRUITER_CERTIFICATE =
  "CREATE_CANDIDATE_RECRUITER_CERTIFICATE";
export const SHOW_SINGLE_CANDIDATE_RECRUITER_CERTIFICATE =
  "SHOW_SINGLE_CANDIDATE_RECRUITER_CERTIFICATE";
export const RESEND_SINGLE_CANDIDATE_RECRUITER_CERTIFICATE =
  "RESEND_SINGLE_CANDIDATE_RECRUITER_CERTIFICATE";
export const STATUS_SINGLE_CANDIDATE_RECRUITER_CERTIFICATE =
  "STATUS_SINGLE_CANDIDATE_RECRUITER_CERTIFICATE";

export const EDIT_SINGLE_CANDIDATE_RECRUITER_CERTIFICATE =
  "EDIT_SINGLE_CANDIDATE_RECRUITER_CERTIFICATE";
export const UPDATE_SINGLE_CANDIDATE_RECRUITER_CERTIFICATE =
  "UPDATE_SINGLE_CANDIDATE_RECRUITER_CERTIFICATE";
export const DELETE_SINGLE_CANDIDATE_RECRUITER_CERTIFICATE =
  "DELETE_SINGLE_CANDIDATE_RECRUITER_CERTIFICATE";

export const SHOW_CANDIDATE_RECRUITER_INTERVIEW =
  "  SHOW_CANDIDATE_RECRUITER_INTERVIEW";
export const SHOW_CANDIDATE_RECRUITER_INTERVIEW_START =
  "  SHOW_CANDIDATE_RECRUITER_INTERVIEW_START  ";
export const CREATE_CANDIDATE_RECRUITER_INTERVIEW =
  "CREATE_CANDIDATE_RECRUITER_INTERVIEW";

export const SHOW_CANDIDATE_RECRUITER_FORM = "  SHOW_CANDIDATE_RECRUITER_FORM";
export const SHOW_CANDIDATE_RECRUITER_FORM_START =
  "  SHOW_CANDIDATE_RECRUITER_FORM_START  ";
export const CREATE_CANDIDATE_RECRUITER_FORM =
  "CREATE_CANDIDATE_RECRUITER_FORM";
export const SHOW_CANDIDATE_RECRUITER_FORM_FAIL =
  "SHOW_CANDIDATE_RECRUITER_FORM_FAIL";

export const SHOW_CANDIDATE_RECRUITER_FORM1 =
  "  SHOW_CANDIDATE_RECRUITER_FORM1";
export const SHOW_CANDIDATE_RECRUITER_FORM_START1 =
  "  SHOW_CANDIDATE_RECRUITER_FORM_START1  ";
export const CREATE_CANDIDATE_RECRUITER_FORM1 =
  "CREATE_CANDIDATE_RECRUITER_FORM1";
export const SHOW_CANDIDATE_RECRUITER_FORM_FAIL1 =
  "SHOW_CANDIDATE_RECRUITER_FORM_FAIL1";

export const SHOW_CANDIDATE_RECRUITER_FORM2 =
  "  SHOW_CANDIDATE_RECRUITER_FORM2";
export const SHOW_CANDIDATE_RECRUITER_FORM_START2 =
  "  SHOW_CANDIDATE_RECRUITER_FORM_START2  ";
export const CREATE_CANDIDATE_RECRUITER_FORM2 =
  "CREATE_CANDIDATE_RECRUITER_FORM2";
export const SHOW_CANDIDATE_RECRUITER_FORM_FAIL2 =
  "SHOW_CANDIDATE_RECRUITER_FORM_FAIL2";

// Job Offer
export const SHOW_CANDIDATE_RECRUITER_JOB_OFFER =
  "SHOW_CANDIDATE_RECRUITER_JOB_OFFER";
export const SHOW_CANDIDATE_RECRUITER_JOB_OFFER_START2 =
  "SHOW_CANDIDATE_RECRUITER_JOB_OFFER_START2  ";
export const CREATE_CANDIDATE_RECRUITER_JOB_OFFER =
  "CREATE_CANDIDATE_RECRUITER_JOB_OFFER";
export const SHOW_CANDIDATE_RECRUITER_JOB_OFFER_FAIL2 =
  "SHOW_CANDIDATE_RECRUITER_JOB_OFFER_FAIL2";

export const SHOW_CANDIDATE_RECRUITER_FORM3 =
  "  SHOW_CANDIDATE_RECRUITER_FORM3";
export const SHOW_CANDIDATE_RECRUITER_FORM_START3 =
  "  SHOW_CANDIDATE_RECRUITER_FORM_START3  ";
export const CREATE_CANDIDATE_RECRUITER_FORM3 =
  "CREATE_CANDIDATE_RECRUITER_FORM3";
export const SHOW_CANDIDATE_RECRUITER_FORM_FAIL3 =
  "SHOW_CANDIDATE_RECRUITER_FORM_FAIL3";

export const UPDATE_CANDIDATE_RECRUITER_INTERVIEW =
  "UPDATE_CANDIDATE_RECRUITER_INTERVIEW  ";
export const EDIT_CANDIDATE_RECRUITER_INTERVIEW =
  "EDIT_CANDIDATE_RECRUITER_INTERVIEW";
export const STATUS_CANDIDATE_RECRUITER_INTERVIEW =
  "STATUS_CANDIDATE_RECRUITER_INTERVIEW";

export const SHOW_CANDIDATE_RECRUITER_CERTIFICATE_FAIL =
  "SHOW_CANDIDATE_RECRUITER_CERTIFICATE_FAIL";

export const SHOW_CANDIDATE_RECRUITER_CERTIFICATE_FAIL2 =
  "SHOW_CANDIDATE_RECRUITER_CERTIFICATE_FAIL2";

export const SHOW_CANDIDATE_RECRUITER_REFF_FAIL =
  "SHOW_CANDIDATE_RECRUITER_REFF_FAIL";
export const SHOW_CANDIDATE_RECRUITER_INTERVIEW_FAIL =
  "SHOW_CANDIDATE_RECRUITER_INTERVIEW_FAIL";

export const SHOW_CANDIDATE_RECRUITER_INTERVIEW_RESEND =
  "SHOW_CANDIDATE_RECRUITER_INTERVIEW_RESEND";

export const GET_PRICING_DETAIL_START = "GET_PRICING_DETAIL_START";
export const GET_PRICING_DETAIL = "GET_PRICING_DETAIL";
export const GET_PRICING_DETAIL_FAIL = "GET_PRICING_DETAIL_FAIL";

export const PAYMENT_SENT = "PAYMENT_SENT";
export const PAYMENT_FAIL = "PAYMENT_FAIL";
export const PAYMENT_START = "PAYMENT_START";

export const VERIFY_COUPOUN = "VERIFY_COUPOUN";
export const VERIFY_COUPOUN_FAIL = "VERIFY_COUPOUN_FAIL";
export const VERIFY_COUPOUN_START = "VERIFY_COUPOUN_START";

export const CARD_DETAILS = "CARD_DETAILS";
export const CARD_DETAILS_FAIL = "CARD_DETAILS_FAIL";
export const CARD_DETAILS_START = "CARD_DETAILS_START";

export const UPDATE_CARD_START = "UPDATE_CARD_START";
export const UPDATE_CARD = "UPDATE_CARD";
export const UPDATE_CARD_FAIL = "UPDATE_CARD_FAIL";

export const ORDER_PRCING_START = "ORDER_PRCING_START";
export const ORDER_PRCING = "ORDER_PRCING";
export const ORDER_PRCING_FAIL = "ORDER_PRCING_FAIL";

export const PRICE_CART_ADD = "PRICE_CART_ADD";
export const PRICE_CART_SUB = "PRICE_CART_SUB";
export const PRICE_CART_EMPTY = "PRICE_CART_EMPTY";

export const ORDER_LIST_START = "ORDER_LIST_START";
export const ORDER_LIST = "ORDER_LIST";
export const ORDER_LIST_FAIL = "ORDER_LIST_FAIL";

export const ORDER_DETAIL_START = "ORDER_DETAIL_START";
export const ORDER_DETAIL = "ORDER_DETAIL";
export const ORDER_DETAIL_FAIL = "ORDER_DETAIL_FAIL";

// License Order
export const LICENSE_ORDER_LIST_START = "LICENSE_ORDER_LIST_START";
export const LICENSE_ORDER_LIST = "LICENSE_ORDER_LIST";
export const LICENSE_ORDER_LIST_FAIL = "LICENSE_ORDER_LIST_FAIL";

export const LICENSE_ORDER_DETAIL_START = "LICENSE_ORDER_DETAIL_START";
export const LICENSE_ORDER_DETAIL = "LICENSE_ORDER_DETAIL";
export const LICENSE_ORDER_DETAIL_FAIL = "LICENSE_ORDER_DETAIL_FAIL";

// candidate form

export const GET_CANDIDATE_FORM = "GET_CANDIDATE_FORM";
export const GET_CANDIDATE_FORM_FAIL = "GET_CANDIDATE_FORM_FAIL";
export const GET_CANDIDATE_FORM_START = "GET_CANDIDATE_FORM_START";
export const GET_CANDIDATE_FORM_2 = "GET_CANDIDATE_FORM_2";
export const GET_CANDIDATE_FORM_FAIL_2 = "GET_CANDIDATE_FORM_FAIL_2";
export const GET_CANDIDATE_FORM_START_2 = "GET_CANDIDATE_FORM_START_2";
export const GET_CANDIDATE_FORM_3 = "GET_CANDIDATE_FORM_3";
export const GET_CANDIDATE_FORM_FAIL_3 = "GET_CANDIDATE_FORM_FAIL_3";
export const GET_CANDIDATE_FORM_START_3 = "GET_CANDIDATE_FORM_START_3";
export const GET_CANDIDATE_FORM_4 = "GET_CANDIDATE_FORM_4";
export const GET_CANDIDATE_FORM_FAIL_4 = "GET_CANDIDATE_FORM_FAIL_4";
export const GET_CANDIDATE_FORM_START_4 = "GET_CANDIDATE_FORM_START_4";

export const GET_CANDIDATE_FORM_5 = "GET_CANDIDATE_FORM_5";
export const GET_CANDIDATE_FORM_FAIL_5 = "GET_CANDIDATE_FORM_FAIL_5";
export const GET_CANDIDATE_FORM_START_5 = "GET_CANDIDATE_FORM_START_5";

// form sent

export const GET_CANDIDATE_FORM_SENT = "GET_CANDIDATE_FORM_SENT";
export const GET_CANDIDATE_FORM_SENT_FAIL = "GET_CANDIDATE_FORM_SENT_FAIL";
export const GET_CANDIDATE_FORM_SENT_START = "GET_CANDIDATE_FORM_SENT_START";
export const GET_CANDIDATE_FORM_SENT_2 = "GET_CANDIDATE_FORM_SENT_2";
export const GET_CANDIDATE_FORM_SENT_FAIL_2 = "GET_CANDIDATE_FORM_SENT_FAIL_2";
export const GET_CANDIDATE_FORM_SENT_START_2 =
  "GET_CANDIDATE_FORM_SENT_START_2";
export const GET_CANDIDATE_FORM_SENT_3 = "GET_CANDIDATE_FORM_SENT_3";
export const GET_CANDIDATE_FORM_SENT_FAIL_3 = "GET_CANDIDATE_FORM_SENT_FAIL_3";
export const GET_CANDIDATE_FORM_SENT_START_3 =
  "GET_CANDIDATE_FORM_SENT_START_3";
export const GET_CANDIDATE_FORM_SENT_4 = "GET_CANDIDATE_FORM_SENT_4";
export const GET_CANDIDATE_FORM_SENT_FAIL_4 = "GET_CANDIDATE_FORM_SENT_FAIL_4";
export const GET_CANDIDATE_FORM_SENT_START_4 =
  "GET_CANDIDATE_FORM_SENT_START_4";
export const GET_CANDIDATE_FORM_SENT_5 = "GET_CANDIDATE_FORM_SENT_5";
export const GET_CANDIDATE_FORM_SENT_FAIL_5 = "GET_CANDIDATE_FORM_SENT_FAIL_5";
export const GET_CANDIDATE_FORM_SENT_START_5 =
  "GET_CANDIDATE_FORM_SENT_START_5";

export const GET_CANDIDATE_FORM_SENT_REMINDER =
  "GET_CANDIDATE_FORM_SENT_REMINDER";
export const GET_CANDIDATE_FORM_SENT_REMINDER_FAIL =
  "GET_CANDIDATE_FORM_SENT_REMINDER_FAIL";
export const GET_CANDIDATE_FORM_SENT_REMINDER_START =
  "GET_CANDIDATE_FORM_SENT_REMINDER_START";
export const GET_CANDIDATE_FORM_SENT_REMINDER_2 =
  "GET_CANDIDATE_FORM_SENT_REMINDER_2";
export const GET_CANDIDATE_FORM_SENT_REMINDER_FAIL_2 =
  "GET_CANDIDATE_FORM_SENT_REMINDER_FAIL_2";
export const GET_CANDIDATE_FORM_SENT_REMINDER_START_2 =
  "GET_CANDIDATE_FORM_SENT_REMINDER_START_2";
export const GET_CANDIDATE_FORM_SENT_REMINDER_3 =
  "GET_CANDIDATE_FORM_SENT_REMINDER_3";
export const GET_CANDIDATE_FORM_SENT_REMINDER_FAIL_3 =
  "GET_CANDIDATE_FORM_SENT_REMINDER_FAIL_3";
export const GET_CANDIDATE_FORM_SENT_REMINDER_START_3 =
  "GET_CANDIDATE_FORM_SENT_REMINDER_START_3";
export const GET_CANDIDATE_FORM_SENT_REMINDER_4 =
  "GET_CANDIDATE_FORM_SENT_REMINDER_4";
export const GET_CANDIDATE_FORM_SENT_REMINDER_FAIL_4 =
  "GET_CANDIDATE_FORM_SENT_REMINDER_FAIL_4";
export const GET_CANDIDATE_FORM_SENT_REMINDER_START_4 =
  "GET_CANDIDATE_FORM_SENT_REMINDER_START_4";
export const GET_CANDIDATE_FORM_SENT_REMINDER_5 =
  "GET_CANDIDATE_FORM_SENT_REMINDER_5";
export const GET_CANDIDATE_FORM_SENT_REMINDER_FAIL_5 =
  "GET_CANDIDATE_FORM_SENT_REMINDER_FAIL_5";
export const GET_CANDIDATE_FORM_SENT_REMINDER_START_5 =
  "GET_CANDIDATE_FORM_SENT_REMINDER_START_5";

export const GET_CANDIDATE_FORM_VIEW = "GET_CANDIDATE_FORM_VIEW";

export const GET_CANDIDATE_SELECT = "GET_CANDIDATE_SELECT";
export const GET_CANDIDATE_SELECT_FAIL = "GET_CANDIDATE_SELECT_FAIL";
export const GET_CANDIDATE_SELECT_START = "GET_CANDIDATE_SELECT_START";

export const GET_ALL_INDUSTRY_PRICING = "GET_ALL_INDUSTRY_PRICING";

export const GET_PROFILE = "GET_PROFILE";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const CHANGE_PASSWORD_PORFILE = "CHANGE_PASSWORD_PORFILE";
export const PROFILE_IMAGE_UPLOAD = "PROFILE_IMAGE_UPLOAD";
export const PROFILE_IMAGE_UPLOAD_DELETE = "PROFILE_IMAGE_UPLOAD_DELETE";

// home-page job

export const GET_ALL_JOBS_HOMEPAGE = "GET_ALL_JOBS_HOMEPAGE";
export const GET_ALL_JOBS_HOMEPAGE_START = "GET_ALL_JOBS_HOMEPAGE_START";
export const GET_ALL_JOBS_HOMEPAGE_FAIL = "GET_ALL_JOBS_HOMEPAGE_FAIL";

export const SINGLE_JOB = "SINGLE_JOB";
export const SINGLE_JOB_START = "SINGLE_JOB_START";
export const SINGLE_JOB_FAIL = "SINGLE_JOB_FAIL";

// home-page blog

export const GET_ALL_BLOGS_HOMEPAGE = "GET_ALL_BLOGS_HOMEPAGE";
export const GET_ALL_BLOGS_HOMEPAGE_START = "GET_ALL_BLOGS_HOMEPAGE_START";
export const GET_ALL_BLOGS_HOMEPAGE_FAIL = "GET_ALL_BLOGS_HOMEPAGE_FAIL";

export const SINGLE_BLOG = "SINGLE_BLOG";
export const SINGLE_BLOG_START = "SINGLE_BLOG_START";
export const SINGLE_BLOG_FAIL = "SINGLE_BLOG_FAIL";

// export const GET_PROFILE = "GET_PROFILE";
// export const UPDATE_PROFILE = "UPDATE_PROFILE";
// export const CHANGE_PASSWORD_PORFILE = "CHANGE_PASSWORD_PORFILE";

// subscription

export const GET_SUBSCRIPTON = "GET_SUBSCRIPTON";
export const GET_SUBSCRIPTON_START = "GET_SUBSCRIPTON_START";
export const GET_SUBSCRIPTON_FAIL = "GET_SUBSCRIPTON_FAIL";

export const DELETE_SUBSCRIPTON = "DELETE_SUBSCRIPTON";
export const DELETE_SUBSCRIPTON_START = "DELETE_SUBSCRIPTON_START";
export const DELETE_SUBSCRIPTON_FAIL = "DELETE_SUBSCRIPTON_FAIL";

export const UPDATE_SUBSCRIPTON = "UPDATE_SUBSCRIPTON";
export const UPDATE_SUBSCRIPTON_START = "UPDATE_SUBSCRIPTON_START";
export const UPDATE_SUBSCRIPTON_FAIL = "UPDATE_SUBSCRIPTON_FAIL";

export const TRAIL_PERIOD = "TRAIL_PERIOD";
export const TRAIL_PERIOD_START = "TRAIL_PERIOD_START";
export const TRAIL_PERIOD_FAIL = "TRAIL_PERIOD_FAIL";

export const RESET_AUTH = "RESET_AUTH";

export const GOOGLE_LOGIN = "GOOGLE_LOGIN";
export const GOOGLE_LOGIN_START = "GOOGLE_LOGIN_START";
export const GOOGLE_LOGIN_FAIL = "GOOGLE_LOGIN_FAIL";

export const APPLY_JOB_HOME = "APPLY_JOB_HOME";
export const APPLY_JOB_HOME_START = "APPLY_JOB_HOME_START";
export const APPLY_JOB_HOME_FAIL = "APPLY_JOB_HOME_FAIL";

export const Verify_Email_LOGIN = "Verify_Email_LOGIN";

export const DELETE_COMPANY_IMAGE = "DELETE_COMPANY_IMAGE";

export const FORM_COMPONENT = "FORM_COMPONENT";
export const FORM_COMPONENT_ROW = "FORM_COMPONENT_ROW";
export const SIDEBAR_ITEM = "SIDEBAR_ITEM";
export const FORM_STRUCTURE = "FORM_STRUCTURE";
export const FORM_STRUCTURE_DRAGGED = "FORM_STRUCTURE_DRAGGED";
export const FORM_STRUCTURE_UPDATE = "FORM_STRUCTURE_UPDATE";
export const FORM_BUILDER_IMAGE = "FORM_BUILDER_IMAGE";
export const COL_FORM_STRUCTURE = "COL_FORM_STRUCTURE";
export const COL_FORM_STRUCTURE_UPDATE = "COL_FORM_STRUCTURE_UPDATE";
export const STORE_SUBSCRIPTION = "store subscription";

export const SIGNER_COLORS = [
  "#f7f7f7",
  "#f7f7f7",
  "#f7f7f7",
  "#f7f7f7",
  "#f7f7f7",
  "#f7f7f7",
  "#f7f7f7",
  "#f7f7f7",
  "#f7f7f7",
];

export const DOCUMENT_SIGNING = "Document Signing";
export const FontSize = [
  {
    id: 1,
    size: 12,
  },
  {
    id: 2,
    size: 14,
  },
  {
    id: 3,
    size: 16,
  },
  {
    id: 4,
    size: 18,
  },
  {
    id: 5,
    size: 20,
  },
  {
    id: 6,
    size: 22,
  },
];

export const FontList = [
  {
    id: 1,
    name: "Arial",
    fontFamily: "Arial",
  },
  {
    id: 2,
    name: "Calibri",
    fontFamily: "calibri",
  },
  {
    id: 3,
    name: "Roboto",
    fontFamily: "roboto",
  },
  {
    id: 4,
    name: "Verdana",
    fontFamily: "verdana",
  },
];

export const FieldTypes = {
  TEXT_INPUT: "Text Inputs",
  DATES: "Dates",
  TAGS: "Tags",
  INITIALS: "Initials",
  SIGNATURE: "Signatures",
  CHECKBOX: "Check Box",
};

export const ALERT_MESSAGE_TEXT = "ALERT_MESSAGE_TEXT";
