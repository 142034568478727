import html2canvas from "html2canvas";
import React, { useCallback, useEffect, useRef, useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import { Tab, Tabs } from "@mui/material";

import "../../assets/sinner_css/ReceiverSignature.css";
import CLear from "../../assets/sinner/clear.png";
import PopupModal from "./PopupModal.jsx";
import { TabPanel, a11yProps } from "../common/mui-tabs/index.js";

// const ReactSketchCanvasStyle = {
//   border: '0.0625rem solid #9c9c9c',
//   borderRadius: '0.25rem',
// };

const fontFamily = [
  "'Montserrat', sans-serif",
  "'My Soul', cursive",
  "'Roboto Condensed', sans-serif",
  "'Square Peg', cursive",
];
/***
 * component for displaying popup for initial fields
 */
function InitialsPopupModal({
  openSignatureBox,
  setOpenSignatureBox,
  field,
  onSignatureDone,
}) {
  const drwRef = useRef();
  const [activeSignWindowVariable, setActiveSignWindowVariable] = useState({
    inputField: field,
    signingType: "signature",
  });
  const [penColor, setPenColor] = useState("black");
  const [value, setValue] = useState(0);

  const [base64String, setBase64String] = useState("");

  const [signText, setSignText] = useState("");

  const [font, setFont] = useState(fontFamily[0]);
  const [signatureError, setSignatureError] = useState(false);
  const [writeError, setWriteError] = useState(false);

  const checkInsertDisable = useCallback(() => {
    if (
      activeSignWindowVariable.signingType === "signature" &&
      (drwRef?.current?.isEmpty() === undefined ||
        drwRef?.current?.isEmpty() === true)
    ) {
      setWriteError(false);
      return;
    }

    if (activeSignWindowVariable.signingType === "write" && signText === "") {
      setSignatureError(false);
      return;
    }
    if (
      activeSignWindowVariable.signingType === "upload" &&
      base64String === ""
    ) {
      setSignatureError(false);
    }
  }, [activeSignWindowVariable.signingType, base64String, signText]);

  const signInTabClicked = useCallback(
    (tab) => {
      checkInsertDisable();
      setActiveSignWindowVariable({
        inputField: activeSignWindowVariable.inputField,
        signingType: tab,
      });
    },
    [activeSignWindowVariable.inputField, checkInsertDisable]
  );
  const penColorChanged = (color) => {
    setPenColor(color);
  };

  const handleDrawCanvasClear = () => {
    drwRef.current.clear();
  };

  useEffect(() => {
    setActiveSignWindowVariable({
      inputField: field,
      signingType: "signature",
    });
    setBase64String("");
    setSignText("");
  }, [field, openSignatureBox]);

  useEffect(() => {
    checkInsertDisable();
  }, [
    drwRef,
    openSignatureBox,
    activeSignWindowVariable,
    signText,
    checkInsertDisable,
  ]);

  const handleInsert = async () => {
    try {
      setSignatureError(false);
      setWriteError(false);

      if (activeSignWindowVariable.signingType === "write" && signText === "") {
        setSignatureError(false);
        setWriteError(true);
        return;
      }

      if (
        activeSignWindowVariable.signingType === "signature" &&
        (drwRef?.current?.isEmpty() === undefined ||
          drwRef?.current?.isEmpty() === true)
      ) {
        setSignatureError(true);
        setWriteError(false);
        return;
      }

      switch (activeSignWindowVariable.signingType) {
        case "signature":
          const img = await drwRef.current.toDataURL();
          onSignatureDone(field, img);
          break;
        case "write":
          const element = document.getElementById("signTextInput");
          const canvas = await html2canvas(element, {
            backgroundColor: null,
          });
          console.log("write");
          const data = canvas.toDataURL("image/jpg");
          onSignatureDone(field, data);
          break;

        case "upload":
          if (base64String !== "") {
            onSignatureDone(field, base64String);
          }
          break;

        default:
          break;
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!openSignatureBox) {
      signInTabClicked("signature");
      setValue(0);
    }
  }, [openSignatureBox, signInTabClicked]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    console.log(newValue);
    switch (newValue) {
      case 0:
        signInTabClicked("signature");
        break;
      case 1:
        signInTabClicked("write");
        break;

      default:
        signInTabClicked("signature");
        break;
    }
  };
  console.log(openSignatureBox);
  return (
    <PopupModal open={openSignatureBox} showCloseIcon={false}>
      <div className='popupBoxOutter'>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label='basic tabs example'>
          <Tab label='Signature' {...a11yProps(0)} />
          {/* <Tab label='Write' {...a11yProps(1)} /> */}
        </Tabs>
        {/* <ul className='nav nav-tabs' id='myTab' role='tablist'>
          <li className='nav-item '>
            <a
              aria-controls='home'
              aria-selected='true'
              className='nav-link active px-2 py-2'
              data-toggle='tab'
              href='#home'
              id='home-tab'
              onClick={() => signInTabClicked("signature")}
              role='tab'>
              Signature
            </a>
          </li>
          <li className='nav-item'>
            <a
              aria-controls='profile'
              aria-selected='false'
              className='nav-link px-2 py-2'
              data-toggle='tab'
              href='#profile'
              id='profile-tab'
              onClick={() => signInTabClicked("write")}
              role='tab'>
              Write
            </a>
          </li>
        </ul> */}
        <div className='tab-content' id='myTabContent'>
          <TabPanel value={value} index={0}>
            <div style={{ minHeight: 300 }}>
              <div className='menuSection'>
                <div className='colorSection'>
                  <div className='popuprowleft'>
                    <div className='name'>Choose Colour</div>
                    <div className='colors'>
                      <div
                        className='black pikcolor'
                        onClick={() => penColorChanged("black")}></div>
                      <div
                        className='blue pikcolor'
                        onClick={() => penColorChanged("blue")}></div>
                      <div
                        className='green pikcolor'
                        onClick={() => penColorChanged("green")}></div>
                      <div
                        className='red pikcolor'
                        onClick={() => penColorChanged("red")}></div>
                    </div>
                  </div>
                  <div className='popuprowright'>
                    <div className='clearCanvas'>
                      <ul>
                        <li title='Clear'>
                          <button
                            className='btn btn-white btn-sm'
                            onClick={handleDrawCanvasClear}
                            title='clear'>
                            <img
                              alt='logo'
                              className='App-logo'
                              src={CLear}
                              title='clear'
                            />
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className='drawSection'
                style={{
                  border: "1px dotted black",
                  width: "max-content",
                  margin: "auto",
                }}>
                <SignatureCanvas
                  canvasProps={{
                    width: field.width,
                    height: field.height,

                    className: "sigCanvas",
                  }}
                  penColor={penColor}
                  ref={drwRef}
                />
              </div>
              {signatureError ? (
                <div className='mt-2 text-center' style={{ color: "red" }}>
                  Please enter signature
                </div>
              ) : null}
            </div>
          </TabPanel>
          {/* <TabPanel value={value} index={1}>
            <div style={{ minHeight: 300 }}>
              <div className='menuSection'>
                <div className='colorSection'>
                  <div className='popuprowleft'>
                    <div className='name'>Choose Colour</div>
                    <div className='colors'>
                      <div
                        className='black pikcolor'
                        onClick={() => penColorChanged("black")}></div>
                      <div
                        className='blue pikcolor'
                        onClick={() => penColorChanged("blue")}></div>
                      <div
                        className='green pikcolor'
                        onClick={() => penColorChanged("green")}></div>
                      <div
                        className='red pikcolor'
                        onClick={() => penColorChanged("red")}></div>
                    </div>
                  </div>
                  <div className='popuprowright'></div>

                  <div className='fontStyleSection' style={{ marginLeft: 10 }}>
                    <select
                      id='cars'
                      name='signFontStyle'
                      onChange={(e) => setFont(fontFamily[e.target.value])}>
                      <option value='0'>Montserrat</option>
                      <option value='1'>My Soul</option>
                      <option value='2'>Roboto</option>
                      <option value='3'>Square</option>
                    </select>
                  </div>
                </div>

                <hr />
                <div className='displaying d-flex justify-content-center'>
                  <p
                    id='signTextInput'
                    style={{
                      fontSize: field.fontSize,
                      fontFamily: font,
                      height: field.height,
                      width: field.width,
                      textAlign: "center",
                      color: penColor,
                      overflow: "hidden",
                      backgroundColor: "transparent",
                    }}>
                    {signText}
                  </p>
                </div>
                <div className='inputSignContainer'>
                  <input
                    name='input'
                    onChange={(e) => {
                      setSignText(e.target.value);
                    }}
                    placeholder='Enter something'
                    style={{ width: "100%" }}
                    type='text'
                  />
                </div>
                {writeError ? (
                  <div className='mt-2' style={{ color: "red" }}>
                    Please enter signature
                  </div>
                ) : null}
              </div>
            </div>
          </TabPanel> */}

          <div className='modal-footer'>
            <button
              className='btn btn-secondary'
              data-mdb-dismiss='modal'
              onClick={() => {
                setOpenSignatureBox(false);
              }}
              type='button'>
              Close
            </button>
            <button
              className='btn btn-secondary bg-primary'
              onClick={handleInsert}
              type='button'>
              Insert
            </button>
          </div>
        </div>
      </div>
    </PopupModal>
  );
}

export default InitialsPopupModal;
