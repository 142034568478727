import React, { useEffect, useRef, useState } from "react";
import { Box } from "@mui/system";
import Actions from "./Actions";
import style from "./style.module.scss";
import { useSelector } from "react-redux";
import shortid from "shortid";
import $ from "jquery";
import useDragField from "../../../../customHook/useDragField";

function RadioField({
  id,
  main_id,
  type,
  label,
  name,
  SelectField,
  two_col,
  col_name,
  index,
  moveCard,
}) {
  const ref = useRef(null);
  const dragField = useDragField(ref, index, moveCard, id);
  const opacity = dragField.opacity;
  const getpreviewform = useSelector((state) => state?.getpreviewform);
  return (
    <Box
      ref={ref}
      style={{ ...style, opacity }}
      data-handler-id={dragField?.handlerId}
      className={`${style.inputBox} dropItem`}
    >
      <Actions
        type={type}
        id={id}
        two_col={two_col}
        col_name={col_name}
        main_id={main_id}
      />
      <Box className={style.checkboxgroup}>
        <label htmlFor="">{label}</label>
        {two_col === 1
          ? getpreviewform.map((item) => {
              return item?.type[col_name]?.data?.map((subcol) => {
                if (subcol.id === id) {
                  return subcol.type.options.map((subitem) => {
                    return (
                      <Box className={style.customCheckBox}>
                        <label className="labelContainer">
                          {subitem.label}
                          {subitem.label === SelectField ? (
                            <input
                              type="radio"
                              checked
                              value={subitem.label}
                              name={name}
                            />
                          ) : (
                            <input
                              type="radio"
                              value={subitem.label}
                              name={name}
                            />
                          )}
                          <span className="checkmark"></span>
                        </label>
                      </Box>
                    );
                  });
                }
              });
            })
          : getpreviewform.map((item) => {
              if (item.id === id) {
                {
                  return item.type.options.map((subitem) => {
                    return (
                      <Box className={style.customCheckBox}>
                        <label className="labelContainer">
                          {subitem.label}
                          {subitem.label === SelectField ? (
                            <input
                              type="radio"
                              checked
                              value={subitem.label}
                              name={name}
                            />
                          ) : (
                            <input
                              type="radio"
                              value={subitem.label}
                              name={name}
                            />
                          )}
                          <span className="checkmark"></span>
                        </label>
                      </Box>
                    );
                  });
                }
              }
            })}
      </Box>
    </Box>
  );
}

export default RadioField;
