import { Grid, Typography } from "@mui/material";
import { Box, Container } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import Content_footer from "../../content-footer/Content_footer";
import "./style.css";
import Navbar from "../../navbar/Navbar";
import { useDispatch, useSelector } from "react-redux";
import { getSingleBlog } from "../../../../store/actions/homeBlogActions";
import data from "./Postdata.js";
import { ShowAlert } from "../../../../store/actions/alertActions";

const BlogPost = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const id = location?.state?.id;
  const parse = require("html-react-parser");
  const navigate = useNavigate();
  const [redirect, setRedirect] = useState(0);
  const detailBlogs = useSelector((state) => state?.hblog?.singleBlog?.results);
  const error = useSelector((state) => state?.hblog?.singleBlog?.code);

  useEffect(() => {
    dispatch(getSingleBlog(id));
  }, []);

  useEffect(() => {
    if (error === 202) {
      dispatch(ShowAlert("This Job is Unable to access", "error"));
      navigate(`/resources`, {});
    }
  }, [error]);

  // console.log("detailBlogs", detailBlogs);

  // console.log("!!!!!!!!!!!!!!!", id);
  // console.log("detailBlogs?.id", detailBlogs?.uuid);
  return (
    <>
      {/* <Navbar />
      <Container>
      {data?.map((item,index)=>(
        item?.id == id && 
        <>
       {item?.id}
        {item?.title}
        {item?.blogtitledata1}
    
        </>
      ))}
        </Container> */}
      <Navbar />
      <Container>
        {detailBlogs?.uuid == id && (
          <>
            <Box marginTop={12} className="blog_post_main_box">
              <Grid container className="blog_post_main_sub_box">
                <Grid item md={6}>
                  <Box className="blog_post_1st_box">
                    <Box>
                      <Typography variant="h1" className="blog_post_heading_h4">
                        {detailBlogs?.title}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        variant="body1"
                        className="blog_post_heading_para"
                      >
                        {detailBlogs?.short_description}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item md={6}>
                  <Box>
                    <img
                      className="blog_post_main_img"
                      src={`${process.env.REACT_APP_URL}/storage/${detailBlogs?.image}`}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Grid container className="blog_post_main_box">
              <Box className="blog_post_main_sub_box">
                <Grid item sx={12}>
                  <Box>
                    <div className="description">
                      {parse(detailBlogs?.description)}
                    </div>
                  </Box>
                  {/* <Box>
                    <Typography
                      variant="body1"
                      className="blog_post_heading_para"
                    >
                      {detailBlogs?.description}
                    </Typography>
                  </Box>
                  <Box marginTop={1.5}>
                    <Typography variant="h4" className="blog_post_heading_h4">
                      {detailBlogs?.description}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      variant="body1"
                      className="blog_post_heading_para"
                    >
                      {detailBlogs?.description}
                    </Typography>
                  </Box> */}
                </Grid>
              </Box>
            </Grid>
          </>
        )}
      </Container>

      <Content_footer />
    </>
  );
};

export default BlogPost;
