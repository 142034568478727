import React, { useState, useLayoutEffect } from "react";
import CryptoJS from "crypto-js";
import * as flatted from "flatted";
import Modal from "react-responsive-modal";

import PopupModal from "../../document_sinner/PopupModal.jsx";
import { getFile } from "../../../utils/s3Config.js";
import { useDispatch, useSelector } from "react-redux";
import { ShowAlert } from "../../../store/actions/alertActions.js";
import { DeleteEDocument } from "../../../api/sinner/SinnerApi.js";
import { Box, CircularProgress } from "@mui/material";
import {
  LOADING_FALSE,
  LOADING_TRUE,
} from "../../../store/constants/constants.js";

function DeleteEDoc({ setDeletePopup, deletePopup, row, setUpdate }) {
  const [decPassword, setDecPassword] = useState("");
  const [passError, setPassError] = useState(null);
  const [successOpenModel, setSuccessOpenModel] = useState(false);
  const singleCandidate = useSelector((state) => state?.singleCandidate);
  const userData = JSON.parse(localStorage.getItem("auth"))?.results || {};

  const dispatch = useDispatch();

  useLayoutEffect(() => {
    if (!deletePopup) {
      setPassError(null);
      setDecPassword("");
    }
  }, [deletePopup]);

  const onPassSubmit = async (e) => {
    e.preventDefault();
    if (!decPassword) {
      setPassError("Password is required");
      return;
    }

    try {
      setSuccessOpenModel(true);
      dispatch({
        type: LOADING_TRUE,
      });
      let result = await getFile(row.metadataUrl);

      if (!result?.sessionId) {
        setPassError("Password is Incorrect");
        return;
      }

      try {
        const bytes = CryptoJS.AES.decrypt(result.blob, decPassword);
        const originalText = bytes.toString(CryptoJS.enc.Utf8);
        const jsonMeta = flatted.parse(originalText);
        const filename = jsonMeta.originalPdf.split("/").pop();
        if (!filename) {
          setPassError("Password is Incorrect");
          return;
        }

        const query = `recruiter_id=${
          userData?.recruiter_id || ""
        }&candidate_id=${userData?.candidate_id || ""}&uuid=${row?.uuid}`;
        let deleteDoc = await DeleteEDocument(query);
        if (deleteDoc?.data?.code === 200) {
          dispatch(ShowAlert(deleteDoc?.data?.message, "success"));
          setUpdate((preVal) => !preVal);
        } else {
          dispatch(ShowAlert(deleteDoc?.data?.message, "error"));
        }
        setSuccessOpenModel(false);

        setDeletePopup(false);
        setDecPassword(null);
      } catch (error) {
        setSuccessOpenModel(false);
        dispatch({
          type: LOADING_FALSE,
        });
        setPassError("Password is Incorrect");
      }
    } catch (error) {
      setSuccessOpenModel(false);
      console.error(error);
      dispatch(ShowAlert("Something went wrong..!!", "error"));
    } finally {
      setTimeout(() => {
        setSuccessOpenModel(false);
        dispatch({
          type: LOADING_FALSE,
        });
      }, 1000);
    }
  };
  return (
    <>
      <PopupModal
        closeOnOverlayClick={false}
        onClose={() => setDeletePopup(false)}
        open={deletePopup}
      >
        <form onSubmit={onPassSubmit}>
          <div className="form-group">
            <label htmlFor="forDoctitle">
              Enter Document Password to Delete
            </label>
            <input
              aria-describedby="forDoctitle"
              autoComplete={"off"}
              className="form-control text-dark"
              id="forDoctitle"
              name="title"
              onChange={(e) => setDecPassword(e.target.value)}
              placeholder="Enter password to delete"
              type="password"
            />
            <small className="form-text text-danger" id="emailHelp">
              {passError && passError}
            </small>
          </div>
          <button
            className="btn"
            style={{
              background: "rgb(248, 230, 65)",
              border: "1px solid black",
              borderRadius: 0,
            }}
            type="submit"
            variant="primary"
          >
            Delete
          </button>
        </form>
      </PopupModal>
      <Modal
        center
        closeOnEsc={false}
        closeOnOverlayClick={false}
        onClose={() => {}}
        open={successOpenModel}
        showCloseIcon={false}
      >
        <div
          className="optionContainer iconPopups"
          style={{ minWidth: 200, paddingTop: 30 }}
        >
          <div className="margin_box popup text-center">
            {/* <img
              src={LoaderGif}
              style={{ margin: "auto", width: 50, height: 50 }}
            /> */}
            {singleCandidate?.loading && (
              <Box className="loader">
                <CircularProgress />
              </Box>
            )}
            {"Processing..."}
          </div>
          <div className="button_box"></div>
        </div>
      </Modal>
    </>
  );
}

export default DeleteEDoc;
