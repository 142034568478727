import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { ReactComponent as Cross } from "../../../../../assets/industries/model/x.svg";
import { ReactComponent as UploadIcon } from "../../../../../assets/candidates/uploadfile.svg";
import Modal from "@mui/material/Modal";
import { useParams } from "react-router-dom";
import styles from "./stylesUpload.module.scss";
import { Button } from "@mui/material";
import {
  uploadFiles,
  uploadResume,
} from "../../../../../api/uploadFile/uploadFile.class";
import { useDispatch, useSelector } from "react-redux";
import { ShowAlert } from "../../../../../store/actions/alertActions";
import Alerts from "../../../../common/Alert";
import { useNavigate } from "react-router-dom";
import TextInput from "../../../../common/TextInput";
import { rejectBubbleForm } from "../../../../../api/formBuilder/formBuilder.class";
export default function CustomModal({
  openModal,
  setOpenModal,
  singleformdata,
  denyToken,
}) {
  const handleClose = () => setOpenModal(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);
  const candidateId = useSelector((state) => state?.auth?.results?.uuid);

  const [file, setFile] = useState({ resume_path: "" });
  const [descvalue, setdescvalue] = useState();

  const handleSubmit = async () => {
    try {
      const payload = {
        candidate_id: candidateId,
        reason: descvalue,
        denyToken,
      };
      const response = await rejectBubbleForm(payload);
      if (response?.data?.success == true) {
        dispatch(ShowAlert(response?.data?.message, "success"));
        setOpenModal(false);
      } else {
        dispatch(ShowAlert(response?.data?.message, "success"));
        setOpenModal(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Alerts />
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={`${styles.industryModel}`}
      >
        <Box className={`${styles.boxModel} xym1`}>
          <Box className={styles.modalwapper}>
            <Box className={styles.cross} onClick={handleClose}>
              <Cross />
            </Box>
            <Typography variant="h4" className={styles.heading}>
              Job offer review
            </Typography>
            <Box className={styles.statusbox}>
              <Box className={styles.offermodelDetail}>
                <p>
                  Status: <span> {singleformdata?.response}</span>
                </p>
              </Box>
              <Box className={styles.offermodelDetail}>
                <p>
                  Letter of offer:{" "}
                  <a
                    target="_blank"
                    href={`${process.env.REACT_APP_URL}${singleformdata?.pdf_url}`}
                  >
                    Job Offer Letter of{" "}
                    {singleformdata?.form_data?.candidate_name}
                  </a>
                </p>
              </Box>
              <Box className={styles.offermodelDetail}>
                <p>
                  Last updated: <span>{singleformdata?.updated_date}</span>
                </p>

                <TextInput
                  customClass={styles.boxForm}
                  name="description"
                  label="Reason for job offer rejection"
                  value={descvalue}
                  textarea={true}
                  onChange={(e) => {
                    setdescvalue(e.target.value);
                  }}
                />
              </Box>

              <Box className={`${styles.button} button-primary d-flex`}>
                <div className="d-flex">
                  <Button
                    onClick={() => {
                      handleSubmit();
                    }}
                  >
                    Save
                  </Button>
                </div>
                <div>
                  <Button className="secondary-btn" onClick={handleClose}>
                    Close
                  </Button>
                </div>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
