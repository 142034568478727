import { Box, Button, CircularProgress, Grid, Typography } from "@mui/material";

import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Cards from "react-credit-cards";
import ReactInputMask from "react-input-mask";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { routes } from "../../../routes";
import {
  checkoutPayment,
  resetPricing,
  subscriptionPayment,
  updateSubcrib,
} from "../../../store/actions/pricingActions";
import {
  createSingleCertificateFunc,
  updateSingleCertificateFunc,
  verifySingleCandidateCertificate,
} from "../../../store/actions/singleCandidateScreenActions";
import TextInput from "../../common/TextInput";
import styles from "./styles.module.scss";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./CheckoutForm";

const Checkout = ({
  setCart,
  setOpen,
  setLicenseValue,
  setLicense,
  setRadio,
  setBilling,
  setUpdateValues,
  data,
  updatedData,
}) => {
  const dispatch = useDispatch();
  const payment = useSelector((state) => state?.pricing);


  const navigate = useNavigate();

  useEffect(() => {
    if (payment?.paymentDone?.success) {
      setOpen(false);
      navigate(routes?.DASHBOARD);
      dispatch(resetPricing());
      setCart({});
      setLicenseValue({});
      setLicense([]);
      setBilling({ address: "", city: "", state: "", country: "" });
      setRadio({});
    }
  }, [payment?.paymentDone]);

  useEffect(() => {
    if (payment?.updateSubscription?.success) {
      setOpen(false);
      navigate(routes?.DASHBOARD);
      dispatch(resetPricing());
      setUpdateValues({
        label: "",
        value: "",
        interval_count: "",
        product: "",
        price: "00",
      });
    }
  }, [payment?.updateSubscription]);

  return (
    <Box className={styles.gridParrent}>
      <Typography component="h2">Check Summary</Typography>
      <table style={{ width: "100%", marginBottom: "20px" }}>
        <tr>
          <th>Check Name</th>
          <td>{data?.industry_certification_license?.name}</td>
        </tr>
        <tr>
          <th>Check Price</th>
          <td>${data?.industry_certification_license?.price}</td>
        </tr>
      </table>
      <Typography component="h2">Card Details</Typography>
      <Grid container spacing={2} sx={{ alignItems: "center" }}>
        <Grid item md={12} lg={12}>
          <Elements stripe={loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)}>
                <CheckoutForm 
                data={data}
                setOpen={setOpen}
                  // updateValues={updateValues}
                  // payment={payment}
                  // billing={billing}
                  // radio={radio}
                  // cart={cart}
                  // licenseValue={licenseValue}
                  // sub={sub}
                />
          </Elements>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Checkout;
