import { UPLOAD_FILE } from "../constants/constants";

const initialState = [];

export const uploadFileBuilderReducers = (
  state = initialState,
  { type, name, payload }
) => {
  switch (type) {
    case UPLOAD_FILE:
      for (var i = 0; i < state?.length; i++) {
        if (state[i]?.name === name) {
          state[i].name = name;
          state[i].value = payload;
        } else {
          state[i + 1] = { name: name, value: payload };
        }
      }
      if (state?.length === 0) {
        state[0] = { name: name, value: payload };
      }
      return [...state];

    default:
      return state;
  }
};
