import { GET_CHECKBOX_OPTION_LIST } from "../constants/constants";
import { DELETE_CHECKBOX_OPTION } from "../constants/constants";

import { Box } from "@mui/system";
import OptionTableRow from "../../components/forms/formbuilder/OptionTable/OptionTableRow";
import style from "../../components/forms/formbuilder/FormComponents/style.module.scss";

const initialState = [];

export const getcheckboxlistReducers = (
  state = initialState,
  { type, payload, index }
) => {
  switch (type) {
    case GET_CHECKBOX_OPTION_LIST:
      return [...state, ...payload];
    case DELETE_CHECKBOX_OPTION:
      const next = [...payload];
      delete next[index];
      return next;
    default:
      return state;
  }
};
