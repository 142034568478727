import { Box, Grid } from "@mui/material";
import React from "react";
import TextInput from "../../../../common/TextInput";
import styles from "./styles.module.scss";
import { Field } from "formik";
import CountrySelect from "../../../../common/CountrySelect/CountrySelect";

export default function VevoCheckCommonForm({
  handleChange,
  values,
  errors,
  touched,
}) {
  const handleKeyDown = (event) => {
    event.preventDefault();
  };
  return (
    <>
      <Grid item md={6} xs={12}>
        <TextInput
          style={{ width: "100%" }}
          compolsory={true}
          customClass={styles.input}
          label="Given Name"
          type="text"
          name="first_name"
          InputLabelProps={{
            shrink: true,
          }}
          placeholder="Given Name"
          onChange={handleChange}
          value={values?.first_name}
        />
        <span>
          {errors.first_name && touched.first_name && errors.first_name}
        </span>
      </Grid>
      <Grid item md={6} xs={12}>
        <TextInput
          style={{ width: "100%" }}
          customClass={styles.input}
          label="Middle Name"
          type="text"
          name="middle_name"
          InputLabelProps={{
            shrink: true,
          }}
          placeholder="Middle Name"
          onChange={handleChange}
          value={values?.middle_name}
        />
        <span>
          {errors.middle_name && touched.middle_name && errors.middle_name}
        </span>
      </Grid>
      <Grid item md={6} xs={12}>
        <TextInput
          style={{ width: "100%" }}
          compolsory={true}
          customClass={styles.input}
          label="Family Name"
          type="text"
          name="last_name"
          InputLabelProps={{
            shrink: true,
          }}
          placeholder="Family Name"
          onChange={handleChange}
          value={values?.last_name}
        />
        <span>{errors.last_name && touched.last_name && errors.last_name}</span>
      </Grid>
      <Grid item xs={6}>
        <TextInput
          compolsory={true}
          style={{ width: "100%" }}
          customClass={styles.input}
          label="Email"
          type="email"
          name="mail_id"
          placeholder={"Email"}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={handleChange}
          value={values.mail_id}
        />
        <span>{errors.mail_id && touched.mail_id && errors.mail_id}</span>
      </Grid>
      <Grid item xs={6}>
        <TextInput
          compolsory={true}
          label="Date of birth"
          type="date"
          name="dob"
          placeholder={"Date of birth"}
          max={new Date().toISOString().split("T")[0]}
          onChange={handleChange}
          value={values.dob}
          onKeyDown={handleKeyDown}
        />
        <span>{errors.dob && touched.dob && errors.dob}</span>
      </Grid>
      <Grid item xs={6}>
        <Box className={styles.parentInput}>
          <Field
            compolsory={true}
            customClass={styles.input}
            label="Country of document "
            name="country"
            component={CountrySelect}
            placeholder="Country of document"
            style={{ marginTop: "19px", minHeight: "41px" }}
          />
          <span
            style={{
              color: "red",
              fontSize: "small",
            }}
          >
            {errors.country && touched.country && errors.country}
          </span>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box className={styles.parentInput}>
          <TextInput
            compolsory={true}
            style={{ width: "100%" }}
            customClass={styles.input}
            label="Passport Document Number"
            placeholder={"Passport Document Number"}
            type="text"
            name="doc_id"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={handleChange}
            value={values.doc_id}
          />
          <span>{errors.doc_id && touched.doc_id && errors.doc_id}</span>
        </Box>
      </Grid>
      <Grid item xs={6}>
        <TextInput
          compolsory={true}
          label="Issue Date"
          type="date"
          name="issue_date"
          placeholder={"Issue Date"}
          onChange={handleChange}
          max={
            values.expiry_date && values.expiry_date !== ""
              ? values.expiry_date.replace(
                  /(\d{2})\/(\d{2})\/(\d{4})/,
                  "$3-$2-$1"
                )
              : new Date().toISOString().split("T")[0]
          }
          value={values.issue_date.replace(
            /(\d{2})\/(\d{2})\/(\d{4})/,
            "$3-$2-$1"
          )}
          onKeyDown={handleKeyDown}
        />
        <span>
          {errors.issue_date && touched.issue_date && errors.issue_date}
        </span>
      </Grid>
      <Grid item xs={6}>
        <TextInput
          compolsory={true}
          label="Expiry Date"
          type="date"
          name="expiry_date"
          placeholder={"Expiry Date"}
          onChange={handleChange}
          min={
            values.issue_date && values.issue_date !== ""
              ? values.issue_date.replace(
                  /(\d{2})\/(\d{2})\/(\d{4})/,
                  "$3-$2-$1"
                )
              : new Date().toISOString().split("T")[0]
          }
          value={values.expiry_date.replace(
            /(\d{2})\/(\d{2})\/(\d{4})/,
            "$3-$2-$1"
          )}
          onKeyDown={handleKeyDown}
        />
        <span>
          {errors.expiry_date && touched.expiry_date && errors.expiry_date}
        </span>
      </Grid>
    </>
  );
}
