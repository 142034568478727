import { FORM_COMPONENT } from "../constants/constants";

const initialState = [];

export const formFieldReducers = (state = initialState, { type, payload }) => {
  switch (type) {
    case FORM_COMPONENT:
      return [payload];
    default:
      return state;
  }
};
