import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { Box, Typography, IconButton } from "@mui/material";
import {
  getProfile,
  getSocialNetwork,
} from "../../../api/candidate/candidate.class";

export const LicenseUserInfo = ({
  name,
  number,
  email,
  edit,
  setProfile,
  profile,
  isProfileUpdate,
  setProfileUpdate,
}) => {
  

  useEffect(() => {
    const API = async () => {
      try {
        const response = await getProfile().then(function (res) {
          return res.data.results;
        });
        // console.log("response", response);
        setProfile(response);
      } catch (error) {
        console.log(error);
      }
    };

    API();

    return () => {
      setProfile("");
    };
  }, [isProfileUpdate]);

  return (
    <div className={styles.parent}>
      <Box className={ `mb-0 ${styles.userInfo}`}>
        <Box sx={{ mr: "10px" }} className="d-flex">
          <Typography variant="h4" className="mr-2">First Name:</Typography>
          <Typography component="p" className="mr-4">{`${profile?.first_name}`}</Typography>
          <Typography variant="h4" className="mr-2">Last Name:</Typography>
          <Typography component="p">{`${profile?.last_name}`}</Typography>
          {/* <Typography variant="h4">Contact number:</Typography>
          <Typography component="p">{`+${profile?.contact_number}`}</Typography> */}
          {/* <Typography variant="h4">Email Address</Typography>
          <Typography component="p">{profile?.email}</Typography> */}
        </Box>
      </Box>
    </div>
  );
};
