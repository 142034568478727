import CryptoJS from "crypto-js";
import * as flatted from "flatted";
import React, { useEffect, useState } from "react";
// import Helmet from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-responsive-modal";
import { useNavigate, useParams } from "react-router-dom";
import S3FileUpload from "react-s3";
// import { toast, ToastContainer } from "react-toastify";
// import useLocalStorage from "use-local-storage";
// import "react-toastify/dist/ReactToastify.css";
import "../../assets/sinner_css/DocSign.css";
// import { useMetamask } from 'use-metamask';

import LoaderGif from "../../assets/common/loader150.gif";
import { setReceiverMetadata } from "../../store/actions/receiverMetadata.action";
// import { getAxiosWithToken } from '../../services/common.services';
// import { URL_PATH } from '../../services/constants';
// import {
//   getDocMetadataFromUrlBlockChain,
//   updateSignerService,
// } from '../../services/docSign.services';
// import { KEY_TOKEN, KEY_USER } from '../../utils/constants';
import { EDH } from "../../utils/EDH";
import s3Config, { getFile, uploadFile } from "../../utils/s3Config";
import PopupModal from "../../components/document_sinner/PopupModal.jsx";
import SignDocContainer from "../../components/document_sinner/SignDocContainer.jsx";
import AskPassword from "../../components/document_sinner/AskPassword.jsx";
// import DashboardHeader from '../common/DashboardHeader';
import MessageBox from "../../components/document_sinner/MessageBox.jsx";
// import useMetamaskEncryptionDecryption from '../hooks/useMetamaskEncryptionDecryption';
import SpinnerLoader from "../../components/document_sinner/SpinnerLoader.jsx";
import {
  EDocView,
  UpdateEDoc,
  UpdateGuestEDoc,
  getGuestDoc,
} from "../../api/sinner/SinnerApi.js";
import { routes } from "../../routes.js";
import { ShowAlert } from "../../store/actions/alertActions.js";
/**
 *
 *
 */

function DocSign() {
  const { id } = useParams();
  const states = useParams();
  const [message, setMessage] = useState(null);
  const [decryptionPopup, setdecryptionPopup] = useState(false);
  const [docInfo, setDocInfo] = useState(null);
  const [decPassword, setDecPassword] = useState("");
  const [passError, setPassError] = useState(null);
  const [metadataString, setMetadataString] = useState(null);

  // const { connect, metaState } = useMetamask();
  const [openNoEtheriumPopup, setOpenNoEtheriumPopup] = useState(false);
  const dispatch = useDispatch();
  const receiverMetadata = useSelector(
    (state) => state.receiverMetadataReducer
  );

  console.log("receiverMetadataup", receiverMetadata);
  const [signer, setSigner] = useState(null);
  const [s3MetadataUrl, setS3MetadataUrl] = useState("");
  const navigation = useNavigate();
  const [successModalPopup, setSuccessModalPopup] = useState(false);
  const [count, setCount] = useState(0);
  const [showLoader, setShowLoader] = useState({
    show: false,
    message: "Processing...",
  });

  let user = JSON.parse(localStorage.getItem("auth"))?.results || {};
  if (states?.email) {
    user = { email: states?.email };
  }

  console.log("receiverMetadata", receiverMetadata);

  useEffect(() => {
    (async () => {
      try {
        setShowLoader({ show: true, message: "Processing..." });

        setMessage(null);
        let doc;
        if (!states?.email) {
          const query = `recruiter_id=${
            user?.recruiter_id || ""
          }&workspace_id=${user?.workspace_id || ""}&email=${
            user?.email || ""
          }&candidate_id=${user?.candidate_id || ""}`;

          doc = await EDocView(query, id);
        } else {
          doc = await getGuestDoc(id, states?.email);
        }

        if (doc.data.code === 404) {
          setMessage("Requested page is Invalid or Expired");
          dispatch(ShowAlert(doc?.data?.message, "error"));
          return;
        } else if (doc.data.code === 200) {
          setMessage("Processing...");

          if (doc?.data?.results?.signer?.isSigned === 1 && !states?.email) {
            setMessage("Document already signed");
            return;
          } else if (
            doc?.data?.results?.signer?.isSigned === 1 &&
            states?.email
          ) {
            console.log(
              "navigation",
              `${routes.CAN_DOCUMENT.DOC_PREVIEW_SINNER_CAN}/${id}/${states?.email}
              `
            );
            navigation(
              `${routes.CAN_DOCUMENT.DOC_PREVIEW_SINNER_CAN}/${id}/${states?.email}
              `
            );
            return;
          }
          setDocInfo(doc.data.results);
          setdecryptionPopup(true);
          console.log(doc.data.results.metadataUrl);
          if (!doc.data.results.metadataUrl) {
            dispatch(ShowAlert("Document not found..!!"));
          } else {
            await processMetadata(doc.data.results.metadataUrl);
            setS3MetadataUrl(doc.data.results.metadataUrl);
          }
          setMessage(null);
          setShowLoader({ show: false, message: "Processing..." });
        } else {
          dispatch(ShowAlert(doc?.data?.message, "error"));
          setMessage("Error in loading page..!!");
        }
      } catch (error) {
        console.error(error);
        dispatch(ShowAlert("Something went wrong..!! ", "error"));
      } finally {
        setShowLoader({ show: false, message: "Processing..." });
      }
    })();
  }, [dispatch, id]);

  const processMetadata = async (metadataUrl) => {
    const docData = await getFile(metadataUrl);
    setMetadataString(docData);
  };

  const onPassSubmit = async () => {
    setPassError(null);
    if (decPassword.length < 6) {
      setPassError("Password is required");
      return;
    }

    // decrypt string
    try {
      const bytes = CryptoJS.AES.decrypt(metadataString.blob, decPassword);
      const originalText = bytes.toString(CryptoJS.enc.Utf8);
      const jsonMeta = flatted.parse(originalText);
      setdecryptionPopup(false);
      console.log("jsonMeta", jsonMeta);
      dispatch(setReceiverMetadata(jsonMeta));
    } catch (error) {
      console.error(error);
      setPassError("Password is Incorrect");
    }
  };

  useEffect(() => {
    if (receiverMetadata === null) {
      return;
    }
    const signer = receiverMetadata.signers.filter(
      (e) => user.email.toLowerCase() === e.email.toLowerCase()
    );
    if (signer.length === 0) {
      setMessage("Document is expired or does not belongs to you");
      return;
    }
    if (signer[0].hasSigned) {
      setMessage("This Document has been already signed");
      return;
    }
    setSigner(signer[0]);
    setMessage(null);
  }, [receiverMetadata]);

  const handleNext = () => {
    if (count >= receiverMetadata.signers[0].fields.length || count < 0) {
      setCount(0);
      return;
    }

    const scrollPosition = receiverMetadata.signers[0].fields[count];
    window.scrollTo({ top: scrollPosition.positionFromParentTop - 100 });
    setCount(count + 1);
  };

  const handlePrev = () => {
    if (count < 0) {
      return;
    }
    console.log("hi");

    if (count > receiverMetadata.signers[0].fields.length - 1) {
      setCount(receiverMetadata.signers[0].fields.length - 1);
      return;
    }
    const scrollPosition = receiverMetadata.signers[0].fields[count];
    window.scrollTo({ top: scrollPosition.positionFromParentTop - 100 });
    setCount(count - 1);
  };

  const handleFormFinish = async () => {
    const error = [];
    signer.fields.forEach((e) => {
      if (e.isRequired) {
        if (!e.isFilled) {
          e.errorMessage = "Highlighted fields is required";
          error.push(e);
        } else {
          e.errorMessage = null;
        }
      }
    });
    dispatch(setReceiverMetadata({ ...receiverMetadata }));
    if (error.length > 0) {
      // toast.error(error[0].errorMessage);
      return;
    }

    await goWithS3Flow();
  };

  const goWithS3Flow = async () => {
    try {
      setSuccessModalPopup(true);
      if (decPassword.length < 5) {
        return;
      }
      signer.hasSigned = true;

      const blobString = flatted.stringify(receiverMetadata);
      metadataString.blobHash = EDH.SHA1_hash(blobString);
      metadataString.isSigned = true;
      const ciphertext = CryptoJS.AES.encrypt(
        blobString,
        decPassword
      ).toString();

      metadataString.blob = ciphertext;

      const jsonFile = new Blob([JSON.stringify(metadataString)], {
        type: "application/json",
      });
      jsonFile.name = docInfo.signer.metadataUrl.replace(/^.*[\\\/]/, "");
      await uploadFile(jsonFile, docInfo.signer.metadataUrl.split(".com/")[1]);
      // console.log(metadataResult);
      const query = `recruiter_id=${user?.recruiter_id || ""}&email=${
        user.email || ""
      }&uuid=${id || ""}&candidate_id=${user?.candidate_id || ""}`;
      let updateToServer;
      if (states?.email) {
        updateToServer = await UpdateGuestEDoc(query);
      } else {
        updateToServer = await UpdateEDoc(query);
      }
      console.log(updateToServer);
      if (updateToServer?.data?.code === 200) {
        dispatch(ShowAlert(updateToServer?.data?.message, "success"));
        setTimeout(() => {
          setSuccessModalPopup(false);
          navigation(
            user?.type === "recruitment"
              ? routes.ALL_DOCUMENTS
              : routes.CANDIDATE_DOC_LIST
          );
        }, 2000);
      } else {
        dispatch(ShowAlert(updateToServer?.data?.message, "error"));
      }
    } catch (error) {
      console.error(error);
      setSuccessModalPopup(false);
    }
  };

const hideAskPassword = () => {
  navigation(
        user?.type === "recruitment"
          ? routes.ALL_DOCUMENTS
          : routes.CANDIDATE_DOC_LIST
      );
  setdecryptionPopup(false);
};
  return (
    <>
      {/* <DashboardHeader /> */}
      <div className="header_box"></div>
      {/* <Helmet>
        <title>{docInfo ? docInfo.title : "Document loading..."}</title>
      </Helmet> */}
      {/* <ToastContainer /> */}
      <div className="start_area">
        <div className="sticky-bar">
          <SpinnerLoader
            isOpen={showLoader.show}
            message={showLoader.message}
          />
          {message
            ? !decryptionPopup &&
              !successModalPopup && <MessageBox message={message} />
            : receiverMetadata &&
              !decryptionPopup &&
              docInfo && (
                <div className="row mx-0">
                  <div className="col-md-6 mb-3 mb-md-0">
                    <h5>
                      Please fill in {signer?.fields?.length || ""} required
                      fields.
                    </h5>
                  </div>
                  <div className="col-md-6">
                    <div
                      className="d-flex justify-content-md-end flex-wrap "
                      style={{ gap: "8px" }}
                    >
                      <button
                        className="nav-link cursor-pointer"
                        // style={{ cursor: "pointer" }}
                        onClick={handlePrev}
                      >
                        Previous
                      </button>
                      <button
                        className="nav-link cursor-pointer"
                        // style={{ cursor: "pointer" }}
                        onClick={handleNext}
                      >
                        Next
                      </button>
                      <button
                        className="nav-link cursor-pointer"
                        // style={{ cursor: "pointer" }}
                        onClick={handleFormFinish}
                      >
                        Finish
                      </button>
                    </div>
                  </div>
                </div>
              )}
        </div>
      </div>
      {s3MetadataUrl && (
        <AskPassword
          decryptionPopup={decryptionPopup}
          onPassSubmit={onPassSubmit}
          passError={passError}
          setDecPassword={setDecPassword}
          hideAskPassword={hideAskPassword}
        />
      )}

      {!decryptionPopup && docInfo && (
        <div className="receiver-area" style={{ marginTop: "40px" }}>
          <div className="container">
            {receiverMetadata && message === null && (
              <SignDocContainer receiverMetadata={receiverMetadata} />
            )}
          </div>
        </div>
      )}

      <PopupModal
        closeOnEsc={false}
        closeOnOverlayClick={false}
        minWidth={200}
        onClose={() => {}}
        open={successModalPopup}
        showCloseIcon={false}
      >
        <div className="margin_box popup text-center">
          <img
            src={LoaderGif}
            alt="loader"
            style={{ margin: "auto", width: 50, height: 50 }}
          />
        </div>
      </PopupModal>

      <Modal
        center
        closeOnEsc={false}
        closeOnOverlayClick={false}
        onClose={() => setOpenNoEtheriumPopup(false)}
        open={openNoEtheriumPopup}
      >
        <div className="optionContainer iconPopups" style={{ paddingTop: 30 }}>
          <div className="margin_box popup">
            Please install Etherium client in browser first
          </div>
        </div>
      </Modal>
    </>
  );
}

export default DocSign;
