import React from "react";

import TextInput from "../../common/TextInput/index";
// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import "./styles.scss";
import { Box, Typography, Grid } from "@mui/material";

// import { useDispatch, useSelector } from "react-redux";

const InterviewPopup = ({ opne, setOpen, data, name, certificate }) => {
  // console.log("data", data);
  // const [selectValue, setSelectValue] = useState(0);
  // const [startDate, setStartDate] = useState("");
  // const candidateID = JSON.parse(localStorage.getItem("candidateID"));

  // const dispatch = useDispatch();
  // const auth = useSelector((state) => state?.auth);
  // const candidate = useSelector((state) => state?.rcandidate);
  // const singleCandidate = useSelector((state) => state?.singleCandidate);
  function convertDate(dateStr) {
    if(dateStr){
      const months = {
        'jan': '01', 'feb': '02', 'mar': '03', 'apr': '04', 'may': '05', 'jun': '06',
        'jul': '07', 'aug': '08', 'sep': '09', 'oct': '10', 'nov': '11', 'dec': '12'
      };
    
      const [day, monthStr, year] = dateStr.split('-');
      const month = months[monthStr.toLowerCase()];
    
      return `${day}-${month}-${year}`;
    }
    return '';
  }
  return (
    <Box className="interviewParrent">
      <Typography variant="h3">{name || "Reference Data"}</Typography>
      {!certificate && ( <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <TextInput
              disabled={true}
              label="Full Name"
              value={data?.full_name}
              customClass={"inputReffShow"}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextInput
              disabled={true}
              label="Job Title"
              value={data?.job_title}
              customClass={"inputReffShow"}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextInput
              disabled={true}
              label="Email"
              value={data?.email}
              customClass={"inputReffShow"}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextInput
              disabled={true}
              label="Phone Number"
              value={data?.contact_number}
              customClass={"inputReffShow"}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextInput
              disabled={true}
              label="Reference Relationship"
              value={data?.reference_relationship}
              customClass={"inputReffShow"}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextInput
              disabled={true}
              label="Reference Company Name"
              value={data?.reference_company_name}
              customClass={"inputReffShow"}
            />
          </Grid>
        </Grid>)}
      {certificate && !data?.is_visa && !data?.is_backy_check && (
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <TextInput
              disabled={true}
              label="First Name"
              value={data?.first_name}
              customClass={"inputReffShow"}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextInput
              disabled={true}
              label="Last Name"
              value={data?.last_name}
              customClass={"inputReffShow"}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextInput
              disabled={true}
              label="Email"
              value={data?.email}
              customClass={"inputReffShow"}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextInput
              disabled={true}
              label="Date Of Birth"
              value={new Date(data?.dob).toLocaleDateString("en-GB")}
              customClass={"inputReffShow"}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextInput
              disabled={true}
              label="State"
              value={data?.state || ""}
              customClass={"inputReffShow"}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextInput
              disabled={true}
              label="Country"
              value={data?.country}
              customClass={"inputReffShow"}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextInput
              disabled={true}
              label="Industry Certificate Name"
              value={data?.industry_certification_license?.name}
              customClass={"inputReffShow"}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextInput
              disabled={true}
              label="Recruiter Industry"
              value={data?.recruiter_industry?.name}
              customClass={"inputReffShow"}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextInput
              disabled={true}
              label="Issue Date"
              value={new Date(data?.issue_date).toLocaleDateString("en-GB")}
              customClass={"inputReffShow"}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextInput
              disabled={true}
              label="Expiry Date"
              value={new Date(data?.expiry_date).toLocaleDateString("en-GB")}
              customClass={"inputReffShow"}
            />
          </Grid>
          <Grid item xs={12} lg={12}>
            <TextInput
              disabled={true}
              label="Document Number"
              value={data?.document_number}
              customClass={"inputReffShow"}
            />
          </Grid>

          <Grid item xs={6} lg={6}>
            <Box className="stars-form">
              <label>Front Image</label>
              {data?.front_image ? (
                <img
                  src={`${process.env.REACT_APP_URL}${data?.front_image}`}
                  width={70}
                  height={70}
                  alt="front-img"
                />
              ) : (
                "No image uploaded"
              )}
            </Box>
          </Grid>
          <Grid item xs={6} lg={6}>
            <Box className="stars-form">
              <label>Back Image</label>
              {data?.back_image ? (
                <img
                  src={`${process.env.REACT_APP_URL}${data?.back_image}`}
                  width={70}
                  height={70}
                  alt="back-img"
                />
              ) : (
                "No image uploaded"
              )}
            </Box>
          </Grid>
        </Grid>
      )}
      { certificate && data?.is_visa && (
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <TextInput
              disabled={true}
              label="Given Name"
              value={`${data?.first_name}`}
              customClass={"inputReffShow"}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextInput
              disabled={true}
              label="Family Name"
              value={`${data?.last_name}`}
              customClass={"inputReffShow"}
            />
          </Grid>
          
          <Grid item xs={12} lg={6}>
            <TextInput
              disabled={true}
              label="Email"
              value={data?.email}
              customClass={"inputReffShow"}
              style={{textTransform: 'lowercase'}}
            />
          </Grid>
          <Grid item xs={6}>
            <TextInput
              disabled={true}
              label="Date of birth"
              type="date"
              name="dob"
              placeholder={"Date of birth"}
              max={new Date().toISOString().split("T")[0]}
              value={data?.dob}
              customClass={"inputReffShow"}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextInput
              disabled={true}
              label="Country of Document"
              value={data?.country}
              customClass={"inputReffShow"}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextInput
              disabled={true}
              label="Document Number"
              value={data?.document_number}
              customClass={"inputReffShow"}
            />
          </Grid>
          <Grid item xs={12} lg={12}>
            <TextInput
              disabled={true}
              label="Recruitment Industries"
              value={data?.recruiter_industry?.name}
              customClass={"inputReffShow"}
            />
          </Grid>
          <Grid item xs={12} lg={12}>
            <TextInput
              disabled={true}
              label="Industry Certification License"
              value={data?.industry_certification_license?.name}
              customClass={"inputReffShow"}
            />
          </Grid>
        </Grid>)}
      { certificate && data?.is_backy_check && (
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          <TextInput
            disabled={true}
            label="First Name"
            value={`${data?.first_name}`}
            customClass={"inputReffShow"}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextInput
            disabled={true}
            label="Middle Name"
            value={`${data?.middle_name}`}
            customClass={"inputReffShow"}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextInput
            disabled={true}
            label="Surname"
            value={`${data?.last_name}`}
            customClass={"inputReffShow"}
          />
        </Grid>
        
        <Grid item xs={12} lg={6}>
          <TextInput
            disabled={true}
            label="Email"
            value={data?.email}
            customClass={"inputReffShow"}
            style={{textTransform: 'lowercase'}}
          />
        </Grid>

        <Grid item xs={6}>
          <TextInput
            disabled={true}
            label="Date of birth"
            type="date"
            name="dob"
            placeholder={"Date of birth"}
            max={new Date().toISOString().split("T")[0]}
            value={data?.dob}
            customClass={"inputReffShow"}
          />
        </Grid>
        {data?.backy_check_type.toLowerCase() === 'wwc' && (<>
          <Grid item xs={6}>
          <TextInput
            disabled={true}
            label="Document Number"
            type="text"
            name="document_number"
            placeholder={"Document Number"}
            value={data?.document_number}
            customClass={"inputReffShow"}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            disabled={true}
            label="Card Expiry Date"
            type="text"
            name="expiry_date"
            placeholder={"Card Expiry Date"}
            value={convertDate(data?.expiry_date)}
            customClass={"inputReffShow"}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            disabled={true}
            label="Card State Issue"
            type="text"
            name="state"
            placeholder={"Card State Issue"}
            value={data?.state}
            customClass={"inputReffShow"}
          />
        </Grid>
        </>)}
        <Grid item xs={12} lg={12}>
          <TextInput
            disabled={true}
            label="Recruitment Industries"
            value={data?.recruiter_industry?.name}
            customClass={"inputReffShow"}
          />
        </Grid>
        <Grid item xs={12} lg={12}>
          <TextInput
            disabled={true}
            label="Industry Certification License"
            value={data?.industry_certification_license?.name}
            customClass={"inputReffShow"}
          />
        </Grid>
      </Grid>)}
    </Box>
  );
};

export default InterviewPopup;
