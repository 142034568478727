import React, { useCallback, useEffect, useState } from "react";

import FormsTabel from "../e_doc_tabel/EDocTable";
import { Box, Typography } from "@mui/material";
import styles from "./styles.module.scss";

import { EDocListing } from "../../../api/sinner/SinnerApi";

import { useDispatch } from "react-redux";
import { ShowAlert } from "../../../store/actions/alertActions";
import {
  LOADING_FALSE,
  LOADING_TRUE,
} from "../../../store/constants/constants";

// import SEO from "../../meta-tags";
const CandidateDocumentPage = () => {
  const [value, setValue] = useState(1);

  const [formData, setFormData] = useState([]);

  const [update, setUpdate] = useState(false);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);

  const dispatch = useDispatch();
  const userData = JSON.parse(localStorage.getItem("auth"))?.results || {};

  useEffect(() => {
    dispatch({
      type: LOADING_TRUE,
    });
    // window.scrollTo(0, 0);
  }, [dispatch, page]);

  {
    const getDocumentList = useCallback(async () => {
      const query = `recruiter_id=${
        userData?.recruiter_id || ""
      }&workspace_id=${userData?.workspace_id || ""}&doc_builder_status=${
        value || 1
      }&page=${page + 1}&candidate_id=${userData?.candidate_id || ""}`;
      try {
        const response = await EDocListing(query);
        if (response?.data?.code === 200) {
          setFormData(response?.data?.results?.data || []);
          setTotal(response?.data?.results?.total);
        } else {
          dispatch(ShowAlert(response?.data?.message, "error"));
        }
      } catch (error) {
        console.error(error);
        dispatch(ShowAlert("Something went wrong...!!", "error"));
      } finally {
        dispatch({
          type: LOADING_FALSE,
        });
      }
    }, [dispatch, page, value]);
    useEffect(() => {
      getDocumentList();
    }, [getDocumentList]);
  }

  // useEffect(() => {
  //   const api = async () => {
  //     const response = await getDeactivateForms({
  //       form_builder_status: "0",
  //     });
  //     setDeactivateData(response?.data?.results?.data);
  //   };
  //   api();
  // }, [update]);

  return (
    <Box className={styles.parent}>
      <Box className={styles.head}>
        <Typography variant='h4'>Documents List</Typography>
      </Box>
      <FormsTabel
        titles={[
          "Document Title",
          "Category Name",
          "Status",
          // "Resend Document",
          "Actions",
        ]}
        rows={formData}
        setUpdate={setUpdate}
        type='All Document'
        page={page}
        setPage={setPage}
        total={total}
      />
    </Box>
  );
};

export default CandidateDocumentPage;
