import React, { useRef, useState } from "react";
import { Box } from "@mui/system";
import Actions from "./Actions";
import style from "./style.module.scss";
import SignatureModel from "../Model/SignatureModel";
import { Button } from "@mui/material";
import useDragField from "../../../../customHook/useDragField";

function SignatureField({
  id,
  main_id,
  label,
  type,
  name,
  two_col,
  col_name,
  index,
  moveCard,
}) {
  const [signOpenmodel, setSignOpenmodel] = useState(false);
  const [signImage, setSignImage] = useState();
  const ref = useRef(null);
  const dragField = useDragField(ref, index, moveCard, id);
  const opacity = dragField.opacity;
  console.log("signImageFromFeild", signImage);
  return (
    <Box
      ref={ref}
      style={{ ...style, opacity }}
      data-handler-id={dragField?.handlerId}
      className={`${style.inputBox} dropItem`}
    >
      <Actions
        id={id}
        type={type}
        two_col={two_col}
        col_name={col_name}
        main_id={main_id}
      />
      <label>{label}</label>
      <Box className={style.buttonGroup}>
        <Box className={`button-preimary ${style.button}`}>
          <Button
            className={"secondary-btn"}
            onClick={() => setSignOpenmodel(true)}
          >
            Draw your Signature
          </Button>
        </Box>

        <Box className={style.imgBox}>
          <img src={signImage} alt="" />
        </Box>
      </Box>
      <SignatureModel
        openModal={signOpenmodel}
        setOpenModal={setSignOpenmodel}
        setSignImage={setSignImage}
        signImage={signImage}
        id={id}
        name={name}
      />
    </Box>
  );
}

export default SignatureField;
