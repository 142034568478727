import React, { useState } from "react";
import { Box } from "@mui/system";
import Actions from "./Actions";
import style from "./style.module.scss";
import { Autocomplete, TextField } from "@mui/material";
import { useSelector } from "react-redux";

function AutocompleteField({
  id,
  main_id,
  label,
  type,
  selectedvalue,
  placeholder,
  name,
  options,
  two_col,
  col_name,
}) {
  const getpreviewform = useSelector((state) => state?.getpreviewform);
  const [inputValue, setInputValue] = React.useState("");
  const [value, setValue] = React.useState(selectedvalue);
  const autoCompleteOptions = getpreviewform.map((obj) => {
    if (obj.id === id) {
      const Newa = obj.type.options.filter(checID);

      function checID(newid) {
        return newid;
      }

      return Newa;
    }
  });

  return (
    <Box className={`${style.inputBox} dropItem`}>
      <Actions
        id={id}
        type={type}
        two_col={two_col}
        col_name={col_name}
        main_id={main_id}
      />
      <label>{label}</label>

      <Autocomplete
        value={value}
        className={style.autoComplete}
        disablePortal
        id="combo-box-demo"
        options={options}
        size="small"
        name={name}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        renderInput={(params) => (
          <TextField {...params} name={name} placeholder={placeholder} />
        )}
      />
    </Box>
  );
}

export default AutocompleteField;
