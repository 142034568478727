import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { ReactComponent as Cross } from "../../../../../assets/industries/model/x.svg";
import { ReactComponent as UploadIcon } from "../../../../../assets/candidates/uploadfile.svg";
import Modal from "@mui/material/Modal";

import styles from "./stylesUpload.module.scss";
import { Button } from "@mui/material";
import {
  uploadFiles,
  uploadResume,
} from "../../../../../api/uploadFile/uploadFile.class";
import { useDispatch, useSelector } from "react-redux";
import { ShowAlert } from "../../../../../store/actions/alertActions";
import Alerts from "../../../../common/Alert";
import { useNavigate } from "react-router-dom";

export default function CustomModal({
  openModal,
  setOpenModal,
  setOpenpopopaccept,
  setOpenpopopreject,
  singleformdata,
  data,
}) {
  const handleClose = () => setOpenModal(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const candidateId = useSelector((state) => state.auth.results.candidate_id);

  const [file, setFile] = useState({ resume_path: "" });
  return (
    <>
      <Alerts />
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={`${styles.industryModel}`}
      >
        <Box className={`${styles.boxModel} xym1`}>
          <Box className={styles.modalwapper}>
            <Box className={styles.cross} onClick={handleClose}>
              <Cross />
            </Box>
            <Typography variant="h4" className={styles.heading}>
              Job offer review
            </Typography>
            <Box className={styles.statusbox}>
              <Box className={styles.offermodelDetail}>
                <p>
                  Status: <span> {singleformdata?.response}</span>
                </p>
              </Box>
              <Box className={styles.offermodelDetail}>
                <p>
                  Letter of offer:{" "}
                  <a
                    target="_blank"
                    href={`${process.env.REACT_APP_URL}${singleformdata?.pdf_url}`}
                  >
                    Job Offer Letter of{" "}
                    {singleformdata?.form_data?.candidate_name}
                  </a>
                </p>
              </Box>
              <Box className={styles.offermodelDetail}>
                <p>
                  Last updated: <span>{singleformdata?.updated_date}</span>
                </p>
              </Box>

              <Box className={`${styles.button} button-primary d-flex`}>
                {singleformdata?.response === "rejected" ||
                singleformdata?.response === "completed" ? (
                  ""
                ) : (
                  <div className="d-flex">
                    <Button
                      onClick={() => {
                        setOpenModal(false);
                        setOpenpopopaccept(true);
                      }}
                    >
                      Accept
                    </Button>
                    <Button
                      data={data}
                      className="ml-2"
                      onClick={() => {
                        setOpenModal(false);
                        setOpenpopopreject(true);
                      }}
                    >
                      Reject
                    </Button>
                  </div>
                )}
                <div>
                  <Button className="secondary-btn" onClick={handleClose}>
                    Close
                  </Button>
                </div>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
