import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  Typography,
  FormControlLabel,
  CircularProgress,
} from "@mui/material";
import styles from "../order_details/all-candidates/styles.module.scss";
import Checkbox from "../common/checkbox";
import {
  LOADING_FALSE,
  LOADING_TRUE,
  SIGNER_COLORS,
} from "../../store/constants/constants";
import SignersField from "./SinnerFields";
import IconPlus from "../../assets/sinner/icon_plus.svg";
import RightArrow from "../../assets/sinner/right-arrow.png";
import { useDispatch, useSelector } from "react-redux";
import { setSigners } from "../../store/actions/pdfupload.actions";
import { setSigningMetadata } from "../../store/actions/signingMetadata.actions";
import { useLocation, useNavigate } from "react-router-dom";
import IconPlusWhite from "../../assets/sinner/icon_plus_white.svg";
import { routes } from "../../routes";
import style from "./styles.module.scss";
import { EDocTemplateDetails } from "../../api/sinner/SinnerApi";
import { ShowAlert } from "../../store/actions/alertActions";
import { getDocument } from "pdfjs-dist/build/pdf";
import { downloadFile } from "../../utils/s3Config";
import Modal from "react-responsive-modal";

function SelectSinner() {
  const [signerFields, setSignerFields] = useState([]);
  const [alreadySigners, setAlreadySigners] = useState([]);
  const [nextButtonClicked, setNextButtonClicked] = useState(null);
  const [errorMsg, setErrorMsg] = useState("");

  const [areYouSigner, setAreYouSigner] = useState(false);
  const singleCandidate = useSelector((state) => state?.singleCandidate);
  const userData = JSON.parse(localStorage.getItem("auth")) || "";

  let fieldId = new Date().getTime();
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const { state } = useLocation();

  const pdfDoc = useSelector((state) => state.pdfUploadReducer).pdfFile;

  useEffect(() => {
    if (!state?.uuid) {
      if (!pdfDoc) {
        navigation(-1);
      }
    } else {
      handelGetTemplateDetails();
    }
  }, [navigation, pdfDoc, state?.uuid]);

  const addSigner = (e) => {
    e.preventDefault();
    if (signerFields.length > 4) {
      return;
    }

    if (signerFields.length > 0) {
      fieldId = new Date().getTime();
    }

    setSignerFields((preVal) => {
      return [
        ...preVal,
        {
          id: fieldId,
          name: "",
          color: SIGNER_COLORS[signerFields.length],
          email: "",
          isValidated: false,
          fields: [],
        },
      ];
    });
  };

  const removeSigner = (id) => {
    const lst = signerFields.filter((item) => item.id !== id);
    setSignerFields(lst);
  };

  const handleNext = () => {
    setNextButtonClicked(new Date().getTime());
    setErrorMsg(null);
    if (!state?.uuid && !areYouSigner && signerFields.length === 0) {
      setErrorMsg("At least 1 signer is required");
      return;
    }

    if (state?.uuid && !areYouSigner && signerFields.length === 0) {
      setErrorMsg(
        `This template required at least ${alreadySigners?.length} ${
          alreadySigners?.length > 1 ? "signers" : "signer"
        }`
      );
      return;
    }
    let isAllfieldValidated = true;
    signerFields.forEach((e) => {
      if (!e.isValidated) {
        isAllfieldValidated = false;
        if (e.emailError === undefined) {
          e.emailError = "Email is required";
        }

        if (e.nameError === undefined) {
          e.nameError = "Name is required";
        }
        return;
      }
    });
    if (!state?.uuid && !areYouSigner && signerFields.length === 0) {
      setErrorMsg("At least 1 Signer is required");
      return;
    }

    if (state?.uuid && !areYouSigner && signerFields.length === 0) {
      setErrorMsg(
        `This template required at least ${alreadySigners?.length} ${
          alreadySigners?.length > 1 ? "signers" : "signer"
        }`
      );
      return;
    }
    if (isAllfieldValidated) {
      let signers = [...signerFields];
      if (areYouSigner === true) {
        signers.push({
          id: new Date().getTime(),
          name: `${userData?.results?.first_name} ${userData?.results?.last_name}`,
          email: userData?.results?.email.toLowerCase(),
          color: SIGNER_COLORS[signers.length + 1],
          isValidated: true,

          me: "me",
          fields: [],
        });
      } else {
        const temp = signers.filter(
          (e) =>
            e.email.toLowerCase() !== userData?.results?.email.toLowerCase()
        );
        signers = temp;
      }
      // here everything is validated now we have to save signers to redux

      if (signers.length > 0) {
        console.log("signers", signers);
        if (state?.uuid) {
          console.log("lengthssss", signers?.length, alreadySigners?.length);
          if (signers?.length < alreadySigners?.length) {
            // setErrorMsg(`${alreadySigners?.length} or more signer is required`);

            setErrorMsg(
              `This template required at least ${alreadySigners?.length} ${
                alreadySigners?.length > 1 ? "signers" : "signer"
              }`
            );

            return;
          }
          for (let i = 0; i < alreadySigners?.length; i++) {
            signers[i].fields = alreadySigners[i].fields;
          }
        }

        dispatch(setSigners(signers));

        dispatch(
          setSigningMetadata({
            id: new Date().getTime(),
            sender: {
              name: `${userData?.results?.first_name} ${userData?.results?.last_name}`,
              email: userData?.results?.email.toLowerCase(),
            },
            signers: signers,
          })
        );
        navigation(`${routes.DOCUMENT_SIGN.DRAG_FIELDS}`, {
          state: {
            allFiles: state?.allFiles || "",
            templateUuid: state?.uuid,
          },
        });
      }
    }
  };

  const handelGetTemplateDetails = async () => {
    try {
      dispatch({
        type: LOADING_TRUE,
      });
      let result = await EDocTemplateDetails(state?.uuid);
      console.log(result);
      if (result?.data?.code === 200) {
        let data = result?.data?.results?.data || [];
        setAlreadySigners(data);
      } else {
        dispatch(ShowAlert(result?.data?.message, "error"));
      }
    } catch (error) {
      console.error(error);
      dispatch(ShowAlert("Something went wrong..!!", "error"));
    } finally {
      dispatch({
        type: LOADING_FALSE,
      });
    }
  };
  return (
    <>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <div className={`${style.loginBox}`}>
              <h1>Select Signers</h1>

              <div className={`${style.form_box} pdf`}>
                <form>
                  <div className='col-full'>
                    <h5>Are you a signer ?</h5>
                    <div className={`${style.image_none}`}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            // type='checkbox'
                            id={`inline-radio-1`}
                            inline
                            label='Yes'
                            name='areYouSigner'
                            setClick={(value) => {
                              setAreYouSigner(value);
                              setErrorMsg(null);
                            }}
                            setClick2={(value) => {}}
                            type='radio'
                            click={areYouSigner}
                          />
                        }
                        label='yes'
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            defaultChecked
                            id={`inline-radio-2`}
                            inline
                            label='No'
                            name='areYouSigner'
                            setClick={(value) => {}}
                            setClick2={(value) => {
                              setAreYouSigner(value);
                              setErrorMsg(null);
                            }}
                            type='radio'
                            click={!areYouSigner}
                          />
                        }
                        label='no'
                      />

                      {errorMsg && <p className='text-danger'>{errorMsg}</p>}
                    </div>
                  </div>
                  <div className='col-full'>
                    <div className={`${style.signer_title}`}>
                      <h5>Add signers</h5>
                      <Button className='secondary-btn' onClick={addSigner}>
                        {" "}
                        <img alt='logo' className='app-logo' src={IconPlus} />
                        add new signer
                      </Button>
                    </div>

                    {signerFields.map((item, i) => {
                      return (
                        <SignersField
                          index={i + 1}
                          item={item}
                          key={item.id}
                          nextButtonClicked={nextButtonClicked}
                          removeSigner={removeSigner}
                          signerFields={signerFields}
                          setSignerFields={setSignerFields}
                        />
                      );
                    })}
                    <div className={`${style.bottomButton}`}>
                      <Button
                        className='button_last_box'
                        onClick={handleNext}
                        variant='primary'>
                        next{" "}
                        <img alt='logo' className='App-logo' src={RightArrow} />{" "}
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        center
        closeOnEsc={false}
        closeOnOverlayClick={false}
        onClose={() => {}}
        open={singleCandidate?.loading}
        showCloseIcon={false}>
        <div
          className='optionContainer iconPopups'
          style={{ minWidth: 200, paddingTop: 30 }}>
          <div className='margin_box popup text-center'>
            {/* <img
              src={LoaderGif}
              style={{ margin: "auto", width: 50, height: 50 }}
            /> */}
            {singleCandidate?.loading && (
              <Box className='loader'>
                <CircularProgress />
              </Box>
            )}
          </div>
          <div className='button_box'></div>
        </div>
      </Modal>
    </>
  );
}

export default SelectSinner;
