/* eslint-disable jsx-a11y/alt-text */
import Proptypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { PDFDocument, rgb, StandardFonts } from "pdf-lib";
import { useParams } from "react-router-dom";

import checkedIcon from "../../../assets/sinner/checkedicon.png";
import LoaderGIF from "../../../assets/common/loader150.gif";
import unCheckedIcon from "../../../assets/sinner/unchecked.png";
import { FieldTypes } from "../../../store/constants/constants";
import PopupModal from "../../document_sinner/PopupModal.jsx";
// import { getAxiosWithToken } from '../../services/common.services';
// import { URL_PATH } from '../../services/constants';

import "../../../assets/sinner_css/DocSign.css";

/**
 * this component used to preview pdf for all signers in signing flow
 * @param {*} param0
 * @returns
 */
function DocSigningPreviewDoc({ metadata, title }) {
  const pdfBgRef = useRef();
  const nodeRef = useRef();
  const canvasRef = useRef();
  let countSignedUser = 0;
  const [signedUserCount, setSignedUserCount] = useState(0);
  // const { id } = useParams();
  const defaultDialogue = { message: null, show: false };
  const [showDialogue, setShowDialogue] = useState(defaultDialogue);
  const [arrayBuffer, setArrayBuffer] = useState();
  const [perPageSize, setPerPageSize] = useState();
  const [totalPage, setTotalPage] = useState();
  const [signerInfo, setSignerInfo] = useState([]);
  const [showAlreadySign, setShowAlreadySign] = useState(false);

  useEffect(() => {
    setSignedUserCount(countSignedUser);
  }, [countSignedUser]);

  useEffect(() => {
    if (metadata.blob) {
      setArrayBuffer(
        metadata.blob && metadata.blob.arrayBuffer
          ? metadata.blob.arrayBuffer
          : null
      );
      setPerPageSize(
        metadata.blob.containerSize.height / metadata.blob.pdfImagesList.length
      );
      setTotalPage(metadata.blob.pdfImagesList.length);
    }
    getSignersInfo();
  }, [metadata]);

  const getSignersInfo = async () => {
    const arrayTwoIds = new Set(metadata.signers.map((el) => el.email));
    console.log("arrayTwoIds", arrayTwoIds);
    console.log("metadata.blob.signers", metadata);
    const arrayOneFiltered = metadata.signers.filter((el) => !el.hasSigned);
    for (let i = 0; i < metadata?.signers?.length; i++) {
      console.log("emaill", arrayOneFiltered);
      if (metadata?.signers[i]?.hasSigned) {
        setShowAlreadySign(true);
        for (let j = 0; j < arrayOneFiltered?.length; j++) {
          if (metadata?.signers[i].email === arrayOneFiltered[j].email) {
            arrayOneFiltered.splice(j, 1);
          }
        }
      }
    }
    const uniqueEmails = new Set();

    // Use filter to create a new array without duplicate emails
    const filteredArray = arrayOneFiltered?.filter((obj) => {
      const email = obj.email;

      // If the email is not in the Set, add it and include the object in the filtered array
      if (!uniqueEmails.has(email)) {
        uniqueEmails.add(email);
        return true;
      }

      // If the email is already in the Set, exclude the object from the filtered array
      return false;
    });

    console.log("arrayOneFiltered", filteredArray);

    setSignerInfo(filteredArray);
  };

  function saveByteArray(byte) {
    const blob = new Blob([byte], { type: "application/pdf" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    const fileName = "Document";
    link.download = fileName;
    link.click();
  }

  function dataURLtoFile(dataurl, filename) {
    const raw = atob(dataurl.split(",")[1]);
    const rawLength = raw.length;

    const array = new Uint8Array(new ArrayBuffer(rawLength));
    for (let i = 0; i < rawLength; i += 1) {
      array[i] = raw.charCodeAt(i);
    }

    return array;
  }

  const changeInNumber = (field) => {
    console.log(typeof field?.height);
    if (
      field?.height &&
      typeof field?.height === "string" &&
      field?.height?.includes("px")
    ) {
      return Number(
        field?.height.split("px")[0] || field?.height.split("PX")[0]
      );
    }
    console.log("outside", field?.height);
    return field?.height;
  };

  const handleDownload = async () => {
    const pdfDoc = await PDFDocument.load(arrayBuffer);

    const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);

    const pages = pdfDoc.getPages();
    console.log("metadata.signers", metadata.signers);

    metadata.signers.forEach((element) => {
      element.fields.forEach(async (field) => {
        if (
          field.fieldType === FieldTypes.TEXT_INPUT ||
          field.fieldType === FieldTypes.DATES
        ) {
          console.log("field?.page", field?.page);
          pages[field?.page - 1].drawText(field.textValue, {
            x:
              field.positionFromParentLeft -
              (field.maxWidth - field.pageWidth) / 2,
            y: field.pagePositionY - changeInNumber(field) / 2,
            width: field.width,
            height: changeInNumber(field),
            size: 16,
            font: helveticaFont,
          });
        }

        if (field.fieldType === FieldTypes.CHECKBOX) {
          const form = pdfDoc.getForm();
          const checkBox = form.createCheckBox(
            `some.checkBox.checked${field.id}`
          );
          console.log("width", field.width);

          checkBox.addToPage(pages[field?.page - 1], {
            x:
              field.positionFromParentLeft -
              (field.maxWidth - field.pageWidth) / 2,
            y: field.pagePositionY - changeInNumber(field),
            width: field.width,
            height: changeInNumber(field),
            textColor: rgb(0, 0, 1),
            borderColor: rgb(0, 0, 1),
            borderWidth: 2,
          });
          checkBox.check();
          checkBox.enableReadOnly();
        }

        if (
          field.fieldType === FieldTypes.INITIALS ||
          field.fieldType === FieldTypes.SIGNATURE
        ) {
          console.log("field.base64Image", field.base64Image);
          const data = dataURLtoFile(field.base64Image, "hello.png");
          console.log(data);
          const jpgImage = await pdfDoc.embedPng(data);
          pages[field?.page - 1].drawImage(jpgImage, {
            x:
              field.positionFromParentLeft -
              (field.maxWidth - field.pageWidth) / 2,
            y: field.pagePositionY - changeInNumber(field),
            width: field.width,
            height: changeInNumber(field),
          });
        }
      });
    });

    console.log("pdfDoc", pdfDoc);

    let page = pdfDoc.addPage();
    const { width, height } = page.getSize();
    page.drawText("Signing Documents", {
      x: 210,
      y: height - 4 * 15,
      size: 20,
      font: helveticaFont,
    });

    page.drawText(`Document title: `, {
      x: 50,
      y: height - 9 * 16,
      size: 16,
    });

    page.drawText(`${metadata.blob.title}`, {
      x: 200,
      y: height - 9 * 16,
      size: 16,
      color: rgb(0.45, 0.45, 0.45),
    });

    page.drawText(`Shared By`, {
      x: 50,
      y: height - 11 * 16,
      size: 18,
    });

    page.drawText(`Name`, {
      x: 50,
      y: height - 13 * 16,
      size: 14,
    });

    page.drawText(`${metadata.blob.sender.name}`, {
      x: 200,
      y: height - 13 * 16,
      size: 14,
      color: rgb(0.45, 0.45, 0.45),
    });

    page.drawText(`Email`, {
      x: 50,
      y: height - 14 * 16,
      size: 14,
    });

    page.drawText(`${metadata.blob.sender.email}`, {
      x: 200,
      y: height - 14 * 16,
      size: 14,
      color: rgb(0.45, 0.45, 0.45),
    });

    let signedCodumentHeight = 16;
    let documentHeight;

    if (metadata && metadata.signers && metadata.signers.length) {
      if (showAlreadySign) {
        page.drawText("Already Signed documents", {
          x: 50,
          y: height - 16 * 16,
          size: 18,
        });
      }

      metadata.signers.map((item) => {
        console.log("itemsss", item);
        if (item?.hasSigned) {
          signedCodumentHeight = signedCodumentHeight + 2;

          documentHeight = height - signedCodumentHeight * 16;
          if (documentHeight > height || documentHeight < 0) {
            page = pdfDoc.addPage();
            signedCodumentHeight = 4;
          }

          page.drawText("Name", {
            x: 50,
            y: height - signedCodumentHeight * 16,
            size: 14,
          });
          page.drawText(item.name, {
            x: 200,
            y: height - signedCodumentHeight * 16,
            size: 14,
            color: rgb(0.45, 0.45, 0.45),
          });
          signedCodumentHeight = signedCodumentHeight + 1;

          documentHeight = height - signedCodumentHeight * 16;
          if (documentHeight > height || documentHeight < 0) {
            page = pdfDoc.addPage();
            signedCodumentHeight = 4;
          }

          page.drawText("Email", {
            x: 50,
            y: height - signedCodumentHeight * 16,
            size: 14,
          });
          page.drawText(item.email, {
            x: 200,
            y: height - signedCodumentHeight * 16,
            size: 14,
            color: rgb(0.45, 0.45, 0.45),
          });
        }
      });

      signedCodumentHeight = signedCodumentHeight + 2;

      documentHeight = height - signedCodumentHeight * 16;
      if (documentHeight > height || documentHeight < 0) {
        page = pdfDoc.addPage();
        signedCodumentHeight = 4;
      }
    }
    console.log("signerInfo", signerInfo);
    if (signerInfo && signerInfo.length) {
      page.drawText("Waiting For Signature", {
        x: 50,
        y: height - signedCodumentHeight * 16,
        size: 18,
      });

      signerInfo.map((item, key) => {
        signedCodumentHeight = signedCodumentHeight + 2;

        documentHeight = height - signedCodumentHeight * 16;
        if (documentHeight > height || documentHeight < 0) {
          page = pdfDoc.addPage();
          signedCodumentHeight = 4;
        }

        page.drawText("Name", {
          x: 50,
          y: height - signedCodumentHeight * 16,
          size: 14,
        });
        page.drawText(item.name, {
          x: 200,
          y: height - signedCodumentHeight * 16,
          size: 14,
          color: rgb(0.45, 0.45, 0.45),
        });
        signedCodumentHeight = signedCodumentHeight + 1;

        documentHeight = height - signedCodumentHeight * 16;
        if (documentHeight > height || documentHeight < 0) {
          page = pdfDoc.addPage();
          signedCodumentHeight = 4;
        }

        page.drawText("Email", {
          x: 50,
          y: height - signedCodumentHeight * 16,
          size: 14,
        });
        page.drawText(item.email, {
          x: 200,
          y: height - signedCodumentHeight * 16,
          size: 14,
          color: rgb(0.45, 0.45, 0.45),
        });
      });
    }

    const pdfBytes = await pdfDoc.save();
    saveByteArray(pdfBytes);
  };
  // console.log("metadata.signers", metadata.signers);
  return (
    <>
      <div className="start_area">
        <div className="sticky-bar">
          <div className="row mx-0">
            <div className="col-md-6 mb-md-0 mb-3 pl-0">
              <h5>
                {signedUserCount} out of <i>{metadata.totalSigners}</i> Signers
                has signed
              </h5>
            </div>
            <div className="col-md-6 pr-0">
              <div className="d-flex justify-content-end">
                <button
                  className="nav-link mr-2 cursor-pointer"
                  onClick={handleDownload}
                >
                  Download
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <PopupModal
        closeOnEsc={false}
        closeOnOverlayClick={false}
        minWidth={200}
        onClose={() => {}}
        open={showDialogue.show}
        showCloseIcon={false}
      >
        {showDialogue && showDialogue.message && (
          <>
            <img
              src={LoaderGIF}
              style={{ margin: "auto", width: 50, height: 50 }}
            />
            <div className="text-center mt-1">{showDialogue.message}</div>
          </>
        )}
      </PopupModal>

      <div className="receiver-area" style={{ marginTop: "40px" }}>
        <div
          className="pdfWrapper"
          ref={canvasRef}
          style={{
            //border: "1px solid",
            width: "fit-content",
            margin: "auto",
          }}
        >
          <div
            className="canvas"
            style={{
              position: "relative",
              height: metadata.blob.containerSize.height,
              width: metadata.blob.containerSize.width,
            }}
            // ref={canvasContainerRef}
          >
            <div className="pdfBg" ref={pdfBgRef}>
              {metadata.blob.pdfImagesList.map((m, i) => (
                <img
                  alt="pdf"
                  key={i}
                  ref={nodeRef}
                  src={m.imgBase64}
                  className="pdfImage"
                  style={{
                    pointerEvents: "none",
                    border: "solid 1px",
                  }}
                ></img>
              ))}

              <div
                className="container"
                style={{ padding: 0, background: "#FFF", maxWidth: "100%" }}
              >
                <div
                  style={{
                    border: "1px solid black",
                    borderTop: 0,
                  }}
                >
                  <div className="form_main_box  px-0 py-3">
                    <div className="text-center">
                      <title>
                        {metadata && metadata.name ? metadata.name : null}
                      </title>
                      <label className="form-label file_title text-capitalize">
                        Signing Documents
                      </label>
                      <hr />
                    </div>
                    <div className="p-4">
                      {/* <div className="form-group">
                        <div className="row">
                          <div className="col-9 text-secondary">
                            {" "}
                            {metadata.blob.title}
                          </div>
                        </div>
                      </div> */}
                      <div>
                        <label
                          className="form-label"
                          htmlFor="intendedAudience"
                        >
                          Shared By
                        </label>
                        <div className="mb-3">
                          <div className="row mx-0">
                            <div className="col-3 px-0">Name</div>
                            <div className="col-9 text-secondary ">
                              {metadata.blob && metadata.blob.sender
                                ? metadata.blob.sender.name
                                : null}{" "}
                            </div>
                          </div>

                          <div className="row mx-0">
                            <div className="col-3 px-0">Email</div>
                            <div className="col-9 text-secondary text-break">
                              {metadata.blob && metadata.blob.sender
                                ? metadata.blob.sender.email
                                : null}{" "}
                            </div>
                          </div>
                        </div>
                      </div>

                      {showAlreadySign ? (
                        <>
                          <div>
                            <label
                              className="form-label"
                              htmlFor="intendedAudience"
                            >
                              Already Signed documents
                            </label>
                          </div>

                          {metadata.signers.map((item, key) => {
                            if (item?.hasSigned) {
                              return (
                                <div
                                  className="filesList file_list_main mb-3"
                                  key={key}
                                >
                                  <div className="row mx-0">
                                    <div className="col-3 px-0">Name</div>
                                    <div className="col-9 text-secondary text-break">
                                      {item.name}
                                    </div>
                                    <div className="col-3 px-0">Email</div>
                                    <div className="col-9 text-secondary text-break">
                                      {item.email}
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          })}
                        </>
                      ) : null}

                      {signerInfo && signerInfo.length > 0 ? (
                        <>
                          <div>
                            <label
                              className="form-label"
                              htmlFor="intendedAudience"
                            >
                              Waiting For Signature
                            </label>
                          </div>

                          {signerInfo.map((item, key) => {
                            return (
                              <div key={key}>
                                <div className="filesList file_list_main mb-3">
                                  <div className="row mx-0">
                                    <div className="col-3 px-0">Name</div>
                                    <div className="col-9 text-secondary text-break">
                                      {item.name}
                                    </div>
                                    <div className="col-3 px-0">Email</div>
                                    <div className="col-9 text-secondary text-break">
                                      {item.email}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {console.log("metadata.signers", metadata.signers)}

            {metadata.signers.map((signer) => {
              if (signer.hasSigned) {
                countSignedUser++;
                return signer.fields.map((field, i) => {
                  switch (field.fieldType) {
                    case FieldTypes.TEXT_INPUT:
                      if (!field.textValue) {
                        return;
                      }

                      return (
                        <div
                          key={field.id}
                          style={{
                            position: "absolute",
                            left: field.positionFromParentLeft,
                            top: field.positionFromParentTop + field.page,
                            height: field.height,
                            width: field.width,
                            fontFamily: field.fontFamily,
                            fontSize: field.fontSize,
                            background: "#8080804f",
                          }}
                        >
                          {field.textValue}
                        </div>
                      );

                    case FieldTypes.CHECKBOX:
                      console.log("field.isChecked", field.isChecked);
                      return (
                        <img
                          key={field.id}
                          src={field.isChecked ? checkedIcon : unCheckedIcon}
                          style={{
                            position: "absolute",
                            left: field.positionFromParentLeft,
                            top: field.positionFromParentTop + field.page,
                            height: field.height,
                            width: field.width,
                            fontFamily: field.fontFamily,
                            objectFit: "contain",
                          }}
                        />
                      );
                    case FieldTypes.INITIALS:
                      if (
                        field.base64Image === null ||
                        field.base64Image === ""
                      ) {
                        return;
                      }
                      return (
                        <img
                          key={field.id}
                          src={field.base64Image}
                          style={{
                            position: "absolute",
                            left: field.positionFromParentLeft,
                            top: field.positionFromParentTop + field.page,
                            height: field.height,
                            width: field.width,
                            fontFamily: field.fontFamily,
                            objectFit: "contain",
                          }}
                        />
                      );
                    case FieldTypes.DATES:
                      if (!field.textValue) {
                        return;
                      }
                      return (
                        <div
                          key={field.id}
                          style={{
                            position: "absolute",
                            left: field.positionFromParentLeft,
                            top: field.positionFromParentTop + field.page,
                            height: field.height,
                            width: field.width,
                            fontFamily: field.fontFamily,
                          }}
                        >
                          {field.textValue}
                        </div>
                      );
                    case FieldTypes.SIGNATURE:
                      if (
                        field.base64Image === null ||
                        field.base64Image === ""
                      ) {
                        return;
                      }
                      return (
                        <img
                          key={field.id}
                          src={field.base64Image}
                          style={{
                            position: "absolute",
                            left: field.positionFromParentLeft,
                            top: field.positionFromParentTop + field.page,
                            height: field.height,
                            width: field.width,
                            fontFamily: field.fontFamily,
                            objectFit: "contain",
                          }}
                        />
                      );
                    default:
                      break;
                  }
                });
              }
            })}
          </div>
        </div>
      </div>
    </>
  );
}
DocSigningPreviewDoc.protoTypes = {
  metadata: Proptypes.any,
};
export default DocSigningPreviewDoc;
