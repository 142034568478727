import React, { useRef, useState } from "react";
import { Box } from "@mui/system";
import Actions from "./Actions";
import style from "./style.module.scss";
import { Rating } from "@mui/material";
import { TbStar, TbStarFilled } from "react-icons/tb";
import CommentModel from "../Model/CommentModel";
import useDragField from "../../../../customHook/useDragField";
function StarRating({
  id,
  main_id,
  label,
  type,
  name,
  selectedvalue,
  two_col,
  col_name,
  value,
  index,
  moveCard,
}) {
  const [rate, setRate] = useState(selectedvalue);
  const [rate2, setRate2] = useState(0);
  const [editCommentmodel, setCommentOpenmodel] = useState(false);
  const [ratingcomment, setRateComment] = useState(null);
  const ref = useRef(null);
  const dragField = useDragField(ref, index, moveCard, id);
  const opacity = dragField.opacity;
  return (
    <Box
      ref={ref}
      style={{ ...style, opacity }}
      data-handler-id={dragField?.handlerId}
      className={`${style.inputBox} dropItem`}
    >
      <Actions
        id={id}
        type={type}
        two_col={two_col}
        col_name={col_name}
        main_id={main_id}
      />
      <Box className={style.ratingBox}>
        <label>{label}</label>
        {[...Array(5)].map((item, index) => {
          const givenRating = index;
          return (
            <>
              {" "}
              <label>
                <input
                  type="checkbox"
                  value={rate + 1}
                  name={name}
                  onClick={() => {
                    setRate(index);
                    setRate2(index + 1);
                    if (index + 1 <= 3) {
                      setCommentOpenmodel(true);
                    } else {
                      setCommentOpenmodel(false);
                    }

                    // alert(`Are you sure you want to give ${givenRating} stars ?`);
                  }}
                />
                {index < rate || index === rate ? (
                  <TbStarFilled color="#faaf00" fontSize="22" />
                ) : (
                  <TbStar color="#00000042" fontSize="22" />
                )}
              </label>
            </>
          );
        })}
      </Box>
      {ratingcomment ? (
        <Box className={style.ratingBox}>
          <label>Comment</label>
          <label>{ratingcomment}</label>{" "}
        </Box>
      ) : value === null ||
        value === "" ||
        value === undefined ||
        value === "undefinedrating" ? (
        ""
      ) : (
        <Box className={style.ratingBox}>
          <label>Comment</label>
          <label>{value}</label>{" "}
        </Box>
      )}

      <input type="hidden" value={ratingcomment} name={name + "rating"} />
      <CommentModel
        openModal={editCommentmodel}
        setOpenModal={setCommentOpenmodel}
        type={type}
        id={id}
        main_id={main_id}
        two_col={two_col}
        col_name={col_name}
        name={name}
        ratingcomment={ratingcomment}
        setRateComment={setRateComment}
      />
    </Box>
  );
}

export default StarRating;
