import { combineReducers } from "redux";
import { alertReducers } from "./alertReducers";
import { authReducers } from "./authReducers";
import { companyReducers } from "./companyReducers";
import { industryReducers } from "./industryReducers";
import { pricingReducers } from "./pricingReducers";
import { applyJobReducers } from "./applyJobReducers";
import { recruiterCandidateReducers } from "./recruiterCandidateReducers";
import { recruiterJobsReducers } from "./recruiterJobsReducers";
import { recruiterMembersReducers } from "./recruiterMembersReducers";
import { singleCandidateScreenReducer } from "./singleCandidateScreenReducer";
import { Rprofile } from "./profileReducers";
import { LOGOUT_USER } from "../constants/constants";
import { ordersReducer } from "./ordersReducers";
import { homeJobReducers } from "./homeJobReducers";
import { homeBlogReducers } from "./homeBlogReducers";
import { getcheckboxReducers } from "./getcheckboxReducers";
import { getcheckboxlistReducers } from "./getcheckboxlistReducers";
import { getlabelkeyReducers } from "./getlabelkeyReducers";
import { getlabelvalueReducers } from "./getlabelvalueReducers";
import { getcomponentidReducers } from "./getcomponentidReducers";
import { testReducer } from "./testReducer";
import { getFormStructureReducers } from "./getFormStructureReducers";
import { formFieldReducers } from "./formFieldReducers";
import { getColFormStructureReducers } from "./getColFormStructureReducers";
import { uploadFileBuilderReducers } from "./uploadFileBuilderReducers";
import { uploadSignBuilderReducers } from "./uploadSignBuilderReducers";
import { uploadFormbuilderimageReducers } from "./uploadFormbuilderimageReducers";
import { LicenseOrdersReducer } from "./licenseOrdersReducers";
import pdfUploadReducer from "./pdfupload.reducers";
import docPublishingReducer from "./docPublishing.reducers";
import signingMetadataReducer from "./signingMetadata.reducers";
import receiverMetadataReducer from "./receiverMetadata.reducer";
import subScriptionReducer from "./EdocSubscription";

const reducerss = combineReducers({
  alert: alertReducers,
  auth: authReducers,
  industry: industryReducers,
  member: recruiterMembersReducers,
  company: companyReducers,
  rjobs: recruiterJobsReducers,
  rcandidate: recruiterCandidateReducers,
  singleCandidate: singleCandidateScreenReducer,
  pricing: pricingReducers,
  applyJob: applyJobReducers,
  rprofile: Rprofile,
  rorders: ordersReducer,
  licenseOrders: LicenseOrdersReducer,
  hjob: homeJobReducers,
  hblog: homeBlogReducers,
  getcheckbox: getcheckboxReducers,
  getcheckboxlist: getcheckboxlistReducers,
  getlabelkey: getlabelkeyReducers,
  getlabelvalue: getlabelvalueReducers,
  getcomponentid: getcomponentidReducers,
  test: testReducer,
  getpreviewform: getFormStructureReducers,
  formFields: formFieldReducers,
  getColFormStructre: getColFormStructureReducers,
  getfileformbuilder: uploadFileBuilderReducers,
  getsignformbuilder: uploadSignBuilderReducers,
  getformbuilderimage: uploadFormbuilderimageReducers,
  pdfUploadReducer,
  docPublishingReducer,
  signingMetadataReducer,
  receiverMetadataReducer,
  eDocSubscriptionData: subScriptionReducer,
});

export const rootReducer = (state, action) => {
  if (action.type === LOGOUT_USER) {
    // console.log("in");
    state = undefined;
  }

  return reducerss(state, action);
};

export default reducerss;
