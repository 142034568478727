import html2canvas from "html2canvas";
import React, { useCallback, useEffect, useRef, useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import { Tab, Tabs } from "@mui/material";

import "../../assets/sinner_css/ReceiverSignature.css";
import CLear from "../../assets/sinner/clear.png";
import { toBase64 } from "../../utils/helpers";
import PopupModal from "./PopupModal.jsx";
import { TabPanel, a11yProps } from "../common/mui-tabs/index.js";

// const ReactSketchCanvasStyle = {
//   border: '0.0625rem solid #9c9c9c',
//   borderRadius: '0.25rem',
// };

const fontFamily = [
  "'Montserrat', sans-serif",
  "'My Soul', cursive",
  "'Roboto Condensed', sans-serif",
  "'Square Peg', cursive",
];

/****
 * it is signatures popup model, popup to take signatures
 */

function SignaturePopupModel({
  openSignatureBox,
  setOpenSignatureBox,
  field,
  onSignatureDone,
}) {
  const drwRef = useRef();
  const signTextInputRef = useRef();

  const [activeSignWindowVariable, setActiveSignWindowVariable] = useState({
    inputField: field,
    signingType: "signature",
  });
  const [penColor, setPenColor] = useState("black");
  const [value, setValue] = useState(0);

  const [base64String, setBase64String] = useState("");

  const [signText, setSignText] = useState("");

  const [font, setFont] = useState(fontFamily[0]);

  const [signatureError, setSignatureError] = useState(false);
  const [writeError, setWriteError] = useState(false);
  const [uploadError, setUploadError] = useState(false);

  const checkInsertDisable = useCallback(() => {
    if (activeSignWindowVariable.signingType === "write" && signText === "") {
      setSignatureError(false);
      setUploadError(false);
      return;
    }
    if (
      activeSignWindowVariable.signingType === "upload" &&
      base64String === ""
    ) {
      setWriteError(false);
      setSignatureError(false);
      return;
    }
    if (
      activeSignWindowVariable.signingType === "signature" &&
      drwRef?.current?.isEmpty()
    ) {
      setWriteError(false);
      setUploadError(false);
    }
  }, [activeSignWindowVariable.signingType, base64String, signText]);

  const signInTabClicked = useCallback(
    (tab) => {
      console.log(tab);
      checkInsertDisable();
      setActiveSignWindowVariable({
        inputField: activeSignWindowVariable.inputField,
        signingType: tab,
      });
    },
    [activeSignWindowVariable.inputField, checkInsertDisable]
  );
  // const checkInsertDisable = () => {
  //   if (activeSignWindowVariable.signingType === "write" && signText === "") {
  //     setSignatureError(false);
  //     setUploadError(false);
  //     return;
  //   }
  //   if (
  //     activeSignWindowVariable.signingType === "upload" &&
  //     base64String === ""
  //   ) {
  //     setWriteError(false);
  //     setSignatureError(false);
  //     return;
  //   }
  //   if (
  //     activeSignWindowVariable.signingType === "signature" &&
  //     drwRef?.current?.isEmpty()
  //   ) {
  //     setWriteError(false);
  //     setUploadError(false);
  //   }
  // };

  useEffect(() => {
    checkInsertDisable();
  }, [base64String, checkInsertDisable]);

  const penColorChanged = (color) => {
    setPenColor(color);
  };

  const handleDrawCanvasClear = () => {
    drwRef.current.clear();
  };

  useEffect(() => {
    setActiveSignWindowVariable({
      inputField: field,
      signingType: "signature",
    });
    setBase64String("");
    setSignText("");
  }, [field, openSignatureBox]);

  useEffect(() => {
    checkInsertDisable();
  }, [
    drwRef,
    openSignatureBox,
    activeSignWindowVariable,
    signText,
    checkInsertDisable,
  ]);

  const handleInsert = async () => {
    if (activeSignWindowVariable.signingType === "write" && signText === "") {
      setWriteError(true);
      return;
    }
    if (
      activeSignWindowVariable.signingType === "upload" &&
      base64String === ""
    ) {
      setUploadError(true);
      return;
    }
    if (
      activeSignWindowVariable.signingType === "signature" &&
      drwRef?.current?.isEmpty()
    ) {
      setSignatureError(true);
      return;
    }
    if (
      activeSignWindowVariable.signingType === "signature" &&
      !drwRef?.current?.isEmpty()
    ) {
      setSignatureError(false);
    }

    switch (activeSignWindowVariable.signingType) {
      case "signature":
        const img = await drwRef.current.toDataURL();
        onSignatureDone(field, img);
        break;
      case "write":
        const element = signTextInputRef?.current;
        const canvas = await html2canvas(element, {
          backgroundColor: null,
        });
        console.log(signTextInputRef?.current);
        const data = canvas.toDataURL("image/jpg");
        onSignatureDone(field, data);
        break;
      case "upload":
        if (base64String !== "") {
          onSignatureDone(field, base64String);
        }
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    if (!openSignatureBox) {
      signInTabClicked("signature");
      setValue(0);
    }
  }, [openSignatureBox, signInTabClicked]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    switch (newValue) {
      case 0:
        signInTabClicked("signature");

        break;
      case 1:
        signInTabClicked("upload");
        break;
      case 2:
        signInTabClicked("write");

        break;
      default:
        signInTabClicked("signature");
        break;
    }
  };
  return (
    <PopupModal open={openSignatureBox} showCloseIcon={false}>
      <div className='popupBoxOutter'>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label='basic tabs example'>
          <Tab label='Signature' {...a11yProps(0)} />
          {/* <Tab label='Write' {...a11yProps(1)} /> */}
          {/* <Tab label='Upload' {...a11yProps(1)} /> */}
        </Tabs>
        {/* <ul className='nav nav-tabs' id='myTab' role='tablist'>
          <li className='nav-item '>
            <a
              aria-controls='home'
              aria-selected='true'
              className='nav-link active px-2 py-2'
              data-toggle='tab'
              href='#home'
              id='home-tab'
              onClick={() => signInTabClicked("signature")}
              role='tab'>
              Signature
            </a>
          </li>
          <li className='nav-item'>
            <a
              aria-controls='profile'
              aria-selected='false'
              className='nav-link px-2 py-2'
              data-toggle='tab'
              href='#profile'
              id='profile-tab'
              onClick={() => signInTabClicked("write")}
              role='tab'>
              Write
            </a>
          </li>
          <li className='nav-item'>
            <a
              aria-controls='contact'
              aria-selected='false'
              className='nav-link px-2 py-2'
              data-toggle='tab'
              href='#contact'
              id='contact-tab'
              onClick={() => signInTabClicked("upload")}
              role='tab'>
              Upload
            </a>
          </li>
        </ul> */}
        <div className='tab-content' id='myTabContent'>
          <TabPanel value={value} index={0}>
            <div style={{ minHeight: 300 }}>
              <div className='menuSection'>
                <div className='colorSection'>
                  <div className='popuprowleft'>
                    <div className='name'>Choose Colour</div>
                    <div className='colors'>
                      <div
                        className='black pikcolor'
                        onClick={() => penColorChanged("black")}></div>
                      <div
                        className='blue pikcolor'
                        onClick={() => penColorChanged("blue")}></div>
                      <div
                        className='green pikcolor'
                        onClick={() => penColorChanged("green")}></div>
                      <div
                        className='red pikcolor'
                        onClick={() => penColorChanged("red")}></div>
                    </div>
                  </div>
                  <div className='popuprowright'>
                    <div className='clearCanvas'>
                      <ul>
                        <li>
                          <button
                            className='btn btn-white btn-sm'
                            onClick={handleDrawCanvasClear}>
                            <img alt='logo' className='App-logo' src={CLear} />
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className='drawSection'
                style={{
                  border: "1px dotted black",
                  width: "max-content",
                  margin: "auto",
                }}>
                <SignatureCanvas
                  canvasProps={{
                    width: field.width,
                    height: field.height,
                    className: "sigCanvas",
                  }}
                  penColor={penColor}
                  ref={drwRef}
                />
              </div>
              {signatureError ? (
                <div className='mt-2 text-center' style={{ color: "red" }}>
                  Please enter signature
                </div>
              ) : null}
            </div>
          </TabPanel>
          {/* <TabPanel value={value} index={1}>
            <div style={{ minHeight: 300 }}>
              <div className='menuSection'>
                <div className='colorSection'>
                  <div className='popuprowleft'>
                    <div className='name'>Choose Colour</div>
                    <div className='colors'>
                      <div
                        className='black pikcolor'
                        onClick={() => penColorChanged("black")}></div>
                      <div
                        className='blue pikcolor'
                        onClick={() => penColorChanged("blue")}></div>
                      <div
                        className='green pikcolor'
                        onClick={() => penColorChanged("green")}></div>
                      <div
                        className='red pikcolor'
                        onClick={() => penColorChanged("red")}></div>
                    </div>
                  </div>
                  <div className='popuprowright'></div>

                  <div className='fontStyleSection' style={{ marginLeft: 10 }}>
                    <select
                      id='cars'
                      name='signFontStyle'
                      onChange={(e) => setFont(fontFamily[e.target.value])}>
                      <option value='0'>Montserrat</option>
                      <option value='1'>My Soul</option>
                      <option value='2'>Roboto</option>
                      <option value='3'>Square</option>
                    </select>
                  </div>
                </div>

                <hr />
                <div
                  className=''
                  style={{
                    border: "1px dotted black",
                    width: "fit-content",
                    margin: "auto",
                    height: field.height,
                  }}>
                  <p
                    id='signTextInput'
                    ref={signTextInputRef}
                    style={{
                      fontSize: field.fontSize,
                      fontFamily: font,
                      height: field.height,
                      width: field.width,
                      textAlign: "center",
                      color: penColor,
                      overflow: "hidden",
                      backgroundColor: "transparent",
                      display: "flex",
                      alignItems: "center",
                    }}>
                    {signText}
                  </p>
                </div>
                <div className='inputSignContainer'>
                  <input
                    name='input'
                    onChange={(e) => {
                      setSignText(e.target.value);
                    }}
                    placeholder='Write something'
                    style={{ width: "100%" }}
                    type='text'
                  />
                </div>
                {writeError ? (
                  <div className='mt-2' style={{ color: "red" }}>
                    Please enter signature
                  </div>
                ) : null}
              </div>
            </div>
          </TabPanel> */}
          {/* <TabPanel value={value} index={1}>
            <div style={{ minHeight: 300 }}>
              <form>
                <div className='form-group'>
                  <label htmlFor='exampleFormControlFile1'>
                    Upload Your signature
                  </label>
                  <input
                    accept='image/png,image/jpeg'
                    className='form-control-file'
                    id='exampleFormControlFile1'
                    onChange={async (e) => {
                      const xx = await toBase64(e.target.files[0]);
                      setBase64String(xx);
                    }}
                    type='file'
                  />
                </div>
              </form>
              {uploadError ? (
                <div className='mt-2' style={{ color: "red" }}>
                  Please Upload signature
                </div>
              ) : null}
            </div>
          </TabPanel> */}
          <div className='modal-footer'>
            <button
              className='btn btn-secondary'
              data-mdb-dismiss='modal'
              onClick={() => {
                setOpenSignatureBox(false);
              }}
              type='button'>
              Close
            </button>
            <button
              className='btn btn-secondary bg-primary'
              onClick={handleInsert}
              type='button'>
              Insert
            </button>
          </div>
        </div>
      </div>
    </PopupModal>
  );
}

export default SignaturePopupModel;
