import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { Box, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { MdOutlineClose } from "react-icons/md";
import { deleteImage } from "../../../store/actions/companyActions";
import { ShowAlert } from "../../../store/actions/alertActions";

const ImageUploader = ({
  label,
  onChange,
  valuenew,
  name,
  value,
  setEdit,
  edit,
  disabled,
  props,
  accept,
  isCompanyLogo,
  i,
  seti,
}) => {
  const dispatch = useDispatch();
  const [image, showImage] = useState(false);
  const [imgSrc, setImgSrc] = useState({});
  const auth = useSelector((state) => state?.auth);
  const company = useSelector((state) => state?.company);

  useEffect(() => {
    if (company?.updatedCompany?.success === false) {
      showImage(false);
    }
  }, [company?.updatedCompany]);

  if (accept === ".pdf, .docx" && i === 0 && valuenew !== null) {
    if (["image/jpeg", "image/png", "image/svg+xml"].includes(valuenew.type)) {
      dispatch(ShowAlert("Upload pdf or docx type file.", "error"));
      seti(1);
    }
  }

  useEffect(() => {
    if (edit) {
      auth?.results?.company_detail?.logo && showImage(true);
    }
  }, [edit]);
  useEffect(() => {
    if (!edit && valuenew) {
      showImage(true);
      setImgSrc(URL?.createObjectURL(valuenew));
    }
  }, [valuenew]);

  // console.log("valuenew", valuenew);
  // console.log("auth", auth);

  return (
    <Box className={styles.imageUploaderWrapper}>
      <label>{label}</label>
      <Box className={styles.imageUploader}>
        <>
          {image ? (
            <>
              <Box className={styles?.crossImageBox}>
                {accept === "image/*" ? (
                  <>
                    <img
                      src={
                        edit
                          ? `${process.env.REACT_APP_URL}${valuenew}`
                          : imgSrc
                      }
                      style={{
                        maxWidth: "100%",
                        maxHeight: "120px",
                        objectFit: "contain",
                      }}
                    />
                    <MdOutlineClose
                      className={styles.cross}
                      onClick={() => {
                        // if (auth?.results?.workspace_type !== "recruitment") {
                        //   return;
                        // }
                        // if (auth?.results?.company_detail?.logo) {
                        //   dispatch(
                        //     deleteImage(auth?.results?.company_detail?.uuid)
                        //   ).then(() => {
                        //     showImage(false);
                        //   });
                        // }
                        showImage(false);
                      }}
                    />
                  </>
                ) : ["image/jpeg", "image/png", "image/svg+xml"].includes(
                    valuenew.type
                  ) ? (
                  <>
                    {" "}
                    <label htmlFor="file-input">
                      <Box className={styles.imageBox}>
                        {/* <Typography>Click to upload an image</Typography> */}
                      </Box>
                      <input
                        // accept="image/*"
                        accept={accept || "image/*"}
                        id="file-input"
                        disabled={disabled}
                        type="file"
                        value={value}
                        onChange={onChange}
                        name={name}
                      />
                    </label>
                    {accept === "image/*" ? (
                      !isCompanyLogo && (
                        <Typography>
                          PNG or JPG file of 1500 x 500 pixels.
                        </Typography>
                      )
                    ) : (
                      <Typography>Upload PDF or DOC file.</Typography>
                    )}
                  </>
                ) : (
                  <>
                    <p>{valuenew.name}</p>
                    <MdOutlineClose
                      className={styles.cross}
                      onClick={() => {
                        // if (auth?.results?.workspace_type !== "recruitment") {
                        //   return;
                        // }
                        // if (auth?.results?.company_detail?.logo) {
                        //   dispatch(
                        //     deleteImage(auth?.results?.company_detail?.uuid)
                        //   ).then(() => {
                        //     showImage(false);
                        //   });
                        // }
                        showImage(false);
                      }}
                    />
                  </>
                )}
                {/* <img
                  src={edit ? `${process.env.REACT_APP_URL}${valuenew}` : image}
                  style={{
                    maxWidth: "100%",
                    maxHeight: "120px",
                    objectFit: "contain",
                  }}
                /> */}
              </Box>
            </>
          ) : (
            <>
              {" "}
              <label htmlFor="file-input">
                <Box className={styles.imageBox}>
                  {/* <Typography>Click to upload an image</Typography> */}
                </Box>
                <input
                  // accept="image/*"
                  accept={accept || "image/*"}
                  id="file-input"
                  disabled={disabled}
                  type="file"
                  value={value}
                  onChange={onChange}
                  name={name}
                />
              </label>
              {accept === "image/*" ? (
                !isCompanyLogo && (
                  <Typography>PNG or JPG file of 1500 x 500 pixels.</Typography>
                )
              ) : (
                <Typography>Upload PDF or DOC file.</Typography>
              )}
            </>
          )}
        </>
      </Box>
    </Box>
  );
};

export default ImageUploader;
